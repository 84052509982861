/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    LinkedCustomizationDTOCustomizationValue,
    LinkedCustomizationDTOCustomizationValueFromJSON,
    LinkedCustomizationDTOCustomizationValueFromJSONTyped,
    LinkedCustomizationDTOCustomizationValueToJSON,
} from './';

/**
 * 
 * @export
 * @interface LinkedCustomizationDTOCustomization
 */
export interface LinkedCustomizationDTOCustomization {
    /**
     * 
     * @type {string}
     * @memberof LinkedCustomizationDTOCustomization
     */
    key: string;
    /**
     * 
     * @type {LinkedCustomizationDTOCustomizationValue}
     * @memberof LinkedCustomizationDTOCustomization
     */
    value?: LinkedCustomizationDTOCustomizationValue;
}

export function LinkedCustomizationDTOCustomizationFromJSON(json: any): LinkedCustomizationDTOCustomization {
    return LinkedCustomizationDTOCustomizationFromJSONTyped(json, false);
}

export function LinkedCustomizationDTOCustomizationFromJSONTyped(json: any, ignoreDiscriminator: boolean): LinkedCustomizationDTOCustomization {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'key': json['key'],
        'value': !exists(json, 'value') ? undefined : LinkedCustomizationDTOCustomizationValueFromJSON(json['value']),
    };
}

export function LinkedCustomizationDTOCustomizationToJSON(value?: LinkedCustomizationDTOCustomization | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'key': value.key,
        'value': LinkedCustomizationDTOCustomizationValueToJSON(value.value),
    };
}


