import { SelectOption } from "@finder/ui-kit/build/types/components/forms/multiSelect/MultiSelect"
import { OrderTypeOptionItemDTOExteriorColor, OrderTypeOptionItemDTOPaintToSampleExteriorColor, OrderTypeOptionTypeDTO } from "../../../../generated/pdsapi"

export type OptionType = OrderTypeOptionItemDTOPaintToSampleExteriorColor["type"] | OrderTypeOptionItemDTOExteriorColor["type"]

export const optionTypes: Record<OptionType, string> = {
    ExteriorColor: "Exterior Color",
    PaintToSampleExteriorColor: "Paint To Sample Exterior Color",
}

export const optionTypeOptions: SelectOption[] = Object.entries(optionTypes).map(([value, label]) => ({
    value,
    label,
}))

export const optionTypeToEnum = (type: OptionType): OrderTypeOptionTypeDTO => {
    switch (type) {
        case "ExteriorColor":
            return OrderTypeOptionTypeDTO.EXTERIOR_COLOR
        case "PaintToSampleExteriorColor":
            return OrderTypeOptionTypeDTO.PAINT_TO_SAMPLE_EXTERIOR_COLOR
    }
}

export const enumToOptionType = (orderTypeOptionTypeDTO: OrderTypeOptionTypeDTO): OptionType => {
    switch (orderTypeOptionTypeDTO) {
        case OrderTypeOptionTypeDTO.EXTERIOR_COLOR:
            return "ExteriorColor"
        case OrderTypeOptionTypeDTO.PAINT_TO_SAMPLE_EXTERIOR_COLOR:
            return "PaintToSampleExteriorColor"
    }
}
