/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SelectOptionDTONumberOfSeats,
    SelectOptionDTONumberOfSeatsFromJSON,
    SelectOptionDTONumberOfSeatsFromJSONTyped,
    SelectOptionDTONumberOfSeatsToJSON,
} from './';

/**
 * 
 * @export
 * @interface OptionalSelectDTONumberOfSeats
 */
export interface OptionalSelectDTONumberOfSeats {
    /**
     * 
     * @type {SelectOptionDTONumberOfSeats}
     * @memberof OptionalSelectDTONumberOfSeats
     */
    value?: SelectOptionDTONumberOfSeats;
    /**
     * 
     * @type {Array<SelectOptionDTONumberOfSeats>}
     * @memberof OptionalSelectDTONumberOfSeats
     */
    options: Array<SelectOptionDTONumberOfSeats>;
}

export function OptionalSelectDTONumberOfSeatsFromJSON(json: any): OptionalSelectDTONumberOfSeats {
    return OptionalSelectDTONumberOfSeatsFromJSONTyped(json, false);
}

export function OptionalSelectDTONumberOfSeatsFromJSONTyped(json: any, ignoreDiscriminator: boolean): OptionalSelectDTONumberOfSeats {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'value': !exists(json, 'value') ? undefined : SelectOptionDTONumberOfSeatsFromJSON(json['value']),
        'options': ((json['options'] as Array<any>).map(SelectOptionDTONumberOfSeatsFromJSON)),
    };
}

export function OptionalSelectDTONumberOfSeatsToJSON(value?: OptionalSelectDTONumberOfSeats | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'value': SelectOptionDTONumberOfSeatsToJSON(value.value),
        'options': ((value.options as Array<any>).map(SelectOptionDTONumberOfSeatsToJSON)),
    };
}


