import { TableFilterProps } from "../../../../components/filteredpagedtable/FilteredPagedTable"
import { toGroupedOption } from "../../../../components/formelements/groupedselecttextformelement/GroupedSelectOption"
import { toOption } from "../../../../components/formelements/selecttextformelement/Option"
import { ColumnGroupsWrapper } from "../../../../components/layouts/columngroupswrapper/ColumnGroupsWrapper"
import { ColumnGroupWrapper } from "../../../../components/layouts/columngroupwrapper/ColumnGroupWrapper"
import { EquipmentFilterOptionsDTO, VehicleEquipmentCategory } from "../../../../generated/pdsapi"
import { EquipmentFilter } from "../../../../pdsapi"
import { MultiSelect } from "@finder/ui-kit"
import { FunctionComponent } from "react"

export const EquipmentCatalogueFilter: FunctionComponent<TableFilterProps<EquipmentFilter, EquipmentFilterOptionsDTO>> = ({
    filterOptions,
    filter,
    setFilter,
}) => {
    const modelGenerationFilter = (
        <MultiSelect
            label={"Model Generations"}
            placeholder={"All"}
            clearable={true}
            searchable={true}
            options={filterOptions?.modelGenerations.map(toOption)}
            value={filter.modelGenerationKeys ?? []}
            onChange={(modelGenerationKeys) =>
                setFilter((filter) => ({
                    ...filter,
                    modelGenerationKeys: modelGenerationKeys.length === 0 ? undefined : modelGenerationKeys,
                    orderTypeKeys: undefined,
                }))
            }
        />
    )

    const modelYearFilter = (
        <MultiSelect
            label={"Model Years"}
            placeholder={"All"}
            clearable={true}
            searchable={true}
            options={filterOptions?.modelYears.map((modelYearOption) => ({
                value: modelYearOption.key.toString(),
                label: modelYearOption.label,
            }))}
            value={filter.modelYears?.map((modelYear) => modelYear.toString()) ?? []}
            onChange={(modelYears) =>
                setFilter((filter) => ({
                    ...filter,
                    modelYears: modelYears.length === 0 ? undefined : modelYears.map((my) => +my),
                    orderTypeKeys: undefined,
                }))
            }
        />
    )

    const relevantOrderTypes =
        filter.modelGenerationKeys || filter.modelYears
            ? filterOptions?.modelYearAndGenerationRelatedOrderTypes
                  .map((modelYearGroup) => ({
                      ...modelYearGroup,
                      options: modelYearGroup.options.filter((relatedOption) => filter.modelGenerationKeys?.includes(relatedOption.relatedKey) ?? true),
                  }))
                  .filter((modelYearGroup) => modelYearGroup.options.length && (filter.modelYears?.includes(modelYearGroup.relatedKey) ?? true))
            : filterOptions?.modelYearAndGenerationRelatedOrderTypes
    const orderTypeFilter = (
        <MultiSelect
            label={"Order Types"}
            placeholder={"All"}
            clearable={true}
            searchable={true}
            options={relevantOrderTypes?.map(toGroupedOption)}
            value={filter.orderTypeKeys ?? []}
            onChange={(orderTypeKeys) =>
                setFilter((filter) => ({
                    ...filter,
                    orderTypeKeys: orderTypeKeys.length === 0 ? undefined : orderTypeKeys,
                }))
            }
        />
    )

    const categoryFilter = (
        <MultiSelect
            label={"Categories"}
            placeholder={"All"}
            clearable={true}
            searchable={true}
            options={filterOptions?.categories.map(toOption)}
            value={filter.categories ?? []}
            onChange={(categories) =>
                setFilter((filter) => ({
                    ...filter,
                    categories: categories.length === 0 ? undefined : (categories as VehicleEquipmentCategory[]),
                }))
            }
        />
    )

    const tagFilter = (
        <MultiSelect
            label={"Tags"}
            placeholder={"All"}
            clearable={true}
            searchable={true}
            options={filterOptions?.tags.map(toGroupedOption)}
            value={filter.tagKeys ?? []}
            onChange={(tagKeys) =>
                setFilter((filter) => ({
                    ...filter,
                    tagKeys: tagKeys.length === 0 ? undefined : tagKeys,
                }))
            }
        />
    )

    const familyFilter = (
        <MultiSelect
            label={"Families"}
            placeholder={"All"}
            clearable={true}
            searchable={true}
            options={filterOptions?.families.map((family) => ({ label: family, value: family }))}
            value={filter.families ?? []}
            onChange={(families) =>
                setFilter((filter) => ({
                    ...filter,
                    families: families.length === 0 ? undefined : families,
                }))
            }
        />
    )

    return (
        <ColumnGroupsWrapper>
            <ColumnGroupWrapper label={"Model"}>
                {modelGenerationFilter}
                {modelYearFilter}
                {orderTypeFilter}
            </ColumnGroupWrapper>
            <ColumnGroupWrapper label={"Option"}>
                {categoryFilter}
                {tagFilter}
                {familyFilter}
            </ColumnGroupWrapper>
        </ColumnGroupsWrapper>
    )
}
