/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    OptionUpdateDTOExteriorColor,
    OptionUpdateDTOPaintToSampleExteriorColor,
    OptionUpdateDTOExteriorColorFromJSONTyped,
    OptionUpdateDTOExteriorColorToJSON,
    OptionUpdateDTOPaintToSampleExteriorColorFromJSONTyped,
    OptionUpdateDTOPaintToSampleExteriorColorToJSON,
} from './';

/**
 * @type OptionUpdateDTOOption
 * 
 * @export
 */
export type OptionUpdateDTOOption = { type: 'ExteriorColor' } & OptionUpdateDTOExteriorColor | { type: 'PaintToSampleExteriorColor' } & OptionUpdateDTOPaintToSampleExteriorColor;

export function OptionUpdateDTOOptionFromJSON(json: any): OptionUpdateDTOOption {
    return OptionUpdateDTOOptionFromJSONTyped(json, false);
}

export function OptionUpdateDTOOptionFromJSONTyped(json: any, ignoreDiscriminator: boolean): OptionUpdateDTOOption {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    switch (json['type']) {
        case 'ExteriorColor':
            return {...OptionUpdateDTOExteriorColorFromJSONTyped(json, true), type: 'ExteriorColor'};
        case 'PaintToSampleExteriorColor':
            return {...OptionUpdateDTOPaintToSampleExteriorColorFromJSONTyped(json, true), type: 'PaintToSampleExteriorColor'};
        default:
            throw new Error(`No variant of OptionUpdateDTOOption exists with 'type=${json['type']}'`);
    }
}

export function OptionUpdateDTOOptionToJSON(value?: OptionUpdateDTOOption | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    switch (value['type']) {
        case 'ExteriorColor':
            return OptionUpdateDTOExteriorColorToJSON(value);
        case 'PaintToSampleExteriorColor':
            return OptionUpdateDTOPaintToSampleExteriorColorToJSON(value);
        default:
            throw new Error(`No variant of OptionUpdateDTOOption exists with 'type=${value['type']}'`);
    }
}

