import { getEnumConverterGroup, getObjectURLParamConverter, numberConverterGroup, stringConverterGroup } from "../../../common/useURLSearchParamState"
import { EquipmentFilterOptionsDTO, EquipmentItemDTO, EquipmentSortField, SortDirection, VehicleEquipmentCategory } from "../../../generated/pdsapi"
import { equipmentApi, EquipmentFilter } from "../../../pdsapi"
import { getProductListContainer, ProductListContainerConfig } from "../../../viewtemplates/list/ProductListContainer"
import { EquipmentCatalogueColumnsConfig } from "./columns/EquipmentCatalogueColumns"
import { EquipmentCatalogueFilter } from "./filter/EquipmentCatalogueFilter"
import { EquipmentCatalogueFilterTagsConfig } from "./filter/EquipmentCatalogueFilterTagsConfig"
import { routes } from "../../../common/routes"
import { Route } from "react-router-dom"

export const EquipmentCatalogueContainerConfig: ProductListContainerConfig<EquipmentItemDTO, EquipmentFilter, EquipmentFilterOptionsDTO, EquipmentSortField> = {
    listName: "Equipment Catalogue",
    itemsName: "Equipments",

    buildDetailsPath: (item) => routes.models.orderTypes.subpages(item.orderTypeKey).equipment.details(item.optionCode),
    buildCreatePath: undefined,
    buildSortPath: undefined,

    defaultFilter: {},
    filterUrlParamsConverter: getObjectURLParamConverter<EquipmentFilter>({
        modelGenerationKeys: stringConverterGroup.optionalArray,
        modelYears: numberConverterGroup.optionalArray,
        orderTypeKeys: stringConverterGroup.optionalArray,
        categories: getEnumConverterGroup(VehicleEquipmentCategory).optionalArray,
        tagKeys: stringConverterGroup.optionalArray,
        families: stringConverterGroup.optionalArray,
    }),
    defaultSorting: {
        sortField: EquipmentSortField.SORT_INDEX,
        sortDirection: SortDirection.ASC,
    },
    sortingUrlParamsConverter: getObjectURLParamConverter({
        sortField: getEnumConverterGroup(EquipmentSortField).required,
        sortDirection: getEnumConverterGroup(SortDirection).required,
    }),
    fetchPage: equipmentApi.catalogue.getPage,
    fetchFilterOptions: equipmentApi.catalogue.getFilterOptions,

    defaultShowsFilter: true,
    Filter: EquipmentCatalogueFilter,
    filterTagsConfig: EquipmentCatalogueFilterTagsConfig,

    columnsConfig: EquipmentCatalogueColumnsConfig,
}

const EquipmentCatalogueContainer = getProductListContainer(EquipmentCatalogueContainerConfig)

export const EquipmentCatalogueRoute = <Route path={routes.equipment.catalogue()} Component={EquipmentCatalogueContainer} />
