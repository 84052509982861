import { OldCustomizationLinksDTO } from "../../../../generated/pdsapi"
import { BasicKey, ProductDetailsTabConfigContentProps } from "../../ProductDetailsContainer"
import { ProductDetailsCard, ProductDetailsCardContentProps } from "../ProductDetailsCard"
import { DetailsLinkedOptionsCardContent } from "./content/DetailsLinkedOptionsCardContent"
import { FC, useCallback } from "react"
import { OldOrderTypeOptionKey } from "../../../../views/models/ordertypes/OrderTypeOptionKey"

interface DetailsLinkedOptionsCardProps {
    getData: (props: { key: string; languageTag: string }) => Promise<OldCustomizationLinksDTO>
    getRoute: (key: OldOrderTypeOptionKey) => string
}

export const DetailsLinkedOptionsCard: FC<ProductDetailsTabConfigContentProps<BasicKey> & DetailsLinkedOptionsCardProps> = (props) => {
    const Content: FC<ProductDetailsCardContentProps<OldCustomizationLinksDTO, Record<string, any>, BasicKey>> = useCallback(
        (contentProps) => <DetailsLinkedOptionsCardContent {...contentProps} getRoute={props.getRoute} />,
        [props.getRoute]
    )

    return <ProductDetailsCard {...props} label={"Links"} Content={Content} />
}
