/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SelectOptionDTOInteriorMaterial,
    SelectOptionDTOInteriorMaterialFromJSON,
    SelectOptionDTOInteriorMaterialFromJSONTyped,
    SelectOptionDTOInteriorMaterialToJSON,
    SelectOptionDTOString,
    SelectOptionDTOStringFromJSON,
    SelectOptionDTOStringFromJSONTyped,
    SelectOptionDTOStringToJSON,
} from './';

/**
 * 
 * @export
 * @interface OrderTypeInteriorFilterOptionsDTO
 */
export interface OrderTypeInteriorFilterOptionsDTO {
    /**
     * 
     * @type {Array<SelectOptionDTOInteriorMaterial>}
     * @memberof OrderTypeInteriorFilterOptionsDTO
     */
    materials: Array<SelectOptionDTOInteriorMaterial>;
    /**
     * 
     * @type {Array<SelectOptionDTOString>}
     * @memberof OrderTypeInteriorFilterOptionsDTO
     */
    colorTags: Array<SelectOptionDTOString>;
}

export function OrderTypeInteriorFilterOptionsDTOFromJSON(json: any): OrderTypeInteriorFilterOptionsDTO {
    return OrderTypeInteriorFilterOptionsDTOFromJSONTyped(json, false);
}

export function OrderTypeInteriorFilterOptionsDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrderTypeInteriorFilterOptionsDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'materials': ((json['materials'] as Array<any>).map(SelectOptionDTOInteriorMaterialFromJSON)),
        'colorTags': ((json['colorTags'] as Array<any>).map(SelectOptionDTOStringFromJSON)),
    };
}

export function OrderTypeInteriorFilterOptionsDTOToJSON(value?: OrderTypeInteriorFilterOptionsDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'materials': ((value.materials as Array<any>).map(SelectOptionDTOInteriorMaterialToJSON)),
        'colorTags': ((value.colorTags as Array<any>).map(SelectOptionDTOStringToJSON)),
    };
}


