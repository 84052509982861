/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    BaseNameDTO,
    BaseNameDTOFromJSON,
    BaseNameDTOFromJSONTyped,
    BaseNameDTOToJSON,
} from './';

/**
 * 
 * @export
 * @interface OptionCustomizationCreateRequestDTOEquipmentInternal
 */
export interface OptionCustomizationCreateRequestDTOEquipmentInternal {
    /**
     * 
     * @type {BaseNameDTO}
     * @memberof OptionCustomizationCreateRequestDTOEquipmentInternal
     */
    name: BaseNameDTO;
    /**
     * 
     * @type {string}
     * @memberof OptionCustomizationCreateRequestDTOEquipmentInternal
     */
    optionCode: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof OptionCustomizationCreateRequestDTOEquipmentInternal
     */
    modelGenerationKeys: Array<string>;
    /**
     * 
     * @type {BaseNameDTO}
     * @memberof OptionCustomizationCreateRequestDTOEquipmentInternal
     */
    description: BaseNameDTO;
    /**
     * 
     * @type {boolean}
     * @memberof OptionCustomizationCreateRequestDTOEquipmentInternal
     */
    isDefaultValueActive: boolean;
    /**
     * 
     * @type {string}
     * @memberof OptionCustomizationCreateRequestDTOEquipmentInternal
     */
    type: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof OptionCustomizationCreateRequestDTOEquipmentInternal
     */
    equipmentTagKeys: Array<string>;
}

export function OptionCustomizationCreateRequestDTOEquipmentInternalFromJSON(json: any): OptionCustomizationCreateRequestDTOEquipmentInternal {
    return OptionCustomizationCreateRequestDTOEquipmentInternalFromJSONTyped(json, false);
}

export function OptionCustomizationCreateRequestDTOEquipmentInternalFromJSONTyped(json: any, ignoreDiscriminator: boolean): OptionCustomizationCreateRequestDTOEquipmentInternal {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': BaseNameDTOFromJSON(json['name']),
        'optionCode': json['optionCode'],
        'modelGenerationKeys': json['modelGenerationKeys'],
        'description': BaseNameDTOFromJSON(json['description']),
        'isDefaultValueActive': json['isDefaultValueActive'],
        'type': json['type'],
        'equipmentTagKeys': json['equipmentTagKeys'],
    };
}

export function OptionCustomizationCreateRequestDTOEquipmentInternalToJSON(value?: OptionCustomizationCreateRequestDTOEquipmentInternal | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': BaseNameDTOToJSON(value.name),
        'optionCode': value.optionCode,
        'modelGenerationKeys': value.modelGenerationKeys,
        'description': BaseNameDTOToJSON(value.description),
        'isDefaultValueActive': value.isDefaultValueActive,
        'type': value.type,
        'equipmentTagKeys': value.equipmentTagKeys,
    };
}


