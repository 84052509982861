import { routes } from "../../../../../../../common/routes"
import { ViewLabeledContentWrapper } from "../../../../../../../components/formelements/FormElementWrapper"
import { TagFormElementView } from "../../../../../../../components/formelements/tagformelement/TagFormElement"
import { TextFormElementView } from "../../../../../../../components/formelements/textformelement/TextFormElement"
import { rowGroupsSpacing, RowGroupWrapper } from "../../../../../../../components/layouts/rowgroupwrapper/RowGroupWrapper"
import { SpacedItems } from "../../../../../../../components/layouts/spaceditems/SpacedItems"
import { NamedColorTile } from "../../../../../../../components/tiles/namedcolortile/NamedColorTile"
import { LinkedExteriorColorCustomizationDTOCustomization } from "../../../../../../../generated/pdsapi"
import { ExteriorColorTile } from "@finder/ui-kit"

export const LinkedExteriorColorCustomizationCardContent = ({ customization }: { customization: LinkedExteriorColorCustomizationDTOCustomization }) => (
    <SpacedItems direction={"column"} lineSpacing={rowGroupsSpacing}>
        <RowGroupWrapper label={"Configuration"}>
            <TagFormElementView
                label={undefined}
                tag={{
                    label: "Jump to customization",
                    route: routes.exteriorColors.customizations.details(customization.key),
                }}
            />
        </RowGroupWrapper>
        <RowGroupWrapper label={"Name"}>
            {(["de", "en"] as ("en" | "de")[]).map((languageTag) => (
                <TextFormElementView key={languageTag} label={`Name - ${languageTag}`} value={customization.value?.name[languageTag]} />
            ))}
        </RowGroupWrapper>
        <RowGroupWrapper label={"Attributes"} childrenSize={12}>
            <TextFormElementView label={"Exterior Color Type"} value={customization.value?.colorTypeName} />
            <ViewLabeledContentWrapper label={"Color"}>
                {customization.value ? <ExteriorColorTile color={customization.value.hexCode} /> : "-"}
            </ViewLabeledContentWrapper>
            <ViewLabeledContentWrapper label={"Exterior Color Tag"}>
                {customization.value?.colorTag ? (
                    <NamedColorTile label={customization.value?.colorTag.name ?? "-"} hexCode={customization.value?.colorTag.hexCode} />
                ) : (
                    "-"
                )}
            </ViewLabeledContentWrapper>
        </RowGroupWrapper>
    </SpacedItems>
)
