import { createContext, useContext } from "react"
import { OrderTypeOptionTypeDTO } from "../../../generated/pdsapi"

export const OrderTypeOptionsContext = createContext<{ orderTypeKey: string; orderTypeName: string; optionType: OrderTypeOptionTypeDTO | undefined }>({
    orderTypeKey: "",
    orderTypeName: "",
    optionType: undefined,
})

export const useOrderTypeOptionsContext = () => {
    const context = useContext(OrderTypeOptionsContext)
    if (!context) {
        throw new Error("useOrderTypeOptionsContext requires a <OrderTypeOptionsContext.Provider> to be in the component hierarchy.")
    }

    return context
}
