/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    OptionCustomizationAttributesUpdateDTORoofColorValue,
    OptionCustomizationAttributesUpdateDTORoofColorValueFromJSON,
    OptionCustomizationAttributesUpdateDTORoofColorValueFromJSONTyped,
    OptionCustomizationAttributesUpdateDTORoofColorValueToJSON,
    OptionCustomizationConditionalValueUpdateDTOOptionCustomizationAttributesUpdateDTORoofColorValue,
    OptionCustomizationConditionalValueUpdateDTOOptionCustomizationAttributesUpdateDTORoofColorValueFromJSON,
    OptionCustomizationConditionalValueUpdateDTOOptionCustomizationAttributesUpdateDTORoofColorValueFromJSONTyped,
    OptionCustomizationConditionalValueUpdateDTOOptionCustomizationAttributesUpdateDTORoofColorValueToJSON,
} from './';

/**
 * 
 * @export
 * @interface OptionCustomizationAttributesUpdateDTORoofColorInternal
 */
export interface OptionCustomizationAttributesUpdateDTORoofColorInternal {
    /**
     * 
     * @type {OptionCustomizationAttributesUpdateDTORoofColorValue}
     * @memberof OptionCustomizationAttributesUpdateDTORoofColorInternal
     */
    defaultValue: OptionCustomizationAttributesUpdateDTORoofColorValue;
    /**
     * 
     * @type {string}
     * @memberof OptionCustomizationAttributesUpdateDTORoofColorInternal
     */
    optionCode: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof OptionCustomizationAttributesUpdateDTORoofColorInternal
     */
    modelGenerationKeys: Array<string>;
    /**
     * 
     * @type {Array<OptionCustomizationConditionalValueUpdateDTOOptionCustomizationAttributesUpdateDTORoofColorValue>}
     * @memberof OptionCustomizationAttributesUpdateDTORoofColorInternal
     */
    conditionalValues: Array<OptionCustomizationConditionalValueUpdateDTOOptionCustomizationAttributesUpdateDTORoofColorValue>;
    /**
     * 
     * @type {boolean}
     * @memberof OptionCustomizationAttributesUpdateDTORoofColorInternal
     */
    isDefaultValueActive: boolean;
    /**
     * 
     * @type {string}
     * @memberof OptionCustomizationAttributesUpdateDTORoofColorInternal
     */
    type: string;
}

export function OptionCustomizationAttributesUpdateDTORoofColorInternalFromJSON(json: any): OptionCustomizationAttributesUpdateDTORoofColorInternal {
    return OptionCustomizationAttributesUpdateDTORoofColorInternalFromJSONTyped(json, false);
}

export function OptionCustomizationAttributesUpdateDTORoofColorInternalFromJSONTyped(json: any, ignoreDiscriminator: boolean): OptionCustomizationAttributesUpdateDTORoofColorInternal {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'defaultValue': OptionCustomizationAttributesUpdateDTORoofColorValueFromJSON(json['defaultValue']),
        'optionCode': json['optionCode'],
        'modelGenerationKeys': json['modelGenerationKeys'],
        'conditionalValues': ((json['conditionalValues'] as Array<any>).map(OptionCustomizationConditionalValueUpdateDTOOptionCustomizationAttributesUpdateDTORoofColorValueFromJSON)),
        'isDefaultValueActive': json['isDefaultValueActive'],
        'type': json['type'],
    };
}

export function OptionCustomizationAttributesUpdateDTORoofColorInternalToJSON(value?: OptionCustomizationAttributesUpdateDTORoofColorInternal | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'defaultValue': OptionCustomizationAttributesUpdateDTORoofColorValueToJSON(value.defaultValue),
        'optionCode': value.optionCode,
        'modelGenerationKeys': value.modelGenerationKeys,
        'conditionalValues': ((value.conditionalValues as Array<any>).map(OptionCustomizationConditionalValueUpdateDTOOptionCustomizationAttributesUpdateDTORoofColorValueToJSON)),
        'isDefaultValueActive': value.isDefaultValueActive,
        'type': value.type,
    };
}


