import { exteriorColorTagApi } from "../../../../pdsapi"
import { getProductSortContainer, ProductSortContainerConfig } from "../../../../viewtemplates/sort/ProductSortContainer"
import { Route } from "react-router-dom"
import { routes } from "../../../../common/routes"
import { ExteriorColorTagProductListContainerConfig } from "../list/ExteriorColorTagListRoute"

export const ExteriorColorTagSortContainerTemplateProps: ProductSortContainerConfig = {
    fetchSortables: exteriorColorTagApi.sort.getSortableItems,
    updateSortIndices: (sortIndexUpdate) => exteriorColorTagApi.sort.updateSortIndices({ sortIndexUpdateRequestDTO: sortIndexUpdate }),
}

const ExteriorColorTagSortContainer = getProductSortContainer(ExteriorColorTagSortContainerTemplateProps)

export const ExteriorColorTagSortRoute = (
    <Route
        path={routes.exteriorColors.tags.sort()}
        element={
            <ExteriorColorTagSortContainer
                parentCrumbs={[
                    {
                        name: ExteriorColorTagProductListContainerConfig.listName,
                        path: routes.exteriorColors.tags.list(),
                    },
                ]}
            />
        }
    />
)
