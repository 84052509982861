/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    OptionTagAttributesUpdateDTOExteriorColorInternal,
    OptionTagAttributesUpdateDTOExteriorColorInternalFromJSONTyped,
    OptionTagAttributesUpdateDTOExteriorColorInternalToJSON,
} from './';

/**
 * @type OptionTagAttributesUpdateDTOExteriorColor
 * 
 * @export
 */
export type OptionTagAttributesUpdateDTOExteriorColor = { type: 'ExteriorColor' } & OptionTagAttributesUpdateDTOExteriorColorInternal;

export function OptionTagAttributesUpdateDTOExteriorColorFromJSON(json: any): OptionTagAttributesUpdateDTOExteriorColor {
    return OptionTagAttributesUpdateDTOExteriorColorFromJSONTyped(json, false);
}

export function OptionTagAttributesUpdateDTOExteriorColorFromJSONTyped(json: any, ignoreDiscriminator: boolean): OptionTagAttributesUpdateDTOExteriorColor {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    switch (json['type']) {
        case 'ExteriorColor':
            return {...OptionTagAttributesUpdateDTOExteriorColorInternalFromJSONTyped(json, true), type: 'ExteriorColor'};
        default:
            throw new Error(`No variant of OptionTagAttributesUpdateDTOExteriorColor exists with 'type=${json['type']}'`);
    }
}

export function OptionTagAttributesUpdateDTOExteriorColorToJSON(value?: OptionTagAttributesUpdateDTOExteriorColor | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    switch (value['type']) {
        case 'ExteriorColor':
            return OptionTagAttributesUpdateDTOExteriorColorInternalToJSON(value);
        default:
            throw new Error(`No variant of OptionTagAttributesUpdateDTOExteriorColor exists with 'type=${value['type']}'`);
    }
}

