/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MultiSelectDTOString,
    MultiSelectDTOStringFromJSON,
    MultiSelectDTOStringFromJSONTyped,
    MultiSelectDTOStringToJSON,
    OptionCustomizationAttributesDTOEquipmentValue,
    OptionCustomizationAttributesDTOEquipmentValueFromJSON,
    OptionCustomizationAttributesDTOEquipmentValueFromJSONTyped,
    OptionCustomizationAttributesDTOEquipmentValueToJSON,
    OptionCustomizationConditionalValueDTOOptionCustomizationAttributesDTOEquipmentValue,
    OptionCustomizationConditionalValueDTOOptionCustomizationAttributesDTOEquipmentValueFromJSON,
    OptionCustomizationConditionalValueDTOOptionCustomizationAttributesDTOEquipmentValueFromJSONTyped,
    OptionCustomizationConditionalValueDTOOptionCustomizationAttributesDTOEquipmentValueToJSON,
    SelectOptionDTOString,
    SelectOptionDTOStringFromJSON,
    SelectOptionDTOStringFromJSONTyped,
    SelectOptionDTOStringToJSON,
} from './';

/**
 * 
 * @export
 * @interface OptionCustomizationAttributesDTOEquipmentInternal
 */
export interface OptionCustomizationAttributesDTOEquipmentInternal {
    /**
     * 
     * @type {OptionCustomizationAttributesDTOEquipmentValue}
     * @memberof OptionCustomizationAttributesDTOEquipmentInternal
     */
    defaultValue: OptionCustomizationAttributesDTOEquipmentValue;
    /**
     * 
     * @type {string}
     * @memberof OptionCustomizationAttributesDTOEquipmentInternal
     */
    optionCode: string;
    /**
     * 
     * @type {Array<OptionCustomizationConditionalValueDTOOptionCustomizationAttributesDTOEquipmentValue>}
     * @memberof OptionCustomizationAttributesDTOEquipmentInternal
     */
    conditionalValues: Array<OptionCustomizationConditionalValueDTOOptionCustomizationAttributesDTOEquipmentValue>;
    /**
     * 
     * @type {MultiSelectDTOString}
     * @memberof OptionCustomizationAttributesDTOEquipmentInternal
     */
    modelGenerations: MultiSelectDTOString;
    /**
     * 
     * @type {boolean}
     * @memberof OptionCustomizationAttributesDTOEquipmentInternal
     */
    isDefaultValueActive: boolean;
    /**
     * 
     * @type {Array<SelectOptionDTOString>}
     * @memberof OptionCustomizationAttributesDTOEquipmentInternal
     */
    derivatives: Array<SelectOptionDTOString>;
    /**
     * 
     * @type {string}
     * @memberof OptionCustomizationAttributesDTOEquipmentInternal
     */
    type: string;
}

export function OptionCustomizationAttributesDTOEquipmentInternalFromJSON(json: any): OptionCustomizationAttributesDTOEquipmentInternal {
    return OptionCustomizationAttributesDTOEquipmentInternalFromJSONTyped(json, false);
}

export function OptionCustomizationAttributesDTOEquipmentInternalFromJSONTyped(json: any, ignoreDiscriminator: boolean): OptionCustomizationAttributesDTOEquipmentInternal {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'defaultValue': OptionCustomizationAttributesDTOEquipmentValueFromJSON(json['defaultValue']),
        'optionCode': json['optionCode'],
        'conditionalValues': ((json['conditionalValues'] as Array<any>).map(OptionCustomizationConditionalValueDTOOptionCustomizationAttributesDTOEquipmentValueFromJSON)),
        'modelGenerations': MultiSelectDTOStringFromJSON(json['modelGenerations']),
        'isDefaultValueActive': json['isDefaultValueActive'],
        'derivatives': ((json['derivatives'] as Array<any>).map(SelectOptionDTOStringFromJSON)),
        'type': json['type'],
    };
}

export function OptionCustomizationAttributesDTOEquipmentInternalToJSON(value?: OptionCustomizationAttributesDTOEquipmentInternal | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'defaultValue': OptionCustomizationAttributesDTOEquipmentValueToJSON(value.defaultValue),
        'optionCode': value.optionCode,
        'conditionalValues': ((value.conditionalValues as Array<any>).map(OptionCustomizationConditionalValueDTOOptionCustomizationAttributesDTOEquipmentValueToJSON)),
        'modelGenerations': MultiSelectDTOStringToJSON(value.modelGenerations),
        'isDefaultValueActive': value.isDefaultValueActive,
        'derivatives': ((value.derivatives as Array<any>).map(SelectOptionDTOStringToJSON)),
        'type': value.type,
    };
}


