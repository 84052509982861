/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    EquipmentCustomizationAttributesValueDTO,
    EquipmentCustomizationAttributesValueDTOFromJSON,
    EquipmentCustomizationAttributesValueDTOFromJSONTyped,
    EquipmentCustomizationAttributesValueDTOToJSON,
    MultiSelectDTOString,
    MultiSelectDTOStringFromJSON,
    MultiSelectDTOStringFromJSONTyped,
    MultiSelectDTOStringToJSON,
    OptionCustomizationConditionalValueDTOEquipmentCustomizationAttributesValueDTO,
    OptionCustomizationConditionalValueDTOEquipmentCustomizationAttributesValueDTOFromJSON,
    OptionCustomizationConditionalValueDTOEquipmentCustomizationAttributesValueDTOFromJSONTyped,
    OptionCustomizationConditionalValueDTOEquipmentCustomizationAttributesValueDTOToJSON,
    SelectOptionDTOString,
    SelectOptionDTOStringFromJSON,
    SelectOptionDTOStringFromJSONTyped,
    SelectOptionDTOStringToJSON,
} from './';

/**
 * 
 * @export
 * @interface OldOptionCustomizationAttributesDTOEquipmentCustomizationAttributesValueDTO
 */
export interface OldOptionCustomizationAttributesDTOEquipmentCustomizationAttributesValueDTO {
    /**
     * 
     * @type {string}
     * @memberof OldOptionCustomizationAttributesDTOEquipmentCustomizationAttributesValueDTO
     */
    optionCode: string;
    /**
     * 
     * @type {MultiSelectDTOString}
     * @memberof OldOptionCustomizationAttributesDTOEquipmentCustomizationAttributesValueDTO
     */
    modelGenerations: MultiSelectDTOString;
    /**
     * 
     * @type {Array<SelectOptionDTOString>}
     * @memberof OldOptionCustomizationAttributesDTOEquipmentCustomizationAttributesValueDTO
     */
    derivatives: Array<SelectOptionDTOString>;
    /**
     * 
     * @type {boolean}
     * @memberof OldOptionCustomizationAttributesDTOEquipmentCustomizationAttributesValueDTO
     */
    isDefaultValueActive: boolean;
    /**
     * 
     * @type {EquipmentCustomizationAttributesValueDTO}
     * @memberof OldOptionCustomizationAttributesDTOEquipmentCustomizationAttributesValueDTO
     */
    defaultValue: EquipmentCustomizationAttributesValueDTO;
    /**
     * 
     * @type {Array<OptionCustomizationConditionalValueDTOEquipmentCustomizationAttributesValueDTO>}
     * @memberof OldOptionCustomizationAttributesDTOEquipmentCustomizationAttributesValueDTO
     */
    conditionalValues: Array<OptionCustomizationConditionalValueDTOEquipmentCustomizationAttributesValueDTO>;
}

export function OldOptionCustomizationAttributesDTOEquipmentCustomizationAttributesValueDTOFromJSON(json: any): OldOptionCustomizationAttributesDTOEquipmentCustomizationAttributesValueDTO {
    return OldOptionCustomizationAttributesDTOEquipmentCustomizationAttributesValueDTOFromJSONTyped(json, false);
}

export function OldOptionCustomizationAttributesDTOEquipmentCustomizationAttributesValueDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): OldOptionCustomizationAttributesDTOEquipmentCustomizationAttributesValueDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'optionCode': json['optionCode'],
        'modelGenerations': MultiSelectDTOStringFromJSON(json['modelGenerations']),
        'derivatives': ((json['derivatives'] as Array<any>).map(SelectOptionDTOStringFromJSON)),
        'isDefaultValueActive': json['isDefaultValueActive'],
        'defaultValue': EquipmentCustomizationAttributesValueDTOFromJSON(json['defaultValue']),
        'conditionalValues': ((json['conditionalValues'] as Array<any>).map(OptionCustomizationConditionalValueDTOEquipmentCustomizationAttributesValueDTOFromJSON)),
    };
}

export function OldOptionCustomizationAttributesDTOEquipmentCustomizationAttributesValueDTOToJSON(value?: OldOptionCustomizationAttributesDTOEquipmentCustomizationAttributesValueDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'optionCode': value.optionCode,
        'modelGenerations': MultiSelectDTOStringToJSON(value.modelGenerations),
        'derivatives': ((value.derivatives as Array<any>).map(SelectOptionDTOStringToJSON)),
        'isDefaultValueActive': value.isDefaultValueActive,
        'defaultValue': EquipmentCustomizationAttributesValueDTOToJSON(value.defaultValue),
        'conditionalValues': ((value.conditionalValues as Array<any>).map(OptionCustomizationConditionalValueDTOEquipmentCustomizationAttributesValueDTOToJSON)),
    };
}


