/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    OptionGroupDTOString,
    OptionGroupDTOStringFromJSON,
    OptionGroupDTOStringFromJSONTyped,
    OptionGroupDTOStringToJSON,
    SelectOptionDTOExteriorColorType,
    SelectOptionDTOExteriorColorTypeFromJSON,
    SelectOptionDTOExteriorColorTypeFromJSONTyped,
    SelectOptionDTOExteriorColorTypeToJSON,
    SelectOptionDTOInteriorMaterial,
    SelectOptionDTOInteriorMaterialFromJSON,
    SelectOptionDTOInteriorMaterialFromJSONTyped,
    SelectOptionDTOInteriorMaterialToJSON,
    SelectOptionDTOString,
    SelectOptionDTOStringFromJSON,
    SelectOptionDTOStringFromJSONTyped,
    SelectOptionDTOStringToJSON,
} from './';

/**
 * 
 * @export
 * @interface OptionCustomizationCreateOptionsDTO
 */
export interface OptionCustomizationCreateOptionsDTO {
    /**
     * 
     * @type {Array<SelectOptionDTOString>}
     * @memberof OptionCustomizationCreateOptionsDTO
     */
    modelGenerations: Array<SelectOptionDTOString>;
    /**
     * 
     * @type {Array<OptionGroupDTOString>}
     * @memberof OptionCustomizationCreateOptionsDTO
     */
    categorizedEquipmentTags: Array<OptionGroupDTOString>;
    /**
     * 
     * @type {Array<SelectOptionDTOString>}
     * @memberof OptionCustomizationCreateOptionsDTO
     */
    interiorColorTags: Array<SelectOptionDTOString>;
    /**
     * 
     * @type {Array<SelectOptionDTOInteriorMaterial>}
     * @memberof OptionCustomizationCreateOptionsDTO
     */
    interiorMaterials: Array<SelectOptionDTOInteriorMaterial>;
    /**
     * 
     * @type {Array<SelectOptionDTOString>}
     * @memberof OptionCustomizationCreateOptionsDTO
     */
    exteriorColorTags: Array<SelectOptionDTOString>;
    /**
     * 
     * @type {Array<SelectOptionDTOExteriorColorType>}
     * @memberof OptionCustomizationCreateOptionsDTO
     */
    exteriorColorTypes: Array<SelectOptionDTOExteriorColorType>;
    /**
     * 
     * @type {Array<SelectOptionDTOString>}
     * @memberof OptionCustomizationCreateOptionsDTO
     */
    roofColorTags: Array<SelectOptionDTOString>;
}

export function OptionCustomizationCreateOptionsDTOFromJSON(json: any): OptionCustomizationCreateOptionsDTO {
    return OptionCustomizationCreateOptionsDTOFromJSONTyped(json, false);
}

export function OptionCustomizationCreateOptionsDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): OptionCustomizationCreateOptionsDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'modelGenerations': ((json['modelGenerations'] as Array<any>).map(SelectOptionDTOStringFromJSON)),
        'categorizedEquipmentTags': ((json['categorizedEquipmentTags'] as Array<any>).map(OptionGroupDTOStringFromJSON)),
        'interiorColorTags': ((json['interiorColorTags'] as Array<any>).map(SelectOptionDTOStringFromJSON)),
        'interiorMaterials': ((json['interiorMaterials'] as Array<any>).map(SelectOptionDTOInteriorMaterialFromJSON)),
        'exteriorColorTags': ((json['exteriorColorTags'] as Array<any>).map(SelectOptionDTOStringFromJSON)),
        'exteriorColorTypes': ((json['exteriorColorTypes'] as Array<any>).map(SelectOptionDTOExteriorColorTypeFromJSON)),
        'roofColorTags': ((json['roofColorTags'] as Array<any>).map(SelectOptionDTOStringFromJSON)),
    };
}

export function OptionCustomizationCreateOptionsDTOToJSON(value?: OptionCustomizationCreateOptionsDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'modelGenerations': ((value.modelGenerations as Array<any>).map(SelectOptionDTOStringToJSON)),
        'categorizedEquipmentTags': ((value.categorizedEquipmentTags as Array<any>).map(OptionGroupDTOStringToJSON)),
        'interiorColorTags': ((value.interiorColorTags as Array<any>).map(SelectOptionDTOStringToJSON)),
        'interiorMaterials': ((value.interiorMaterials as Array<any>).map(SelectOptionDTOInteriorMaterialToJSON)),
        'exteriorColorTags': ((value.exteriorColorTags as Array<any>).map(SelectOptionDTOStringToJSON)),
        'exteriorColorTypes': ((value.exteriorColorTypes as Array<any>).map(SelectOptionDTOExteriorColorTypeToJSON)),
        'roofColorTags': ((value.roofColorTags as Array<any>).map(SelectOptionDTOStringToJSON)),
    };
}


