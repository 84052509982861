import { ViewState } from "../../../../../../../common/ViewState"
import { showErrorToast } from "../../../../../../../common/errorToastHelper"
import { browserLanguage } from "../../../../../../../common/global"
import { EmptyView } from "../../../../../../../components/emptyview/EmptyView"
import { rowGroupsSpacing, RowGroupWrapper } from "../../../../../../../components/layouts/rowgroupwrapper/RowGroupWrapper"
import { SpacedItems } from "../../../../../../../components/layouts/spaceditems/SpacedItems"
import { WidgetWrapper } from "../../../../../../../components/layouts/widgetwrapper/WidgetWrapper"
import { Tag } from "../../../../../../../components/tag/Tag"
import { NamedExteriorColorTile } from "../../../../../../../components/tiles/namedexteriorcolortile/NamedExteriorColorTile"
import { OrderTypeOptionTypeDTO, SimilarCustomizationsDTO, SimilarCustomizationsDTOSimilarCustomization } from "../../../../../../../generated/pdsapi"
import { optionApi } from "../../../../../../../pdsapi"
import { ProductDetailsTabConfigContentProps } from "../../../../../../../viewtemplates/details/ProductDetailsContainer"
import { useToast } from "@finder/ui-kit"
import { PFlex } from "@porsche-design-system/components-react"
import { spacing } from "@porsche-design-system/utilities"
import { FC, useEffect, useRef, useState } from "react"
import { useNavigate } from "react-router-dom"
import { routes } from "../../../../../../../common/routes"
import { optionCustomizationTypeToEnum } from "../../../../../../options/customizations/optionCustomizationTypes"

export const OptionSimilarCustomizationsCard: FC<
    ProductDetailsTabConfigContentProps<{ orderTypeKey: string; optionCode: string; type: OrderTypeOptionTypeDTO }>
> = ({ itemKey }) => {
    const toastRef = useRef(useToast())
    const [similarCustomizations, setSimilarCustomizations] = useState<SimilarCustomizationsDTO>()

    useEffect(() => {
        const fetchSimilarCustomizations = async () => {
            try {
                const linkedCustomization = await optionApi.details.getSimilarCustomizations({
                    ...itemKey,
                    languageTag: browserLanguage,
                })
                setSimilarCustomizations(linkedCustomization)
            } catch (e) {
                showErrorToast(toastRef.current, e)
            }
        }

        fetchSimilarCustomizations()
    }, [itemKey, toastRef])

    return (
        <WidgetWrapper label={"Similar Customizations"}>
            {similarCustomizations ? (
                <OptionSimilarCustomizationsCardContent similarCustomizations={similarCustomizations} />
            ) : (
                <EmptyView viewState={ViewState.LOADING} />
            )}
        </WidgetWrapper>
    )
}

export const OptionSimilarCustomizationsCardContent = ({ similarCustomizations }: { similarCustomizations: SimilarCustomizationsDTO }) => {
    const navigate = useNavigate()
    return (
        <SpacedItems direction={"column"} lineSpacing={rowGroupsSpacing}>
            <RowGroupWrapper label={"Customizations for other Model Generations"} childrenSize={12}>
                <SpacedItems direction={"row"} wrap={true} itemSpacing={spacing[16]} lineSpacing={spacing[8]}>
                    {similarCustomizations.similarCustomizations.map((customization, index) => {
                        return (
                            <Tag
                                key={`Tag_${index}`}
                                onClick={() =>
                                    navigate(
                                        routes.options.customizations.details({
                                            key: customization.key,
                                            type: optionCustomizationTypeToEnum(customization.type),
                                        })
                                    )
                                }
                            >
                                <SpecificSimilarCustomizationContent customization={customization} />
                            </Tag>
                        )
                    })}
                </SpacedItems>
            </RowGroupWrapper>
        </SpacedItems>
    )
}

const SpecificSimilarCustomizationContent = ({ customization }: { customization: SimilarCustomizationsDTOSimilarCustomization }) => {
    switch (customization.type) {
        case "ExteriorColor": {
            return (
                <PFlex>
                    <NamedExteriorColorTile label={customization.label} hexCode={customization.hexCode} />
                </PFlex>
            )
        }
        case "PaintToSampleExteriorColor": {
            return <div>{customization.label}</div>
        }
    }
}
