import { interiorCustomizationApi } from "../../../../pdsapi"
import { getCustomizationDetailsContainer } from "../../../../viewtemplates/details/CustomizationDetailsContainer"
import { BasicKey, ProductDetailsContainerConfig } from "../../../../viewtemplates/details/ProductDetailsContainer"
import { ProductDetailsCard } from "../../../../viewtemplates/details/cards/ProductDetailsCard"
import { DetailsLinkedOptionsCard } from "../../../../viewtemplates/details/cards/linkedoptionscard/DetailsLinkedOptionsCard"
import { DetailsVehicleImagesCard } from "../../../../viewtemplates/details/cards/vehicleimagescard/DetailsVehicleImagesCard"
import { ProductDetailsTab } from "../../../../viewtemplates/details/tabs/ProductDetailsTab"
import { DetailsOptionCustomizationLocalizationTab } from "../../../../viewtemplates/details/tabs/optioncustomizationlocalizationtab/DetailsOptionCustomizationLocalizationTab"
import { InteriorCustomizationDetailsAttributesCardContent } from "./data/attributes/InteriorCustomizationDetailsAttributesCardContent"
import { routes } from "../../../../common/routes"
import { Route, useParams } from "react-router-dom"
import { InteriorCustomizationProductListContainerConfig } from "../list/InteriorCustomizationListRoute"

export const InteriorCustomizationProductDetailsContainerConfig: ProductDetailsContainerConfig<BasicKey> = {
    getBaseInformation: interiorCustomizationApi.details.getBaseInformation,
    deleteByKey: interiorCustomizationApi.details.deleteByKey,
    tabsConfig: [
        {
            label: "Data",
            Content: (props) => (
                <ProductDetailsTab>
                    <ProductDetailsCard
                        {...props}
                        label={"Attributes"}
                        getData={interiorCustomizationApi.details.getAttributes}
                        updateData={({ key, update }) =>
                            interiorCustomizationApi.details.updateAttributes({
                                key,
                                oldOptionCustomizationAttributesUpdateDTOInteriorCustomizationAttributesValueUpdateDTO: update,
                            })
                        }
                        Content={InteriorCustomizationDetailsAttributesCardContent}
                    />
                    <DetailsLinkedOptionsCard
                        {...props}
                        getData={interiorCustomizationApi.details.getLinks}
                        getRoute={({ orderTypeKey, optionCode }) => routes.models.orderTypes.subpages(orderTypeKey).interiors.details(optionCode)}
                    />
                    <DetailsVehicleImagesCard {...props} load={interiorCustomizationApi.details.getVehicleImages} />
                </ProductDetailsTab>
            ),
        },
        {
            label: "Name",
            Content: (props) => (
                <DetailsOptionCustomizationLocalizationTab
                    {...props}
                    getData={interiorCustomizationApi.details.getName}
                    updateDefaultValue={interiorCustomizationApi.details.updateDefaultName}
                    updateConditionalValue={interiorCustomizationApi.details.updateConditionalValueName}
                />
            ),
        },
        {
            label: "Description",
            Content: (props) => (
                <DetailsOptionCustomizationLocalizationTab
                    {...props}
                    getData={interiorCustomizationApi.details.getDescription}
                    updateDefaultValue={interiorCustomizationApi.details.updateDefaultDescription}
                    updateConditionalValue={interiorCustomizationApi.details.updateConditionalValueDescription}
                />
            ),
        },
    ],
}

const InteriorCustomizationDetailsContainer = getCustomizationDetailsContainer(InteriorCustomizationProductDetailsContainerConfig)

export const InteriorCustomizationDetailsRoute = (
    <Route
        path={routes.interiors.customizations.details(":key")}
        Component={() => {
            const params = useParams<{ key: string }>()

            return (
                <InteriorCustomizationDetailsContainer
                    itemKey={params}
                    parentCrumbs={[
                        {
                            name: InteriorCustomizationProductListContainerConfig.listName,
                            path: routes.interiors.customizations.list(),
                        },
                    ]}
                />
            )
        }}
    />
)
