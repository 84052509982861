/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    AuthorizationApiError,
    AuthorizationApiErrorFromJSON,
    AuthorizationApiErrorToJSON,
    OptionTagCreateOptionsDTO,
    OptionTagCreateOptionsDTOFromJSON,
    OptionTagCreateOptionsDTOToJSON,
    OptionTagCreateRequestDTO,
    OptionTagCreateRequestDTOFromJSON,
    OptionTagCreateRequestDTOToJSON,
    SimpleOrValidationApiError,
    SimpleOrValidationApiErrorFromJSON,
    SimpleOrValidationApiErrorToJSON,
    TypedCreateResponseDTOOptionTagTypeDTO,
    TypedCreateResponseDTOOptionTagTypeDTOFromJSON,
    TypedCreateResponseDTOOptionTagTypeDTOToJSON,
} from '../models';

export interface CreateOptionTagRequest {
    optionTagCreateRequestDTO: OptionTagCreateRequestDTO;
}

export interface GetOptionTagCreateOptionsRequest {
    languageTag: string;
}

/**
 * 
 */
export class OptionTagCreateWebControllerApi extends runtime.BaseAPI {

    /**
     * Create a new OptionTag
     */
    async createOptionTagRaw(requestParameters: CreateOptionTagRequest): Promise<runtime.ApiResponse<TypedCreateResponseDTOOptionTagTypeDTO>> {
        if (requestParameters.optionTagCreateRequestDTO === null || requestParameters.optionTagCreateRequestDTO === undefined) {
            throw new runtime.RequiredError('optionTagCreateRequestDTO','Required parameter requestParameters.optionTagCreateRequestDTO was null or undefined when calling createOptionTag.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/web/option-tags/create`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: OptionTagCreateRequestDTOToJSON(requestParameters.optionTagCreateRequestDTO),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => TypedCreateResponseDTOOptionTagTypeDTOFromJSON(jsonValue));
    }

    /**
     * Create a new OptionTag
     */
    async createOptionTag(requestParameters: CreateOptionTagRequest): Promise<TypedCreateResponseDTOOptionTagTypeDTO> {
        const response = await this.createOptionTagRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get select options to create OptionTags
     */
    async getOptionTagCreateOptionsRaw(requestParameters: GetOptionTagCreateOptionsRequest): Promise<runtime.ApiResponse<OptionTagCreateOptionsDTO>> {
        if (requestParameters.languageTag === null || requestParameters.languageTag === undefined) {
            throw new runtime.RequiredError('languageTag','Required parameter requestParameters.languageTag was null or undefined when calling getOptionTagCreateOptions.');
        }

        const queryParameters: any = {};

        if (requestParameters.languageTag !== undefined) {
            queryParameters['languageTag'] = requestParameters.languageTag;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/web/option-tags/create/options`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => OptionTagCreateOptionsDTOFromJSON(jsonValue));
    }

    /**
     * Get select options to create OptionTags
     */
    async getOptionTagCreateOptions(requestParameters: GetOptionTagCreateOptionsRequest): Promise<OptionTagCreateOptionsDTO> {
        const response = await this.getOptionTagCreateOptionsRaw(requestParameters);
        return await response.value();
    }

}
