import { exteriorColorTagApi } from "../../../../pdsapi"
import { BasicKey, getProductDetailsContainer, ProductDetailsContainerConfig } from "../../../../viewtemplates/details/ProductDetailsContainer"
import { ProductDetailsCard } from "../../../../viewtemplates/details/cards/ProductDetailsCard"
import { DetailsLocalizationCard } from "../../../../viewtemplates/details/cards/localizationcard/DetailsLocalizationCard"
import { ProductDetailsTab } from "../../../../viewtemplates/details/tabs/ProductDetailsTab"
import { ExteriorColorTagDetailsAttributesCardContent } from "./data/attributes/ExteriorColorTagDetailsAttributesCardContent"
import { Route, useParams } from "react-router-dom"
import { routes } from "../../../../common/routes"
import { ExteriorColorTagProductListContainerConfig } from "../list/ExteriorColorTagListRoute"

export const ExteriorColorTagProductDetailsContainerConfig: ProductDetailsContainerConfig<BasicKey> = {
    getBaseInformation: exteriorColorTagApi.details.getBaseInformation,
    deleteByKey: exteriorColorTagApi.details.deleteByKey,
    tabsConfig: [
        {
            label: "Data",
            Content: (props) => (
                <ProductDetailsTab>
                    <ProductDetailsCard
                        {...props}
                        label={"Attributes"}
                        getData={exteriorColorTagApi.details.getAttributes}
                        updateData={({ key, update }) =>
                            exteriorColorTagApi.details.updateAttributes({
                                key,
                                exteriorColorTagAttributesUpdateDTO: update,
                            })
                        }
                        Content={ExteriorColorTagDetailsAttributesCardContent}
                    />
                </ProductDetailsTab>
            ),
        },
        {
            label: "Name",
            Content: (props) => (
                <ProductDetailsTab>
                    <DetailsLocalizationCard
                        {...props}
                        label={"Localizations"}
                        getData={exteriorColorTagApi.details.getNameLocalizations}
                        updateData={(update) =>
                            exteriorColorTagApi.details.updateName({
                                key: update.key,
                                requestBody: update.update,
                            })
                        }
                    />
                </ProductDetailsTab>
            ),
        },
    ],
}

const ExteriorColorTagDetailsContainer = getProductDetailsContainer(ExteriorColorTagProductDetailsContainerConfig)

export const ExteriorColorTagDetailsRoute = (
    <Route
        path={routes.exteriorColors.tags.details(":key")}
        Component={() => {
            const params = useParams<{ key: string }>()

            return (
                <ExteriorColorTagDetailsContainer
                    itemKey={params}
                    parentCrumbs={[
                        {
                            name: ExteriorColorTagProductListContainerConfig.listName,
                            path: routes.exteriorColors.tags.list(),
                        },
                    ]}
                />
            )
        }}
    />
)
