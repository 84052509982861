import { propertiesOf } from "../../../../../../common/property"
import { FormElementWrapper } from "../../../../../../components/formelements/FormElementWrapper"
import { getBoolFormElement } from "../../../../../../components/formelements/boolformelement/BoolFormElement"
import { getColorPickerFormElement } from "../../../../../../components/formelements/colorpickerformelement/ColorPickerFormElement"
import { getTextFormElement } from "../../../../../../components/formelements/textformelement/TextFormElement"
import { rowGroupsSpacing, RowGroupWrapper } from "../../../../../../components/layouts/rowgroupwrapper/RowGroupWrapper"
import { SpacedItems } from "../../../../../../components/layouts/spaceditems/SpacedItems"
import { InteriorColorTagAttributesDTO, InteriorColorTagAttributesUpdateDTO, InteriorColorTagCreateRequestDTO } from "../../../../../../generated/pdsapi"
import { ProductDetailsCardContentProps } from "../../../../../../viewtemplates/details/cards/ProductDetailsCard"
import { DetailsDefaultLocalizationsFormRow } from "../../../../../../viewtemplates/details/cards/components/defaultlocalizationsformrow/DetailsDefaultLocalizationsFormRow"
import { FC } from "react"
import { BasicKey } from "../../../../../../viewtemplates/details/ProductDetailsContainer"

export const InteriorColorTagDetailsAttributesCardContent: FC<
    ProductDetailsCardContentProps<InteriorColorTagAttributesDTO, InteriorColorTagAttributesUpdateDTO, BasicKey>
> = ({ formDisplayData }) => {
    const properties = propertiesOf<InteriorColorTagAttributesUpdateDTO>()

    return (
        <SpacedItems direction={"column"} lineSpacing={rowGroupsSpacing}>
            <RowGroupWrapper label={"Configuration"}>
                <FormElementWrapper
                    label={"Code"}
                    formDisplayData={formDisplayData}
                    field={properties.key}
                    FormElement={getTextFormElement<InteriorColorTagAttributesDTO, InteriorColorTagAttributesUpdateDTO>()}
                    getViewProps={(data) => ({
                        value: data.key,
                        showAsHint: false,
                    })}
                    getEditProps={(data) => ({
                        value: data.key,
                        readOnly: true,
                    })}
                />
            </RowGroupWrapper>

            <DetailsDefaultLocalizationsFormRow
                label={"Name"}
                formDisplayData={formDisplayData}
                field={properties.name}
                getViewProps={(data) => ({
                    value: data.name,
                })}
                getEditProps={(data) => ({
                    value: data.name,
                })}
            />

            <RowGroupWrapper label={"Status"}>
                <FormElementWrapper
                    label={"Active"}
                    formDisplayData={formDisplayData}
                    field={properties.active}
                    FormElement={getBoolFormElement<InteriorColorTagAttributesDTO, InteriorColorTagAttributesUpdateDTO>()}
                    getViewProps={(data) => ({
                        falseMessage: "Inactive",
                        trueMessage: "Active",
                        value: data.active,
                    })}
                    getEditProps={(data) => ({
                        falseMessage: "Inactive",
                        trueMessage: "Active",
                        value: data.active,
                    })}
                />
            </RowGroupWrapper>

            <RowGroupWrapper label={"Color"}>
                <FormElementWrapper
                    label={"Hex Code"}
                    formDisplayData={formDisplayData}
                    field={properties.hexCode}
                    FormElement={getColorPickerFormElement<InteriorColorTagAttributesDTO, InteriorColorTagCreateRequestDTO>()}
                    getViewProps={(data) => ({
                        hexValue: data.hexCode,
                    })}
                    getEditProps={(data) => ({
                        hexValue: data.hexCode,
                    })}
                />
            </RowGroupWrapper>
        </SpacedItems>
    )
}
