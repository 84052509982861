import { modelGenerationApi } from "../../../../pdsapi"
import { getProductSortContainer, ProductSortContainerConfig } from "../../../../viewtemplates/sort/ProductSortContainer"
import { Route } from "react-router-dom"
import { routes } from "../../../../common/routes"
import { ModelGenerationProductListContainerConfig } from "../list/ModelGenerationListRoute"

export const ModelGenerationSortContainerTemplateProps: ProductSortContainerConfig = {
    fetchSortables: modelGenerationApi.sort.getSortableItems,
    updateSortIndices: (sortIndexUpdate) => modelGenerationApi.sort.updateSortIndices({ sortIndexUpdateRequestDTO: sortIndexUpdate }),
}

const ModelGenerationSortContainer = getProductSortContainer(ModelGenerationSortContainerTemplateProps)

export const ModelGenerationSortRoute = (
    <Route
        path={routes.models.modelGenerations.sort()}
        element={
            <ModelGenerationSortContainer
                parentCrumbs={[
                    {
                        name: ModelGenerationProductListContainerConfig.listName,
                        path: routes.models.modelGenerations.list(),
                    },
                ]}
            />
        }
    />
)
