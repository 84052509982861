import { propertiesOf } from "../../../../../../../common/property"
import { FormElementWrapper } from "../../../../../../../components/formelements/FormElementWrapper"
import { ReadonlyFormElementWrapper } from "../../../../../../../components/formelements/ReadonlyFormElementWrapper"
import { getDerivedNameFormElement } from "../../../../../../../components/formelements/derivednameformelement/DerivedNameFormElement"
import { getNumberFormElement } from "../../../../../../../components/formelements/numberformelement/NumberFormElement"
import { getTextFormElement } from "../../../../../../../components/formelements/textformelement/TextFormElement"
import { rowGroupsSpacing, RowGroupWrapper } from "../../../../../../../components/layouts/rowgroupwrapper/RowGroupWrapper"
import { SpacedItems } from "../../../../../../../components/layouts/spaceditems/SpacedItems"
import { OptionDTOPaintToSampleExteriorColor, OptionUpdateDTOPaintToSampleExteriorColor } from "../../../../../../../generated/pdsapi"
import { ProductDetailsCardContentProps } from "../../../../../../../viewtemplates/details/cards/ProductDetailsCard"
import { FC } from "react"
import { OptionImage } from "../../../../../../../components/optionimage/OptionImage"
import { PIcon, PText } from "@porsche-design-system/components-react"
import { routes } from "../../../../../../../common/routes"
import { getTagFormTextElement } from "../../../../../../../components/formelements/tagformtextelement/TagFormTextElement"
import { useOrderTypeOptionsContext } from "../../../../OrderTypeOptionsContext"
import { getTagFormMultiTextElement } from "../../../../../../../components/formelements/tagformmultitextelement/TagFormMultiTextElement"
import { OldOrderTypeOptionKey } from "../../../../OrderTypeOptionKey"

export const PaintToSampleExteriorColorOptionCardContent: FC<
    ProductDetailsCardContentProps<OptionDTOPaintToSampleExteriorColor, OptionUpdateDTOPaintToSampleExteriorColor, OldOrderTypeOptionKey>
> = ({ formDisplayData }) => {
    const properties = propertiesOf<OptionUpdateDTOPaintToSampleExteriorColor>()
    const { orderTypeKey } = useOrderTypeOptionsContext()

    return (
        <SpacedItems direction={"column"} lineSpacing={rowGroupsSpacing}>
            <RowGroupWrapper label={"Configuration"}>
                <ReadonlyFormElementWrapper
                    label={"Option Code"}
                    formDisplayData={formDisplayData}
                    FormElement={getTextFormElement()}
                    getViewProps={(data) => ({
                        value: data.optionCode,
                    })}
                />
            </RowGroupWrapper>
            <RowGroupWrapper>
                <FormElementWrapper
                    label={"Sort Index"}
                    formDisplayData={formDisplayData}
                    field={properties.sortIndex}
                    FormElement={getNumberFormElement<OptionDTOPaintToSampleExteriorColor, OptionUpdateDTOPaintToSampleExteriorColor>()}
                    getViewProps={(data) => ({
                        value: data.sortIndex,
                    })}
                    getEditProps={(data) => ({
                        value: data.sortIndex,
                        step: 1,
                        min: 0,
                    })}
                />
            </RowGroupWrapper>

            <RowGroupWrapper label={"Name"}>
                {(["de", "en"] as const).map((languageTag) => (
                    <FormElementWrapper
                        key={languageTag}
                        label={`Name - ${languageTag}`}
                        formDisplayData={formDisplayData}
                        field={properties.name[languageTag]}
                        FormElement={getDerivedNameFormElement<OptionDTOPaintToSampleExteriorColor, OptionUpdateDTOPaintToSampleExteriorColor>()}
                        getViewProps={(data) => {
                            const name = data.name[languageTag]
                            return {
                                primaryName: {
                                    value: name.merged.withFallback,
                                    isDerived: !name.merged.raw,
                                },
                                secondaryNames: [
                                    {
                                        label: "Option",
                                        value: name.option.withFallback,
                                        isDerived: !name.option.raw,
                                    },
                                    {
                                        label: "Customization",
                                        value: name.customization?.withFallback,
                                        isDerived: !name.customization?.raw,
                                    },
                                ],
                            }
                        }}
                        getEditProps={(data) => ({
                            rawName: data.name[languageTag].option.raw,
                        })}
                    />
                ))}
            </RowGroupWrapper>

            <RowGroupWrapper label={"Description"}>
                {(["de", "en"] as const).map((languageTag) => (
                    <FormElementWrapper
                        key={languageTag}
                        label={`Description - ${languageTag}`}
                        formDisplayData={formDisplayData}
                        field={properties.description[languageTag]}
                        FormElement={getDerivedNameFormElement<OptionDTOPaintToSampleExteriorColor, OptionUpdateDTOPaintToSampleExteriorColor>()}
                        getViewProps={(data) => {
                            const description = data.description[languageTag]
                            return {
                                primaryName: {
                                    value: description.merged.withFallback,
                                    isDerived: !description.merged.raw,
                                },
                                secondaryNames: [
                                    {
                                        label: "Option",
                                        value: description.option.withFallback,
                                        isDerived: !description.option.raw,
                                    },
                                    {
                                        label: "Customization",
                                        value: description.customization?.withFallback,
                                        isDerived: !description.customization?.raw,
                                    },
                                ],
                            }
                        }}
                        getEditProps={(data) => ({
                            rawName: data.description[languageTag].option.raw,
                        })}
                    />
                ))}
            </RowGroupWrapper>

            <RowGroupWrapper label={"References"}>
                <FormElementWrapper
                    label={"Paint to Sample Equipment Option Code"}
                    formDisplayData={formDisplayData}
                    field={properties.paintToSampleEquipmentOptionCode}
                    FormElement={getTagFormTextElement<OptionDTOPaintToSampleExteriorColor, OptionUpdateDTOPaintToSampleExteriorColor>()}
                    getViewProps={(data) => ({
                        tag: data.paintToSampleEquipmentOptionCode
                            ? {
                                  label: data.paintToSampleEquipmentOptionCode,
                                  route: routes.models.orderTypes.subpages(orderTypeKey).equipment.details(data.paintToSampleEquipmentOptionCode),
                              }
                            : undefined,
                    })}
                    getEditProps={(data) => ({
                        value: data.paintToSampleEquipmentOptionCode ?? "",
                        readOnly: false,
                        optional: true,
                    })}
                />
                <FormElementWrapper
                    label={"Paint to Sample Plus Equipment Option Code"}
                    formDisplayData={formDisplayData}
                    field={properties.paintToSamplePlusEquipmentOptionCode}
                    FormElement={getTagFormTextElement<OptionDTOPaintToSampleExteriorColor, OptionUpdateDTOPaintToSampleExteriorColor>()}
                    getViewProps={(data) => ({
                        tag: data.paintToSamplePlusEquipmentOptionCode
                            ? {
                                  label: data.paintToSamplePlusEquipmentOptionCode,
                                  route: routes.models.orderTypes.subpages(orderTypeKey).equipment.details(data.paintToSamplePlusEquipmentOptionCode),
                              }
                            : undefined,
                    })}
                    getEditProps={(data) => ({
                        value: data.paintToSamplePlusEquipmentOptionCode ?? "",
                        readOnly: false,
                        optional: true,
                    })}
                />
            </RowGroupWrapper>
            <RowGroupWrapper childrenSize={8}>
                <FormElementWrapper
                    label={"Z-Order Option Code(s)"}
                    formDisplayData={formDisplayData}
                    field={properties.zOrderOptionCodes}
                    FormElement={getTagFormMultiTextElement<OptionDTOPaintToSampleExteriorColor, OptionUpdateDTOPaintToSampleExteriorColor>()}
                    getViewProps={(data) => ({
                        tags: (data.zOrderOptionCodes ?? []).map((value) => ({
                            label: value,
                            route: routes.models.orderTypes.subpages(orderTypeKey).equipment.details(value),
                        })),
                        emptyLabel: "-",
                    })}
                    getEditProps={(data) => ({
                        value: data.zOrderOptionCodes ?? [],
                        readOnly: false,
                        optional: true,
                    })}
                />
                <div style={{ display: "flex", gap: "4px" }}>
                    <PIcon name={"information"} color={"contrast-medium"}></PIcon>
                    <PText color={"contrast-medium"} size={"x-small"}>
                        Comma-separated list of Z-Order Options from which to take the colors for this Paint to Sample Option (e.g. "24931, 25401"). Ideally
                        this field contains only one option code, but on some older order types we have found more than one.
                    </PText>
                </div>
            </RowGroupWrapper>

            <RowGroupWrapper label={"Images"}>
                {formDisplayData.data?.imageKeys.map((imageKey) => <OptionImage key={imageKey} imageKey={imageKey} sizes={"400px"} />)}
            </RowGroupWrapper>
        </SpacedItems>
    )
}
