import { TableFilterProps } from "../../../../../components/filteredpagedtable/FilteredPagedTable"
import { toOption } from "../../../../../components/formelements/selecttextformelement/Option"
import { ColumnGroupsWrapper } from "../../../../../components/layouts/columngroupswrapper/ColumnGroupsWrapper"
import { ColumnGroupWrapper } from "../../../../../components/layouts/columngroupwrapper/ColumnGroupWrapper"
import { EquipmentTagCategory, OptionTagFilterOptionsDTO, OptionTagTypeDTO } from "../../../../../generated/pdsapi"
import { OptionTagFilter } from "../../../../../pdsapi"
import { MultiSelect } from "@finder/ui-kit"
import { FunctionComponent } from "react"
import { ActiveFilter } from "../../../../../components/filteredpagedtable/filter/active/ActiveFilter"

export const OptionTagListFilter: FunctionComponent<TableFilterProps<OptionTagFilter, OptionTagFilterOptionsDTO>> = ({ filter, setFilter, filterOptions }) => {
    const typeFilter = (
        <MultiSelect
            label={"Types"}
            placeholder={"All"}
            clearable={true}
            searchable={true}
            options={filterOptions?.types?.map(toOption)}
            value={filter.types ?? []}
            onChange={(types) =>
                setFilter((filter) => ({
                    ...filter,
                    types: types.length === 0 ? undefined : (types as OptionTagTypeDTO[]),
                }))
            }
        />
    )
    const activeFilter = (
        <ActiveFilter
            active={filter.active}
            onChange={(active) =>
                setFilter((filter) => ({
                    ...filter,
                    active,
                }))
            }
        />
    )
    const equipmentTagCategoryFilter = (
        <MultiSelect
            label={"Equipment Tag Categories"}
            placeholder={"All"}
            clearable={true}
            searchable={true}
            options={filterOptions?.equipmentTagCategories?.map(toOption)}
            value={filter.equipmentTagCategories ?? []}
            onChange={(equipmentTagCategories) =>
                setFilter((filter) => ({
                    ...filter,
                    equipmentTagCategories: equipmentTagCategories.length === 0 ? undefined : (equipmentTagCategories as EquipmentTagCategory[]),
                }))
            }
        />
    )

    return (
        <ColumnGroupsWrapper>
            <ColumnGroupWrapper label={"Type"}>{typeFilter}</ColumnGroupWrapper>
            <ColumnGroupWrapper label={"Status"}>{activeFilter}</ColumnGroupWrapper>
            <ColumnGroupWrapper label={"Category"}>{equipmentTagCategoryFilter}</ColumnGroupWrapper>
        </ColumnGroupsWrapper>
    )
}
