import React, { FC } from "react"
import { routes } from "../../../../../../../common/routes"
import { PInlineNotification, PText } from "@porsche-design-system/components-react"
import { Tag } from "../../../../../../../components/tag/Tag"
import { useNavigate } from "react-router-dom"
import { ProductDetailsCardNotificationProps } from "../../../../../../../viewtemplates/details/cards/ProductDetailsCard"
import {
    OptionDTO,
    OptionDTOPaintToSampleExteriorColor,
    OptionUpdateDTOOption,
    OptionUpdateDTOPaintToSampleExteriorColor,
} from "../../../../../../../generated/pdsapi"
import { OrderTypeOptionKey } from "../../../../OrderTypeOptionKey"

const CardNotification: FC<{ orderTypeKey: string; equipmentOptionCode: string; text: string }> = ({ orderTypeKey, equipmentOptionCode, text }) => {
    const navigate = useNavigate()
    return (
        <PInlineNotification state={"warning"} dismissButton={false} style={{ marginBottom: 8 }}>
            <div style={{ display: "flex", gap: "4px" }}>
                <PText color={"contrast-medium"} size={"x-small"}>
                    {text}
                </PText>
                <Tag
                    key={`Tag_${equipmentOptionCode}`}
                    onClick={() => navigate(routes.models.orderTypes.subpages(orderTypeKey).equipment.details(equipmentOptionCode))}
                >
                    {equipmentOptionCode}
                </Tag>
            </div>
        </PInlineNotification>
    )
}

export const OptionItemDetailsCardNotification: FC<ProductDetailsCardNotificationProps<OptionDTO, OptionUpdateDTOOption, OrderTypeOptionKey>> = ({
    itemKey,
    data,
}) => {
    if (data.type === "PaintToSampleExteriorColor") {
        const { paintToSampleEquipmentOptionCode, paintToSamplePlusEquipmentOptionCode } = data as
            | OptionDTOPaintToSampleExteriorColor
            | OptionUpdateDTOPaintToSampleExteriorColor
        return (
            <>
                {paintToSampleEquipmentOptionCode && (
                    <CardNotification
                        orderTypeKey={itemKey.orderTypeKey}
                        equipmentOptionCode={paintToSampleEquipmentOptionCode}
                        text={"Customers will see the information for Paint to Sample from"}
                    />
                )}
                {paintToSamplePlusEquipmentOptionCode && (
                    <CardNotification
                        orderTypeKey={itemKey.orderTypeKey}
                        equipmentOptionCode={paintToSamplePlusEquipmentOptionCode}
                        text={"Customers will see the information for Paint to Sample Plus from"}
                    />
                )}
                {(paintToSampleEquipmentOptionCode || paintToSamplePlusEquipmentOptionCode) && <div style={{ height: 8 }} />}
            </>
        )
    } else {
        return null
    }
}
