import { usePdsBootContext } from "../../common/PdsBootContext"
import { showErrorToast } from "../../common/errorToastHelper"
import { flattenDeepMapIntoValidationErrorMap } from "../../common/forms"
import { browserLanguage, hasMessage, removeEmptyFields } from "../../common/global"
import { Crumb } from "../../components/breadcrumb/Breadcrumb"
import { EditFormDisplayData, LoadingFormDisplayData } from "../../components/formelements/FormDisplayData"
import { ViewHeadline } from "../../components/headlines/viewheadline/ViewHeadline"
import { UserAuthority } from "../../generated/pdsapi"
import { convertValidationErrorsToMap, isValidationApiError } from "../../pdsapi/apiHelper"
import { useToast } from "@finder/ui-kit"
import { PButton } from "@porsche-design-system/components-react"
import { FC, JSX, useCallback, useEffect, useRef, useState } from "react"
import { FieldValues, Path, useForm } from "react-hook-form"
import { useNavigate } from "react-router-dom"

export interface ProductCreateContentProps<DATA extends FieldValues, CREATE_OPTIONS> {
    formDisplayData: LoadingFormDisplayData | EditFormDisplayData<CREATE_OPTIONS, DATA>
}

export interface ProductCreateContainerConfig<DATA extends FieldValues, CREATE_OPTIONS, KEY = { key: string }> {
    headline: string
    buildDetailsPath: (key: KEY) => string

    getCreateOptions: (props: { languageTag: string }) => Promise<CREATE_OPTIONS>
    create: (item: DATA) => Promise<KEY>

    Content: FC<ProductCreateContentProps<DATA, CREATE_OPTIONS>>
}

export interface ProductCreateContainerProps {
    parentCrumbs: Crumb[]
}

export const getProductCreateContainer = <DATA extends FieldValues, CREATE_OPTIONS, KEY = { key: string }>({
    Content,
    create,
    getCreateOptions,
    headline,
    buildDetailsPath,
}: ProductCreateContainerConfig<DATA, CREATE_OPTIONS, KEY>) => {
    const ProductCreateContainer: FC<ProductCreateContainerProps> = ({ parentCrumbs }) => {
        const toastRef = useRef(useToast())
        const navigate = useNavigate()
        const parentPath = parentCrumbs.findLast((crumb) => crumb.path)?.path ?? "/"

        const [createOptions, setCreateOptions] = useState<CREATE_OPTIONS | undefined>(undefined)

        useEffect(() => {
            const fetchSelectOptions = async () => {
                try {
                    const options = await getCreateOptions({ languageTag: browserLanguage })
                    setCreateOptions(options)
                } catch (e) {
                    toastRef.current.show("warning", hasMessage(e) ? e.message : "Something went wrong")
                }
            }

            // noinspection JSIgnoredPromiseFromCall
            fetchSelectOptions()
        }, [])

        const handleCancel = useCallback(() => {
            navigate(parentPath)
        }, [parentPath])

        const cancelButton = (
            <PButton key={"closeButton"} icon={"close"} onClick={handleCancel} variant={"tertiary"} type={"button"}>
                Cancel
            </PButton>
        )

        return createOptions ? (
            <Edit
                headline={headline}
                cancelButton={cancelButton}
                parentCrumbs={parentCrumbs}
                buildDetailsPath={buildDetailsPath}
                createOptions={createOptions}
                Content={Content}
                create={create}
            />
        ) : (
            <Loading headline={headline} cancelButton={cancelButton} parentCrumbs={parentCrumbs} Content={Content} />
        )
    }

    return ProductCreateContainer
}

interface LoadingProps {
    headline: string
    cancelButton: JSX.Element
    parentCrumbs: Crumb[]
    Content: FC<ProductCreateContentProps<any, any>>
}

const Loading = ({ headline, cancelButton, parentCrumbs, Content }: LoadingProps) => (
    <>
        <ViewHeadline headline={headline} actions={[cancelButton]} parentCrumbs={parentCrumbs} />

        <Content formDisplayData={{ kind: "LOADING" }} />
    </>
)

interface EditProps<DATA extends FieldValues, CREATE_OPTIONS, KEY = { key: string }> {
    headline: string
    cancelButton: JSX.Element
    parentCrumbs: Crumb[]
    buildDetailsPath: (key: KEY) => string
    createOptions: CREATE_OPTIONS
    create: (item: DATA) => Promise<KEY>
    Content: FC<ProductCreateContentProps<DATA, CREATE_OPTIONS>>
}

const Edit = <DATA extends FieldValues, CREATE_OPTIONS, K>({
    headline,
    cancelButton,
    parentCrumbs,
    buildDetailsPath,
    Content,
    createOptions,
    create,
}: EditProps<DATA, CREATE_OPTIONS, K>) => {
    const navigate = useNavigate()
    const { hasAuthority } = usePdsBootContext()
    const toastRef = useRef(useToast())

    const { register, control, handleSubmit, formState, setValue, watch } = useForm<DATA>()
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [validationErrors, setValidationErrors] = useState<Map<Path<DATA>, string>>(new Map())

    useEffect(() => {
        setValidationErrors(flattenDeepMapIntoValidationErrorMap(formState.errors))
    }, [formState.errors])

    const onSubmit = handleSubmit(async (item: DATA) => {
        setIsSubmitting(true)
        const cleanedObj = removeEmptyFields(item)

        try {
            const key = await create(cleanedObj)
            toastRef.current.show("success", "Created successfully.")
            navigate(buildDetailsPath(key))
        } catch (e) {
            if (isValidationApiError(e)) {
                setValidationErrors(convertValidationErrorsToMap(e.validationErrors!))
            }

            showErrorToast(toastRef.current, e)
        } finally {
            setIsSubmitting(false)
        }
    })

    const formDisplayData: EditFormDisplayData<CREATE_OPTIONS, DATA> = {
        kind: "EDIT",
        data: createOptions,
        register,
        setValue,
        watch,
        control,
        validationErrors,
    }

    const actions = [cancelButton]
    if (hasAuthority(UserAuthority.MAINTAIN_DATA)) {
        actions.push(
            <PButton key={"saveButton"} icon={"save"} type={"submit"} loading={isSubmitting}>
                Save
            </PButton>
        )
    }

    return (
        <form onSubmit={onSubmit}>
            <ViewHeadline headline={headline} actions={actions} parentCrumbs={parentCrumbs} />

            <Content formDisplayData={formDisplayData} />
        </form>
    )
}
