import { FC } from "react"
import { OrderTypeManualPressEmbargoesUpdateDTO, OrderTypePressEmbargoesDTO } from "../../../../../generated/pdsapi"
import { propertiesOf } from "../../../../../common/property"
import { FormElementWrapper } from "../../../../../components/formelements/FormElementWrapper"
import { rowGroupsSpacing, RowGroupWrapper, RowGroupWrapperProps } from "../../../../../components/layouts/rowgroupwrapper/RowGroupWrapper"
import { SpacedItems } from "../../../../../components/layouts/spaceditems/SpacedItems"
import { ProductDetailsCardContentProps } from "../../../../../viewtemplates/details/cards/ProductDetailsCard"
import { LabeledContentWrapper } from "../../../../../components/layouts/labeledcontentwrapper/LabeledContentWrapper"
import { PText } from "@porsche-design-system/components-react"
import { SkeletonText } from "../../../../../components/skeleton/SkeletonText"
import { getDateTimeFormElement } from "../../../../../components/formelements/datetimeformelement/DateTimeFormElement"
import { usePdsBootContext } from "../../../../../common/PdsBootContext"
import { formatAsUtcDateTime } from "../../../../../common/global"
import { BasicKey } from "../../../../../viewtemplates/details/ProductDetailsContainer"

export const OrderTypePressEmbargoesCardContent: FC<
    ProductDetailsCardContentProps<OrderTypePressEmbargoesDTO, OrderTypeManualPressEmbargoesUpdateDTO, BasicKey>
> = ({ formDisplayData }) => {
    const properties = propertiesOf<OrderTypeManualPressEmbargoesUpdateDTO>()
    const { supportedMarketplaces } = usePdsBootContext()
    return (
        <SpacedItems direction={"column"} lineSpacing={rowGroupsSpacing}>
            <PressEmbargoRowGroupWrapper label={"Global"}>
                <LabeledContentWrapper labelColor={"neutral-contrast-medium"} label={"Synced"}>
                    {formDisplayData.data ? <PText>{formatAsUtcDateTime(formDisplayData.data?.globalPressEmbargo.synced) ?? "-"}</PText> : <SkeletonText />}
                </LabeledContentWrapper>
                <FormElementWrapper
                    label={"Manual"}
                    formDisplayData={formDisplayData}
                    field={properties.global}
                    FormElement={getDateTimeFormElement<OrderTypePressEmbargoesDTO, OrderTypeManualPressEmbargoesUpdateDTO>()}
                    getViewProps={(data) => ({
                        value: data.globalPressEmbargo.manual,
                        showAsHint: false,
                    })}
                    getEditProps={(data) => ({
                        value: data.globalPressEmbargo.manual,
                        readOnly: false,
                        optional: true,
                    })}
                />
            </PressEmbargoRowGroupWrapper>
            {supportedMarketplaces.map((marketplace) => (
                <PressEmbargoRowGroupWrapper label={marketplace.label}>
                    <LabeledContentWrapper labelColor={"neutral-contrast-medium"} label={"Synced"}>
                        {formDisplayData.data ? (
                            <PText>
                                {formatAsUtcDateTime(formDisplayData.data?.marketplaceSpecificPressEmbargoes[marketplace.marketplace]?.synced) ?? "-"}
                            </PText>
                        ) : (
                            <SkeletonText />
                        )}
                    </LabeledContentWrapper>
                    <FormElementWrapper
                        label={"Manual"}
                        formDisplayData={formDisplayData}
                        field={properties.marketplaceSpecifics[marketplace.marketplace]}
                        FormElement={getDateTimeFormElement<OrderTypePressEmbargoesDTO, OrderTypeManualPressEmbargoesUpdateDTO>()}
                        getViewProps={(data) => ({
                            value: data.marketplaceSpecificPressEmbargoes[marketplace.marketplace]?.manual,
                            showAsHint: false,
                        })}
                        getEditProps={(data) => ({
                            value: data.marketplaceSpecificPressEmbargoes[marketplace.marketplace]?.manual,
                            readOnly: false,
                            optional: true,
                        })}
                    />
                </PressEmbargoRowGroupWrapper>
            ))}
        </SpacedItems>
    )
}

/**
 * Just a RowGroupWrapper with predefined sizings for our press-embargo use-case
 */
const PressEmbargoRowGroupWrapper: FC<RowGroupWrapperProps> = (props) => (
    <RowGroupWrapper parentSize={{ base: 12, m: 3 }} childrenContainerSize={{ base: 12, m: 9 }} childrenSize={6} {...props} />
)
