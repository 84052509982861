/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    InteriorMaterial,
    InteriorMaterialFromJSON,
    InteriorMaterialToJSON,
    OptionCustomizationFilterOptionsDTO,
    OptionCustomizationFilterOptionsDTOFromJSON,
    OptionCustomizationFilterOptionsDTOToJSON,
    OptionCustomizationSortField,
    OptionCustomizationSortFieldFromJSON,
    OptionCustomizationSortFieldToJSON,
    OptionCustomizationTypeDTO,
    OptionCustomizationTypeDTOFromJSON,
    OptionCustomizationTypeDTOToJSON,
    PageDTOOptionCustomizationItemDTO,
    PageDTOOptionCustomizationItemDTOFromJSON,
    PageDTOOptionCustomizationItemDTOToJSON,
    SortDirection,
    SortDirectionFromJSON,
    SortDirectionToJSON,
} from '../models';

export interface GetOptionCustomizationFilterOptionsRequest {
    languageTag: string;
}

export interface GetOptionCustomizationsPageRequest {
    languageTag: string;
    sortField: OptionCustomizationSortField;
    offset: number;
    size: number;
    sortDirection?: SortDirection;
    search?: string;
    types?: Array<OptionCustomizationTypeDTO>;
    modelGenerationKeys?: Array<string>;
    equipmentTagKeys?: Array<string>;
    interiorColorTagKeys?: Array<string>;
    exteriorColorTagKeys?: Array<string>;
    roofColorTagKeys?: Array<string>;
    interiorMaterials?: Array<InteriorMaterial>;
}

/**
 * 
 */
export class OptionCustomizationListWebControllerApi extends runtime.BaseAPI {

    /**
     * Get options to filter OptionCustomizations
     */
    async getOptionCustomizationFilterOptionsRaw(requestParameters: GetOptionCustomizationFilterOptionsRequest): Promise<runtime.ApiResponse<OptionCustomizationFilterOptionsDTO>> {
        if (requestParameters.languageTag === null || requestParameters.languageTag === undefined) {
            throw new runtime.RequiredError('languageTag','Required parameter requestParameters.languageTag was null or undefined when calling getOptionCustomizationFilterOptions.');
        }

        const queryParameters: any = {};

        if (requestParameters.languageTag !== undefined) {
            queryParameters['languageTag'] = requestParameters.languageTag;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/web/option-customizations/list/filter-options`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => OptionCustomizationFilterOptionsDTOFromJSON(jsonValue));
    }

    /**
     * Get options to filter OptionCustomizations
     */
    async getOptionCustomizationFilterOptions(requestParameters: GetOptionCustomizationFilterOptionsRequest): Promise<OptionCustomizationFilterOptionsDTO> {
        const response = await this.getOptionCustomizationFilterOptionsRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get page of OptionCustomizations
     */
    async getOptionCustomizationsPageRaw(requestParameters: GetOptionCustomizationsPageRequest): Promise<runtime.ApiResponse<PageDTOOptionCustomizationItemDTO>> {
        if (requestParameters.languageTag === null || requestParameters.languageTag === undefined) {
            throw new runtime.RequiredError('languageTag','Required parameter requestParameters.languageTag was null or undefined when calling getOptionCustomizationsPage.');
        }

        if (requestParameters.sortField === null || requestParameters.sortField === undefined) {
            throw new runtime.RequiredError('sortField','Required parameter requestParameters.sortField was null or undefined when calling getOptionCustomizationsPage.');
        }

        if (requestParameters.offset === null || requestParameters.offset === undefined) {
            throw new runtime.RequiredError('offset','Required parameter requestParameters.offset was null or undefined when calling getOptionCustomizationsPage.');
        }

        if (requestParameters.size === null || requestParameters.size === undefined) {
            throw new runtime.RequiredError('size','Required parameter requestParameters.size was null or undefined when calling getOptionCustomizationsPage.');
        }

        const queryParameters: any = {};

        if (requestParameters.languageTag !== undefined) {
            queryParameters['languageTag'] = requestParameters.languageTag;
        }

        if (requestParameters.sortField !== undefined) {
            queryParameters['sortField'] = requestParameters.sortField;
        }

        if (requestParameters.sortDirection !== undefined) {
            queryParameters['sortDirection'] = requestParameters.sortDirection;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.types) {
            queryParameters['types'] = requestParameters.types;
        }

        if (requestParameters.modelGenerationKeys) {
            queryParameters['modelGenerationKeys'] = requestParameters.modelGenerationKeys;
        }

        if (requestParameters.equipmentTagKeys) {
            queryParameters['equipmentTagKeys'] = requestParameters.equipmentTagKeys;
        }

        if (requestParameters.interiorColorTagKeys) {
            queryParameters['interiorColorTagKeys'] = requestParameters.interiorColorTagKeys;
        }

        if (requestParameters.exteriorColorTagKeys) {
            queryParameters['exteriorColorTagKeys'] = requestParameters.exteriorColorTagKeys;
        }

        if (requestParameters.roofColorTagKeys) {
            queryParameters['roofColorTagKeys'] = requestParameters.roofColorTagKeys;
        }

        if (requestParameters.interiorMaterials) {
            queryParameters['interiorMaterials'] = requestParameters.interiorMaterials;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/web/option-customizations/list/page`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PageDTOOptionCustomizationItemDTOFromJSON(jsonValue));
    }

    /**
     * Get page of OptionCustomizations
     */
    async getOptionCustomizationsPage(requestParameters: GetOptionCustomizationsPageRequest): Promise<PageDTOOptionCustomizationItemDTO> {
        const response = await this.getOptionCustomizationsPageRaw(requestParameters);
        return await response.value();
    }

}
