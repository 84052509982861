import { FormElementWrapper } from "../../../../../../components/formelements/FormElementWrapper"
import { toGroupedOption } from "../../../../../../components/formelements/groupedselecttextformelement/GroupedSelectOption"
import { getGroupedTagsFormElement } from "../../../../../../components/formelements/groupedtagsformelement/GroupedTagsFormElement"
import { toTagOption } from "../../../../../../components/formelements/tagformelement/TagOption"
import { rowGroupsSpacing, RowGroupWrapper } from "../../../../../../components/layouts/rowgroupwrapper/RowGroupWrapper"
import { SpacedItems } from "../../../../../../components/layouts/spaceditems/SpacedItems"
import { ProductDetailsCardContentProps } from "../../../../../../viewtemplates/details/cards/ProductDetailsCard"
import { DetailsDefaultLocalizationsFormRow } from "../../../../../../viewtemplates/details/cards/components/defaultlocalizationsformrow/DetailsDefaultLocalizationsFormRow"
import { FC } from "react"
import {
    OldOptionCustomizationAttributesDTOEquipmentCustomizationAttributesValueDTO,
    OldOptionCustomizationAttributesUpdateDTOEquipmentCustomizationAttributesValueUpdateDTO,
} from "../../../../../../generated/pdsapi"
import {
    OptionCustomizationDetailsAttributesCardContent,
    OptionCustomizationValueContent,
} from "../../../../../../components/optioncustomization/OptionCustomizationDetailsAttributesCardContent"
import { routes } from "../../../../../../common/routes"
import { BasicKey } from "../../../../../../viewtemplates/details/ProductDetailsContainer"

type AttributesDTO = OldOptionCustomizationAttributesDTOEquipmentCustomizationAttributesValueDTO
type Value = AttributesDTO["defaultValue"]
type AttributesUpdateDTO = OldOptionCustomizationAttributesUpdateDTOEquipmentCustomizationAttributesValueUpdateDTO
type ValueUpdate = AttributesUpdateDTO["defaultValue"]

export const EquipmentCustomizationDetailsAttributesCardContent: FC<ProductDetailsCardContentProps<AttributesDTO, AttributesUpdateDTO, BasicKey>> = (props) => (
    <OptionCustomizationDetailsAttributesCardContent
        {...props}
        convertValueToValueUpdate={convertValueToValueUpdate}
        CustomizationValueContent={CustomizationValueContent}
    />
)

const convertValueToValueUpdate = (value: Value): ValueUpdate => ({
    name: value.name,
    description: value.description,
    equipmentTagKeys: value.equipmentTags.value.map((option) => option.key),
})

const CustomizationValueContent: OptionCustomizationValueContent<Value, ValueUpdate> = ({ getValue, property, formDisplayData }) => (
    <SpacedItems direction={"column"} lineSpacing={rowGroupsSpacing}>
        <DetailsDefaultLocalizationsFormRow
            label={"Name"}
            formDisplayData={formDisplayData}
            field={property.name}
            getViewProps={(data) => ({
                value: getValue(data).name,
            })}
            getEditProps={(data) => ({
                value: getValue(data).name,
                optional: true,
            })}
        />
        <DetailsDefaultLocalizationsFormRow
            label={"Description"}
            formDisplayData={formDisplayData}
            field={property.description}
            getViewProps={(data) => ({
                value: getValue(data).description,
            })}
            getEditProps={(data) => ({
                value: getValue(data).description,
                optional: true,
            })}
        />
        <RowGroupWrapper label={"Tags"}>
            <FormElementWrapper
                label={"Equipment Tags"}
                formDisplayData={formDisplayData}
                field={property.equipmentTagKeys}
                FormElement={getGroupedTagsFormElement<AttributesDTO, AttributesUpdateDTO>()}
                getViewProps={(data) => ({
                    tags: getValue(data).equipmentTags.value.map((option) => toTagOption(option, routes.equipment.tags.details)),
                    emptyLabel: "-",
                })}
                getEditProps={(data) => ({
                    value: getValue(data).equipmentTags.value.map((option) => option.key),
                    options: getValue(data).equipmentTags.categorizedOptions.map(toGroupedOption),
                    emptyLabel: "-",
                    clearable: true,
                })}
            />
        </RowGroupWrapper>
    </SpacedItems>
)
