/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    BaseNameDTO,
    BaseNameDTOFromJSON,
    BaseNameDTOFromJSONTyped,
    BaseNameDTOToJSON,
} from './';

/**
 * 
 * @export
 * @interface LinkedCustomizationDTOCustomizationValuePaintToSampleExteriorColorInternal
 */
export interface LinkedCustomizationDTOCustomizationValuePaintToSampleExteriorColorInternal {
    /**
     * 
     * @type {string}
     * @memberof LinkedCustomizationDTOCustomizationValuePaintToSampleExteriorColorInternal
     */
    type: string;
    /**
     * 
     * @type {BaseNameDTO}
     * @memberof LinkedCustomizationDTOCustomizationValuePaintToSampleExteriorColorInternal
     */
    name: BaseNameDTO;
}

export function LinkedCustomizationDTOCustomizationValuePaintToSampleExteriorColorInternalFromJSON(json: any): LinkedCustomizationDTOCustomizationValuePaintToSampleExteriorColorInternal {
    return LinkedCustomizationDTOCustomizationValuePaintToSampleExteriorColorInternalFromJSONTyped(json, false);
}

export function LinkedCustomizationDTOCustomizationValuePaintToSampleExteriorColorInternalFromJSONTyped(json: any, ignoreDiscriminator: boolean): LinkedCustomizationDTOCustomizationValuePaintToSampleExteriorColorInternal {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'type': json['type'],
        'name': BaseNameDTOFromJSON(json['name']),
    };
}

export function LinkedCustomizationDTOCustomizationValuePaintToSampleExteriorColorInternalToJSON(value?: LinkedCustomizationDTOCustomizationValuePaintToSampleExteriorColorInternal | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'type': value.type,
        'name': BaseNameDTOToJSON(value.name),
    };
}


