/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    OptionCustomizationCreateRequestDTOEquipmentInternal,
    OptionCustomizationCreateRequestDTOEquipmentInternalFromJSONTyped,
    OptionCustomizationCreateRequestDTOEquipmentInternalToJSON,
} from './';

/**
 * @type OptionCustomizationCreateRequestDTOEquipment
 * 
 * @export
 */
export type OptionCustomizationCreateRequestDTOEquipment = { type: 'Equipment' } & OptionCustomizationCreateRequestDTOEquipmentInternal;

export function OptionCustomizationCreateRequestDTOEquipmentFromJSON(json: any): OptionCustomizationCreateRequestDTOEquipment {
    return OptionCustomizationCreateRequestDTOEquipmentFromJSONTyped(json, false);
}

export function OptionCustomizationCreateRequestDTOEquipmentFromJSONTyped(json: any, ignoreDiscriminator: boolean): OptionCustomizationCreateRequestDTOEquipment {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    switch (json['type']) {
        case 'Equipment':
            return {...OptionCustomizationCreateRequestDTOEquipmentInternalFromJSONTyped(json, true), type: 'Equipment'};
        default:
            throw new Error(`No variant of OptionCustomizationCreateRequestDTOEquipment exists with 'type=${json['type']}'`);
    }
}

export function OptionCustomizationCreateRequestDTOEquipmentToJSON(value?: OptionCustomizationCreateRequestDTOEquipment | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    switch (value['type']) {
        case 'Equipment':
            return OptionCustomizationCreateRequestDTOEquipmentInternalToJSON(value);
        default:
            throw new Error(`No variant of OptionCustomizationCreateRequestDTOEquipment exists with 'type=${value['type']}'`);
    }
}

