import { DefaultCardViewContentWrapper } from "../../../../components/layouts/defaultcardviewcontentwrapper/DefaultCardViewContentWrapper"
import { OptionTagCreateOptionsDTO, OptionTagCreateRequestDTO } from "../../../../generated/pdsapi"
import { optionTagApi } from "../../../../pdsapi"
import { getProductCreateContainer, ProductCreateContainerConfig } from "../../../../viewtemplates/create/ProductCreateContainer"
import { OptionTagCreateAttributesCard } from "./attributes/OptionTagCreateAttributesCard"
import { OptionTagKey } from "../OptionTagKey"
import { routes } from "../../../../common/routes"
import { Route } from "react-router-dom"
import { OptionTagProductListContainerConfig } from "../list/OptionTagListRoute"

export const OptionTagProductCreateContainerConfig: ProductCreateContainerConfig<OptionTagCreateRequestDTO, OptionTagCreateOptionsDTO, OptionTagKey> = {
    headline: "Create new Option Tag",
    buildDetailsPath: routes.options.tags.details,

    create: (item) =>
        optionTagApi.create.create({
            optionTagCreateRequestDTO: item,
        }),
    getCreateOptions: optionTagApi.create.getCreateOptions,

    Content: (props) => {
        return (
            <DefaultCardViewContentWrapper>
                <OptionTagCreateAttributesCard {...props} />
            </DefaultCardViewContentWrapper>
        )
    },
}

const OptionTagCreateContainer = getProductCreateContainer(OptionTagProductCreateContainerConfig)

export const OptionTagCreateRoute = (
    <Route
        path={routes.options.tags.create()}
        element={
            <OptionTagCreateContainer
                parentCrumbs={[
                    {
                        name: OptionTagProductListContainerConfig.listName,
                        path: routes.options.tags.list(),
                    },
                ]}
            />
        }
    />
)
