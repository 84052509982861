import {
    getEnumConverterGroup,
    getObjectURLParamConverter,
    numberConverterGroup,
    stringConverterGroup,
    useURLSearchParamState,
} from "../../../../common/useURLSearchParamState"
import { FilteredPagedTable, FilteredPagedTableProps } from "../../../../components/filteredpagedtable/FilteredPagedTable"
import { ViewHeadline } from "../../../../components/headlines/viewheadline/ViewHeadline"
import { StyledCard } from "../../../../components/layouts/defaultcardviewcontentwrapper/DefaultCardViewContentWrapper"
import { ViewContentWrapper } from "../../../../components/layouts/viewcontentwrapper/ViewContentWrapper"
import { MarketSelector } from "../../../../components/selectors/marketselector/MarketSelector"
import { TextSelector } from "../../../../components/selectors/textselector/TextSelector"
import { TechnicalDataMenu, TechnicalDataMenuItemIndex } from "../../../../components/technicaldatamenu/TechnicalDataMenu"
import { technicalDataMenuItemGroups } from "../../../../components/technicaldatamenu/technicalDataMenuItemGroups"
import {
    PdsMarketplace,
    SortDirection,
    TechnicalDataAttribute,
    TechnicalDataAttributeDataDTO,
    TechnicalDataFilterOptionsDTO,
    TechnicalDataItemDTO,
    TechnicalDataSortField,
} from "../../../../generated/pdsapi"
import { technicalDataApi, TechnicalDataFilter } from "../../../../pdsapi"
import { getTechnicalDataListColumnsConfig } from "./columns/TechnicalDataListColumns"
import { TechnicalDataListFilter } from "./filter/TechnicalDataListFilter"
import { TechnicalDataListFilterTagsConfig } from "./filter/TechnicalDataListFilterTagsConfig"
import { PFlex, PFlexItem, PHeadline, PText } from "@porsche-design-system/components-react"
import { spacing } from "@porsche-design-system/utilities"
import { FC, useEffect, useMemo, useState } from "react"
import styled from "styled-components"
import { Route } from "react-router-dom"
import { routes } from "../../../../common/routes"

export const TechnicalDataListContainer: FC<{
    technicalDataListApi?: typeof technicalDataApi.list
}> = ({ technicalDataListApi }) => {
    const [technicalDataMenuItem, setTechnicalDataMenuItem] = useURLSearchParamState<TechnicalDataMenuItemIndex>(
        "technicalDataMenuItem",
        { groupIndex: 0, itemIndex: 0 },
        getObjectURLParamConverter({
            groupIndex: numberConverterGroup.required,
            itemIndex: numberConverterGroup.required,
        })
    )
    const selectedItem = technicalDataMenuItemGroups[technicalDataMenuItem.groupIndex].items[technicalDataMenuItem.itemIndex]
    const [technicalDataAttribute, setTechnicalDataAttribute] = useURLSearchParamState(
        "technicalDataAttribute",
        undefined,
        getEnumConverterGroup(TechnicalDataAttribute).optional
    )
    const [marketplace, setMarketplace] = useURLSearchParamState<PdsMarketplace | undefined>(
        "marketplace",
        undefined,
        getEnumConverterGroup(PdsMarketplace).optional
    )
    const [attributeData, setAttributeData] = useState<Record<string, TechnicalDataAttributeDataDTO>>({})

    const api = technicalDataListApi ?? technicalDataApi.list
    useEffect(() => {
        api.getAttributeOptions().then(setAttributeData)
    }, [api])

    const selectedAttribute =
        technicalDataAttribute && selectedItem.relatedAttributes.includes(technicalDataAttribute) ? attributeData[technicalDataAttribute] : undefined
    const pagedTableProps:
        | FilteredPagedTableProps<TechnicalDataItemDTO, TechnicalDataFilter, TechnicalDataFilterOptionsDTO, TechnicalDataSortField>
        | undefined = useMemo(() => {
        if (!technicalDataAttribute || !selectedAttribute) {
            return undefined
        }

        return {
            itemsName: `${selectedAttribute.label} entries`,
            urlParamsPrefix: "list",
            columnsConfig: getTechnicalDataListColumnsConfig(selectedAttribute.columns),
            buildDetailsPath: (item) =>
                routes.models.orderTypes
                    .subpages(item.orderTypeKey)
                    .technicalData(technicalDataMenuItem.groupIndex, technicalDataMenuItem.itemIndex, marketplace),

            fetchPage: (findParams) =>
                api.getPage({
                    ...findParams,
                    technicalDataAttribute,
                    marketplace,
                }),
            fetchFilterOptions: api.getFilterOptions,

            defaultFilter: {},
            filterUrlParamsConverter: getObjectURLParamConverter<TechnicalDataFilter>({
                modelSeriesKeys: stringConverterGroup.optionalArray,
                modelGenerationKeys: stringConverterGroup.optionalArray,
                modelYears: numberConverterGroup.optionalArray,
                orderTypeKeys: stringConverterGroup.optionalArray,
            }),
            defaultSorting: {
                sortField: TechnicalDataSortField.MODEL_YEAR,
                sortDirection: SortDirection.ASC,
            },
            sortingUrlParamsConverter: getObjectURLParamConverter({
                sortField: getEnumConverterGroup(TechnicalDataSortField).required,
                sortDirection: getEnumConverterGroup(SortDirection).required,
            }),
            defaultShowsFilter: false,

            filterFlexWidth: "one-third",
            FilterComponent: TechnicalDataListFilter,
            filterTagsConfig: TechnicalDataListFilterTagsConfig,
        }
    }, [api, technicalDataAttribute, selectedAttribute, marketplace, technicalDataMenuItem])

    return (
        <>
            <ViewHeadline headline={"Technical Data"} />

            <ViewContentWrapper>
                <PFlex>
                    <PFlexItem width={"one-quarter"}>
                        <ContentArea>
                            <StyledCard>
                                <TechnicalDataMenu
                                    selectedIndex={technicalDataMenuItem}
                                    groups={technicalDataMenuItemGroups}
                                    onItemSelected={setTechnicalDataMenuItem}
                                    urlParamsPrefix={"menu"}
                                />
                            </StyledCard>
                        </ContentArea>
                    </PFlexItem>
                    <PFlexItem width={"three-quarters"}>
                        <ContentArea>
                            <StyledCard>
                                <PFlex justifyContent={"space-between"}>
                                    <PFlex direction={"column"}>
                                        <PText>Selected datatype</PText>
                                        <PHeadline variant={"headline-3"}>{selectedItem.label}</PHeadline>
                                    </PFlex>

                                    <PFlexItem width={"one-quarter"}>
                                        <TextSelector
                                            label={"Attribute"}
                                            value={technicalDataAttribute}
                                            options={[
                                                { label: "Please select...", value: "" },
                                                ...selectedItem.relatedAttributes.map((attribute) => ({
                                                    label: attributeData[attribute]?.label ?? attribute,
                                                    value: attribute,
                                                })),
                                            ]}
                                            onChange={(attribute) => setTechnicalDataAttribute(attribute as TechnicalDataAttribute)}
                                        />
                                    </PFlexItem>

                                    <PFlexItem width={"one-quarter"}>
                                        <MarketSelector marketplace={marketplace} onChange={setMarketplace} includeGlobal={true} />
                                    </PFlexItem>
                                </PFlex>
                            </StyledCard>
                        </ContentArea>

                        <ContentArea>
                            <StyledCard>{pagedTableProps && <FilteredPagedTable {...pagedTableProps} />}</StyledCard>
                        </ContentArea>
                    </PFlexItem>
                </PFlex>
            </ViewContentWrapper>
        </>
    )
}

const ContentArea = styled.div`
    padding-right: ${spacing[16]};
    padding-bottom: ${spacing[16]};
`

export const TechnicalDataListRoute = <Route path={routes.other.technicalData.list()} Component={TechnicalDataListContainer} />
