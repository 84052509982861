/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    OptionDTOBaseLocalization,
    OptionDTOBaseLocalizationFromJSON,
    OptionDTOBaseLocalizationFromJSONTyped,
    OptionDTOBaseLocalizationToJSON,
    OptionalSelectDTOExteriorColorGroup,
    OptionalSelectDTOExteriorColorGroupFromJSON,
    OptionalSelectDTOExteriorColorGroupFromJSONTyped,
    OptionalSelectDTOExteriorColorGroupToJSON,
} from './';

/**
 * 
 * @export
 * @interface OptionDTOExteriorColorInternal
 */
export interface OptionDTOExteriorColorInternal {
    /**
     * 
     * @type {OptionDTOBaseLocalization}
     * @memberof OptionDTOExteriorColorInternal
     */
    name: OptionDTOBaseLocalization;
    /**
     * 
     * @type {string}
     * @memberof OptionDTOExteriorColorInternal
     */
    optionCode: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof OptionDTOExteriorColorInternal
     */
    imageKeys: Array<string>;
    /**
     * 
     * @type {OptionDTOBaseLocalization}
     * @memberof OptionDTOExteriorColorInternal
     */
    description: OptionDTOBaseLocalization;
    /**
     * 
     * @type {number}
     * @memberof OptionDTOExteriorColorInternal
     */
    sortIndex: number;
    /**
     * 
     * @type {string}
     * @memberof OptionDTOExteriorColorInternal
     */
    type: string;
    /**
     * 
     * @type {OptionalSelectDTOExteriorColorGroup}
     * @memberof OptionDTOExteriorColorInternal
     */
    exteriorColorGroup: OptionalSelectDTOExteriorColorGroup;
}

export function OptionDTOExteriorColorInternalFromJSON(json: any): OptionDTOExteriorColorInternal {
    return OptionDTOExteriorColorInternalFromJSONTyped(json, false);
}

export function OptionDTOExteriorColorInternalFromJSONTyped(json: any, ignoreDiscriminator: boolean): OptionDTOExteriorColorInternal {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': OptionDTOBaseLocalizationFromJSON(json['name']),
        'optionCode': json['optionCode'],
        'imageKeys': json['imageKeys'],
        'description': OptionDTOBaseLocalizationFromJSON(json['description']),
        'sortIndex': json['sortIndex'],
        'type': json['type'],
        'exteriorColorGroup': OptionalSelectDTOExteriorColorGroupFromJSON(json['exteriorColorGroup']),
    };
}

export function OptionDTOExteriorColorInternalToJSON(value?: OptionDTOExteriorColorInternal | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': OptionDTOBaseLocalizationToJSON(value.name),
        'optionCode': value.optionCode,
        'imageKeys': value.imageKeys,
        'description': OptionDTOBaseLocalizationToJSON(value.description),
        'sortIndex': value.sortIndex,
        'type': value.type,
        'exteriorColorGroup': OptionalSelectDTOExteriorColorGroupToJSON(value.exteriorColorGroup),
    };
}


