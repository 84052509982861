import { showErrorToast } from "../../../../common/errorToastHelper"
import { browserLanguage } from "../../../../common/global"
import { stringConverterGroup, useURLSearchParamState } from "../../../../common/useURLSearchParamState"
import { WidgetWrapper } from "../../../../components/layouts/widgetwrapper/WidgetWrapper"
import { TextSelector } from "../../../../components/selectors/textselector/TextSelector"
import { OptionCustomizationLocalizationsDTO } from "../../../../generated/pdsapi"
import { LocalizationsUpdateDTO } from "../../../../pdsapi"
import { BasicKey, ProductDetailsTabConfigContentProps } from "../../ProductDetailsContainer"
import { DetailsLocalizationCard } from "../../cards/localizationcard/DetailsLocalizationCard"
import { ProductDetailsTab } from "../ProductDetailsTab"
import { useToast } from "@finder/ui-kit"
import { FC, useCallback, useEffect, useMemo, useRef, useState } from "react"
import { conditionalValueLabelOf } from "../../../../common/conditionalValueLabel"

interface DetailsOptionCustomizationLocalizationTabProps {
    getData: (props: { key: string; languageTag: string }) => Promise<OptionCustomizationLocalizationsDTO>
    updateDefaultValue: (props: { key: string; requestBody: LocalizationsUpdateDTO }) => Promise<void>
    updateConditionalValue: (props: { key: string; conditionalValueKey: string; requestBody: LocalizationsUpdateDTO }) => Promise<void>
}

export const DetailsOptionCustomizationLocalizationTab: FC<ProductDetailsTabConfigContentProps<BasicKey> & DetailsOptionCustomizationLocalizationTabProps> = (
    props
) => {
    const { itemKey, getData, updateDefaultValue, updateConditionalValue, urlPathParamsPrefix, reloadDataIndicator } = props

    const toastRef = useRef(useToast())
    const [conditionalValueKey, setConditionalValueKey] = useURLSearchParamState(
        `${urlPathParamsPrefix}.conditionalValueKey`,
        undefined,
        stringConverterGroup.optional
    )

    const [optionCustomizationLocalizations, setOptionCustomizationLocalizations] = useState<OptionCustomizationLocalizationsDTO | undefined>()

    const optionCustomizationLocalizationsPromise = useMemo(
        () =>
            getData({
                ...itemKey,
                languageTag: browserLanguage,
            }),
        [getData, itemKey, reloadDataIndicator]
    )

    useEffect(() => {
        const fetchData = async () => {
            try {
                setOptionCustomizationLocalizations(await optionCustomizationLocalizationsPromise)
            } catch (e) {
                showErrorToast(toastRef.current, e)
            }
        }

        fetchData()
    }, [optionCustomizationLocalizationsPromise])

    const getContentData = useCallback(
        () =>
            optionCustomizationLocalizationsPromise.then((optionCustomizationLocalizations) =>
                conditionalValueKey === undefined
                    ? optionCustomizationLocalizations.defaultValue
                    : optionCustomizationLocalizations.conditionalValues.find((conditionalValue) => conditionalValue.key === conditionalValueKey)!.value
            ),
        [optionCustomizationLocalizationsPromise, conditionalValueKey]
    )
    const updateData = useCallback(
        ({ key, update }: { key: string; update: LocalizationsUpdateDTO }) =>
            conditionalValueKey === undefined
                ? updateDefaultValue({
                      key,
                      requestBody: update,
                  })
                : updateConditionalValue({ key, conditionalValueKey, requestBody: update }),
        [conditionalValueKey, updateDefaultValue, updateConditionalValue]
    )

    return (
        <ProductDetailsTab>
            <WidgetWrapper label={"Condition"}>
                <TextSelector
                    label={undefined}
                    options={[
                        {
                            value: "",
                            label: "Default Value",
                        },
                        ...(optionCustomizationLocalizations?.conditionalValues.map((conditionalValue) => ({
                            value: conditionalValue.key,
                            label: conditionalValueLabelOf(conditionalValue),
                        })) ?? []),
                    ]}
                    value={conditionalValueKey ?? ""}
                    onChange={(value) => setConditionalValueKey(value ? value : undefined)}
                />
            </WidgetWrapper>
            <DetailsLocalizationCard {...props} label={"Localizations"} getData={getContentData} updateData={updateData} />
        </ProductDetailsTab>
    )
}
