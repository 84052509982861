import { DefaultCardViewContentWrapper } from "../../../../components/layouts/defaultcardviewcontentwrapper/DefaultCardViewContentWrapper"
import { EquipmentCustomizationCreateOptionsDTO, EquipmentCustomizationCreateRequestDTO } from "../../../../generated/pdsapi"
import { equipmentCustomizationApi } from "../../../../pdsapi"
import { getProductCreateContainer, ProductCreateContainerConfig } from "../../../../viewtemplates/create/ProductCreateContainer"
import { EquipmentCustomizationCreateAttributesCard } from "./attributes/EquipmentCustomizationCreateAttributesCard"
import { routes } from "../../../../common/routes"
import { EquipmentCustomizationProductListContainerConfig } from "../list/EquipmentCustomizationListRoute"
import { Route } from "react-router-dom"

export const EquipmentCustomizationProductCreateContainerConfig: ProductCreateContainerConfig<
    EquipmentCustomizationCreateRequestDTO,
    EquipmentCustomizationCreateOptionsDTO
> = {
    headline: "Create new Equipment Customization",
    buildDetailsPath: ({ key }) => routes.equipment.customizations.details(key),

    create: (item) =>
        equipmentCustomizationApi.create.create({
            equipmentCustomizationCreateRequestDTO: item,
        }),
    getCreateOptions: equipmentCustomizationApi.create.getCreateOptions,

    Content: (props) => (
        <DefaultCardViewContentWrapper>
            <EquipmentCustomizationCreateAttributesCard {...props} />
        </DefaultCardViewContentWrapper>
    ),
}

const EquipmentCustomizationCreateContainer = getProductCreateContainer(EquipmentCustomizationProductCreateContainerConfig)

export const EquipmentCustomizationCreateRoute = (
    <Route
        path={routes.equipment.customizations.create()}
        element={
            <EquipmentCustomizationCreateContainer
                parentCrumbs={[
                    {
                        name: EquipmentCustomizationProductListContainerConfig.listName,
                        path: routes.equipment.customizations.list(),
                    },
                ]}
            />
        }
    />
)
