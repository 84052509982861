import { DefaultCardViewContentWrapper } from "../../../../components/layouts/defaultcardviewcontentwrapper/DefaultCardViewContentWrapper"
import { ExteriorColorCustomizationCreateOptionsDTO, ExteriorColorCustomizationCreateRequestDTO } from "../../../../generated/pdsapi"
import { exteriorColorCustomizationApi } from "../../../../pdsapi"
import { getProductCreateContainer, ProductCreateContainerConfig } from "../../../../viewtemplates/create/ProductCreateContainer"
import { VehicleImagesCreateCard } from "../../../../viewtemplates/create/cards/vehicleimagescard/VehicleImagesCreateCard"
import { ExteriorColorCustomizationCreateAttributesCard } from "./attributes/ExteriorColorCustomizationCreateAttributesCard"
import { routes } from "../../../../common/routes"
import { ExteriorColorCustomizationProductListContainerConfig } from "../list/ExteriorColorCustomizationListRoute"
import { Route } from "react-router-dom"

export const ExteriorColorCustomizationProductCreateContainerConfig: ProductCreateContainerConfig<
    ExteriorColorCustomizationCreateRequestDTO,
    ExteriorColorCustomizationCreateOptionsDTO
> = {
    headline: "Create new Exterior Color Customization",
    buildDetailsPath: ({ key }) => routes.exteriorColors.customColors.details(key),

    create: (item) =>
        exteriorColorCustomizationApi.create.create({
            exteriorColorCustomizationCreateRequestDTO: item,
        }),
    getCreateOptions: exteriorColorCustomizationApi.create.getCreateOptions,

    Content: (props) => (
        <DefaultCardViewContentWrapper>
            <ExteriorColorCustomizationCreateAttributesCard {...props} />
            <VehicleImagesCreateCard formDisplayData={props.formDisplayData} getVehicleImages={exteriorColorCustomizationApi.create.getVehicleImages} />
        </DefaultCardViewContentWrapper>
    ),
}

const ExteriorColorCustomizationCreateContainer = getProductCreateContainer(ExteriorColorCustomizationProductCreateContainerConfig)

export const ExteriorColorCustomizationCreateRoute = (
    <Route
        path={routes.exteriorColors.customColors.create()}
        element={
            <ExteriorColorCustomizationCreateContainer
                parentCrumbs={[
                    {
                        name: ExteriorColorCustomizationProductListContainerConfig.listName,
                        path: routes.exteriorColors.customizations.list(),
                    },
                ]}
            />
        }
    />
)
