import { ReadonlyFormElementWrapper } from "../../../../../components/formelements/ReadonlyFormElementWrapper"
import { getTagsFormElement } from "../../../../../components/formelements/tagsformelement/TagsFormElement"
import { rowGroupsSpacing, RowGroupWrapper } from "../../../../../components/layouts/rowgroupwrapper/RowGroupWrapper"
import { SpacedItems } from "../../../../../components/layouts/spaceditems/SpacedItems"
import { OldCustomizationLinksDTO } from "../../../../../generated/pdsapi"
import { ProductDetailsCardContentProps } from "../../ProductDetailsCard"
import { FC } from "react"
import { OldOrderTypeOptionKey } from "../../../../../views/models/ordertypes/OrderTypeOptionKey"
import { BasicKey } from "../../../ProductDetailsContainer"

export const DetailsLinkedOptionsCardContent: FC<
    ProductDetailsCardContentProps<OldCustomizationLinksDTO, object, BasicKey> & {
        getRoute: (key: OldOrderTypeOptionKey) => string
    }
> = ({ formDisplayData, getRoute }) => (
    <SpacedItems direction={"column"} lineSpacing={rowGroupsSpacing}>
        <RowGroupWrapper label={"Options"} childrenSize={12}>
            <ReadonlyFormElementWrapper
                label={undefined}
                formDisplayData={formDisplayData}
                FormElement={getTagsFormElement()}
                getViewProps={(data) => ({
                    emptyLabel: "-",
                    tags: data.options.map((relatedOption) => ({
                        label: relatedOption.label,
                        route: getRoute(relatedOption),
                    })),
                })}
            />
        </RowGroupWrapper>
    </SpacedItems>
)
