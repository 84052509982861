import { propertiesOf } from "../../../../../common/property"
import { EditFormElementWrapper } from "../../../../../components/formelements/EditFormElementWrapper"
import { getBoolFormElement } from "../../../../../components/formelements/boolformelement/BoolFormElement"
import { getColorPickerFormElement } from "../../../../../components/formelements/colorpickerformelement/ColorPickerFormElement"
import { getTextFormElement } from "../../../../../components/formelements/textformelement/TextFormElement"
import { rowGroupsSpacing, RowGroupWrapper } from "../../../../../components/layouts/rowgroupwrapper/RowGroupWrapper"
import { SpacedItems } from "../../../../../components/layouts/spaceditems/SpacedItems"
import {
    OptionTagCreateOptionsDTO,
    OptionTagCreateRequestDTO,
    OptionTagCreateRequestDTOEquipment,
    OptionTagCreateRequestDTOExteriorColor,
    OptionTagCreateRequestDTOInteriorColor,
    OptionTagCreateRequestDTORoofColor,
} from "../../../../../generated/pdsapi"
import { ProductCreateContentProps } from "../../../../../viewtemplates/create/ProductCreateContainer"
import { CreateDefaultLocalizationsRow } from "../../../../../viewtemplates/create/components/createdefaultlocalizationsrow/CreateDefaultLocalizationsRow"
import { FC, JSX } from "react"
import { useLocation } from "react-router-dom"
import { EditFormDisplayData, getEditData, LoadingFormDisplayData } from "../../../../../components/formelements/FormDisplayData"
import { getSelectTextFormElement } from "../../../../../components/formelements/selecttextformelement/SelectTextFormElement"
import { OptionTagType, optionTagTypeOptions } from "../../optionTagTypes"
import { toOption } from "../../../../../components/formelements/selecttextformelement/Option"

export const OptionTagCreateAttributesCard: FC<ProductCreateContentProps<OptionTagCreateRequestDTO, OptionTagCreateOptionsDTO>> = ({ formDisplayData }) => {
    const properties = propertiesOf<OptionTagCreateRequestDTO>()
    const searchParams = new URLSearchParams(useLocation().search)

    return (
        <SpacedItems direction={"column"} lineSpacing={rowGroupsSpacing}>
            <RowGroupWrapper label={"Configuration"}>
                <EditFormElementWrapper
                    label={"Type"}
                    formDisplayData={formDisplayData}
                    field={properties.type}
                    FormElement={getSelectTextFormElement<OptionTagCreateOptionsDTO, OptionTagCreateRequestDTO>()}
                    getEditProps={() => ({
                        options: optionTagTypeOptions,
                        value: "ExteriorColor" satisfies OptionTagType,
                    })}
                />

                <EditFormElementWrapper
                    label={"Key"}
                    formDisplayData={formDisplayData}
                    field={properties.key}
                    FormElement={getTextFormElement<OptionTagCreateOptionsDTO, OptionTagCreateRequestDTO>()}
                    getEditProps={() => ({
                        value: searchParams.get("key") ?? "",
                    })}
                />
            </RowGroupWrapper>
            <CreateDefaultLocalizationsRow
                label={"Name"}
                formDisplayData={formDisplayData}
                field={properties.name}
                getEditProps={() => ({
                    value: { de: "", en: "" },
                })}
            />
            <RowGroupWrapper label={"Status"}>
                <EditFormElementWrapper
                    label={"Active"}
                    formDisplayData={formDisplayData}
                    field={properties.active}
                    FormElement={getBoolFormElement<OptionTagCreateOptionsDTO, OptionTagCreateRequestDTO>()}
                    getEditProps={() => ({
                        falseMessage: "Inactive",
                        trueMessage: "Active",
                        value: true,
                    })}
                />
            </RowGroupWrapper>
            <TypeSpecificRowGroup formDisplayData={formDisplayData} />
        </SpacedItems>
    )
}

const TypeSpecificRowGroup = ({
    formDisplayData,
}: {
    formDisplayData: LoadingFormDisplayData | EditFormDisplayData<OptionTagCreateOptionsDTO, OptionTagCreateRequestDTO>
}): JSX.Element | null => {
    switch (getEditData(formDisplayData)?.watch("type")) {
        case "Equipment": {
            const properties = propertiesOf<OptionTagCreateRequestDTOEquipment>()

            return (
                <RowGroupWrapper label={"Category"}>
                    <EditFormElementWrapper
                        label={"Equipment Tag Category"}
                        formDisplayData={formDisplayData}
                        field={properties.category}
                        FormElement={getSelectTextFormElement<OptionTagCreateOptionsDTO, OptionTagCreateRequestDTO>()}
                        getEditProps={(data) => ({
                            options: data.equipmentTagCategories.map(toOption),
                            value: data.equipmentTagCategories[0].key,
                        })}
                    />
                </RowGroupWrapper>
            )
        }
        case "InteriorColor":
        case "ExteriorColor":
        case "RoofColor": {
            const properties = propertiesOf<
                OptionTagCreateRequestDTOInteriorColor | OptionTagCreateRequestDTOExteriorColor | OptionTagCreateRequestDTORoofColor
            >()

            return (
                <RowGroupWrapper label={"Color"}>
                    <EditFormElementWrapper
                        label={"Hex Code"}
                        formDisplayData={formDisplayData}
                        field={properties.hexCode}
                        FormElement={getColorPickerFormElement<OptionTagCreateOptionsDTO, OptionTagCreateRequestDTO>()}
                        getEditProps={() => ({
                            hexValue: "#000000",
                        })}
                    />
                </RowGroupWrapper>
            )
        }

        case undefined:
            return null
    }
}
