import { getEnumConverterGroup, getObjectURLParamConverter } from "../../../../common/useURLSearchParamState"
import { ModelSeriesItemDTO, ModelSeriesSortField, SortDirection } from "../../../../generated/pdsapi"
import { modelSeriesApi, ModelSeriesFilter } from "../../../../pdsapi"
import { getProductListContainer, ProductListContainerConfig } from "../../../../viewtemplates/list/ProductListContainer"
import { ModelSeriesListColumnsConfig } from "./columns/ModelSeriesListColumns"
import { ModelSeriesListFilter } from "./filter/ModelSeriesListFilter"
import { ModelSeriesListFilterTagsConfig } from "./filter/ModelSeriesListFilterTagsConfig"
import { routes } from "../../../../common/routes"
import { Route } from "react-router-dom"

export const ModelSeriesProductListContainerConfig: ProductListContainerConfig<ModelSeriesItemDTO, ModelSeriesFilter, unknown, ModelSeriesSortField> = {
    listName: "Model Series",
    createItemLabel: "Add new Model Series",

    buildDetailsPath: (modelSeries) => routes.models.modelSeries.details(modelSeries.key),
    buildCreatePath: routes.models.modelSeries.create,
    buildSortPath: routes.models.modelSeries.sort,

    defaultFilter: {},
    filterUrlParamsConverter: getObjectURLParamConverter<ModelSeriesFilter>({}),
    defaultSorting: {
        sortField: ModelSeriesSortField.SORT_INDEX,
        sortDirection: SortDirection.ASC,
    },
    sortingUrlParamsConverter: getObjectURLParamConverter({
        sortField: getEnumConverterGroup(ModelSeriesSortField).required,
        sortDirection: getEnumConverterGroup(SortDirection).required,
    }),
    fetchPage: modelSeriesApi.list.getPage,
    fetchFilterOptions: async () => ({}),

    defaultShowsFilter: false,
    Filter: ModelSeriesListFilter,
    filterTagsConfig: ModelSeriesListFilterTagsConfig,

    columnsConfig: ModelSeriesListColumnsConfig,
}

const ModelSeriesListContainer = getProductListContainer(ModelSeriesProductListContainerConfig)

export const ModelSeriesListRoute = <Route path={routes.models.modelSeries.list()} Component={ModelSeriesListContainer} />
