import { OptionsInFamilyDTO } from "../../../../../../../generated/pdsapi"
import { ProductDetailsTabConfigContentProps } from "../../../../../../../viewtemplates/details/ProductDetailsContainer"
import { ProductDetailsCard, ProductDetailsCardContentProps } from "../../../../../../../viewtemplates/details/cards/ProductDetailsCard"
import { OptionsInFamilyCardContent } from "./content/OptionsInFamilyCardContent"
import { FC, useCallback } from "react"
import { OldOrderTypeOptionKey } from "../../../../OrderTypeOptionKey"

interface OptionsInFamilyCardProps {
    getData: (props: OldOrderTypeOptionKey & { languageTag: string }) => Promise<OptionsInFamilyDTO>
    getRoute: (key: OldOrderTypeOptionKey) => string
}

export const OptionsInFamilyCard: FC<ProductDetailsTabConfigContentProps<OldOrderTypeOptionKey> & OptionsInFamilyCardProps> = (props) => {
    const Content: FC<ProductDetailsCardContentProps<OptionsInFamilyDTO, object, OldOrderTypeOptionKey>> = useCallback(
        (contentProps) => <OptionsInFamilyCardContent {...contentProps} getRoute={props.getRoute} />,
        [props.getRoute]
    )

    return <ProductDetailsCard<OptionsInFamilyDTO, object, OldOrderTypeOptionKey> {...props} label={"Options in Family"} Content={Content} />
}
