import { optionCustomizationApi } from "../../../../pdsapi"
import { getCustomizationDetailsContainer } from "../../../../viewtemplates/details/CustomizationDetailsContainer"
import { ProductDetailsContainerConfig } from "../../../../viewtemplates/details/ProductDetailsContainer"
import { ProductDetailsCard } from "../../../../viewtemplates/details/cards/ProductDetailsCard"
import { ProductDetailsTab } from "../../../../viewtemplates/details/tabs/ProductDetailsTab"
import { OptionCustomizationDetailsAttributesCardContent } from "./data/attributes/OptionCustomizationDetailsAttributesCardContent"
import { LinkedOptionsCardContent } from "./data/linkedoptions/LinkedOptionsCardContent"
import { VehicleImagesCard } from "./data/vehicleimages/VehicleImagesCard"
import { OptionCustomizationKey } from "./OptionCustomizationKey"
import { OptionCustomizationLocalizationTab } from "./localization/OptionCustomizationLocalizationTab"
import { OptionCustomizationVideosTab } from "./video/OptionCustomizationVideosTab"
import { Route, useParams } from "react-router-dom"
import { OptionCustomizationTypeDTO } from "../../../../generated/pdsapi"
import { OptionCustomizationListContainerConfig } from "../list/OptionCustomizationListRoute"
import { routes } from "../../../../common/routes"

export const OptionCustomizationProductDetailsContainerConfig: ProductDetailsContainerConfig<OptionCustomizationKey> = {
    getBaseInformation: optionCustomizationApi.details.getBaseInformation,
    deleteByKey: optionCustomizationApi.details.deleteByKey,
    tabsConfig: [
        {
            label: "Data",
            Content: (props) => (
                <ProductDetailsTab>
                    <ProductDetailsCard
                        {...props}
                        label={"Attributes"}
                        getData={optionCustomizationApi.details.getAttributes}
                        updateData={({ key, type, update }) =>
                            optionCustomizationApi.details.updateAttributes({
                                key,
                                type,
                                optionCustomizationAttributesUpdateDTOObjectOptionCustomization: update,
                            })
                        }
                        Content={OptionCustomizationDetailsAttributesCardContent}
                    />
                    <ProductDetailsCard {...props} label={"Links"} getData={optionCustomizationApi.details.getLinks} Content={LinkedOptionsCardContent} />
                    <VehicleImagesCard {...props} load={optionCustomizationApi.details.getVehicleImages} />
                </ProductDetailsTab>
            ),
        },
        {
            label: "Name",
            Content: (props) => (
                <OptionCustomizationLocalizationTab
                    {...props}
                    getLocalization={optionCustomizationApi.details.getName}
                    updateDefaultValue={optionCustomizationApi.details.updateDefaultName}
                    updateConditionalValue={optionCustomizationApi.details.updateConditionalValueName}
                />
            ),
        },
        {
            label: "Description",
            Content: (props) => (
                <OptionCustomizationLocalizationTab
                    {...props}
                    getLocalization={optionCustomizationApi.details.getDescription}
                    updateDefaultValue={optionCustomizationApi.details.updateDefaultDescription}
                    updateConditionalValue={optionCustomizationApi.details.updateConditionalValueDescription}
                />
            ),
        },
        {
            label: "Videos",
            Content: (props) => (
                <OptionCustomizationVideosTab
                    {...props}
                    getVideo={optionCustomizationApi.details.getVideos}
                    updateDefaultValue={optionCustomizationApi.details.updateDefaultVideo}
                    updateConditionalValue={optionCustomizationApi.details.updateConditionalValueVideo}
                />
            ),
        },
    ],
}

const OptionCustomizationDetailsComponent = getCustomizationDetailsContainer(OptionCustomizationProductDetailsContainerConfig)

export const OptionCustomizationDetailsRoute = (
    <Route
        path={routes.options.customizations.details({ type: ":type" as OptionCustomizationTypeDTO, key: ":key" })}
        Component={() => {
            const params = useParams<{ type: OptionCustomizationTypeDTO; key: string }>()

            return (
                <OptionCustomizationDetailsComponent
                    itemKey={params}
                    parentCrumbs={[
                        {
                            name: OptionCustomizationListContainerConfig.listName,
                            path: routes.options.customizations.list(),
                        },
                    ]}
                />
            )
        }}
    />
)
