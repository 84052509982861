import { usePdsBootContext } from "../../../../../common/PdsBootContext"
import { Sorting } from "../../../../../common/Sorting"
import { getEnumConverterGroup, getObjectURLParamConverter, URLParamConverter } from "../../../../../common/useURLSearchParamState"
import { AccordionItem } from "../../../../../components/accordion/AccordionItem"
import {
    FilteredPagedTable,
    FilteredPagedTableFindParams,
    TableColumnsConfig,
    TableFilterProps,
    TableFilterTagsConfig,
} from "../../../../../components/filteredpagedtable/FilteredPagedTable"
import { Box } from "../../../../../components/layouts/box/Box"
import { SortDirection, UserAuthority } from "../../../../../generated/pdsapi"
import { useOrderTypeDetailsContext } from "../OrderTypeDetailsContext"
import { PButton } from "@porsche-design-system/components-react"
import { spacing } from "@porsche-design-system/utilities"
import { FC, useCallback } from "react"
import { useNavigate } from "react-router-dom"

export interface OrderTypeOptionsCardProps<OPTION, FILTER, FILTER_OPTIONS, SORT_FIELD extends string> {
    label: string
    getCreateRoute: (orderTypeKey: string) => string
    getDetailsRoute: (orderTypeKey: string, option: OPTION) => string
    getOptions: (findParams: FilteredPagedTableFindParams<FILTER, SORT_FIELD> & { key: string }) => Promise<{ totalItemCount: number; content: OPTION[] }>
    getFilterOptions: (params: { key: string; languageTag: string }) => Promise<FILTER_OPTIONS>

    defaultSortField: SORT_FIELD
    sortFieldEnum: { [index: string]: SORT_FIELD | undefined }

    defaultFilter: FILTER
    filterUrlParamsConverter: URLParamConverter<FILTER>

    columnsConfig: TableColumnsConfig<OPTION, SORT_FIELD>
    FilterComponent: FC<TableFilterProps<FILTER, FILTER_OPTIONS>>
    filterTagsConfig: TableFilterTagsConfig<FILTER, FILTER_OPTIONS>

    isInitiallyExpanded: boolean
}

export const OrderTypeOptionsCard = <OPTION, FILTER, FILTER_OPTIONS, SORT_FIELD extends string>({
    urlPathParamsPrefix,
    orderTypeKey,
    label,

    getCreateRoute,
    getDetailsRoute,
    getOptions,
    getFilterOptions,

    defaultSortField,
    sortFieldEnum,

    defaultFilter,
    filterUrlParamsConverter,

    columnsConfig,
    FilterComponent,
    filterTagsConfig,

    isInitiallyExpanded,
}: { urlPathParamsPrefix: string; orderTypeKey: string } & OrderTypeOptionsCardProps<OPTION, FILTER, FILTER_OPTIONS, SORT_FIELD>) => {
    const navigate = useNavigate()
    const { hasAuthority } = usePdsBootContext()
    const { isEditable } = useOrderTypeDetailsContext()
    const createButton =
        isEditable && hasAuthority(UserAuthority.MAINTAIN_DATA) ? (
            <PButton key={"createButton"} icon={"plus"} onClick={() => navigate(getCreateRoute(orderTypeKey))}>
                Create
            </PButton>
        ) : undefined

    const fetchPage = useCallback(
        (findParams: FilteredPagedTableFindParams<FILTER, any>) =>
            getOptions({
                key: orderTypeKey,
                ...findParams,
            }),
        [getOptions, orderTypeKey]
    )

    const fetchFilterOptions = useCallback(
        (params: { languageTag: string }) =>
            getFilterOptions({
                key: orderTypeKey,
                ...params,
            }),
        [getFilterOptions, orderTypeKey]
    )
    const buildDetailsPath = useCallback((option: OPTION) => getDetailsRoute(orderTypeKey, option), [orderTypeKey, getDetailsRoute])

    return (
        <AccordionItem label={label} isInitiallyExpanded={isInitiallyExpanded} urlParamsPrefix={`${urlPathParamsPrefix}.accordion`} actions={createButton}>
            <Box height={spacing[32]} />
            <FilteredPagedTable
                itemsName={label}
                urlParamsPrefix={`${urlPathParamsPrefix}.list`}
                columnsConfig={columnsConfig}
                buildDetailsPath={buildDetailsPath}
                fetchPage={fetchPage}
                fetchFilterOptions={fetchFilterOptions}
                defaultFilter={defaultFilter}
                filterUrlParamsConverter={filterUrlParamsConverter}
                defaultSorting={{
                    sortField: defaultSortField,
                    sortDirection: SortDirection.ASC,
                }}
                sortingUrlParamsConverter={getObjectURLParamConverter<Sorting<SORT_FIELD>>({
                    sortField: getEnumConverterGroup(sortFieldEnum).required,
                    sortDirection: getEnumConverterGroup(SortDirection).required,
                })}
                defaultShowsFilter={false}
                FilterComponent={FilterComponent}
                filterTagsConfig={filterTagsConfig}
            />
        </AccordionItem>
    )
}
