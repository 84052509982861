import { DefaultCardViewContentWrapper } from "../../../../components/layouts/defaultcardviewcontentwrapper/DefaultCardViewContentWrapper"
import { InteriorCustomizationCreateOptionsDTO, InteriorCustomizationCreateRequestDTO } from "../../../../generated/pdsapi"
import { interiorCustomizationApi } from "../../../../pdsapi"
import { getProductCreateContainer, ProductCreateContainerConfig } from "../../../../viewtemplates/create/ProductCreateContainer"
import { VehicleImagesCreateCard } from "../../../../viewtemplates/create/cards/vehicleimagescard/VehicleImagesCreateCard"
import { InteriorCustomizationCreateAttributesCard } from "./attributes/InteriorCustomizationCreateAttributesCard"
import { routes } from "../../../../common/routes"
import { Route } from "react-router-dom"
import { InteriorCustomizationProductListContainerConfig } from "../list/InteriorCustomizationListRoute"

export const InteriorCustomizationProductCreateContainerConfig: ProductCreateContainerConfig<
    InteriorCustomizationCreateRequestDTO,
    InteriorCustomizationCreateOptionsDTO
> = {
    headline: "Create new Interior Customization",
    buildDetailsPath: ({ key }) => routes.interiors.customizations.details(key),

    create: (item) =>
        interiorCustomizationApi.create.create({
            interiorCustomizationCreateRequestDTO: item,
        }),
    getCreateOptions: interiorCustomizationApi.create.getCreateOptions,

    Content: (props) => (
        <DefaultCardViewContentWrapper>
            <InteriorCustomizationCreateAttributesCard {...props} />
            <VehicleImagesCreateCard formDisplayData={props.formDisplayData} getVehicleImages={interiorCustomizationApi.create.getVehicleImages} />
        </DefaultCardViewContentWrapper>
    ),
}

const InteriorCustomizationCreateContainer = getProductCreateContainer(InteriorCustomizationProductCreateContainerConfig)

export const InteriorCustomizationCreateRoute = (
    <Route
        path={routes.interiors.customizations.create()}
        element={
            <InteriorCustomizationCreateContainer
                parentCrumbs={[
                    {
                        name: InteriorCustomizationProductListContainerConfig.listName,
                        path: routes.interiors.customizations.list(),
                    },
                ]}
            />
        }
    />
)
