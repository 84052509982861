/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    AuthorizationApiError,
    AuthorizationApiErrorFromJSON,
    AuthorizationApiErrorToJSON,
    LinkedCustomizationDTO,
    LinkedCustomizationDTOFromJSON,
    LinkedCustomizationDTOToJSON,
    OptionDTO,
    OptionDTOFromJSON,
    OptionDTOToJSON,
    OptionUpdateDTOOption,
    OptionUpdateDTOOptionFromJSON,
    OptionUpdateDTOOptionToJSON,
    OrderTypeOptionTypeDTO,
    OrderTypeOptionTypeDTOFromJSON,
    OrderTypeOptionTypeDTOToJSON,
    SimilarCustomizationsDTO,
    SimilarCustomizationsDTOFromJSON,
    SimilarCustomizationsDTOToJSON,
    SimpleApiError,
    SimpleApiErrorFromJSON,
    SimpleApiErrorToJSON,
    SimpleOrValidationApiError,
    SimpleOrValidationApiErrorFromJSON,
    SimpleOrValidationApiErrorToJSON,
} from '../models';

export interface GetLinkedCustomizationOfOptionRequest {
    orderTypeKey: string;
    optionCode: string;
    type: OrderTypeOptionTypeDTO;
    languageTag: string;
}

export interface GetOptionOfOptionRequest {
    orderTypeKey: string;
    optionCode: string;
    type: OrderTypeOptionTypeDTO;
    languageTag: string;
}

export interface GetSimilarCustomizationsOfOptionRequest {
    orderTypeKey: string;
    optionCode: string;
    type: OrderTypeOptionTypeDTO;
    languageTag: string;
}

export interface UpdateOptionOfOptionRequest {
    orderTypeKey: string;
    optionCode: string;
    optionUpdateDTOOption: OptionUpdateDTOOption;
}

/**
 * 
 */
export class OrderTypeOptionDataDetailsWebControllerApi extends runtime.BaseAPI {

    /**
     * Get linkedCustomization of Option with given key
     */
    async getLinkedCustomizationOfOptionRaw(requestParameters: GetLinkedCustomizationOfOptionRequest): Promise<runtime.ApiResponse<LinkedCustomizationDTO>> {
        if (requestParameters.orderTypeKey === null || requestParameters.orderTypeKey === undefined) {
            throw new runtime.RequiredError('orderTypeKey','Required parameter requestParameters.orderTypeKey was null or undefined when calling getLinkedCustomizationOfOption.');
        }

        if (requestParameters.optionCode === null || requestParameters.optionCode === undefined) {
            throw new runtime.RequiredError('optionCode','Required parameter requestParameters.optionCode was null or undefined when calling getLinkedCustomizationOfOption.');
        }

        if (requestParameters.type === null || requestParameters.type === undefined) {
            throw new runtime.RequiredError('type','Required parameter requestParameters.type was null or undefined when calling getLinkedCustomizationOfOption.');
        }

        if (requestParameters.languageTag === null || requestParameters.languageTag === undefined) {
            throw new runtime.RequiredError('languageTag','Required parameter requestParameters.languageTag was null or undefined when calling getLinkedCustomizationOfOption.');
        }

        const queryParameters: any = {};

        if (requestParameters.optionCode !== undefined) {
            queryParameters['optionCode'] = requestParameters.optionCode;
        }

        if (requestParameters.type !== undefined) {
            queryParameters['type'] = requestParameters.type;
        }

        if (requestParameters.languageTag !== undefined) {
            queryParameters['languageTag'] = requestParameters.languageTag;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/web/order-types/{orderTypeKey}/option/data/linked-customization`.replace(`{${"orderTypeKey"}}`, encodeURIComponent(String(requestParameters.orderTypeKey))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => LinkedCustomizationDTOFromJSON(jsonValue));
    }

    /**
     * Get linkedCustomization of Option with given key
     */
    async getLinkedCustomizationOfOption(requestParameters: GetLinkedCustomizationOfOptionRequest): Promise<LinkedCustomizationDTO> {
        const response = await this.getLinkedCustomizationOfOptionRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get option of Option with given key
     */
    async getOptionOfOptionRaw(requestParameters: GetOptionOfOptionRequest): Promise<runtime.ApiResponse<OptionDTO>> {
        if (requestParameters.orderTypeKey === null || requestParameters.orderTypeKey === undefined) {
            throw new runtime.RequiredError('orderTypeKey','Required parameter requestParameters.orderTypeKey was null or undefined when calling getOptionOfOption.');
        }

        if (requestParameters.optionCode === null || requestParameters.optionCode === undefined) {
            throw new runtime.RequiredError('optionCode','Required parameter requestParameters.optionCode was null or undefined when calling getOptionOfOption.');
        }

        if (requestParameters.type === null || requestParameters.type === undefined) {
            throw new runtime.RequiredError('type','Required parameter requestParameters.type was null or undefined when calling getOptionOfOption.');
        }

        if (requestParameters.languageTag === null || requestParameters.languageTag === undefined) {
            throw new runtime.RequiredError('languageTag','Required parameter requestParameters.languageTag was null or undefined when calling getOptionOfOption.');
        }

        const queryParameters: any = {};

        if (requestParameters.optionCode !== undefined) {
            queryParameters['optionCode'] = requestParameters.optionCode;
        }

        if (requestParameters.type !== undefined) {
            queryParameters['type'] = requestParameters.type;
        }

        if (requestParameters.languageTag !== undefined) {
            queryParameters['languageTag'] = requestParameters.languageTag;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/web/order-types/{orderTypeKey}/option/data/option`.replace(`{${"orderTypeKey"}}`, encodeURIComponent(String(requestParameters.orderTypeKey))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => OptionDTOFromJSON(jsonValue));
    }

    /**
     * Get option of Option with given key
     */
    async getOptionOfOption(requestParameters: GetOptionOfOptionRequest): Promise<OptionDTO> {
        const response = await this.getOptionOfOptionRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get similarCustomizations of Option with given key
     */
    async getSimilarCustomizationsOfOptionRaw(requestParameters: GetSimilarCustomizationsOfOptionRequest): Promise<runtime.ApiResponse<SimilarCustomizationsDTO>> {
        if (requestParameters.orderTypeKey === null || requestParameters.orderTypeKey === undefined) {
            throw new runtime.RequiredError('orderTypeKey','Required parameter requestParameters.orderTypeKey was null or undefined when calling getSimilarCustomizationsOfOption.');
        }

        if (requestParameters.optionCode === null || requestParameters.optionCode === undefined) {
            throw new runtime.RequiredError('optionCode','Required parameter requestParameters.optionCode was null or undefined when calling getSimilarCustomizationsOfOption.');
        }

        if (requestParameters.type === null || requestParameters.type === undefined) {
            throw new runtime.RequiredError('type','Required parameter requestParameters.type was null or undefined when calling getSimilarCustomizationsOfOption.');
        }

        if (requestParameters.languageTag === null || requestParameters.languageTag === undefined) {
            throw new runtime.RequiredError('languageTag','Required parameter requestParameters.languageTag was null or undefined when calling getSimilarCustomizationsOfOption.');
        }

        const queryParameters: any = {};

        if (requestParameters.optionCode !== undefined) {
            queryParameters['optionCode'] = requestParameters.optionCode;
        }

        if (requestParameters.type !== undefined) {
            queryParameters['type'] = requestParameters.type;
        }

        if (requestParameters.languageTag !== undefined) {
            queryParameters['languageTag'] = requestParameters.languageTag;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/web/order-types/{orderTypeKey}/option/data/similar-customizations`.replace(`{${"orderTypeKey"}}`, encodeURIComponent(String(requestParameters.orderTypeKey))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => SimilarCustomizationsDTOFromJSON(jsonValue));
    }

    /**
     * Get similarCustomizations of Option with given key
     */
    async getSimilarCustomizationsOfOption(requestParameters: GetSimilarCustomizationsOfOptionRequest): Promise<SimilarCustomizationsDTO> {
        const response = await this.getSimilarCustomizationsOfOptionRaw(requestParameters);
        return await response.value();
    }

    /**
     * Updates option of an existing Option
     */
    async updateOptionOfOptionRaw(requestParameters: UpdateOptionOfOptionRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.orderTypeKey === null || requestParameters.orderTypeKey === undefined) {
            throw new runtime.RequiredError('orderTypeKey','Required parameter requestParameters.orderTypeKey was null or undefined when calling updateOptionOfOption.');
        }

        if (requestParameters.optionCode === null || requestParameters.optionCode === undefined) {
            throw new runtime.RequiredError('optionCode','Required parameter requestParameters.optionCode was null or undefined when calling updateOptionOfOption.');
        }

        if (requestParameters.optionUpdateDTOOption === null || requestParameters.optionUpdateDTOOption === undefined) {
            throw new runtime.RequiredError('optionUpdateDTOOption','Required parameter requestParameters.optionUpdateDTOOption was null or undefined when calling updateOptionOfOption.');
        }

        const queryParameters: any = {};

        if (requestParameters.optionCode !== undefined) {
            queryParameters['optionCode'] = requestParameters.optionCode;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/web/order-types/{orderTypeKey}/option/data/option`.replace(`{${"orderTypeKey"}}`, encodeURIComponent(String(requestParameters.orderTypeKey))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: OptionUpdateDTOOptionToJSON(requestParameters.optionUpdateDTOOption),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Updates option of an existing Option
     */
    async updateOptionOfOption(requestParameters: UpdateOptionOfOptionRequest): Promise<void> {
        await this.updateOptionOfOptionRaw(requestParameters);
    }

}
