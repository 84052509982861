/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface OptionCustomizationItemDTOEquipmentInternal
 */
export interface OptionCustomizationItemDTOEquipmentInternal {
    /**
     * 
     * @type {string}
     * @memberof OptionCustomizationItemDTOEquipmentInternal
     */
    key: string;
    /**
     * 
     * @type {string}
     * @memberof OptionCustomizationItemDTOEquipmentInternal
     */
    optionCode: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof OptionCustomizationItemDTOEquipmentInternal
     */
    modelGenerationNames: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof OptionCustomizationItemDTOEquipmentInternal
     */
    type: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof OptionCustomizationItemDTOEquipmentInternal
     */
    equipmentTagNames: Array<string>;
}

export function OptionCustomizationItemDTOEquipmentInternalFromJSON(json: any): OptionCustomizationItemDTOEquipmentInternal {
    return OptionCustomizationItemDTOEquipmentInternalFromJSONTyped(json, false);
}

export function OptionCustomizationItemDTOEquipmentInternalFromJSONTyped(json: any, ignoreDiscriminator: boolean): OptionCustomizationItemDTOEquipmentInternal {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'key': json['key'],
        'optionCode': json['optionCode'],
        'modelGenerationNames': json['modelGenerationNames'],
        'type': json['type'],
        'equipmentTagNames': json['equipmentTagNames'],
    };
}

export function OptionCustomizationItemDTOEquipmentInternalToJSON(value?: OptionCustomizationItemDTOEquipmentInternal | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'key': value.key,
        'optionCode': value.optionCode,
        'modelGenerationNames': value.modelGenerationNames,
        'type': value.type,
        'equipmentTagNames': value.equipmentTagNames,
    };
}


