import { DefaultCardViewContentWrapper } from "../../../../components/layouts/defaultcardviewcontentwrapper/DefaultCardViewContentWrapper"
import { InteriorColorTagCreateRequestDTO } from "../../../../generated/pdsapi"
import { interiorColorTagApi } from "../../../../pdsapi"
import { getProductCreateContainer, ProductCreateContainerConfig } from "../../../../viewtemplates/create/ProductCreateContainer"
import { InteriorColorTagCreateAttributesCard } from "./attributes/InteriorColorTagCreateAttributesCard"
import { routes } from "../../../../common/routes"
import { Route } from "react-router-dom"
import { InteriorColorTagProductListContainerConfig } from "../list/InteriorColorTagListRoute"

export const InteriorColorTagProductCreateContainerConfig: ProductCreateContainerConfig<InteriorColorTagCreateRequestDTO, unknown> = {
    headline: "Create new Interior Color Tag",
    buildDetailsPath: ({ key }) => routes.interiors.colorTags.details(key),

    create: (item) =>
        interiorColorTagApi.create.create({
            interiorColorTagCreateRequestDTO: item,
        }),
    getCreateOptions: async () => ({}),

    Content: (props) => (
        <DefaultCardViewContentWrapper>
            <InteriorColorTagCreateAttributesCard {...props} />
        </DefaultCardViewContentWrapper>
    ),
}

const InteriorColorTagCreateContainer = getProductCreateContainer(InteriorColorTagProductCreateContainerConfig)

export const InteriorColorTagCreateRoute = (
    <Route
        path={routes.interiors.colorTags.create()}
        element={
            <InteriorColorTagCreateContainer
                parentCrumbs={[
                    {
                        name: InteriorColorTagProductListContainerConfig.listName,
                        path: routes.interiors.colorTags.list(),
                    },
                ]}
            />
        }
    />
)
