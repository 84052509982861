import { roofColorTagApi } from "../../../../pdsapi"
import { getProductSortContainer, ProductSortContainerConfig } from "../../../../viewtemplates/sort/ProductSortContainer"
import { Route } from "react-router-dom"
import { routes } from "../../../../common/routes"
import { RoofColorTagProductListContainerConfig } from "../list/RoofColorTagListRoute"

export const RoofColorTagSortContainerTemplateProps: ProductSortContainerConfig = {
    fetchSortables: roofColorTagApi.sort.getSortableItems,
    updateSortIndices: (sortIndexUpdate) => roofColorTagApi.sort.updateSortIndices({ sortIndexUpdateRequestDTO: sortIndexUpdate }),
}

const RoofColorTagSortContainer = getProductSortContainer(RoofColorTagSortContainerTemplateProps)

export const RoofColorTagSortRoute = (
    <Route
        path={routes.roofColors.tags.sort()}
        element={
            <RoofColorTagSortContainer
                parentCrumbs={[
                    {
                        name: RoofColorTagProductListContainerConfig.listName,
                        path: routes.roofColors.tags.list(),
                    },
                ]}
            />
        }
    />
)
