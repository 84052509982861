/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    OptionCustomizationTypeDTO,
    OptionCustomizationTypeDTOFromJSON,
    OptionCustomizationTypeDTOFromJSONTyped,
    OptionCustomizationTypeDTOToJSON,
} from './';

/**
 * 
 * @export
 * @interface SelectOptionDTOOptionCustomizationTypeDTO
 */
export interface SelectOptionDTOOptionCustomizationTypeDTO {
    /**
     * 
     * @type {OptionCustomizationTypeDTO}
     * @memberof SelectOptionDTOOptionCustomizationTypeDTO
     */
    key: OptionCustomizationTypeDTO;
    /**
     * 
     * @type {string}
     * @memberof SelectOptionDTOOptionCustomizationTypeDTO
     */
    label: string;
}

export function SelectOptionDTOOptionCustomizationTypeDTOFromJSON(json: any): SelectOptionDTOOptionCustomizationTypeDTO {
    return SelectOptionDTOOptionCustomizationTypeDTOFromJSONTyped(json, false);
}

export function SelectOptionDTOOptionCustomizationTypeDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): SelectOptionDTOOptionCustomizationTypeDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'key': OptionCustomizationTypeDTOFromJSON(json['key']),
        'label': json['label'],
    };
}

export function SelectOptionDTOOptionCustomizationTypeDTOToJSON(value?: SelectOptionDTOOptionCustomizationTypeDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'key': OptionCustomizationTypeDTOToJSON(value.key),
        'label': value.label,
    };
}


