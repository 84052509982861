/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    SimilarCustomizationsDTOSimilarCustomizationExteriorColor,
    SimilarCustomizationsDTOSimilarCustomizationPaintToSampleExteriorColor,
    SimilarCustomizationsDTOSimilarCustomizationExteriorColorFromJSONTyped,
    SimilarCustomizationsDTOSimilarCustomizationExteriorColorToJSON,
    SimilarCustomizationsDTOSimilarCustomizationPaintToSampleExteriorColorFromJSONTyped,
    SimilarCustomizationsDTOSimilarCustomizationPaintToSampleExteriorColorToJSON,
} from './';

/**
 * @type SimilarCustomizationsDTOSimilarCustomization
 * 
 * @export
 */
export type SimilarCustomizationsDTOSimilarCustomization = { type: 'ExteriorColor' } & SimilarCustomizationsDTOSimilarCustomizationExteriorColor | { type: 'PaintToSampleExteriorColor' } & SimilarCustomizationsDTOSimilarCustomizationPaintToSampleExteriorColor;

export function SimilarCustomizationsDTOSimilarCustomizationFromJSON(json: any): SimilarCustomizationsDTOSimilarCustomization {
    return SimilarCustomizationsDTOSimilarCustomizationFromJSONTyped(json, false);
}

export function SimilarCustomizationsDTOSimilarCustomizationFromJSONTyped(json: any, ignoreDiscriminator: boolean): SimilarCustomizationsDTOSimilarCustomization {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    switch (json['type']) {
        case 'ExteriorColor':
            return {...SimilarCustomizationsDTOSimilarCustomizationExteriorColorFromJSONTyped(json, true), type: 'ExteriorColor'};
        case 'PaintToSampleExteriorColor':
            return {...SimilarCustomizationsDTOSimilarCustomizationPaintToSampleExteriorColorFromJSONTyped(json, true), type: 'PaintToSampleExteriorColor'};
        default:
            throw new Error(`No variant of SimilarCustomizationsDTOSimilarCustomization exists with 'type=${json['type']}'`);
    }
}

export function SimilarCustomizationsDTOSimilarCustomizationToJSON(value?: SimilarCustomizationsDTOSimilarCustomization | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    switch (value['type']) {
        case 'ExteriorColor':
            return SimilarCustomizationsDTOSimilarCustomizationExteriorColorToJSON(value);
        case 'PaintToSampleExteriorColor':
            return SimilarCustomizationsDTOSimilarCustomizationPaintToSampleExteriorColorToJSON(value);
        default:
            throw new Error(`No variant of SimilarCustomizationsDTOSimilarCustomization exists with 'type=${value['type']}'`);
    }
}

