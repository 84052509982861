import { ReadonlyFormElementWrapper } from "../../../../../../../../components/formelements/ReadonlyFormElementWrapper"
import { getTagsFormElement } from "../../../../../../../../components/formelements/tagsformelement/TagsFormElement"
import { rowGroupsSpacing, RowGroupWrapper } from "../../../../../../../../components/layouts/rowgroupwrapper/RowGroupWrapper"
import { SpacedItems } from "../../../../../../../../components/layouts/spaceditems/SpacedItems"
import { OptionsInFamilyDTO } from "../../../../../../../../generated/pdsapi"
import { ProductDetailsCardContentProps } from "../../../../../../../../viewtemplates/details/cards/ProductDetailsCard"
import { FC } from "react"
import { OldOrderTypeOptionKey } from "../../../../../OrderTypeOptionKey"

export const OptionsInFamilyCardContent: FC<
    ProductDetailsCardContentProps<OptionsInFamilyDTO, object, OldOrderTypeOptionKey> & {
        getRoute: (key: OldOrderTypeOptionKey) => string
    }
> = ({ formDisplayData, getRoute }) => (
    <SpacedItems direction={"column"} lineSpacing={rowGroupsSpacing}>
        <RowGroupWrapper label={"Options"} childrenSize={12}>
            <ReadonlyFormElementWrapper
                label={undefined}
                formDisplayData={formDisplayData}
                FormElement={getTagsFormElement()}
                getViewProps={(data) => ({
                    emptyLabel: "-",
                    tags: data.optionsInFamily.map((relatedOption) => ({
                        label: relatedOption.label,
                        route: getRoute(relatedOption),
                    })),
                    scrollToTop: true,
                })}
            />
        </RowGroupWrapper>
    </SpacedItems>
)
