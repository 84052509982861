/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    SimilarCustomizationsDTOSimilarCustomizationPaintToSampleExteriorColorInternal,
    SimilarCustomizationsDTOSimilarCustomizationPaintToSampleExteriorColorInternalFromJSONTyped,
    SimilarCustomizationsDTOSimilarCustomizationPaintToSampleExteriorColorInternalToJSON,
} from './';

/**
 * @type SimilarCustomizationsDTOSimilarCustomizationPaintToSampleExteriorColor
 * 
 * @export
 */
export type SimilarCustomizationsDTOSimilarCustomizationPaintToSampleExteriorColor = { type: 'PaintToSampleExteriorColor' } & SimilarCustomizationsDTOSimilarCustomizationPaintToSampleExteriorColorInternal;

export function SimilarCustomizationsDTOSimilarCustomizationPaintToSampleExteriorColorFromJSON(json: any): SimilarCustomizationsDTOSimilarCustomizationPaintToSampleExteriorColor {
    return SimilarCustomizationsDTOSimilarCustomizationPaintToSampleExteriorColorFromJSONTyped(json, false);
}

export function SimilarCustomizationsDTOSimilarCustomizationPaintToSampleExteriorColorFromJSONTyped(json: any, ignoreDiscriminator: boolean): SimilarCustomizationsDTOSimilarCustomizationPaintToSampleExteriorColor {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    switch (json['type']) {
        case 'PaintToSampleExteriorColor':
            return {...SimilarCustomizationsDTOSimilarCustomizationPaintToSampleExteriorColorInternalFromJSONTyped(json, true), type: 'PaintToSampleExteriorColor'};
        default:
            throw new Error(`No variant of SimilarCustomizationsDTOSimilarCustomizationPaintToSampleExteriorColor exists with 'type=${json['type']}'`);
    }
}

export function SimilarCustomizationsDTOSimilarCustomizationPaintToSampleExteriorColorToJSON(value?: SimilarCustomizationsDTOSimilarCustomizationPaintToSampleExteriorColor | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    switch (value['type']) {
        case 'PaintToSampleExteriorColor':
            return SimilarCustomizationsDTOSimilarCustomizationPaintToSampleExteriorColorInternalToJSON(value);
        default:
            throw new Error(`No variant of SimilarCustomizationsDTOSimilarCustomizationPaintToSampleExteriorColor exists with 'type=${value['type']}'`);
    }
}

