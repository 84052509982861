import { propertiesOf } from "../../../../../../common/property"
import { FormElementWrapper } from "../../../../../../components/formelements/FormElementWrapper"
import { getColorPickerFormElement } from "../../../../../../components/formelements/colorpickerformelement/ColorPickerFormElement"
import { getMultiTextFormElement } from "../../../../../../components/formelements/multitextformelement/MultiTextFormElement"
import { toOption } from "../../../../../../components/formelements/selecttextformelement/Option"
import { getSelectTextFormElement } from "../../../../../../components/formelements/selecttextformelement/SelectTextFormElement"
import { getTextFormElement } from "../../../../../../components/formelements/textformelement/TextFormElement"
import { rowGroupsSpacing, RowGroupWrapper } from "../../../../../../components/layouts/rowgroupwrapper/RowGroupWrapper"
import { SpacedItems } from "../../../../../../components/layouts/spaceditems/SpacedItems"
import {
    CustomInteriorColorAttributesDTO,
    CustomInteriorColorAttributesUpdateDTO,
    CustomInteriorColorCreateRequestDTO,
} from "../../../../../../generated/pdsapi"
import { ProductDetailsCardContentProps } from "../../../../../../viewtemplates/details/cards/ProductDetailsCard"
import { DetailsDefaultLocalizationsFormRow } from "../../../../../../viewtemplates/details/cards/components/defaultlocalizationsformrow/DetailsDefaultLocalizationsFormRow"
import { FC } from "react"
import { BasicKey } from "../../../../../../viewtemplates/details/ProductDetailsContainer"

export const CustomInteriorColorDetailsAttributesCardContent: FC<
    ProductDetailsCardContentProps<CustomInteriorColorAttributesDTO, CustomInteriorColorAttributesUpdateDTO, BasicKey>
> = ({ formDisplayData }) => {
    const properties = propertiesOf<CustomInteriorColorAttributesUpdateDTO>()

    return (
        <SpacedItems direction={"column"} lineSpacing={rowGroupsSpacing}>
            <RowGroupWrapper label={"Configuration"}>
                <FormElementWrapper
                    label={"Code"}
                    formDisplayData={formDisplayData}
                    field={properties.key}
                    FormElement={getTextFormElement<CustomInteriorColorAttributesDTO, CustomInteriorColorAttributesUpdateDTO>()}
                    getViewProps={(data) => ({
                        value: data.key,
                    })}
                    getEditProps={(data) => ({
                        value: data.key,
                        readOnly: true,
                    })}
                />
                <FormElementWrapper
                    label={"Known Names"}
                    formDisplayData={formDisplayData}
                    field={properties.knownNames}
                    FormElement={getMultiTextFormElement<unknown, CustomInteriorColorCreateRequestDTO>()}
                    getViewProps={(data) => ({
                        value: data.knownNames,
                    })}
                    getEditProps={(data) => ({
                        value: data.knownNames,
                    })}
                />
            </RowGroupWrapper>

            <DetailsDefaultLocalizationsFormRow
                label={"Name"}
                formDisplayData={formDisplayData}
                field={properties.name}
                getViewProps={(data) => ({
                    value: data.name,
                })}
                getEditProps={(data) => ({
                    value: data.name,
                })}
            />

            <RowGroupWrapper label={"Color"}>
                <FormElementWrapper
                    label={"Hex Code"}
                    formDisplayData={formDisplayData}
                    field={properties.hexCode}
                    FormElement={getColorPickerFormElement<CustomInteriorColorAttributesDTO, CustomInteriorColorCreateRequestDTO>()}
                    getViewProps={(data) => ({
                        hexValue: data.hexCode,
                    })}
                    getEditProps={(data) => ({
                        hexValue: data.hexCode,
                    })}
                />
                <FormElementWrapper
                    label={"Interior color tag"}
                    formDisplayData={formDisplayData}
                    field={properties.interiorColorTagKey}
                    FormElement={getSelectTextFormElement<unknown, CustomInteriorColorCreateRequestDTO>()}
                    getViewProps={(data) => ({
                        text: data.interiorColorTag.value?.label ?? "-",
                    })}
                    getEditProps={(data) => ({
                        options: [{ value: "", label: "-" }, ...data.interiorColorTag.options.map(toOption)],
                        value: data.interiorColorTag.value?.key ?? "",
                        optional: true,
                    })}
                />
            </RowGroupWrapper>
        </SpacedItems>
    )
}
