/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    OptionTagAttributesUpdateDTOEquipment,
    OptionTagAttributesUpdateDTOExteriorColor,
    OptionTagAttributesUpdateDTOInteriorColor,
    OptionTagAttributesUpdateDTORoofColor,
    OptionTagAttributesUpdateDTOEquipmentFromJSONTyped,
    OptionTagAttributesUpdateDTOEquipmentToJSON,
    OptionTagAttributesUpdateDTOExteriorColorFromJSONTyped,
    OptionTagAttributesUpdateDTOExteriorColorToJSON,
    OptionTagAttributesUpdateDTOInteriorColorFromJSONTyped,
    OptionTagAttributesUpdateDTOInteriorColorToJSON,
    OptionTagAttributesUpdateDTORoofColorFromJSONTyped,
    OptionTagAttributesUpdateDTORoofColorToJSON,
} from './';

/**
 * @type OptionTagAttributesUpdateDTOOptionTag
 * 
 * @export
 */
export type OptionTagAttributesUpdateDTOOptionTag = { type: 'Equipment' } & OptionTagAttributesUpdateDTOEquipment | { type: 'ExteriorColor' } & OptionTagAttributesUpdateDTOExteriorColor | { type: 'InteriorColor' } & OptionTagAttributesUpdateDTOInteriorColor | { type: 'RoofColor' } & OptionTagAttributesUpdateDTORoofColor;

export function OptionTagAttributesUpdateDTOOptionTagFromJSON(json: any): OptionTagAttributesUpdateDTOOptionTag {
    return OptionTagAttributesUpdateDTOOptionTagFromJSONTyped(json, false);
}

export function OptionTagAttributesUpdateDTOOptionTagFromJSONTyped(json: any, ignoreDiscriminator: boolean): OptionTagAttributesUpdateDTOOptionTag {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    switch (json['type']) {
        case 'Equipment':
            return {...OptionTagAttributesUpdateDTOEquipmentFromJSONTyped(json, true), type: 'Equipment'};
        case 'ExteriorColor':
            return {...OptionTagAttributesUpdateDTOExteriorColorFromJSONTyped(json, true), type: 'ExteriorColor'};
        case 'InteriorColor':
            return {...OptionTagAttributesUpdateDTOInteriorColorFromJSONTyped(json, true), type: 'InteriorColor'};
        case 'RoofColor':
            return {...OptionTagAttributesUpdateDTORoofColorFromJSONTyped(json, true), type: 'RoofColor'};
        default:
            throw new Error(`No variant of OptionTagAttributesUpdateDTOOptionTag exists with 'type=${json['type']}'`);
    }
}

export function OptionTagAttributesUpdateDTOOptionTagToJSON(value?: OptionTagAttributesUpdateDTOOptionTag | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    switch (value['type']) {
        case 'Equipment':
            return OptionTagAttributesUpdateDTOEquipmentToJSON(value);
        case 'ExteriorColor':
            return OptionTagAttributesUpdateDTOExteriorColorToJSON(value);
        case 'InteriorColor':
            return OptionTagAttributesUpdateDTOInteriorColorToJSON(value);
        case 'RoofColor':
            return OptionTagAttributesUpdateDTORoofColorToJSON(value);
        default:
            throw new Error(`No variant of OptionTagAttributesUpdateDTOOptionTag exists with 'type=${value['type']}'`);
    }
}

