import { getEnumConverterGroup, getObjectURLParamConverter, stringConverterGroup } from "../../../../common/useURLSearchParamState"
import { OrderTypeFilterOptionsDTO, OrderTypeItemDTO, OrderTypeSortField, SortDirection } from "../../../../generated/pdsapi"
import { orderTypeApi, OrderTypeFilter } from "../../../../pdsapi"
import { getProductListContainer, ProductListContainerConfig } from "../../../../viewtemplates/list/ProductListContainer"
import { OrderTypeListColumnsConfig } from "./columns/OrderTypeListColumns"
import { OrderTypeListFilter } from "./filter/OrderTypeListFilter"
import { OrderTypeListFilterTagsConfig } from "./filter/OrderTypeListFilterTagsConfig"
import { Route } from "react-router-dom"
import { routes } from "../../../../common/routes"

export const OrderTypeProductListContainerConfig: ProductListContainerConfig<OrderTypeItemDTO, OrderTypeFilter, OrderTypeFilterOptionsDTO, OrderTypeSortField> =
    {
        listName: "Order Types",
        createItemLabel: "Add new Order Type",

        buildDetailsPath: (orderType) => routes.models.orderTypes.details(orderType.key),
        buildCreatePath: routes.models.orderTypes.create,
        buildSortPath: undefined,

        defaultFilter: {},
        filterUrlParamsConverter: getObjectURLParamConverter<OrderTypeFilter>({
            modelSeriesKeys: stringConverterGroup.optionalArray,
            modelGenerationKeys: stringConverterGroup.optionalArray,
            derivativeKeys: stringConverterGroup.optionalArray,
        }),
        defaultSorting: {
            sortField: OrderTypeSortField.SORT_INDEX,
            sortDirection: SortDirection.ASC,
        },
        sortingUrlParamsConverter: getObjectURLParamConverter({
            sortField: getEnumConverterGroup(OrderTypeSortField).required,
            sortDirection: getEnumConverterGroup(SortDirection).required,
        }),
        fetchPage: orderTypeApi.list.getPage,
        fetchFilterOptions: orderTypeApi.list.getFilterOptions,

        defaultShowsFilter: false,
        Filter: OrderTypeListFilter,
        filterTagsConfig: OrderTypeListFilterTagsConfig,

        columnsConfig: OrderTypeListColumnsConfig,
    }

const OrderTypeListContainer = getProductListContainer(OrderTypeProductListContainerConfig)

export const OrderTypeListRoute = <Route path={routes.models.orderTypes.list()} Component={OrderTypeListContainer} />
