import { DefaultCardViewContentWrapper } from "../../../../components/layouts/defaultcardviewcontentwrapper/DefaultCardViewContentWrapper"
import { RoofColorCustomizationCreateOptionsDTO, RoofColorCustomizationCreateRequestDTO } from "../../../../generated/pdsapi"
import { roofColorCustomizationApi } from "../../../../pdsapi"
import { getProductCreateContainer, ProductCreateContainerConfig } from "../../../../viewtemplates/create/ProductCreateContainer"
import { VehicleImagesCreateCard } from "../../../../viewtemplates/create/cards/vehicleimagescard/VehicleImagesCreateCard"
import { RoofColorCustomizationCreateAttributesCard } from "./attributes/RoofColorCustomizationCreateAttributesCard"
import { Route } from "react-router-dom"
import { routes } from "../../../../common/routes"
import { RoofColorCustomizationProductListContainerConfig } from "../list/RoofColorCustomizationListRoute"

export const RoofColorCustomizationProductCreateContainerConfig: ProductCreateContainerConfig<
    RoofColorCustomizationCreateRequestDTO,
    RoofColorCustomizationCreateOptionsDTO
> = {
    headline: "Create new Roof Color Customization",
    buildDetailsPath: (roofColorCustomization) => routes.roofColors.customizations.details(roofColorCustomization.key),

    create: (item) =>
        roofColorCustomizationApi.create.create({
            roofColorCustomizationCreateRequestDTO: item,
        }),
    getCreateOptions: roofColorCustomizationApi.create.getCreateOptions,

    Content: (props) => (
        <DefaultCardViewContentWrapper>
            <RoofColorCustomizationCreateAttributesCard {...props} />
            <VehicleImagesCreateCard formDisplayData={props.formDisplayData} getVehicleImages={roofColorCustomizationApi.create.getVehicleImages} />
        </DefaultCardViewContentWrapper>
    ),
}

const RoofColorCustomizationCreateContainer = getProductCreateContainer(RoofColorCustomizationProductCreateContainerConfig)

export const RoofColorCustomizationCreateRoute = (
    <Route
        path={routes.roofColors.customizations.create()}
        element={
            <RoofColorCustomizationCreateContainer
                parentCrumbs={[
                    {
                        name: RoofColorCustomizationProductListContainerConfig.listName,
                        path: routes.roofColors.customizations.list(),
                    },
                ]}
            />
        }
    />
)
