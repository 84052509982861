import { derivativeCategoryApi } from "../../../../pdsapi"
import { getProductSortContainer, ProductSortContainerConfig } from "../../../../viewtemplates/sort/ProductSortContainer"
import { Route } from "react-router-dom"
import { routes } from "../../../../common/routes"
import { DerivativeCategoryProductListContainerConfig } from "../list/DerivativeCategoryListRoute"

export const DerivativeCategorySortContainerTemplateProps: ProductSortContainerConfig = {
    fetchSortables: derivativeCategoryApi.sort.getSortableItems,
    updateSortIndices: (sortIndexUpdate) => derivativeCategoryApi.sort.updateSortIndices({ sortIndexUpdateRequestDTO: sortIndexUpdate }),
}

const DerivativeCategorySortContainer = getProductSortContainer(DerivativeCategorySortContainerTemplateProps)

export const DerivativeCategorySortRoute = (
    <Route
        path={routes.models.derivativeCategories.sort()}
        element={
            <DerivativeCategorySortContainer
                parentCrumbs={[
                    {
                        name: DerivativeCategoryProductListContainerConfig.listName,
                        path: routes.models.derivativeCategories.list(),
                    },
                ]}
            />
        }
    />
)
