/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    BaseNameDTO,
    BaseNameDTOFromJSON,
    BaseNameDTOFromJSONTyped,
    BaseNameDTOToJSON,
    ExteriorColorGroup,
    ExteriorColorGroupFromJSON,
    ExteriorColorGroupFromJSONTyped,
    ExteriorColorGroupToJSON,
} from './';

/**
 * 
 * @export
 * @interface OptionUpdateDTOExteriorColorInternal
 */
export interface OptionUpdateDTOExteriorColorInternal {
    /**
     * 
     * @type {BaseNameDTO}
     * @memberof OptionUpdateDTOExteriorColorInternal
     */
    name: BaseNameDTO;
    /**
     * 
     * @type {BaseNameDTO}
     * @memberof OptionUpdateDTOExteriorColorInternal
     */
    description: BaseNameDTO;
    /**
     * 
     * @type {number}
     * @memberof OptionUpdateDTOExteriorColorInternal
     */
    sortIndex: number;
    /**
     * 
     * @type {string}
     * @memberof OptionUpdateDTOExteriorColorInternal
     */
    type: string;
    /**
     * 
     * @type {ExteriorColorGroup}
     * @memberof OptionUpdateDTOExteriorColorInternal
     */
    exteriorColorGroup?: ExteriorColorGroup;
}

export function OptionUpdateDTOExteriorColorInternalFromJSON(json: any): OptionUpdateDTOExteriorColorInternal {
    return OptionUpdateDTOExteriorColorInternalFromJSONTyped(json, false);
}

export function OptionUpdateDTOExteriorColorInternalFromJSONTyped(json: any, ignoreDiscriminator: boolean): OptionUpdateDTOExteriorColorInternal {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': BaseNameDTOFromJSON(json['name']),
        'description': BaseNameDTOFromJSON(json['description']),
        'sortIndex': json['sortIndex'],
        'type': json['type'],
        'exteriorColorGroup': !exists(json, 'exteriorColorGroup') ? undefined : ExteriorColorGroupFromJSON(json['exteriorColorGroup']),
    };
}

export function OptionUpdateDTOExteriorColorInternalToJSON(value?: OptionUpdateDTOExteriorColorInternal | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': BaseNameDTOToJSON(value.name),
        'description': BaseNameDTOToJSON(value.description),
        'sortIndex': value.sortIndex,
        'type': value.type,
        'exteriorColorGroup': ExteriorColorGroupToJSON(value.exteriorColorGroup),
    };
}


