import { interiorColorTagApi } from "../../../../pdsapi"
import { getProductSortContainer, ProductSortContainerConfig } from "../../../../viewtemplates/sort/ProductSortContainer"
import { Route } from "react-router-dom"
import { routes } from "../../../../common/routes"
import { InteriorColorTagProductListContainerConfig } from "../list/InteriorColorTagListRoute"

export const InteriorColorTagSortContainerTemplateProps: ProductSortContainerConfig = {
    fetchSortables: interiorColorTagApi.sort.getSortableItems,
    updateSortIndices: (sortIndexUpdate) => interiorColorTagApi.sort.updateSortIndices({ sortIndexUpdateRequestDTO: sortIndexUpdate }),
}

const InteriorColorTagSortContainer = getProductSortContainer(InteriorColorTagSortContainerTemplateProps)

export const InteriorColorTagSortRoute = (
    <Route
        path={routes.interiors.colorTags.sort()}
        element={
            <InteriorColorTagSortContainer
                parentCrumbs={[
                    {
                        name: InteriorColorTagProductListContainerConfig.listName,
                        path: routes.interiors.colorTags.list(),
                    },
                ]}
            />
        }
    />
)
