/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    OptionGroupDTOString,
    OptionGroupDTOStringFromJSON,
    OptionGroupDTOStringFromJSONTyped,
    OptionGroupDTOStringToJSON,
    SelectOptionDTOVehicleEquipmentCategory,
    SelectOptionDTOVehicleEquipmentCategoryFromJSON,
    SelectOptionDTOVehicleEquipmentCategoryFromJSONTyped,
    SelectOptionDTOVehicleEquipmentCategoryToJSON,
} from './';

/**
 * 
 * @export
 * @interface OrderTypeEquipmentFilterOptionsDTO
 */
export interface OrderTypeEquipmentFilterOptionsDTO {
    /**
     * 
     * @type {Array<SelectOptionDTOVehicleEquipmentCategory>}
     * @memberof OrderTypeEquipmentFilterOptionsDTO
     */
    categories: Array<SelectOptionDTOVehicleEquipmentCategory>;
    /**
     * 
     * @type {Array<OptionGroupDTOString>}
     * @memberof OrderTypeEquipmentFilterOptionsDTO
     */
    tags: Array<OptionGroupDTOString>;
    /**
     * 
     * @type {Array<string>}
     * @memberof OrderTypeEquipmentFilterOptionsDTO
     */
    families: Array<string>;
}

export function OrderTypeEquipmentFilterOptionsDTOFromJSON(json: any): OrderTypeEquipmentFilterOptionsDTO {
    return OrderTypeEquipmentFilterOptionsDTOFromJSONTyped(json, false);
}

export function OrderTypeEquipmentFilterOptionsDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrderTypeEquipmentFilterOptionsDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'categories': ((json['categories'] as Array<any>).map(SelectOptionDTOVehicleEquipmentCategoryFromJSON)),
        'tags': ((json['tags'] as Array<any>).map(OptionGroupDTOStringFromJSON)),
        'families': json['families'],
    };
}

export function OrderTypeEquipmentFilterOptionsDTOToJSON(value?: OrderTypeEquipmentFilterOptionsDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'categories': ((value.categories as Array<any>).map(SelectOptionDTOVehicleEquipmentCategoryToJSON)),
        'tags': ((value.tags as Array<any>).map(OptionGroupDTOStringToJSON)),
        'families': value.families,
    };
}


