import { DefaultCardViewContentWrapper } from "../../../../components/layouts/defaultcardviewcontentwrapper/DefaultCardViewContentWrapper"
import { ExteriorColorTagCreateRequestDTO } from "../../../../generated/pdsapi"
import { exteriorColorTagApi } from "../../../../pdsapi"
import { getProductCreateContainer, ProductCreateContainerConfig } from "../../../../viewtemplates/create/ProductCreateContainer"
import { ExteriorColorTagCreateAttributesCard } from "./attributes/ExteriorColorTagCreateAttributesCard"
import { routes } from "../../../../common/routes"
import { ExteriorColorTagProductListContainerConfig } from "../list/ExteriorColorTagListRoute"
import { Route } from "react-router-dom"

export const ExteriorColorTagProductCreateContainerConfig: ProductCreateContainerConfig<ExteriorColorTagCreateRequestDTO, unknown> = {
    headline: "Create new Exterior Color Tag",
    buildDetailsPath: ({ key }) => routes.exteriorColors.tags.details(key),

    create: (item) =>
        exteriorColorTagApi.create.create({
            exteriorColorTagCreateRequestDTO: item,
        }),
    getCreateOptions: async () => ({}),

    Content: (props) => (
        <DefaultCardViewContentWrapper>
            <ExteriorColorTagCreateAttributesCard {...props} />
        </DefaultCardViewContentWrapper>
    ),
}

const ExteriorColorTagCreateContainer = getProductCreateContainer(ExteriorColorTagProductCreateContainerConfig)

export const ExteriorColorTagCreateRoute = (
    <Route
        path={routes.exteriorColors.tags.create()}
        element={
            <ExteriorColorTagCreateContainer
                parentCrumbs={[
                    {
                        name: ExteriorColorTagProductListContainerConfig.listName,
                        path: routes.exteriorColors.tags.list(),
                    },
                ]}
            />
        }
    />
)
