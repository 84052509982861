/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MultiSelectDTOString,
    MultiSelectDTOStringFromJSON,
    MultiSelectDTOStringFromJSONTyped,
    MultiSelectDTOStringToJSON,
    OptionCustomizationAttributesDTOInteriorValue,
    OptionCustomizationAttributesDTOInteriorValueFromJSON,
    OptionCustomizationAttributesDTOInteriorValueFromJSONTyped,
    OptionCustomizationAttributesDTOInteriorValueToJSON,
    OptionCustomizationConditionalValueDTOOptionCustomizationAttributesDTOInteriorValue,
    OptionCustomizationConditionalValueDTOOptionCustomizationAttributesDTOInteriorValueFromJSON,
    OptionCustomizationConditionalValueDTOOptionCustomizationAttributesDTOInteriorValueFromJSONTyped,
    OptionCustomizationConditionalValueDTOOptionCustomizationAttributesDTOInteriorValueToJSON,
    SelectOptionDTOString,
    SelectOptionDTOStringFromJSON,
    SelectOptionDTOStringFromJSONTyped,
    SelectOptionDTOStringToJSON,
} from './';

/**
 * 
 * @export
 * @interface OptionCustomizationAttributesDTOInteriorInternal
 */
export interface OptionCustomizationAttributesDTOInteriorInternal {
    /**
     * 
     * @type {OptionCustomizationAttributesDTOInteriorValue}
     * @memberof OptionCustomizationAttributesDTOInteriorInternal
     */
    defaultValue: OptionCustomizationAttributesDTOInteriorValue;
    /**
     * 
     * @type {string}
     * @memberof OptionCustomizationAttributesDTOInteriorInternal
     */
    optionCode: string;
    /**
     * 
     * @type {Array<OptionCustomizationConditionalValueDTOOptionCustomizationAttributesDTOInteriorValue>}
     * @memberof OptionCustomizationAttributesDTOInteriorInternal
     */
    conditionalValues: Array<OptionCustomizationConditionalValueDTOOptionCustomizationAttributesDTOInteriorValue>;
    /**
     * 
     * @type {MultiSelectDTOString}
     * @memberof OptionCustomizationAttributesDTOInteriorInternal
     */
    modelGenerations: MultiSelectDTOString;
    /**
     * 
     * @type {boolean}
     * @memberof OptionCustomizationAttributesDTOInteriorInternal
     */
    isDefaultValueActive: boolean;
    /**
     * 
     * @type {Array<SelectOptionDTOString>}
     * @memberof OptionCustomizationAttributesDTOInteriorInternal
     */
    derivatives: Array<SelectOptionDTOString>;
    /**
     * 
     * @type {string}
     * @memberof OptionCustomizationAttributesDTOInteriorInternal
     */
    type: string;
}

export function OptionCustomizationAttributesDTOInteriorInternalFromJSON(json: any): OptionCustomizationAttributesDTOInteriorInternal {
    return OptionCustomizationAttributesDTOInteriorInternalFromJSONTyped(json, false);
}

export function OptionCustomizationAttributesDTOInteriorInternalFromJSONTyped(json: any, ignoreDiscriminator: boolean): OptionCustomizationAttributesDTOInteriorInternal {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'defaultValue': OptionCustomizationAttributesDTOInteriorValueFromJSON(json['defaultValue']),
        'optionCode': json['optionCode'],
        'conditionalValues': ((json['conditionalValues'] as Array<any>).map(OptionCustomizationConditionalValueDTOOptionCustomizationAttributesDTOInteriorValueFromJSON)),
        'modelGenerations': MultiSelectDTOStringFromJSON(json['modelGenerations']),
        'isDefaultValueActive': json['isDefaultValueActive'],
        'derivatives': ((json['derivatives'] as Array<any>).map(SelectOptionDTOStringFromJSON)),
        'type': json['type'],
    };
}

export function OptionCustomizationAttributesDTOInteriorInternalToJSON(value?: OptionCustomizationAttributesDTOInteriorInternal | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'defaultValue': OptionCustomizationAttributesDTOInteriorValueToJSON(value.defaultValue),
        'optionCode': value.optionCode,
        'conditionalValues': ((value.conditionalValues as Array<any>).map(OptionCustomizationConditionalValueDTOOptionCustomizationAttributesDTOInteriorValueToJSON)),
        'modelGenerations': MultiSelectDTOStringToJSON(value.modelGenerations),
        'isDefaultValueActive': value.isDefaultValueActive,
        'derivatives': ((value.derivatives as Array<any>).map(SelectOptionDTOStringToJSON)),
        'type': value.type,
    };
}


