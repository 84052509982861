import { DefaultCardViewContentWrapper } from "../../../../../components/layouts/defaultcardviewcontentwrapper/DefaultCardViewContentWrapper"
import { OptionCreateRequestDTO, OptionCreateRequestOptionsDTO } from "../../../../../generated/pdsapi"
import { optionApi } from "../../../../../pdsapi"
import { getProductCreateContainer, ProductCreateContainerConfig } from "../../../../../viewtemplates/create/ProductCreateContainer"
import { OrderTypeOptionKey } from "../../OrderTypeOptionKey"
import { routes } from "../../../../../common/routes"
import { Route, useParams } from "react-router-dom"
import { useOrderTypeSubpageCrumbs } from "../../useOrderTypeSubpageCrumbs"
import { OrderTypeOptionCreateOptionCard } from "./option/OrderTypeOptionCreateOptionCard"

export const OrderTypeOptionProductCreateContainerConfig: ProductCreateContainerConfig<
    OptionCreateRequestDTO,
    OptionCreateRequestOptionsDTO,
    OrderTypeOptionKey
> = {
    headline: "Create new Option",
    buildDetailsPath: ({ orderTypeKey, optionCode, type }) => routes.models.orderTypes.subpages(orderTypeKey).options.details(optionCode, type),

    create: (item) =>
        optionApi.create.create({
            optionCreateRequestDTO: item,
        }),
    getCreateOptions: optionApi.create.getCreateOptions,

    Content: (props) => (
        <DefaultCardViewContentWrapper>
            <OrderTypeOptionCreateOptionCard {...props} />
        </DefaultCardViewContentWrapper>
    ),
}

const OrderTypeOptionCreateContainer = getProductCreateContainer(OrderTypeOptionProductCreateContainerConfig)

export const OrderTypeOptionCreateRoute = (
    <Route
        path={routes.models.orderTypes.subpages(":orderTypeKey").options.create()}
        Component={() => {
            const params = useParams<{ orderTypeKey: string }>()
            const parentCrumbs = useOrderTypeSubpageCrumbs(params.orderTypeKey)

            return (
                <OrderTypeOptionCreateContainer
                    parentCrumbs={[
                        ...parentCrumbs,
                        {
                            name: "Options",
                            path: routes.models.orderTypes.subpages(params.orderTypeKey).options.list(),
                        },
                    ]}
                />
            )
        }}
    />
)
