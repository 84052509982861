/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    OptionCustomizationAttributesUpdateDTOEquipmentValue,
    OptionCustomizationAttributesUpdateDTOEquipmentValueFromJSON,
    OptionCustomizationAttributesUpdateDTOEquipmentValueFromJSONTyped,
    OptionCustomizationAttributesUpdateDTOEquipmentValueToJSON,
    OptionCustomizationConditionalValueUpdateDTOOptionCustomizationAttributesUpdateDTOEquipmentValue,
    OptionCustomizationConditionalValueUpdateDTOOptionCustomizationAttributesUpdateDTOEquipmentValueFromJSON,
    OptionCustomizationConditionalValueUpdateDTOOptionCustomizationAttributesUpdateDTOEquipmentValueFromJSONTyped,
    OptionCustomizationConditionalValueUpdateDTOOptionCustomizationAttributesUpdateDTOEquipmentValueToJSON,
} from './';

/**
 * 
 * @export
 * @interface OptionCustomizationAttributesUpdateDTOEquipmentInternal
 */
export interface OptionCustomizationAttributesUpdateDTOEquipmentInternal {
    /**
     * 
     * @type {OptionCustomizationAttributesUpdateDTOEquipmentValue}
     * @memberof OptionCustomizationAttributesUpdateDTOEquipmentInternal
     */
    defaultValue: OptionCustomizationAttributesUpdateDTOEquipmentValue;
    /**
     * 
     * @type {string}
     * @memberof OptionCustomizationAttributesUpdateDTOEquipmentInternal
     */
    optionCode: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof OptionCustomizationAttributesUpdateDTOEquipmentInternal
     */
    modelGenerationKeys: Array<string>;
    /**
     * 
     * @type {Array<OptionCustomizationConditionalValueUpdateDTOOptionCustomizationAttributesUpdateDTOEquipmentValue>}
     * @memberof OptionCustomizationAttributesUpdateDTOEquipmentInternal
     */
    conditionalValues: Array<OptionCustomizationConditionalValueUpdateDTOOptionCustomizationAttributesUpdateDTOEquipmentValue>;
    /**
     * 
     * @type {boolean}
     * @memberof OptionCustomizationAttributesUpdateDTOEquipmentInternal
     */
    isDefaultValueActive: boolean;
    /**
     * 
     * @type {string}
     * @memberof OptionCustomizationAttributesUpdateDTOEquipmentInternal
     */
    type: string;
}

export function OptionCustomizationAttributesUpdateDTOEquipmentInternalFromJSON(json: any): OptionCustomizationAttributesUpdateDTOEquipmentInternal {
    return OptionCustomizationAttributesUpdateDTOEquipmentInternalFromJSONTyped(json, false);
}

export function OptionCustomizationAttributesUpdateDTOEquipmentInternalFromJSONTyped(json: any, ignoreDiscriminator: boolean): OptionCustomizationAttributesUpdateDTOEquipmentInternal {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'defaultValue': OptionCustomizationAttributesUpdateDTOEquipmentValueFromJSON(json['defaultValue']),
        'optionCode': json['optionCode'],
        'modelGenerationKeys': json['modelGenerationKeys'],
        'conditionalValues': ((json['conditionalValues'] as Array<any>).map(OptionCustomizationConditionalValueUpdateDTOOptionCustomizationAttributesUpdateDTOEquipmentValueFromJSON)),
        'isDefaultValueActive': json['isDefaultValueActive'],
        'type': json['type'],
    };
}

export function OptionCustomizationAttributesUpdateDTOEquipmentInternalToJSON(value?: OptionCustomizationAttributesUpdateDTOEquipmentInternal | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'defaultValue': OptionCustomizationAttributesUpdateDTOEquipmentValueToJSON(value.defaultValue),
        'optionCode': value.optionCode,
        'modelGenerationKeys': value.modelGenerationKeys,
        'conditionalValues': ((value.conditionalValues as Array<any>).map(OptionCustomizationConditionalValueUpdateDTOOptionCustomizationAttributesUpdateDTOEquipmentValueToJSON)),
        'isDefaultValueActive': value.isDefaultValueActive,
        'type': value.type,
    };
}


