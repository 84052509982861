import { equipmentTagApi } from "../../../../pdsapi"
import { getProductSortContainer, ProductSortContainerConfig } from "../../../../viewtemplates/sort/ProductSortContainer"
import { Route } from "react-router-dom"
import { routes } from "../../../../common/routes"
import { EquipmentTagProductListContainerConfig } from "../list/EquipmentTagListRoute"

export const EquipmentTagSortContainerTemplateProps: ProductSortContainerConfig = {
    fetchSortables: equipmentTagApi.sort.getSortableItems,
    updateSortIndices: (sortIndexUpdate) => equipmentTagApi.sort.updateSortIndices({ sortIndexUpdateRequestDTO: sortIndexUpdate }),
    disclaimer:
        "Updating equipment tag sorting is a longer running process triggered in the background. The updated order will only be visible after a refresh in a couple of minutes.",
}

const EquipmentTagSortContainer = getProductSortContainer(EquipmentTagSortContainerTemplateProps)

export const EquipmentTagSortRoute = (
    <Route
        path={routes.equipment.tags.sort()}
        element={
            <EquipmentTagSortContainer
                parentCrumbs={[
                    {
                        name: EquipmentTagProductListContainerConfig.listName,
                        path: routes.equipment.tags.list(),
                    },
                ]}
            />
        }
    />
)
