import { TableFilterTagsConfig } from "../../../../../components/filteredpagedtable/FilteredPagedTable"
import { Tag } from "../../../../../components/tag/Tag"
import { OptionTagFilterOptionsDTO } from "../../../../../generated/pdsapi"
import { OptionTagFilter } from "../../../../../pdsapi"
import { findOption } from "../../../../../viewtemplates/list/filter/ListFilterTagsConfig"
import { JSX } from "react"
import { ActiveTag } from "../../../../../components/filteredpagedtable/filtertags/activetag/ActiveTag"

export const OptionTagListFilterTagsConfig: TableFilterTagsConfig<OptionTagFilter, OptionTagFilterOptionsDTO> = ({ filter, setFilter, filterOptions }) => {
    const filterTags: JSX.Element[] = []

    filter.types?.forEach((type) => {
        filterTags.push(
            <Tag
                key={`filterTag_type_${type}`}
                onRemove={() =>
                    setFilter((filter) => {
                        const newTypes = filter.types?.filter((t) => type !== t)
                        return {
                            ...filter,
                            types: newTypes?.length !== 0 ? newTypes : undefined,
                        }
                    })
                }
            >
                {findOption(filterOptions?.types, type)?.label ?? type}
            </Tag>
        )
    })
    if (filter.active !== undefined) {
        filterTags.push(
            <ActiveTag
                key={"active_tag"}
                active={filter.active}
                removeActiveFlag={() =>
                    setFilter((findParams) => ({
                        ...findParams,
                        active: undefined,
                    }))
                }
            />
        )
    }
    filter.equipmentTagCategories?.forEach((category) => {
        filterTags.push(
            <Tag
                key={`filterTag_category_${category}`}
                onRemove={() =>
                    setFilter((filter) => {
                        const newCategories = filter.equipmentTagCategories?.filter((c) => category !== c)
                        return {
                            ...filter,
                            equipmentTagCategories: newCategories?.length !== 0 ? newCategories : undefined,
                        }
                    })
                }
            >
                {findOption(filterOptions?.equipmentTagCategories, category)?.label ?? category}
            </Tag>
        )
    })

    return filterTags
}
