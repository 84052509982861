import { DefaultCardViewContentWrapper } from "../../../../../components/layouts/defaultcardviewcontentwrapper/DefaultCardViewContentWrapper"
import { EquipmentCreateOptionsDTO, EquipmentCreateRequestDTO } from "../../../../../generated/pdsapi"
import { equipmentApi } from "../../../../../pdsapi"
import { getProductCreateContainer, ProductCreateContainerConfig } from "../../../../../viewtemplates/create/ProductCreateContainer"
import { OrderTypeEquipmentCreateOptionCard } from "./option/OrderTypeEquipmentCreateOptionCard"
import { Route, useParams } from "react-router-dom"
import { routes } from "../../../../../common/routes"
import { OldOrderTypeOptionKey } from "../../OrderTypeOptionKey"
import { useOrderTypeSubpageCrumbs } from "../../useOrderTypeSubpageCrumbs"

export const OrderTypeEquipmentProductCreateContainerConfig: ProductCreateContainerConfig<
    EquipmentCreateRequestDTO,
    EquipmentCreateOptionsDTO,
    OldOrderTypeOptionKey
> = {
    headline: "Create new Equipment",
    buildDetailsPath: ({ orderTypeKey, optionCode }) => routes.models.orderTypes.subpages(orderTypeKey).equipment.details(optionCode),

    create: (item) =>
        equipmentApi.create.create({
            equipmentCreateRequestDTO: item,
        }),
    getCreateOptions: equipmentApi.create.getCreateOptions,

    Content: (props) => (
        <DefaultCardViewContentWrapper>
            <OrderTypeEquipmentCreateOptionCard {...props} />
        </DefaultCardViewContentWrapper>
    ),
}

const OrderTypeEquipmentCreateContainer = getProductCreateContainer(OrderTypeEquipmentProductCreateContainerConfig)

export const OrderTypeEquipmentCreateRoute = (
    <Route
        path={routes.models.orderTypes.subpages(":orderTypeKey").equipment.create()}
        Component={() => {
            const params = useParams<{ orderTypeKey: string }>()
            const parentCrumbs = useOrderTypeSubpageCrumbs(params.orderTypeKey)

            return (
                <OrderTypeEquipmentCreateContainer
                    parentCrumbs={[
                        ...parentCrumbs,
                        {
                            name: "Equipment",
                            path: routes.models.orderTypes.subpages(params.orderTypeKey).equipment.list(),
                        },
                    ]}
                />
            )
        }}
    />
)
