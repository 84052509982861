import { DefaultCardViewContentWrapper } from "../../../../components/layouts/defaultcardviewcontentwrapper/DefaultCardViewContentWrapper"
import { ModelSeriesCreateRequestDTO } from "../../../../generated/pdsapi"
import { modelSeriesApi } from "../../../../pdsapi"
import { getProductCreateContainer, ProductCreateContainerConfig } from "../../../../viewtemplates/create/ProductCreateContainer"
import { ModelSeriesCreateAttributesCard } from "./attributes/ModelSeriesCreateAttributesCard"
import { routes } from "../../../../common/routes"
import { Route } from "react-router-dom"
import { ModelSeriesProductListContainerConfig } from "../list/ModelSeriesListRoute"

export const ModelSeriesProductCreateContainerConfig: ProductCreateContainerConfig<ModelSeriesCreateRequestDTO, unknown> = {
    headline: "Create new Model Series",
    buildDetailsPath: ({ key }) => routes.models.modelSeries.details(key),

    create: (item) =>
        modelSeriesApi.create.create({
            modelSeriesCreateRequestDTO: item,
        }),
    getCreateOptions: async () => ({}),

    Content: (props) => (
        <DefaultCardViewContentWrapper>
            <ModelSeriesCreateAttributesCard {...props} />
        </DefaultCardViewContentWrapper>
    ),
}

const ModelSeriesCreateContainer = getProductCreateContainer(ModelSeriesProductCreateContainerConfig)

export const ModelSeriesCreateRoute = (
    <Route
        path={routes.models.modelSeries.create()}
        element={
            <ModelSeriesCreateContainer
                parentCrumbs={[
                    {
                        name: ModelSeriesProductListContainerConfig.listName,
                        path: routes.models.modelSeries.list(),
                    },
                ]}
            />
        }
    />
)
