/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    OptionTagAttributesDTORoofColorInternal,
    OptionTagAttributesDTORoofColorInternalFromJSONTyped,
    OptionTagAttributesDTORoofColorInternalToJSON,
} from './';

/**
 * @type OptionTagAttributesDTORoofColor
 * 
 * @export
 */
export type OptionTagAttributesDTORoofColor = { type: 'RoofColor' } & OptionTagAttributesDTORoofColorInternal;

export function OptionTagAttributesDTORoofColorFromJSON(json: any): OptionTagAttributesDTORoofColor {
    return OptionTagAttributesDTORoofColorFromJSONTyped(json, false);
}

export function OptionTagAttributesDTORoofColorFromJSONTyped(json: any, ignoreDiscriminator: boolean): OptionTagAttributesDTORoofColor {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    switch (json['type']) {
        case 'RoofColor':
            return {...OptionTagAttributesDTORoofColorInternalFromJSONTyped(json, true), type: 'RoofColor'};
        default:
            throw new Error(`No variant of OptionTagAttributesDTORoofColor exists with 'type=${json['type']}'`);
    }
}

export function OptionTagAttributesDTORoofColorToJSON(value?: OptionTagAttributesDTORoofColor | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    switch (value['type']) {
        case 'RoofColor':
            return OptionTagAttributesDTORoofColorInternalToJSON(value);
        default:
            throw new Error(`No variant of OptionTagAttributesDTORoofColor exists with 'type=${value['type']}'`);
    }
}

