import { DerivativeCategoryListRoute } from "./list/DerivativeCategoryListRoute"
import { DerivativeCategoryDetailsRoute } from "./details/DerivativeCategoryDetailsRoute"
import { DerivativeCategoryCreateRoute } from "./create/DerivativeCategoryCreateRoute"
import { DerivativeCategorySortRoute } from "./sort/DerivativeCategorySortRoute"

export const derivativeCategoryRoutes = [
    DerivativeCategoryListRoute,
    DerivativeCategoryCreateRoute,
    DerivativeCategoryDetailsRoute,
    DerivativeCategorySortRoute,
]
