import { propertiesOf } from "../../../../../../common/property"
import { EditFormElementWrapper } from "../../../../../../components/formelements/EditFormElementWrapper"
import { getNumberFormElement } from "../../../../../../components/formelements/numberformelement/NumberFormElement"
import { getSelectTextFormElement } from "../../../../../../components/formelements/selecttextformelement/SelectTextFormElement"
import { getTextFormElement } from "../../../../../../components/formelements/textformelement/TextFormElement"
import { rowGroupsSpacing, RowGroupWrapper } from "../../../../../../components/layouts/rowgroupwrapper/RowGroupWrapper"
import { SpacedItems } from "../../../../../../components/layouts/spaceditems/SpacedItems"
import {
    OptionCreateRequestDTO,
    OptionCreateRequestDTOExteriorColor,
    OptionCreateRequestDTOPaintToSampleExteriorColor,
    OptionCreateRequestOptionsDTO,
    OrderTypeOptionTypeDTO,
} from "../../../../../../generated/pdsapi"
import { ProductCreateContentProps } from "../../../../../../viewtemplates/create/ProductCreateContainer"
import { CreateDefaultLocalizationsRow } from "../../../../../../viewtemplates/create/components/createdefaultlocalizationsrow/CreateDefaultLocalizationsRow"
import React, { FC, JSX } from "react"
import { toOption } from "../../../../../../components/formelements/selecttextformelement/Option"
import { EditFormDisplayData, getEditData, LoadingFormDisplayData } from "../../../../../../components/formelements/FormDisplayData"
import { useParams } from "react-router-dom"
import { OrderTypeOptionKey } from "../../../OrderTypeOptionKey"
import { useOrderTypeOptionsContext } from "../../../OrderTypeOptionsContext"
import { enumToOptionType, optionTypeOptions } from "../../optionTypeMappings"
import { PIcon, PText } from "@porsche-design-system/components-react"
import { getMultiTextFormElement } from "../../../../../../components/formelements/multitextformelement/MultiTextFormElement"

export const OrderTypeOptionCreateOptionCard: FC<ProductCreateContentProps<OptionCreateRequestDTO, OptionCreateRequestOptionsDTO>> = ({ formDisplayData }) => {
    const properties = propertiesOf<OptionCreateRequestDTO>()
    const { orderTypeKey } = useParams<OrderTypeOptionKey>()
    const { optionType } = useOrderTypeOptionsContext()

    return (
        <SpacedItems direction={"column"} lineSpacing={rowGroupsSpacing}>
            <RowGroupWrapper label={"Configuration"}>
                <EditFormElementWrapper
                    label={"Order Type"}
                    formDisplayData={formDisplayData}
                    field={properties.orderTypeKey}
                    FormElement={getSelectTextFormElement<unknown, OptionCreateRequestDTO>()}
                    getEditProps={() => ({
                        options: [{ value: orderTypeKey, label: orderTypeKey }],
                        value: orderTypeKey,
                        readOnly: true,
                    })}
                />
                <EditFormElementWrapper
                    label={"Type"}
                    formDisplayData={formDisplayData}
                    field={properties.type}
                    FormElement={getSelectTextFormElement<OptionCreateRequestOptionsDTO, OptionCreateRequestDTO>()}
                    getEditProps={() => ({
                        options: optionTypeOptions,
                        value: enumToOptionType(optionType ?? OrderTypeOptionTypeDTO.EXTERIOR_COLOR),
                    })}
                />
                <EditFormElementWrapper
                    label={"Option Code"}
                    formDisplayData={formDisplayData}
                    field={properties.optionCode}
                    FormElement={getTextFormElement<unknown, OptionCreateRequestDTO>()}
                    getEditProps={() => ({
                        value: "",
                    })}
                />
            </RowGroupWrapper>
            <RowGroupWrapper>
                <EditFormElementWrapper
                    label={"Sort Index"}
                    formDisplayData={formDisplayData}
                    field={properties.sortIndex}
                    FormElement={getNumberFormElement<unknown, OptionCreateRequestDTO>()}
                    getEditProps={() => ({
                        step: 1,
                        min: 0,
                        value: undefined,
                        optional: true,
                    })}
                />
            </RowGroupWrapper>

            <CreateDefaultLocalizationsRow
                label={"Name"}
                formDisplayData={formDisplayData}
                field={properties.name}
                getEditProps={() => ({
                    optional: true,
                    value: { de: "", en: "" },
                })}
            />

            <CreateDefaultLocalizationsRow
                label={"Description"}
                formDisplayData={formDisplayData}
                field={properties.description}
                getEditProps={() => ({
                    optional: true,
                    value: { de: "", en: "" },
                })}
            />

            <TypeSpecificRowGroup formDisplayData={formDisplayData}></TypeSpecificRowGroup>
        </SpacedItems>
    )
}

const TypeSpecificRowGroup = ({
    formDisplayData,
}: {
    formDisplayData: LoadingFormDisplayData | EditFormDisplayData<OptionCreateRequestOptionsDTO, OptionCreateRequestDTO>
}): JSX.Element | null => {
    switch (getEditData(formDisplayData)?.watch("type")) {
        case "ExteriorColor": {
            const properties = propertiesOf<OptionCreateRequestDTOExteriorColor>()

            return (
                <RowGroupWrapper>
                    <EditFormElementWrapper
                        label={"Exterior Color Group"}
                        formDisplayData={formDisplayData}
                        field={properties.exteriorColorGroup}
                        FormElement={getSelectTextFormElement<unknown, OptionCreateRequestDTO>()}
                        getEditProps={(options) => ({
                            options: [{ value: "", label: "-" }, ...options.exteriorColorGroups.map(toOption)],
                            value: "",
                            optional: true,
                        })}
                    />
                </RowGroupWrapper>
            )
        }

        case "PaintToSampleExteriorColor": {
            const properties = propertiesOf<OptionCreateRequestDTOPaintToSampleExteriorColor>()

            return (
                <SpacedItems direction={"column"} lineSpacing={rowGroupsSpacing}>
                    <RowGroupWrapper label={"References"}>
                        <EditFormElementWrapper
                            label={"Paint to Sample Equipment Option Code"}
                            formDisplayData={formDisplayData}
                            field={properties.paintToSampleEquipmentOptionCode}
                            FormElement={getTextFormElement<unknown, OptionCreateRequestDTO>()}
                            getEditProps={() => ({
                                value: "",
                                readOnly: false,
                                optional: true,
                            })}
                        />
                        <EditFormElementWrapper
                            label={"Paint to Sample Plus Equipment Option Code"}
                            formDisplayData={formDisplayData}
                            field={properties.paintToSamplePlusEquipmentOptionCode}
                            FormElement={getTextFormElement<unknown, OptionCreateRequestDTO>()}
                            getEditProps={() => ({
                                value: "",
                                readOnly: false,
                                optional: true,
                            })}
                        />
                    </RowGroupWrapper>
                    <RowGroupWrapper childrenSize={8}>
                        <EditFormElementWrapper
                            label={"Z-Order Option Code(s)"}
                            formDisplayData={formDisplayData}
                            field={properties.zOrderOptionCodes}
                            FormElement={getMultiTextFormElement<unknown, OptionCreateRequestDTO>()}
                            getEditProps={() => ({
                                value: [],
                                readOnly: false,
                                optional: true,
                            })}
                        />
                        <div style={{ display: "flex", gap: "4px" }}>
                            <PIcon name={"information"} color={"contrast-medium"}></PIcon>
                            <PText color={"neutral-contrast-medium"} size={"x-small"}>
                                Comma-separated list of Z-Order Options from which to take the colors for this Paint to Sample Option (e.g. "24931, 25401").
                                Ideally this field contains only one option code, but on some older order types we have found more than one.
                            </PText>
                        </div>
                    </RowGroupWrapper>
                </SpacedItems>
            )
        }
        case undefined:
            return null
    }
}
