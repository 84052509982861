/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    InteriorCustomizationAttributesValueDTO,
    InteriorCustomizationAttributesValueDTOFromJSON,
    InteriorCustomizationAttributesValueDTOFromJSONTyped,
    InteriorCustomizationAttributesValueDTOToJSON,
    MultiSelectDTOString,
    MultiSelectDTOStringFromJSON,
    MultiSelectDTOStringFromJSONTyped,
    MultiSelectDTOStringToJSON,
    OptionCustomizationConditionalValueDTOInteriorCustomizationAttributesValueDTO,
    OptionCustomizationConditionalValueDTOInteriorCustomizationAttributesValueDTOFromJSON,
    OptionCustomizationConditionalValueDTOInteriorCustomizationAttributesValueDTOFromJSONTyped,
    OptionCustomizationConditionalValueDTOInteriorCustomizationAttributesValueDTOToJSON,
    SelectOptionDTOString,
    SelectOptionDTOStringFromJSON,
    SelectOptionDTOStringFromJSONTyped,
    SelectOptionDTOStringToJSON,
} from './';

/**
 * 
 * @export
 * @interface OldOptionCustomizationAttributesDTOInteriorCustomizationAttributesValueDTO
 */
export interface OldOptionCustomizationAttributesDTOInteriorCustomizationAttributesValueDTO {
    /**
     * 
     * @type {string}
     * @memberof OldOptionCustomizationAttributesDTOInteriorCustomizationAttributesValueDTO
     */
    optionCode: string;
    /**
     * 
     * @type {MultiSelectDTOString}
     * @memberof OldOptionCustomizationAttributesDTOInteriorCustomizationAttributesValueDTO
     */
    modelGenerations: MultiSelectDTOString;
    /**
     * 
     * @type {Array<SelectOptionDTOString>}
     * @memberof OldOptionCustomizationAttributesDTOInteriorCustomizationAttributesValueDTO
     */
    derivatives: Array<SelectOptionDTOString>;
    /**
     * 
     * @type {boolean}
     * @memberof OldOptionCustomizationAttributesDTOInteriorCustomizationAttributesValueDTO
     */
    isDefaultValueActive: boolean;
    /**
     * 
     * @type {InteriorCustomizationAttributesValueDTO}
     * @memberof OldOptionCustomizationAttributesDTOInteriorCustomizationAttributesValueDTO
     */
    defaultValue: InteriorCustomizationAttributesValueDTO;
    /**
     * 
     * @type {Array<OptionCustomizationConditionalValueDTOInteriorCustomizationAttributesValueDTO>}
     * @memberof OldOptionCustomizationAttributesDTOInteriorCustomizationAttributesValueDTO
     */
    conditionalValues: Array<OptionCustomizationConditionalValueDTOInteriorCustomizationAttributesValueDTO>;
}

export function OldOptionCustomizationAttributesDTOInteriorCustomizationAttributesValueDTOFromJSON(json: any): OldOptionCustomizationAttributesDTOInteriorCustomizationAttributesValueDTO {
    return OldOptionCustomizationAttributesDTOInteriorCustomizationAttributesValueDTOFromJSONTyped(json, false);
}

export function OldOptionCustomizationAttributesDTOInteriorCustomizationAttributesValueDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): OldOptionCustomizationAttributesDTOInteriorCustomizationAttributesValueDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'optionCode': json['optionCode'],
        'modelGenerations': MultiSelectDTOStringFromJSON(json['modelGenerations']),
        'derivatives': ((json['derivatives'] as Array<any>).map(SelectOptionDTOStringFromJSON)),
        'isDefaultValueActive': json['isDefaultValueActive'],
        'defaultValue': InteriorCustomizationAttributesValueDTOFromJSON(json['defaultValue']),
        'conditionalValues': ((json['conditionalValues'] as Array<any>).map(OptionCustomizationConditionalValueDTOInteriorCustomizationAttributesValueDTOFromJSON)),
    };
}

export function OldOptionCustomizationAttributesDTOInteriorCustomizationAttributesValueDTOToJSON(value?: OldOptionCustomizationAttributesDTOInteriorCustomizationAttributesValueDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'optionCode': value.optionCode,
        'modelGenerations': MultiSelectDTOStringToJSON(value.modelGenerations),
        'derivatives': ((value.derivatives as Array<any>).map(SelectOptionDTOStringToJSON)),
        'isDefaultValueActive': value.isDefaultValueActive,
        'defaultValue': InteriorCustomizationAttributesValueDTOToJSON(value.defaultValue),
        'conditionalValues': ((value.conditionalValues as Array<any>).map(OptionCustomizationConditionalValueDTOInteriorCustomizationAttributesValueDTOToJSON)),
    };
}


