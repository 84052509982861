import { ViewState } from "../../../../../../../common/ViewState"
import { showErrorToast } from "../../../../../../../common/errorToastHelper"
import { browserLanguage } from "../../../../../../../common/global"
import { EmptyView } from "../../../../../../../components/emptyview/EmptyView"
import { rowGroupsSpacing, RowGroupWrapper } from "../../../../../../../components/layouts/rowgroupwrapper/RowGroupWrapper"
import { SpacedItems } from "../../../../../../../components/layouts/spaceditems/SpacedItems"
import { WidgetWrapper } from "../../../../../../../components/layouts/widgetwrapper/WidgetWrapper"
import { Tag } from "../../../../../../../components/tag/Tag"
import { NamedExteriorColorTile } from "../../../../../../../components/tiles/namedexteriorcolortile/NamedExteriorColorTile"
import { SimilarExteriorColorCustomizationsDTO } from "../../../../../../../generated/pdsapi"
import { exteriorColorApi } from "../../../../../../../pdsapi"
import { ProductDetailsTabConfigContentProps } from "../../../../../../../viewtemplates/details/ProductDetailsContainer"
import { useToast } from "@finder/ui-kit"
import { PFlex } from "@porsche-design-system/components-react"
import { spacing } from "@porsche-design-system/utilities"
import { FC, useEffect, useRef, useState } from "react"
import { useNavigate } from "react-router-dom"
import { routes } from "../../../../../../../common/routes"

export const ExteriorColorSimilarCustomizationsCard: FC<ProductDetailsTabConfigContentProps<{ orderTypeKey: string; optionCode: string }>> = ({ itemKey }) => {
    const toastRef = useRef(useToast())
    const [similarCustomizations, setSimilarCustomizations] = useState<SimilarExteriorColorCustomizationsDTO>()

    useEffect(() => {
        const fetchSimilarCustomizations = async () => {
            try {
                const linkedCustomization = await exteriorColorApi.details.getSimilarCustomizations({
                    ...itemKey,
                    languageTag: browserLanguage,
                })
                setSimilarCustomizations(linkedCustomization)
            } catch (e) {
                showErrorToast(toastRef.current, e)
            }
        }

        fetchSimilarCustomizations()
    }, [itemKey, toastRef])

    return (
        <WidgetWrapper label={"Similar Customizations"}>
            {similarCustomizations ? (
                <ExteriorColorSimilarCustomizationsCardContent similarCustomizations={similarCustomizations} />
            ) : (
                <EmptyView viewState={ViewState.LOADING} />
            )}
        </WidgetWrapper>
    )
}

export const ExteriorColorSimilarCustomizationsCardContent = ({ similarCustomizations }: { similarCustomizations: SimilarExteriorColorCustomizationsDTO }) => {
    const navigate = useNavigate()

    return (
        <SpacedItems direction={"column"} lineSpacing={rowGroupsSpacing}>
            <RowGroupWrapper label={"Customizations for other Model Generations"} childrenSize={12}>
                <SpacedItems direction={"row"} wrap={true} itemSpacing={spacing[16]} lineSpacing={spacing[8]}>
                    {similarCustomizations.customizationsForOtherModelGenerations.map((customization, index) => {
                        return (
                            <Tag key={`Tag_${index}`} onClick={() => navigate(routes.exteriorColors.customizations.details(customization.key))}>
                                <PFlex>
                                    <NamedExteriorColorTile label={customization.label} hexCode={customization.hexCode} />
                                </PFlex>
                            </Tag>
                        )
                    })}
                </SpacedItems>
            </RowGroupWrapper>
        </SpacedItems>
    )
}
