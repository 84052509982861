/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    LinkedCustomizationDTOCustomization,
    LinkedCustomizationDTOCustomizationFromJSON,
    LinkedCustomizationDTOCustomizationFromJSONTyped,
    LinkedCustomizationDTOCustomizationToJSON,
} from './';

/**
 * 
 * @export
 * @interface LinkedCustomizationDTO
 */
export interface LinkedCustomizationDTO {
    /**
     * 
     * @type {string}
     * @memberof LinkedCustomizationDTO
     */
    modelGenerationKey?: string;
    /**
     * 
     * @type {boolean}
     * @memberof LinkedCustomizationDTO
     */
    hasSimilarCustomizations: boolean;
    /**
     * 
     * @type {LinkedCustomizationDTOCustomization}
     * @memberof LinkedCustomizationDTO
     */
    customization?: LinkedCustomizationDTOCustomization;
}

export function LinkedCustomizationDTOFromJSON(json: any): LinkedCustomizationDTO {
    return LinkedCustomizationDTOFromJSONTyped(json, false);
}

export function LinkedCustomizationDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): LinkedCustomizationDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'modelGenerationKey': !exists(json, 'modelGenerationKey') ? undefined : json['modelGenerationKey'],
        'hasSimilarCustomizations': json['hasSimilarCustomizations'],
        'customization': !exists(json, 'customization') ? undefined : LinkedCustomizationDTOCustomizationFromJSON(json['customization']),
    };
}

export function LinkedCustomizationDTOToJSON(value?: LinkedCustomizationDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'modelGenerationKey': value.modelGenerationKey,
        'hasSimilarCustomizations': value.hasSimilarCustomizations,
        'customization': LinkedCustomizationDTOCustomizationToJSON(value.customization),
    };
}


