/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    BaseNameDTO,
    BaseNameDTOFromJSON,
    BaseNameDTOFromJSONTyped,
    BaseNameDTOToJSON,
} from './';

/**
 * 
 * @export
 * @interface OptionCustomizationAttributesUpdateDTOEquipmentValue
 */
export interface OptionCustomizationAttributesUpdateDTOEquipmentValue {
    /**
     * 
     * @type {BaseNameDTO}
     * @memberof OptionCustomizationAttributesUpdateDTOEquipmentValue
     */
    name: BaseNameDTO;
    /**
     * 
     * @type {BaseNameDTO}
     * @memberof OptionCustomizationAttributesUpdateDTOEquipmentValue
     */
    description: BaseNameDTO;
    /**
     * 
     * @type {Array<string>}
     * @memberof OptionCustomizationAttributesUpdateDTOEquipmentValue
     */
    equipmentTagKeys: Array<string>;
}

export function OptionCustomizationAttributesUpdateDTOEquipmentValueFromJSON(json: any): OptionCustomizationAttributesUpdateDTOEquipmentValue {
    return OptionCustomizationAttributesUpdateDTOEquipmentValueFromJSONTyped(json, false);
}

export function OptionCustomizationAttributesUpdateDTOEquipmentValueFromJSONTyped(json: any, ignoreDiscriminator: boolean): OptionCustomizationAttributesUpdateDTOEquipmentValue {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': BaseNameDTOFromJSON(json['name']),
        'description': BaseNameDTOFromJSON(json['description']),
        'equipmentTagKeys': json['equipmentTagKeys'],
    };
}

export function OptionCustomizationAttributesUpdateDTOEquipmentValueToJSON(value?: OptionCustomizationAttributesUpdateDTOEquipmentValue | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': BaseNameDTOToJSON(value.name),
        'description': BaseNameDTOToJSON(value.description),
        'equipmentTagKeys': value.equipmentTagKeys,
    };
}


