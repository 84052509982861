/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    LinkedCustomizationDTOCustomizationValuePaintToSampleExteriorColorInternal,
    LinkedCustomizationDTOCustomizationValuePaintToSampleExteriorColorInternalFromJSONTyped,
    LinkedCustomizationDTOCustomizationValuePaintToSampleExteriorColorInternalToJSON,
} from './';

/**
 * @type LinkedCustomizationDTOCustomizationValuePaintToSampleExteriorColor
 * 
 * @export
 */
export type LinkedCustomizationDTOCustomizationValuePaintToSampleExteriorColor = { type: 'PaintToSampleExteriorColor' } & LinkedCustomizationDTOCustomizationValuePaintToSampleExteriorColorInternal;

export function LinkedCustomizationDTOCustomizationValuePaintToSampleExteriorColorFromJSON(json: any): LinkedCustomizationDTOCustomizationValuePaintToSampleExteriorColor {
    return LinkedCustomizationDTOCustomizationValuePaintToSampleExteriorColorFromJSONTyped(json, false);
}

export function LinkedCustomizationDTOCustomizationValuePaintToSampleExteriorColorFromJSONTyped(json: any, ignoreDiscriminator: boolean): LinkedCustomizationDTOCustomizationValuePaintToSampleExteriorColor {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    switch (json['type']) {
        case 'PaintToSampleExteriorColor':
            return {...LinkedCustomizationDTOCustomizationValuePaintToSampleExteriorColorInternalFromJSONTyped(json, true), type: 'PaintToSampleExteriorColor'};
        default:
            throw new Error(`No variant of LinkedCustomizationDTOCustomizationValuePaintToSampleExteriorColor exists with 'type=${json['type']}'`);
    }
}

export function LinkedCustomizationDTOCustomizationValuePaintToSampleExteriorColorToJSON(value?: LinkedCustomizationDTOCustomizationValuePaintToSampleExteriorColor | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    switch (value['type']) {
        case 'PaintToSampleExteriorColor':
            return LinkedCustomizationDTOCustomizationValuePaintToSampleExteriorColorInternalToJSON(value);
        default:
            throw new Error(`No variant of LinkedCustomizationDTOCustomizationValuePaintToSampleExteriorColor exists with 'type=${value['type']}'`);
    }
}

