/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    OptionTagItemDTOEquipment,
    OptionTagItemDTOExteriorColor,
    OptionTagItemDTOInteriorColor,
    OptionTagItemDTORoofColor,
    OptionTagItemDTOEquipmentFromJSONTyped,
    OptionTagItemDTOEquipmentToJSON,
    OptionTagItemDTOExteriorColorFromJSONTyped,
    OptionTagItemDTOExteriorColorToJSON,
    OptionTagItemDTOInteriorColorFromJSONTyped,
    OptionTagItemDTOInteriorColorToJSON,
    OptionTagItemDTORoofColorFromJSONTyped,
    OptionTagItemDTORoofColorToJSON,
} from './';

/**
 * @type OptionTagItemDTO
 * 
 * @export
 */
export type OptionTagItemDTO = { type: 'Equipment' } & OptionTagItemDTOEquipment | { type: 'ExteriorColor' } & OptionTagItemDTOExteriorColor | { type: 'InteriorColor' } & OptionTagItemDTOInteriorColor | { type: 'RoofColor' } & OptionTagItemDTORoofColor;

export function OptionTagItemDTOFromJSON(json: any): OptionTagItemDTO {
    return OptionTagItemDTOFromJSONTyped(json, false);
}

export function OptionTagItemDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): OptionTagItemDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    switch (json['type']) {
        case 'Equipment':
            return {...OptionTagItemDTOEquipmentFromJSONTyped(json, true), type: 'Equipment'};
        case 'ExteriorColor':
            return {...OptionTagItemDTOExteriorColorFromJSONTyped(json, true), type: 'ExteriorColor'};
        case 'InteriorColor':
            return {...OptionTagItemDTOInteriorColorFromJSONTyped(json, true), type: 'InteriorColor'};
        case 'RoofColor':
            return {...OptionTagItemDTORoofColorFromJSONTyped(json, true), type: 'RoofColor'};
        default:
            throw new Error(`No variant of OptionTagItemDTO exists with 'type=${json['type']}'`);
    }
}

export function OptionTagItemDTOToJSON(value?: OptionTagItemDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    switch (value['type']) {
        case 'Equipment':
            return OptionTagItemDTOEquipmentToJSON(value);
        case 'ExteriorColor':
            return OptionTagItemDTOExteriorColorToJSON(value);
        case 'InteriorColor':
            return OptionTagItemDTOInteriorColorToJSON(value);
        case 'RoofColor':
            return OptionTagItemDTORoofColorToJSON(value);
        default:
            throw new Error(`No variant of OptionTagItemDTO exists with 'type=${value['type']}'`);
    }
}

