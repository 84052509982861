/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SelectOptionDTOString,
    SelectOptionDTOStringFromJSON,
    SelectOptionDTOStringFromJSONTyped,
    SelectOptionDTOStringToJSON,
} from './';

/**
 * 
 * @export
 * @interface SimilarEquipmentCustomizationsDTO
 */
export interface SimilarEquipmentCustomizationsDTO {
    /**
     * 
     * @type {Array<SelectOptionDTOString>}
     * @memberof SimilarEquipmentCustomizationsDTO
     */
    customizationsForOtherModelGenerations: Array<SelectOptionDTOString>;
}

export function SimilarEquipmentCustomizationsDTOFromJSON(json: any): SimilarEquipmentCustomizationsDTO {
    return SimilarEquipmentCustomizationsDTOFromJSONTyped(json, false);
}

export function SimilarEquipmentCustomizationsDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): SimilarEquipmentCustomizationsDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'customizationsForOtherModelGenerations': ((json['customizationsForOtherModelGenerations'] as Array<any>).map(SelectOptionDTOStringFromJSON)),
    };
}

export function SimilarEquipmentCustomizationsDTOToJSON(value?: SimilarEquipmentCustomizationsDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'customizationsForOtherModelGenerations': ((value.customizationsForOtherModelGenerations as Array<any>).map(SelectOptionDTOStringToJSON)),
    };
}


