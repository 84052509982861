import { DefaultCardViewContentWrapper } from "../../../../components/layouts/defaultcardviewcontentwrapper/DefaultCardViewContentWrapper"
import { OrderTypeCreateOptionsDTO, OrderTypeCreateRequestDTO } from "../../../../generated/pdsapi"
import { orderTypeApi } from "../../../../pdsapi"
import { getProductCreateContainer, ProductCreateContainerConfig } from "../../../../viewtemplates/create/ProductCreateContainer"
import { OrderTypeCreateAttributesCard } from "./attributes/OrderTypeCreateAttributesCard"
import { Route } from "react-router-dom"
import { routes } from "../../../../common/routes"
import { OrderTypeProductListContainerConfig } from "../list/OrderTypeListRoute"

export const OrderTypeProductCreateContainerConfig: ProductCreateContainerConfig<OrderTypeCreateRequestDTO, OrderTypeCreateOptionsDTO> = {
    headline: "Create new Order Type",
    buildDetailsPath: ({ key }) => routes.models.orderTypes.details(key),

    create: (item) =>
        orderTypeApi.create.create({
            orderTypeCreateRequestDTO: item,
        }),
    getCreateOptions: orderTypeApi.create.getCreateOptions,

    Content: (props) => (
        <DefaultCardViewContentWrapper>
            <OrderTypeCreateAttributesCard {...props} />
        </DefaultCardViewContentWrapper>
    ),
}

const OrderTypeCreateContainer = getProductCreateContainer(OrderTypeProductCreateContainerConfig)

export const OrderTypeCreateRoute = (
    <Route
        path={routes.models.orderTypes.create()}
        element={
            <OrderTypeCreateContainer
                parentCrumbs={[
                    {
                        name: OrderTypeProductListContainerConfig.listName,
                        path: routes.models.orderTypes.list(),
                    },
                ]}
            />
        }
    />
)
