import { getEnumConverterGroup, getObjectURLParamConverter, stringConverterGroup, URLParamConverter } from "../../../../common/useURLSearchParamState"
import { CustomExteriorColorFilterOptionsDTO, CustomExteriorColorItemDTO, ExteriorColorType, SortDirection } from "../../../../generated/pdsapi"
import { customExteriorColorApi, CustomExteriorColorFilter } from "../../../../pdsapi"
import { getProductListContainer, ProductListContainerConfig } from "../../../../viewtemplates/list/ProductListContainer"
import { CustomExteriorColorListColumnsConfig } from "./columns/CustomExteriorColorListColumns"
import { CustomExteriorColorListFilter } from "./filter/CustomExteriorColorListFilter"
import { CustomExteriorColorListFilterTagsConfig } from "./filter/CustomExteriorColorListFilterTagsConfig"
import { routes } from "../../../../common/routes"
import { Route } from "react-router-dom"

export const CustomExteriorColorProductListContainerConfig: ProductListContainerConfig<
    CustomExteriorColorItemDTO,
    CustomExteriorColorFilter,
    CustomExteriorColorFilterOptionsDTO,
    unknown
> = {
    listName: "Custom Exterior Colors",
    createItemLabel: "Add new Custom Exterior Color",

    buildDetailsPath: (customExteriorColor) => routes.exteriorColors.customColors.details(customExteriorColor.key),
    buildCreatePath: routes.exteriorColors.customColors.create,
    buildSortPath: undefined,

    defaultFilter: {},
    filterUrlParamsConverter: getObjectURLParamConverter<CustomExteriorColorFilter>({
        exteriorColorTagKeys: stringConverterGroup.optionalArray,
        exteriorColorTypes: getEnumConverterGroup(ExteriorColorType).optionalArray,
    }),
    defaultSorting: {
        sortField: undefined,
        sortDirection: SortDirection.ASC,
    },
    sortingUrlParamsConverter: getObjectURLParamConverter({
        sortField: stringConverterGroup.optional as URLParamConverter<unknown>,
        sortDirection: getEnumConverterGroup(SortDirection).required,
    }),
    fetchPage: customExteriorColorApi.list.getPage,
    fetchFilterOptions: customExteriorColorApi.list.getFilterOptions,

    defaultShowsFilter: false,
    Filter: CustomExteriorColorListFilter,
    filterTagsConfig: CustomExteriorColorListFilterTagsConfig,

    columnsConfig: CustomExteriorColorListColumnsConfig,
}

const CustomExteriorColorListContainer = getProductListContainer(CustomExteriorColorProductListContainerConfig)

export const CustomExteriorColorListRoute = <Route path={routes.exteriorColors.customColors.list()} Component={CustomExteriorColorListContainer} />
