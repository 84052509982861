/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SelectOptionDTOEquipmentTagCategory,
    SelectOptionDTOEquipmentTagCategoryFromJSON,
    SelectOptionDTOEquipmentTagCategoryFromJSONTyped,
    SelectOptionDTOEquipmentTagCategoryToJSON,
    SelectOptionDTOOptionTagTypeDTO,
    SelectOptionDTOOptionTagTypeDTOFromJSON,
    SelectOptionDTOOptionTagTypeDTOFromJSONTyped,
    SelectOptionDTOOptionTagTypeDTOToJSON,
} from './';

/**
 * 
 * @export
 * @interface OptionTagFilterOptionsDTO
 */
export interface OptionTagFilterOptionsDTO {
    /**
     * 
     * @type {Array<SelectOptionDTOOptionTagTypeDTO>}
     * @memberof OptionTagFilterOptionsDTO
     */
    types: Array<SelectOptionDTOOptionTagTypeDTO>;
    /**
     * 
     * @type {Array<SelectOptionDTOEquipmentTagCategory>}
     * @memberof OptionTagFilterOptionsDTO
     */
    equipmentTagCategories: Array<SelectOptionDTOEquipmentTagCategory>;
}

export function OptionTagFilterOptionsDTOFromJSON(json: any): OptionTagFilterOptionsDTO {
    return OptionTagFilterOptionsDTOFromJSONTyped(json, false);
}

export function OptionTagFilterOptionsDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): OptionTagFilterOptionsDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'types': ((json['types'] as Array<any>).map(SelectOptionDTOOptionTagTypeDTOFromJSON)),
        'equipmentTagCategories': ((json['equipmentTagCategories'] as Array<any>).map(SelectOptionDTOEquipmentTagCategoryFromJSON)),
    };
}

export function OptionTagFilterOptionsDTOToJSON(value?: OptionTagFilterOptionsDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'types': ((value.types as Array<any>).map(SelectOptionDTOOptionTagTypeDTOToJSON)),
        'equipmentTagCategories': ((value.equipmentTagCategories as Array<any>).map(SelectOptionDTOEquipmentTagCategoryToJSON)),
    };
}


