/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    AuthorizationApiError,
    AuthorizationApiErrorFromJSON,
    AuthorizationApiErrorToJSON,
    BaseInformationDTO,
    BaseInformationDTOFromJSON,
    BaseInformationDTOToJSON,
    LocalizationsItemDTO,
    LocalizationsItemDTOFromJSON,
    LocalizationsItemDTOToJSON,
    OptionTagAttributesDTO,
    OptionTagAttributesDTOFromJSON,
    OptionTagAttributesDTOToJSON,
    OptionTagAttributesUpdateDTOOptionTag,
    OptionTagAttributesUpdateDTOOptionTagFromJSON,
    OptionTagAttributesUpdateDTOOptionTagToJSON,
    OptionTagTypeDTO,
    OptionTagTypeDTOFromJSON,
    OptionTagTypeDTOToJSON,
    SimpleApiError,
    SimpleApiErrorFromJSON,
    SimpleApiErrorToJSON,
    SimpleOrValidationApiError,
    SimpleOrValidationApiErrorFromJSON,
    SimpleOrValidationApiErrorToJSON,
} from '../models';

export interface DeleteOptionTagRequest {
    type: OptionTagTypeDTO;
    key: string;
}

export interface GetAttributesOfOptionTagRequest {
    type: OptionTagTypeDTO;
    key: string;
    languageTag: string;
}

export interface GetBaseInformationOfOptionTagRequest {
    type: OptionTagTypeDTO;
    key: string;
    languageTag: string;
}

export interface GetLocalizationsOfNameOfOptionTagRequest {
    type: OptionTagTypeDTO;
    key: string;
}

export interface UpdateAttributesOfOptionTagRequest {
    type: OptionTagTypeDTO;
    key: string;
    optionTagAttributesUpdateDTOOptionTag: OptionTagAttributesUpdateDTOOptionTag;
}

export interface UpdateNameOfOptionTagRequest {
    type: OptionTagTypeDTO;
    key: string;
    requestBody: { [key: string]: string; };
}

/**
 * 
 */
export class OptionTagDetailsWebControllerApi extends runtime.BaseAPI {

    /**
     * Delete a OptionTag by a given key
     */
    async deleteOptionTagRaw(requestParameters: DeleteOptionTagRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.type === null || requestParameters.type === undefined) {
            throw new runtime.RequiredError('type','Required parameter requestParameters.type was null or undefined when calling deleteOptionTag.');
        }

        if (requestParameters.key === null || requestParameters.key === undefined) {
            throw new runtime.RequiredError('key','Required parameter requestParameters.key was null or undefined when calling deleteOptionTag.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/web/option-tags/details/{type}/{key}`.replace(`{${"type"}}`, encodeURIComponent(String(requestParameters.type))).replace(`{${"key"}}`, encodeURIComponent(String(requestParameters.key))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete a OptionTag by a given key
     */
    async deleteOptionTag(requestParameters: DeleteOptionTagRequest): Promise<void> {
        await this.deleteOptionTagRaw(requestParameters);
    }

    /**
     * Get attributes of OptionTag with given key
     */
    async getAttributesOfOptionTagRaw(requestParameters: GetAttributesOfOptionTagRequest): Promise<runtime.ApiResponse<OptionTagAttributesDTO>> {
        if (requestParameters.type === null || requestParameters.type === undefined) {
            throw new runtime.RequiredError('type','Required parameter requestParameters.type was null or undefined when calling getAttributesOfOptionTag.');
        }

        if (requestParameters.key === null || requestParameters.key === undefined) {
            throw new runtime.RequiredError('key','Required parameter requestParameters.key was null or undefined when calling getAttributesOfOptionTag.');
        }

        if (requestParameters.languageTag === null || requestParameters.languageTag === undefined) {
            throw new runtime.RequiredError('languageTag','Required parameter requestParameters.languageTag was null or undefined when calling getAttributesOfOptionTag.');
        }

        const queryParameters: any = {};

        if (requestParameters.languageTag !== undefined) {
            queryParameters['languageTag'] = requestParameters.languageTag;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/web/option-tags/details/{type}/{key}/attributes`.replace(`{${"type"}}`, encodeURIComponent(String(requestParameters.type))).replace(`{${"key"}}`, encodeURIComponent(String(requestParameters.key))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => OptionTagAttributesDTOFromJSON(jsonValue));
    }

    /**
     * Get attributes of OptionTag with given key
     */
    async getAttributesOfOptionTag(requestParameters: GetAttributesOfOptionTagRequest): Promise<OptionTagAttributesDTO> {
        const response = await this.getAttributesOfOptionTagRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get baseInformation of OptionTag with given key
     */
    async getBaseInformationOfOptionTagRaw(requestParameters: GetBaseInformationOfOptionTagRequest): Promise<runtime.ApiResponse<BaseInformationDTO>> {
        if (requestParameters.type === null || requestParameters.type === undefined) {
            throw new runtime.RequiredError('type','Required parameter requestParameters.type was null or undefined when calling getBaseInformationOfOptionTag.');
        }

        if (requestParameters.key === null || requestParameters.key === undefined) {
            throw new runtime.RequiredError('key','Required parameter requestParameters.key was null or undefined when calling getBaseInformationOfOptionTag.');
        }

        if (requestParameters.languageTag === null || requestParameters.languageTag === undefined) {
            throw new runtime.RequiredError('languageTag','Required parameter requestParameters.languageTag was null or undefined when calling getBaseInformationOfOptionTag.');
        }

        const queryParameters: any = {};

        if (requestParameters.languageTag !== undefined) {
            queryParameters['languageTag'] = requestParameters.languageTag;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/web/option-tags/details/{type}/{key}/base-information`.replace(`{${"type"}}`, encodeURIComponent(String(requestParameters.type))).replace(`{${"key"}}`, encodeURIComponent(String(requestParameters.key))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => BaseInformationDTOFromJSON(jsonValue));
    }

    /**
     * Get baseInformation of OptionTag with given key
     */
    async getBaseInformationOfOptionTag(requestParameters: GetBaseInformationOfOptionTagRequest): Promise<BaseInformationDTO> {
        const response = await this.getBaseInformationOfOptionTagRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get localizationsOfName of OptionTag with given key
     */
    async getLocalizationsOfNameOfOptionTagRaw(requestParameters: GetLocalizationsOfNameOfOptionTagRequest): Promise<runtime.ApiResponse<{ [key: string]: LocalizationsItemDTO; }>> {
        if (requestParameters.type === null || requestParameters.type === undefined) {
            throw new runtime.RequiredError('type','Required parameter requestParameters.type was null or undefined when calling getLocalizationsOfNameOfOptionTag.');
        }

        if (requestParameters.key === null || requestParameters.key === undefined) {
            throw new runtime.RequiredError('key','Required parameter requestParameters.key was null or undefined when calling getLocalizationsOfNameOfOptionTag.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/web/option-tags/details/{type}/{key}/name/localizations`.replace(`{${"type"}}`, encodeURIComponent(String(requestParameters.type))).replace(`{${"key"}}`, encodeURIComponent(String(requestParameters.key))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => runtime.mapValues(jsonValue, LocalizationsItemDTOFromJSON));
    }

    /**
     * Get localizationsOfName of OptionTag with given key
     */
    async getLocalizationsOfNameOfOptionTag(requestParameters: GetLocalizationsOfNameOfOptionTagRequest): Promise<{ [key: string]: LocalizationsItemDTO; }> {
        const response = await this.getLocalizationsOfNameOfOptionTagRaw(requestParameters);
        return await response.value();
    }

    /**
     * Updates attributes of an existing OptionTag
     */
    async updateAttributesOfOptionTagRaw(requestParameters: UpdateAttributesOfOptionTagRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.type === null || requestParameters.type === undefined) {
            throw new runtime.RequiredError('type','Required parameter requestParameters.type was null or undefined when calling updateAttributesOfOptionTag.');
        }

        if (requestParameters.key === null || requestParameters.key === undefined) {
            throw new runtime.RequiredError('key','Required parameter requestParameters.key was null or undefined when calling updateAttributesOfOptionTag.');
        }

        if (requestParameters.optionTagAttributesUpdateDTOOptionTag === null || requestParameters.optionTagAttributesUpdateDTOOptionTag === undefined) {
            throw new runtime.RequiredError('optionTagAttributesUpdateDTOOptionTag','Required parameter requestParameters.optionTagAttributesUpdateDTOOptionTag was null or undefined when calling updateAttributesOfOptionTag.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/web/option-tags/details/{type}/{key}/attributes`.replace(`{${"type"}}`, encodeURIComponent(String(requestParameters.type))).replace(`{${"key"}}`, encodeURIComponent(String(requestParameters.key))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: OptionTagAttributesUpdateDTOOptionTagToJSON(requestParameters.optionTagAttributesUpdateDTOOptionTag),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Updates attributes of an existing OptionTag
     */
    async updateAttributesOfOptionTag(requestParameters: UpdateAttributesOfOptionTagRequest): Promise<void> {
        await this.updateAttributesOfOptionTagRaw(requestParameters);
    }

    /**
     * Updates name of an existing OptionTag
     */
    async updateNameOfOptionTagRaw(requestParameters: UpdateNameOfOptionTagRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.type === null || requestParameters.type === undefined) {
            throw new runtime.RequiredError('type','Required parameter requestParameters.type was null or undefined when calling updateNameOfOptionTag.');
        }

        if (requestParameters.key === null || requestParameters.key === undefined) {
            throw new runtime.RequiredError('key','Required parameter requestParameters.key was null or undefined when calling updateNameOfOptionTag.');
        }

        if (requestParameters.requestBody === null || requestParameters.requestBody === undefined) {
            throw new runtime.RequiredError('requestBody','Required parameter requestParameters.requestBody was null or undefined when calling updateNameOfOptionTag.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/web/option-tags/details/{type}/{key}/name`.replace(`{${"type"}}`, encodeURIComponent(String(requestParameters.type))).replace(`{${"key"}}`, encodeURIComponent(String(requestParameters.key))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.requestBody,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Updates name of an existing OptionTag
     */
    async updateNameOfOptionTag(requestParameters: UpdateNameOfOptionTagRequest): Promise<void> {
        await this.updateNameOfOptionTagRaw(requestParameters);
    }

}
