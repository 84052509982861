/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum OptionTagTypeDTO {
    EQUIPMENT = 'EQUIPMENT',
    INTERIOR_COLOR = 'INTERIOR_COLOR',
    EXTERIOR_COLOR = 'EXTERIOR_COLOR',
    ROOF_COLOR = 'ROOF_COLOR'
}

export function OptionTagTypeDTOFromJSON(json: any): OptionTagTypeDTO {
    return OptionTagTypeDTOFromJSONTyped(json, false);
}

export function OptionTagTypeDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): OptionTagTypeDTO {
    return json as OptionTagTypeDTO;
}

export function OptionTagTypeDTOToJSON(value?: OptionTagTypeDTO | null): any {
    return value as any;
}

