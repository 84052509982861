/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    OptionCustomizationAttributesUpdateDTOInteriorInternal,
    OptionCustomizationAttributesUpdateDTOInteriorInternalFromJSONTyped,
    OptionCustomizationAttributesUpdateDTOInteriorInternalToJSON,
} from './';

/**
 * @type OptionCustomizationAttributesUpdateDTOInterior
 * 
 * @export
 */
export type OptionCustomizationAttributesUpdateDTOInterior = { type: 'Interior' } & OptionCustomizationAttributesUpdateDTOInteriorInternal;

export function OptionCustomizationAttributesUpdateDTOInteriorFromJSON(json: any): OptionCustomizationAttributesUpdateDTOInterior {
    return OptionCustomizationAttributesUpdateDTOInteriorFromJSONTyped(json, false);
}

export function OptionCustomizationAttributesUpdateDTOInteriorFromJSONTyped(json: any, ignoreDiscriminator: boolean): OptionCustomizationAttributesUpdateDTOInterior {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    switch (json['type']) {
        case 'Interior':
            return {...OptionCustomizationAttributesUpdateDTOInteriorInternalFromJSONTyped(json, true), type: 'Interior'};
        default:
            throw new Error(`No variant of OptionCustomizationAttributesUpdateDTOInterior exists with 'type=${json['type']}'`);
    }
}

export function OptionCustomizationAttributesUpdateDTOInteriorToJSON(value?: OptionCustomizationAttributesUpdateDTOInterior | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    switch (value['type']) {
        case 'Interior':
            return OptionCustomizationAttributesUpdateDTOInteriorInternalToJSON(value);
        default:
            throw new Error(`No variant of OptionCustomizationAttributesUpdateDTOInterior exists with 'type=${value['type']}'`);
    }
}

