/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    OptionCustomizationAttributesUpdateDTOPaintToSampleExteriorColorValue,
    OptionCustomizationAttributesUpdateDTOPaintToSampleExteriorColorValueFromJSON,
    OptionCustomizationAttributesUpdateDTOPaintToSampleExteriorColorValueFromJSONTyped,
    OptionCustomizationAttributesUpdateDTOPaintToSampleExteriorColorValueToJSON,
    OptionCustomizationConditionalValueUpdateDTOOptionCustomizationAttributesUpdateDTOPaintToSampleExteriorColorValue,
    OptionCustomizationConditionalValueUpdateDTOOptionCustomizationAttributesUpdateDTOPaintToSampleExteriorColorValueFromJSON,
    OptionCustomizationConditionalValueUpdateDTOOptionCustomizationAttributesUpdateDTOPaintToSampleExteriorColorValueFromJSONTyped,
    OptionCustomizationConditionalValueUpdateDTOOptionCustomizationAttributesUpdateDTOPaintToSampleExteriorColorValueToJSON,
} from './';

/**
 * 
 * @export
 * @interface OptionCustomizationAttributesUpdateDTOPaintToSampleExteriorColorInternal
 */
export interface OptionCustomizationAttributesUpdateDTOPaintToSampleExteriorColorInternal {
    /**
     * 
     * @type {OptionCustomizationAttributesUpdateDTOPaintToSampleExteriorColorValue}
     * @memberof OptionCustomizationAttributesUpdateDTOPaintToSampleExteriorColorInternal
     */
    defaultValue: OptionCustomizationAttributesUpdateDTOPaintToSampleExteriorColorValue;
    /**
     * 
     * @type {string}
     * @memberof OptionCustomizationAttributesUpdateDTOPaintToSampleExteriorColorInternal
     */
    optionCode: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof OptionCustomizationAttributesUpdateDTOPaintToSampleExteriorColorInternal
     */
    modelGenerationKeys: Array<string>;
    /**
     * 
     * @type {Array<OptionCustomizationConditionalValueUpdateDTOOptionCustomizationAttributesUpdateDTOPaintToSampleExteriorColorValue>}
     * @memberof OptionCustomizationAttributesUpdateDTOPaintToSampleExteriorColorInternal
     */
    conditionalValues: Array<OptionCustomizationConditionalValueUpdateDTOOptionCustomizationAttributesUpdateDTOPaintToSampleExteriorColorValue>;
    /**
     * 
     * @type {boolean}
     * @memberof OptionCustomizationAttributesUpdateDTOPaintToSampleExteriorColorInternal
     */
    isDefaultValueActive: boolean;
    /**
     * 
     * @type {string}
     * @memberof OptionCustomizationAttributesUpdateDTOPaintToSampleExteriorColorInternal
     */
    type: string;
}

export function OptionCustomizationAttributesUpdateDTOPaintToSampleExteriorColorInternalFromJSON(json: any): OptionCustomizationAttributesUpdateDTOPaintToSampleExteriorColorInternal {
    return OptionCustomizationAttributesUpdateDTOPaintToSampleExteriorColorInternalFromJSONTyped(json, false);
}

export function OptionCustomizationAttributesUpdateDTOPaintToSampleExteriorColorInternalFromJSONTyped(json: any, ignoreDiscriminator: boolean): OptionCustomizationAttributesUpdateDTOPaintToSampleExteriorColorInternal {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'defaultValue': OptionCustomizationAttributesUpdateDTOPaintToSampleExteriorColorValueFromJSON(json['defaultValue']),
        'optionCode': json['optionCode'],
        'modelGenerationKeys': json['modelGenerationKeys'],
        'conditionalValues': ((json['conditionalValues'] as Array<any>).map(OptionCustomizationConditionalValueUpdateDTOOptionCustomizationAttributesUpdateDTOPaintToSampleExteriorColorValueFromJSON)),
        'isDefaultValueActive': json['isDefaultValueActive'],
        'type': json['type'],
    };
}

export function OptionCustomizationAttributesUpdateDTOPaintToSampleExteriorColorInternalToJSON(value?: OptionCustomizationAttributesUpdateDTOPaintToSampleExteriorColorInternal | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'defaultValue': OptionCustomizationAttributesUpdateDTOPaintToSampleExteriorColorValueToJSON(value.defaultValue),
        'optionCode': value.optionCode,
        'modelGenerationKeys': value.modelGenerationKeys,
        'conditionalValues': ((value.conditionalValues as Array<any>).map(OptionCustomizationConditionalValueUpdateDTOOptionCustomizationAttributesUpdateDTOPaintToSampleExteriorColorValueToJSON)),
        'isDefaultValueActive': value.isDefaultValueActive,
        'type': value.type,
    };
}


