/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    OptionCustomizationCreateRequestDTOEquipment,
    OptionCustomizationCreateRequestDTOExteriorColor,
    OptionCustomizationCreateRequestDTOInterior,
    OptionCustomizationCreateRequestDTOPaintToSampleExteriorColor,
    OptionCustomizationCreateRequestDTORoofColor,
    OptionCustomizationCreateRequestDTOEquipmentFromJSONTyped,
    OptionCustomizationCreateRequestDTOEquipmentToJSON,
    OptionCustomizationCreateRequestDTOExteriorColorFromJSONTyped,
    OptionCustomizationCreateRequestDTOExteriorColorToJSON,
    OptionCustomizationCreateRequestDTOInteriorFromJSONTyped,
    OptionCustomizationCreateRequestDTOInteriorToJSON,
    OptionCustomizationCreateRequestDTOPaintToSampleExteriorColorFromJSONTyped,
    OptionCustomizationCreateRequestDTOPaintToSampleExteriorColorToJSON,
    OptionCustomizationCreateRequestDTORoofColorFromJSONTyped,
    OptionCustomizationCreateRequestDTORoofColorToJSON,
} from './';

/**
 * @type OptionCustomizationCreateRequestDTO
 * 
 * @export
 */
export type OptionCustomizationCreateRequestDTO = { type: 'Equipment' } & OptionCustomizationCreateRequestDTOEquipment | { type: 'ExteriorColor' } & OptionCustomizationCreateRequestDTOExteriorColor | { type: 'Interior' } & OptionCustomizationCreateRequestDTOInterior | { type: 'PaintToSampleExteriorColor' } & OptionCustomizationCreateRequestDTOPaintToSampleExteriorColor | { type: 'RoofColor' } & OptionCustomizationCreateRequestDTORoofColor;

export function OptionCustomizationCreateRequestDTOFromJSON(json: any): OptionCustomizationCreateRequestDTO {
    return OptionCustomizationCreateRequestDTOFromJSONTyped(json, false);
}

export function OptionCustomizationCreateRequestDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): OptionCustomizationCreateRequestDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    switch (json['type']) {
        case 'Equipment':
            return {...OptionCustomizationCreateRequestDTOEquipmentFromJSONTyped(json, true), type: 'Equipment'};
        case 'ExteriorColor':
            return {...OptionCustomizationCreateRequestDTOExteriorColorFromJSONTyped(json, true), type: 'ExteriorColor'};
        case 'Interior':
            return {...OptionCustomizationCreateRequestDTOInteriorFromJSONTyped(json, true), type: 'Interior'};
        case 'PaintToSampleExteriorColor':
            return {...OptionCustomizationCreateRequestDTOPaintToSampleExteriorColorFromJSONTyped(json, true), type: 'PaintToSampleExteriorColor'};
        case 'RoofColor':
            return {...OptionCustomizationCreateRequestDTORoofColorFromJSONTyped(json, true), type: 'RoofColor'};
        default:
            throw new Error(`No variant of OptionCustomizationCreateRequestDTO exists with 'type=${json['type']}'`);
    }
}

export function OptionCustomizationCreateRequestDTOToJSON(value?: OptionCustomizationCreateRequestDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    switch (value['type']) {
        case 'Equipment':
            return OptionCustomizationCreateRequestDTOEquipmentToJSON(value);
        case 'ExteriorColor':
            return OptionCustomizationCreateRequestDTOExteriorColorToJSON(value);
        case 'Interior':
            return OptionCustomizationCreateRequestDTOInteriorToJSON(value);
        case 'PaintToSampleExteriorColor':
            return OptionCustomizationCreateRequestDTOPaintToSampleExteriorColorToJSON(value);
        case 'RoofColor':
            return OptionCustomizationCreateRequestDTORoofColorToJSON(value);
        default:
            throw new Error(`No variant of OptionCustomizationCreateRequestDTO exists with 'type=${value['type']}'`);
    }
}

