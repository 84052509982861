import { OptionTagCreateRequestDTO, OptionTagItemDTO, OptionTagTypeDTO } from "../../../generated/pdsapi"
import { SelectOption } from "@finder/ui-kit/build/types/components/forms/multiSelect/MultiSelect"

export type OptionTagType = OptionTagCreateRequestDTO["type"] | OptionTagItemDTO["type"]

const optionTagTypes: Record<OptionTagType, string> = {
    Equipment: "Equipment",
    InteriorColor: "Interior Color",
    ExteriorColor: "Exterior Color",
    RoofColor: "Roof Color",
}

export const optionTagTypeOptions: SelectOption[] = Object.entries(optionTagTypes).map(([value, label]) => ({
    value,
    label,
}))

export const optionTagTypeToEnum = (type: OptionTagType): OptionTagTypeDTO => {
    switch (type) {
        case "Equipment":
            return OptionTagTypeDTO.EQUIPMENT
        case "InteriorColor":
            return OptionTagTypeDTO.INTERIOR_COLOR
        case "ExteriorColor":
            return OptionTagTypeDTO.EXTERIOR_COLOR
        case "RoofColor":
            return OptionTagTypeDTO.ROOF_COLOR
    }
}
