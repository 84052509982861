/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    RelatedOptionDTOStringString,
    RelatedOptionDTOStringStringFromJSON,
    RelatedOptionDTOStringStringFromJSONTyped,
    RelatedOptionDTOStringStringToJSON,
    SelectOptionDTODriveTrain,
    SelectOptionDTODriveTrainFromJSON,
    SelectOptionDTODriveTrainFromJSONTyped,
    SelectOptionDTODriveTrainToJSON,
    SelectOptionDTOEngineType,
    SelectOptionDTOEngineTypeFromJSON,
    SelectOptionDTOEngineTypeFromJSONTyped,
    SelectOptionDTOEngineTypeToJSON,
    SelectOptionDTONumberOfSeats,
    SelectOptionDTONumberOfSeatsFromJSON,
    SelectOptionDTONumberOfSeatsFromJSONTyped,
    SelectOptionDTONumberOfSeatsToJSON,
} from './';

/**
 * 
 * @export
 * @interface OrderTypeCreateOptionsDTO
 */
export interface OrderTypeCreateOptionsDTO {
    /**
     * 
     * @type {Array<RelatedOptionDTOStringString>}
     * @memberof OrderTypeCreateOptionsDTO
     */
    modelSeriesRelatedModelGenerations: Array<RelatedOptionDTOStringString>;
    /**
     * 
     * @type {Array<RelatedOptionDTOStringString>}
     * @memberof OrderTypeCreateOptionsDTO
     */
    modelSeriesRelatedDerivatives: Array<RelatedOptionDTOStringString>;
    /**
     * 
     * @type {Array<SelectOptionDTODriveTrain>}
     * @memberof OrderTypeCreateOptionsDTO
     */
    driveTrains: Array<SelectOptionDTODriveTrain>;
    /**
     * 
     * @type {Array<SelectOptionDTOEngineType>}
     * @memberof OrderTypeCreateOptionsDTO
     */
    engineTypes: Array<SelectOptionDTOEngineType>;
    /**
     * 
     * @type {Array<SelectOptionDTONumberOfSeats>}
     * @memberof OrderTypeCreateOptionsDTO
     */
    numberOfSeats: Array<SelectOptionDTONumberOfSeats>;
}

export function OrderTypeCreateOptionsDTOFromJSON(json: any): OrderTypeCreateOptionsDTO {
    return OrderTypeCreateOptionsDTOFromJSONTyped(json, false);
}

export function OrderTypeCreateOptionsDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrderTypeCreateOptionsDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'modelSeriesRelatedModelGenerations': ((json['modelSeriesRelatedModelGenerations'] as Array<any>).map(RelatedOptionDTOStringStringFromJSON)),
        'modelSeriesRelatedDerivatives': ((json['modelSeriesRelatedDerivatives'] as Array<any>).map(RelatedOptionDTOStringStringFromJSON)),
        'driveTrains': ((json['driveTrains'] as Array<any>).map(SelectOptionDTODriveTrainFromJSON)),
        'engineTypes': ((json['engineTypes'] as Array<any>).map(SelectOptionDTOEngineTypeFromJSON)),
        'numberOfSeats': ((json['numberOfSeats'] as Array<any>).map(SelectOptionDTONumberOfSeatsFromJSON)),
    };
}

export function OrderTypeCreateOptionsDTOToJSON(value?: OrderTypeCreateOptionsDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'modelSeriesRelatedModelGenerations': ((value.modelSeriesRelatedModelGenerations as Array<any>).map(RelatedOptionDTOStringStringToJSON)),
        'modelSeriesRelatedDerivatives': ((value.modelSeriesRelatedDerivatives as Array<any>).map(RelatedOptionDTOStringStringToJSON)),
        'driveTrains': ((value.driveTrains as Array<any>).map(SelectOptionDTODriveTrainToJSON)),
        'engineTypes': ((value.engineTypes as Array<any>).map(SelectOptionDTOEngineTypeToJSON)),
        'numberOfSeats': ((value.numberOfSeats as Array<any>).map(SelectOptionDTONumberOfSeatsToJSON)),
    };
}


