import { routes } from "../../../../../../common/routes"
import { getEnumConverterGroup, getObjectURLParamConverter, stringConverterGroup } from "../../../../../../common/useURLSearchParamState"
import {
    ExteriorColorSortField,
    ExteriorColorType,
    OrderTypeExteriorColorFilterOptionsDTO,
    OrderTypeOptionItemDTOExteriorColor,
} from "../../../../../../generated/pdsapi"
import { orderTypeApi, OrderTypeExteriorColorsFilter } from "../../../../../../pdsapi"
import { OrderTypeOptionsCardProps } from "../OrderTypeOptionsCard"
import { OrderTypeExteriorColorsCardColumnsConfig } from "./columns/OrderTypeExteriorColorsCardColumns"
import { OrderTypeExteriorColorsCardFilter } from "./filter/OrderTypeExteriorColorsCardFilter"
import { OrderTypeExteriorColorsCardFilterTagsConfig } from "./filter/OrderTypeExteriorColorsCardFilterTagsConfig"

export const orderTypeExteriorColorsCardConfig: OrderTypeOptionsCardProps<
    OrderTypeOptionItemDTOExteriorColor,
    OrderTypeExteriorColorsFilter,
    OrderTypeExteriorColorFilterOptionsDTO,
    ExteriorColorSortField
> = {
    label: "ExteriorColor",

    getCreateRoute: (orderTypeKey) => routes.models.orderTypes.subpages(orderTypeKey).exteriorColors.create(),
    getDetailsRoute: (orderTypeKey, option) => routes.models.orderTypes.subpages(orderTypeKey).exteriorColors.details(option.optionCode),
    getOptions: orderTypeApi.details.options.getExteriorColors,
    getFilterOptions: orderTypeApi.details.options.getExteriorColorFilterOptions,

    defaultSortField: ExteriorColorSortField.SORT_INDEX,
    sortFieldEnum: ExteriorColorSortField,

    defaultFilter: {},
    filterUrlParamsConverter: getObjectURLParamConverter<OrderTypeExteriorColorsFilter>({
        tagKeys: stringConverterGroup.optionalArray,
        colorTypes: getEnumConverterGroup(ExteriorColorType).optionalArray,
    }),

    columnsConfig: OrderTypeExteriorColorsCardColumnsConfig,
    FilterComponent: OrderTypeExteriorColorsCardFilter,
    filterTagsConfig: OrderTypeExteriorColorsCardFilterTagsConfig,

    isInitiallyExpanded: false,
}
