/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MultiSelectDTOString,
    MultiSelectDTOStringFromJSON,
    MultiSelectDTOStringFromJSONTyped,
    MultiSelectDTOStringToJSON,
    OptionCustomizationAttributesDTOExteriorColorValue,
    OptionCustomizationAttributesDTOExteriorColorValueFromJSON,
    OptionCustomizationAttributesDTOExteriorColorValueFromJSONTyped,
    OptionCustomizationAttributesDTOExteriorColorValueToJSON,
    OptionCustomizationConditionalValueDTOOptionCustomizationAttributesDTOExteriorColorValue,
    OptionCustomizationConditionalValueDTOOptionCustomizationAttributesDTOExteriorColorValueFromJSON,
    OptionCustomizationConditionalValueDTOOptionCustomizationAttributesDTOExteriorColorValueFromJSONTyped,
    OptionCustomizationConditionalValueDTOOptionCustomizationAttributesDTOExteriorColorValueToJSON,
    SelectOptionDTOString,
    SelectOptionDTOStringFromJSON,
    SelectOptionDTOStringFromJSONTyped,
    SelectOptionDTOStringToJSON,
} from './';

/**
 * 
 * @export
 * @interface OptionCustomizationAttributesDTOExteriorColorInternal
 */
export interface OptionCustomizationAttributesDTOExteriorColorInternal {
    /**
     * 
     * @type {OptionCustomizationAttributesDTOExteriorColorValue}
     * @memberof OptionCustomizationAttributesDTOExteriorColorInternal
     */
    defaultValue: OptionCustomizationAttributesDTOExteriorColorValue;
    /**
     * 
     * @type {string}
     * @memberof OptionCustomizationAttributesDTOExteriorColorInternal
     */
    optionCode: string;
    /**
     * 
     * @type {Array<OptionCustomizationConditionalValueDTOOptionCustomizationAttributesDTOExteriorColorValue>}
     * @memberof OptionCustomizationAttributesDTOExteriorColorInternal
     */
    conditionalValues: Array<OptionCustomizationConditionalValueDTOOptionCustomizationAttributesDTOExteriorColorValue>;
    /**
     * 
     * @type {MultiSelectDTOString}
     * @memberof OptionCustomizationAttributesDTOExteriorColorInternal
     */
    modelGenerations: MultiSelectDTOString;
    /**
     * 
     * @type {boolean}
     * @memberof OptionCustomizationAttributesDTOExteriorColorInternal
     */
    isDefaultValueActive: boolean;
    /**
     * 
     * @type {Array<SelectOptionDTOString>}
     * @memberof OptionCustomizationAttributesDTOExteriorColorInternal
     */
    derivatives: Array<SelectOptionDTOString>;
    /**
     * 
     * @type {string}
     * @memberof OptionCustomizationAttributesDTOExteriorColorInternal
     */
    type: string;
}

export function OptionCustomizationAttributesDTOExteriorColorInternalFromJSON(json: any): OptionCustomizationAttributesDTOExteriorColorInternal {
    return OptionCustomizationAttributesDTOExteriorColorInternalFromJSONTyped(json, false);
}

export function OptionCustomizationAttributesDTOExteriorColorInternalFromJSONTyped(json: any, ignoreDiscriminator: boolean): OptionCustomizationAttributesDTOExteriorColorInternal {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'defaultValue': OptionCustomizationAttributesDTOExteriorColorValueFromJSON(json['defaultValue']),
        'optionCode': json['optionCode'],
        'conditionalValues': ((json['conditionalValues'] as Array<any>).map(OptionCustomizationConditionalValueDTOOptionCustomizationAttributesDTOExteriorColorValueFromJSON)),
        'modelGenerations': MultiSelectDTOStringFromJSON(json['modelGenerations']),
        'isDefaultValueActive': json['isDefaultValueActive'],
        'derivatives': ((json['derivatives'] as Array<any>).map(SelectOptionDTOStringFromJSON)),
        'type': json['type'],
    };
}

export function OptionCustomizationAttributesDTOExteriorColorInternalToJSON(value?: OptionCustomizationAttributesDTOExteriorColorInternal | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'defaultValue': OptionCustomizationAttributesDTOExteriorColorValueToJSON(value.defaultValue),
        'optionCode': value.optionCode,
        'conditionalValues': ((value.conditionalValues as Array<any>).map(OptionCustomizationConditionalValueDTOOptionCustomizationAttributesDTOExteriorColorValueToJSON)),
        'modelGenerations': MultiSelectDTOStringToJSON(value.modelGenerations),
        'isDefaultValueActive': value.isDefaultValueActive,
        'derivatives': ((value.derivatives as Array<any>).map(SelectOptionDTOStringToJSON)),
        'type': value.type,
    };
}


