/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    AuthorizationApiError,
    AuthorizationApiErrorFromJSON,
    AuthorizationApiErrorToJSON,
    BaseInformationDTO,
    BaseInformationDTOFromJSON,
    BaseInformationDTOToJSON,
    DerivativeCategoryAttributesDTO,
    DerivativeCategoryAttributesDTOFromJSON,
    DerivativeCategoryAttributesDTOToJSON,
    DerivativeCategoryAttributesUpdateDTO,
    DerivativeCategoryAttributesUpdateDTOFromJSON,
    DerivativeCategoryAttributesUpdateDTOToJSON,
    DerivativeCategoryLinksDTO,
    DerivativeCategoryLinksDTOFromJSON,
    DerivativeCategoryLinksDTOToJSON,
    LocalizationsItemDTO,
    LocalizationsItemDTOFromJSON,
    LocalizationsItemDTOToJSON,
    SimpleApiError,
    SimpleApiErrorFromJSON,
    SimpleApiErrorToJSON,
    SimpleOrValidationApiError,
    SimpleOrValidationApiErrorFromJSON,
    SimpleOrValidationApiErrorToJSON,
} from '../models';

export interface DeleteDerivativeCategoryRequest {
    key: string;
}

export interface GetAttributesOfDerivativeCategoryRequest {
    key: string;
    languageTag: string;
}

export interface GetBaseInformationOfDerivativeCategoryRequest {
    key: string;
    languageTag: string;
}

export interface GetLinksOfDerivativeCategoryRequest {
    key: string;
    languageTag: string;
}

export interface GetLocalizationsOfDescriptionOfDerivativeCategoryRequest {
    key: string;
}

export interface GetLocalizationsOfNameOfDerivativeCategoryRequest {
    key: string;
}

export interface UpdateAttributesOfDerivativeCategoryRequest {
    key: string;
    derivativeCategoryAttributesUpdateDTO: DerivativeCategoryAttributesUpdateDTO;
}

export interface UpdateDescriptionOfDerivativeCategoryRequest {
    key: string;
    requestBody: { [key: string]: string; };
}

export interface UpdateNameOfDerivativeCategoryRequest {
    key: string;
    requestBody: { [key: string]: string; };
}

/**
 * 
 */
export class DerivativeCategoryDetailsWebControllerApi extends runtime.BaseAPI {

    /**
     * Delete a DerivativeCategory by a given key
     */
    async deleteDerivativeCategoryRaw(requestParameters: DeleteDerivativeCategoryRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.key === null || requestParameters.key === undefined) {
            throw new runtime.RequiredError('key','Required parameter requestParameters.key was null or undefined when calling deleteDerivativeCategory.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/web/derivative-categories/details/{key}`.replace(`{${"key"}}`, encodeURIComponent(String(requestParameters.key))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete a DerivativeCategory by a given key
     */
    async deleteDerivativeCategory(requestParameters: DeleteDerivativeCategoryRequest): Promise<void> {
        await this.deleteDerivativeCategoryRaw(requestParameters);
    }

    /**
     * Get attributes of DerivativeCategory with given key
     */
    async getAttributesOfDerivativeCategoryRaw(requestParameters: GetAttributesOfDerivativeCategoryRequest): Promise<runtime.ApiResponse<DerivativeCategoryAttributesDTO>> {
        if (requestParameters.key === null || requestParameters.key === undefined) {
            throw new runtime.RequiredError('key','Required parameter requestParameters.key was null or undefined when calling getAttributesOfDerivativeCategory.');
        }

        if (requestParameters.languageTag === null || requestParameters.languageTag === undefined) {
            throw new runtime.RequiredError('languageTag','Required parameter requestParameters.languageTag was null or undefined when calling getAttributesOfDerivativeCategory.');
        }

        const queryParameters: any = {};

        if (requestParameters.languageTag !== undefined) {
            queryParameters['languageTag'] = requestParameters.languageTag;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/web/derivative-categories/details/{key}/attributes`.replace(`{${"key"}}`, encodeURIComponent(String(requestParameters.key))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => DerivativeCategoryAttributesDTOFromJSON(jsonValue));
    }

    /**
     * Get attributes of DerivativeCategory with given key
     */
    async getAttributesOfDerivativeCategory(requestParameters: GetAttributesOfDerivativeCategoryRequest): Promise<DerivativeCategoryAttributesDTO> {
        const response = await this.getAttributesOfDerivativeCategoryRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get baseInformation of DerivativeCategory with given key
     */
    async getBaseInformationOfDerivativeCategoryRaw(requestParameters: GetBaseInformationOfDerivativeCategoryRequest): Promise<runtime.ApiResponse<BaseInformationDTO>> {
        if (requestParameters.key === null || requestParameters.key === undefined) {
            throw new runtime.RequiredError('key','Required parameter requestParameters.key was null or undefined when calling getBaseInformationOfDerivativeCategory.');
        }

        if (requestParameters.languageTag === null || requestParameters.languageTag === undefined) {
            throw new runtime.RequiredError('languageTag','Required parameter requestParameters.languageTag was null or undefined when calling getBaseInformationOfDerivativeCategory.');
        }

        const queryParameters: any = {};

        if (requestParameters.languageTag !== undefined) {
            queryParameters['languageTag'] = requestParameters.languageTag;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/web/derivative-categories/details/{key}/base-information`.replace(`{${"key"}}`, encodeURIComponent(String(requestParameters.key))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => BaseInformationDTOFromJSON(jsonValue));
    }

    /**
     * Get baseInformation of DerivativeCategory with given key
     */
    async getBaseInformationOfDerivativeCategory(requestParameters: GetBaseInformationOfDerivativeCategoryRequest): Promise<BaseInformationDTO> {
        const response = await this.getBaseInformationOfDerivativeCategoryRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get links of DerivativeCategory with given key
     */
    async getLinksOfDerivativeCategoryRaw(requestParameters: GetLinksOfDerivativeCategoryRequest): Promise<runtime.ApiResponse<DerivativeCategoryLinksDTO>> {
        if (requestParameters.key === null || requestParameters.key === undefined) {
            throw new runtime.RequiredError('key','Required parameter requestParameters.key was null or undefined when calling getLinksOfDerivativeCategory.');
        }

        if (requestParameters.languageTag === null || requestParameters.languageTag === undefined) {
            throw new runtime.RequiredError('languageTag','Required parameter requestParameters.languageTag was null or undefined when calling getLinksOfDerivativeCategory.');
        }

        const queryParameters: any = {};

        if (requestParameters.languageTag !== undefined) {
            queryParameters['languageTag'] = requestParameters.languageTag;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/web/derivative-categories/details/{key}/links`.replace(`{${"key"}}`, encodeURIComponent(String(requestParameters.key))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => DerivativeCategoryLinksDTOFromJSON(jsonValue));
    }

    /**
     * Get links of DerivativeCategory with given key
     */
    async getLinksOfDerivativeCategory(requestParameters: GetLinksOfDerivativeCategoryRequest): Promise<DerivativeCategoryLinksDTO> {
        const response = await this.getLinksOfDerivativeCategoryRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get localizationsOfDescription of DerivativeCategory with given key
     */
    async getLocalizationsOfDescriptionOfDerivativeCategoryRaw(requestParameters: GetLocalizationsOfDescriptionOfDerivativeCategoryRequest): Promise<runtime.ApiResponse<{ [key: string]: LocalizationsItemDTO; }>> {
        if (requestParameters.key === null || requestParameters.key === undefined) {
            throw new runtime.RequiredError('key','Required parameter requestParameters.key was null or undefined when calling getLocalizationsOfDescriptionOfDerivativeCategory.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/web/derivative-categories/details/{key}/description/localizations`.replace(`{${"key"}}`, encodeURIComponent(String(requestParameters.key))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => runtime.mapValues(jsonValue, LocalizationsItemDTOFromJSON));
    }

    /**
     * Get localizationsOfDescription of DerivativeCategory with given key
     */
    async getLocalizationsOfDescriptionOfDerivativeCategory(requestParameters: GetLocalizationsOfDescriptionOfDerivativeCategoryRequest): Promise<{ [key: string]: LocalizationsItemDTO; }> {
        const response = await this.getLocalizationsOfDescriptionOfDerivativeCategoryRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get localizationsOfName of DerivativeCategory with given key
     */
    async getLocalizationsOfNameOfDerivativeCategoryRaw(requestParameters: GetLocalizationsOfNameOfDerivativeCategoryRequest): Promise<runtime.ApiResponse<{ [key: string]: LocalizationsItemDTO; }>> {
        if (requestParameters.key === null || requestParameters.key === undefined) {
            throw new runtime.RequiredError('key','Required parameter requestParameters.key was null or undefined when calling getLocalizationsOfNameOfDerivativeCategory.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/web/derivative-categories/details/{key}/name/localizations`.replace(`{${"key"}}`, encodeURIComponent(String(requestParameters.key))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => runtime.mapValues(jsonValue, LocalizationsItemDTOFromJSON));
    }

    /**
     * Get localizationsOfName of DerivativeCategory with given key
     */
    async getLocalizationsOfNameOfDerivativeCategory(requestParameters: GetLocalizationsOfNameOfDerivativeCategoryRequest): Promise<{ [key: string]: LocalizationsItemDTO; }> {
        const response = await this.getLocalizationsOfNameOfDerivativeCategoryRaw(requestParameters);
        return await response.value();
    }

    /**
     * Updates attributes of an existing DerivativeCategory
     */
    async updateAttributesOfDerivativeCategoryRaw(requestParameters: UpdateAttributesOfDerivativeCategoryRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.key === null || requestParameters.key === undefined) {
            throw new runtime.RequiredError('key','Required parameter requestParameters.key was null or undefined when calling updateAttributesOfDerivativeCategory.');
        }

        if (requestParameters.derivativeCategoryAttributesUpdateDTO === null || requestParameters.derivativeCategoryAttributesUpdateDTO === undefined) {
            throw new runtime.RequiredError('derivativeCategoryAttributesUpdateDTO','Required parameter requestParameters.derivativeCategoryAttributesUpdateDTO was null or undefined when calling updateAttributesOfDerivativeCategory.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/web/derivative-categories/details/{key}/attributes`.replace(`{${"key"}}`, encodeURIComponent(String(requestParameters.key))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: DerivativeCategoryAttributesUpdateDTOToJSON(requestParameters.derivativeCategoryAttributesUpdateDTO),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Updates attributes of an existing DerivativeCategory
     */
    async updateAttributesOfDerivativeCategory(requestParameters: UpdateAttributesOfDerivativeCategoryRequest): Promise<void> {
        await this.updateAttributesOfDerivativeCategoryRaw(requestParameters);
    }

    /**
     * Updates description of an existing DerivativeCategory
     */
    async updateDescriptionOfDerivativeCategoryRaw(requestParameters: UpdateDescriptionOfDerivativeCategoryRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.key === null || requestParameters.key === undefined) {
            throw new runtime.RequiredError('key','Required parameter requestParameters.key was null or undefined when calling updateDescriptionOfDerivativeCategory.');
        }

        if (requestParameters.requestBody === null || requestParameters.requestBody === undefined) {
            throw new runtime.RequiredError('requestBody','Required parameter requestParameters.requestBody was null or undefined when calling updateDescriptionOfDerivativeCategory.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/web/derivative-categories/details/{key}/description`.replace(`{${"key"}}`, encodeURIComponent(String(requestParameters.key))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.requestBody,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Updates description of an existing DerivativeCategory
     */
    async updateDescriptionOfDerivativeCategory(requestParameters: UpdateDescriptionOfDerivativeCategoryRequest): Promise<void> {
        await this.updateDescriptionOfDerivativeCategoryRaw(requestParameters);
    }

    /**
     * Updates name of an existing DerivativeCategory
     */
    async updateNameOfDerivativeCategoryRaw(requestParameters: UpdateNameOfDerivativeCategoryRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.key === null || requestParameters.key === undefined) {
            throw new runtime.RequiredError('key','Required parameter requestParameters.key was null or undefined when calling updateNameOfDerivativeCategory.');
        }

        if (requestParameters.requestBody === null || requestParameters.requestBody === undefined) {
            throw new runtime.RequiredError('requestBody','Required parameter requestParameters.requestBody was null or undefined when calling updateNameOfDerivativeCategory.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/web/derivative-categories/details/{key}/name`.replace(`{${"key"}}`, encodeURIComponent(String(requestParameters.key))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.requestBody,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Updates name of an existing DerivativeCategory
     */
    async updateNameOfDerivativeCategory(requestParameters: UpdateNameOfDerivativeCategoryRequest): Promise<void> {
        await this.updateNameOfDerivativeCategoryRaw(requestParameters);
    }

}
