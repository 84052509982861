/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SelectOptionDTOString,
    SelectOptionDTOStringFromJSON,
    SelectOptionDTOStringFromJSONTyped,
    SelectOptionDTOStringToJSON,
} from './';

/**
 * 
 * @export
 * @interface CustomInteriorColorCreateOptionsDTO
 */
export interface CustomInteriorColorCreateOptionsDTO {
    /**
     * 
     * @type {Array<SelectOptionDTOString>}
     * @memberof CustomInteriorColorCreateOptionsDTO
     */
    interiorColorTags: Array<SelectOptionDTOString>;
}

export function CustomInteriorColorCreateOptionsDTOFromJSON(json: any): CustomInteriorColorCreateOptionsDTO {
    return CustomInteriorColorCreateOptionsDTOFromJSONTyped(json, false);
}

export function CustomInteriorColorCreateOptionsDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): CustomInteriorColorCreateOptionsDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'interiorColorTags': ((json['interiorColorTags'] as Array<any>).map(SelectOptionDTOStringFromJSON)),
    };
}

export function CustomInteriorColorCreateOptionsDTOToJSON(value?: CustomInteriorColorCreateOptionsDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'interiorColorTags': ((value.interiorColorTags as Array<any>).map(SelectOptionDTOStringToJSON)),
    };
}


