import { propertiesOf, Property } from "../../../../../../../common/property"
import { FormElementWrapper } from "../../../../../../../components/formelements/FormElementWrapper"
import { ReadonlyFormElementWrapper } from "../../../../../../../components/formelements/ReadonlyFormElementWrapper"
import { getDerivedNameFormElement } from "../../../../../../../components/formelements/derivednameformelement/DerivedNameFormElement"
import { getNumberFormElement } from "../../../../../../../components/formelements/numberformelement/NumberFormElement"
import { getTextFormElement } from "../../../../../../../components/formelements/textformelement/TextFormElement"
import { rowGroupsSpacing, RowGroupWrapper } from "../../../../../../../components/layouts/rowgroupwrapper/RowGroupWrapper"
import { SpacedItems } from "../../../../../../../components/layouts/spaceditems/SpacedItems"
import {
    OptionDTO,
    OptionDTOExteriorColor,
    OptionDTOPaintToSampleExteriorColor,
    OptionUpdateDTOExteriorColor,
    OptionUpdateDTOOption,
    OptionUpdateDTOPaintToSampleExteriorColor,
} from "../../../../../../../generated/pdsapi"
import { ProductDetailsCardContentProps } from "../../../../../../../viewtemplates/details/cards/ProductDetailsCard"
import React, { FC } from "react"
import { OptionImage } from "../../../../../../../components/optionimage/OptionImage"
import { getSelectTextFormElement } from "../../../../../../../components/formelements/selecttextformelement/SelectTextFormElement"
import { toOption } from "../../../../../../../components/formelements/selecttextformelement/Option"
import { FormDisplayData } from "../../../../../../../components/formelements/FormDisplayData"
import { getTagFormTextElement } from "../../../../../../../components/formelements/tagformtextelement/TagFormTextElement"
import { routes } from "../../../../../../../common/routes"
import { getTagFormMultiTextElement } from "../../../../../../../components/formelements/tagformmultitextelement/TagFormMultiTextElement"
import { OrderTypeOptionKey } from "../../../../OrderTypeOptionKey"
import { optionTypeOptions } from "../../../optionTypeMappings"

export const OptionItemDetailsCardContent: FC<ProductDetailsCardContentProps<OptionDTO, OptionUpdateDTOOption, OrderTypeOptionKey>> = ({
    formDisplayData,
    itemKey,
}) => {
    const properties = propertiesOf<OptionUpdateDTOOption>()

    return (
        <SpacedItems direction={"column"} lineSpacing={rowGroupsSpacing}>
            <RowGroupWrapper label={"Configuration"} childrenSize={6}>
                <FormElementWrapper
                    label={"Type"}
                    formDisplayData={formDisplayData}
                    field={properties.type}
                    FormElement={getSelectTextFormElement<OptionDTO, OptionUpdateDTOOption>()}
                    getViewProps={(data) => ({
                        text: optionTypeOptions.find((option) => option.value === data.type)?.label ?? data.type,
                    })}
                    getEditProps={(data) => ({
                        value: data.type,
                        options: optionTypeOptions,
                        readOnly: true,
                    })}
                />
                <ReadonlyFormElementWrapper
                    label={"Option Code"}
                    formDisplayData={formDisplayData}
                    FormElement={getTextFormElement()}
                    getViewProps={(data) => ({
                        value: data.optionCode,
                    })}
                />
            </RowGroupWrapper>
            <RowGroupWrapper childrenSize={6}>
                <FormElementWrapper
                    label={"Sort Index"}
                    formDisplayData={formDisplayData}
                    field={properties.sortIndex}
                    FormElement={getNumberFormElement<OptionDTO, OptionUpdateDTOOption>()}
                    getViewProps={(data) => ({
                        value: data.sortIndex,
                    })}
                    getEditProps={(data) => ({
                        value: data.sortIndex,
                        step: 1,
                        min: 0,
                    })}
                />
            </RowGroupWrapper>

            <RowGroupWrapper label={"Name"} childrenSize={6}>
                {(["de", "en"] as const).map((languageTag) => (
                    <FormElementWrapper
                        key={languageTag}
                        label={`Name - ${languageTag}`}
                        formDisplayData={formDisplayData}
                        field={properties.name[languageTag]}
                        FormElement={getDerivedNameFormElement<OptionDTO, OptionUpdateDTOOption>()}
                        getViewProps={(data) => {
                            const name = data.name[languageTag]
                            return {
                                primaryName: {
                                    value: name.merged.withFallback,
                                    isDerived: !name.merged.raw,
                                },
                                secondaryNames: [
                                    {
                                        label: "Option",
                                        value: name.option.withFallback,
                                        isDerived: !name.option.raw,
                                    },
                                    {
                                        label: "Customization",
                                        value: name.customization?.withFallback,
                                        isDerived: !name.customization?.raw,
                                    },
                                ],
                            }
                        }}
                        getEditProps={(data) => ({
                            rawName: data.name[languageTag].option.raw,
                        })}
                    />
                ))}
            </RowGroupWrapper>

            <RowGroupWrapper label={"Description"} childrenSize={6}>
                {(["de", "en"] as const).map((languageTag) => (
                    <FormElementWrapper
                        key={languageTag}
                        label={`Description - ${languageTag}`}
                        formDisplayData={formDisplayData}
                        field={properties.description[languageTag]}
                        FormElement={getDerivedNameFormElement<OptionDTO, OptionUpdateDTOOption>()}
                        getViewProps={(data) => {
                            const description = data.description[languageTag]
                            return {
                                primaryName: {
                                    value: description.merged.withFallback,
                                    isDerived: !description.merged.raw,
                                },
                                secondaryNames: [
                                    {
                                        label: "Option",
                                        value: description.option.withFallback,
                                        isDerived: !description.option.raw,
                                    },
                                    {
                                        label: "Customization",
                                        value: description.customization?.withFallback,
                                        isDerived: !description.customization?.raw,
                                    },
                                ],
                            }
                        }}
                        getEditProps={(data) => ({
                            rawName: data.description[languageTag].option.raw,
                        })}
                    />
                ))}
            </RowGroupWrapper>
            <SpecificOptionItemContent formDisplayData={formDisplayData} property={properties} itemKey={itemKey} />
            <RowGroupWrapper label={"Images"} childrenSize={5}>
                {formDisplayData.data?.imageKeys.map((imageKey) => <OptionImage key={imageKey} imageKey={imageKey} sizes={"400px"} />)}
            </RowGroupWrapper>
        </SpacedItems>
    )
}

interface SpecificOptionItemContentProps<DATA extends OptionDTO = OptionDTO, UPDATE extends OptionUpdateDTOOption = OptionUpdateDTOOption> {
    itemKey: OrderTypeOptionKey
    property: Property<UPDATE, UPDATE>
    formDisplayData: FormDisplayData<DATA, UPDATE>
}

const SpecificOptionItemContent: FC<SpecificOptionItemContentProps> = (props) => {
    switch (props.formDisplayData.data?.type) {
        case "ExteriorColor": {
            const { property, formDisplayData } = props as SpecificOptionItemContentProps<OptionDTOExteriorColor, OptionUpdateDTOExteriorColor>
            return (
                <RowGroupWrapper label={"Additional Information"} childrenSize={6}>
                    <FormElementWrapper
                        label={"Exterior Color Group"}
                        formDisplayData={formDisplayData}
                        field={property.exteriorColorGroup}
                        FormElement={getSelectTextFormElement<OptionDTOExteriorColor, OptionUpdateDTOExteriorColor>()}
                        getViewProps={(data) => ({
                            text: data.exteriorColorGroup.value?.label ?? "-",
                        })}
                        getEditProps={(options) => ({
                            options: [{ value: "", label: "-" }, ...options.exteriorColorGroup.options.map(toOption)],
                            value: options.exteriorColorGroup.value?.key ?? "",
                            optional: true,
                        })}
                    />
                </RowGroupWrapper>
            )
        }

        case "PaintToSampleExteriorColor": {
            const { property, formDisplayData } = props as SpecificOptionItemContentProps<
                OptionDTOPaintToSampleExteriorColor,
                OptionUpdateDTOPaintToSampleExteriorColor
            >

            return (
                <SpacedItems direction={"column"} lineSpacing={rowGroupsSpacing}>
                    <RowGroupWrapper label={"References"} childrenSize={6}>
                        <FormElementWrapper
                            label={"Paint to Sample Equipment Option Code"}
                            formDisplayData={formDisplayData}
                            field={property.paintToSampleEquipmentOptionCode}
                            FormElement={getTagFormTextElement<OptionDTOPaintToSampleExteriorColor, OptionUpdateDTOPaintToSampleExteriorColor>()}
                            getViewProps={({ paintToSampleEquipmentOptionCode }) => ({
                                tag: paintToSampleEquipmentOptionCode
                                    ? {
                                          label: paintToSampleEquipmentOptionCode,
                                          route: routes.models.orderTypes
                                              .subpages(props.itemKey.orderTypeKey)
                                              .equipment.details(paintToSampleEquipmentOptionCode),
                                      }
                                    : undefined,
                            })}
                            getEditProps={(data) => ({
                                value: data.paintToSampleEquipmentOptionCode ?? "",
                                readOnly: false,
                                optional: true,
                            })}
                        />
                        <FormElementWrapper
                            label={"Paint to Sample Plus Equipment Option Code"}
                            formDisplayData={formDisplayData}
                            field={property.paintToSamplePlusEquipmentOptionCode}
                            FormElement={getTagFormTextElement<OptionDTOPaintToSampleExteriorColor, OptionUpdateDTOPaintToSampleExteriorColor>()}
                            getViewProps={({ paintToSamplePlusEquipmentOptionCode }) => ({
                                tag: paintToSamplePlusEquipmentOptionCode
                                    ? {
                                          label: paintToSamplePlusEquipmentOptionCode,
                                          route: routes.models.orderTypes
                                              .subpages(props.itemKey.orderTypeKey)
                                              .equipment.details(paintToSamplePlusEquipmentOptionCode),
                                      }
                                    : undefined,
                            })}
                            getEditProps={(data) => ({
                                value: data.paintToSamplePlusEquipmentOptionCode ?? "",
                                readOnly: false,
                                optional: true,
                            })}
                        />
                        <FormElementWrapper
                            label={"Z-Order Option Code(s)"}
                            formDisplayData={formDisplayData}
                            field={property.zOrderOptionCodes}
                            FormElement={getTagFormMultiTextElement<OptionDTOPaintToSampleExteriorColor, OptionUpdateDTOPaintToSampleExteriorColor>()}
                            getViewProps={(data) => ({
                                tags: (data.zOrderOptionCodes ?? []).map((value) => ({
                                    label: value,
                                    route: routes.models.orderTypes.subpages(props.itemKey.orderTypeKey).equipment.details(value),
                                })),
                                emptyLabel: "-",
                            })}
                            getEditProps={(data) => ({
                                value: data.zOrderOptionCodes ?? [],
                                readOnly: false,
                                optional: true,
                            })}
                        />
                    </RowGroupWrapper>
                </SpacedItems>
            )
        }
    }
}
