/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SelectOptionDTOBodyType,
    SelectOptionDTOBodyTypeFromJSON,
    SelectOptionDTOBodyTypeFromJSONTyped,
    SelectOptionDTOBodyTypeToJSON,
} from './';

/**
 * 
 * @export
 * @interface SelectDTOBodyType
 */
export interface SelectDTOBodyType {
    /**
     * 
     * @type {SelectOptionDTOBodyType}
     * @memberof SelectDTOBodyType
     */
    value: SelectOptionDTOBodyType;
    /**
     * 
     * @type {Array<SelectOptionDTOBodyType>}
     * @memberof SelectDTOBodyType
     */
    options: Array<SelectOptionDTOBodyType>;
}

export function SelectDTOBodyTypeFromJSON(json: any): SelectDTOBodyType {
    return SelectDTOBodyTypeFromJSONTyped(json, false);
}

export function SelectDTOBodyTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): SelectDTOBodyType {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'value': SelectOptionDTOBodyTypeFromJSON(json['value']),
        'options': ((json['options'] as Array<any>).map(SelectOptionDTOBodyTypeFromJSON)),
    };
}

export function SelectDTOBodyTypeToJSON(value?: SelectDTOBodyType | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'value': SelectOptionDTOBodyTypeToJSON(value.value),
        'options': ((value.options as Array<any>).map(SelectOptionDTOBodyTypeToJSON)),
    };
}


