/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SelectOptionDTOEngineDesign,
    SelectOptionDTOEngineDesignFromJSON,
    SelectOptionDTOEngineDesignFromJSONTyped,
    SelectOptionDTOEngineDesignToJSON,
    SelectOptionDTOEngineLayout,
    SelectOptionDTOEngineLayoutFromJSON,
    SelectOptionDTOEngineLayoutFromJSONTyped,
    SelectOptionDTOEngineLayoutToJSON,
    SelectOptionDTOFuelType,
    SelectOptionDTOFuelTypeFromJSON,
    SelectOptionDTOFuelTypeFromJSONTyped,
    SelectOptionDTOFuelTypeToJSON,
    SelectOptionDTOTransmission,
    SelectOptionDTOTransmissionFromJSON,
    SelectOptionDTOTransmissionFromJSONTyped,
    SelectOptionDTOTransmissionToJSON,
} from './';

/**
 * 
 * @export
 * @interface OrderTypeTechnicalDataRelatedDataDTO
 */
export interface OrderTypeTechnicalDataRelatedDataDTO {
    /**
     * 
     * @type {Array<string>}
     * @memberof OrderTypeTechnicalDataRelatedDataDTO
     */
    possibleOptionCodes: Array<string>;
    /**
     * 
     * @type {Array<SelectOptionDTOEngineDesign>}
     * @memberof OrderTypeTechnicalDataRelatedDataDTO
     */
    engineDesigns: Array<SelectOptionDTOEngineDesign>;
    /**
     * 
     * @type {Array<SelectOptionDTOEngineLayout>}
     * @memberof OrderTypeTechnicalDataRelatedDataDTO
     */
    engineLayouts: Array<SelectOptionDTOEngineLayout>;
    /**
     * 
     * @type {Array<SelectOptionDTOTransmission>}
     * @memberof OrderTypeTechnicalDataRelatedDataDTO
     */
    transmissions: Array<SelectOptionDTOTransmission>;
    /**
     * 
     * @type {Array<SelectOptionDTOFuelType>}
     * @memberof OrderTypeTechnicalDataRelatedDataDTO
     */
    fuelTypes: Array<SelectOptionDTOFuelType>;
}

export function OrderTypeTechnicalDataRelatedDataDTOFromJSON(json: any): OrderTypeTechnicalDataRelatedDataDTO {
    return OrderTypeTechnicalDataRelatedDataDTOFromJSONTyped(json, false);
}

export function OrderTypeTechnicalDataRelatedDataDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrderTypeTechnicalDataRelatedDataDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'possibleOptionCodes': json['possibleOptionCodes'],
        'engineDesigns': ((json['engineDesigns'] as Array<any>).map(SelectOptionDTOEngineDesignFromJSON)),
        'engineLayouts': ((json['engineLayouts'] as Array<any>).map(SelectOptionDTOEngineLayoutFromJSON)),
        'transmissions': ((json['transmissions'] as Array<any>).map(SelectOptionDTOTransmissionFromJSON)),
        'fuelTypes': ((json['fuelTypes'] as Array<any>).map(SelectOptionDTOFuelTypeFromJSON)),
    };
}

export function OrderTypeTechnicalDataRelatedDataDTOToJSON(value?: OrderTypeTechnicalDataRelatedDataDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'possibleOptionCodes': value.possibleOptionCodes,
        'engineDesigns': ((value.engineDesigns as Array<any>).map(SelectOptionDTOEngineDesignToJSON)),
        'engineLayouts': ((value.engineLayouts as Array<any>).map(SelectOptionDTOEngineLayoutToJSON)),
        'transmissions': ((value.transmissions as Array<any>).map(SelectOptionDTOTransmissionToJSON)),
        'fuelTypes': ((value.fuelTypes as Array<any>).map(SelectOptionDTOFuelTypeToJSON)),
    };
}


