/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    OptionUpdateDTOExteriorColorInternal,
    OptionUpdateDTOExteriorColorInternalFromJSONTyped,
    OptionUpdateDTOExteriorColorInternalToJSON,
} from './';

/**
 * @type OptionUpdateDTOExteriorColor
 * 
 * @export
 */
export type OptionUpdateDTOExteriorColor = { type: 'ExteriorColor' } & OptionUpdateDTOExteriorColorInternal;

export function OptionUpdateDTOExteriorColorFromJSON(json: any): OptionUpdateDTOExteriorColor {
    return OptionUpdateDTOExteriorColorFromJSONTyped(json, false);
}

export function OptionUpdateDTOExteriorColorFromJSONTyped(json: any, ignoreDiscriminator: boolean): OptionUpdateDTOExteriorColor {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    switch (json['type']) {
        case 'ExteriorColor':
            return {...OptionUpdateDTOExteriorColorInternalFromJSONTyped(json, true), type: 'ExteriorColor'};
        default:
            throw new Error(`No variant of OptionUpdateDTOExteriorColor exists with 'type=${json['type']}'`);
    }
}

export function OptionUpdateDTOExteriorColorToJSON(value?: OptionUpdateDTOExteriorColor | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    switch (value['type']) {
        case 'ExteriorColor':
            return OptionUpdateDTOExteriorColorInternalToJSON(value);
        default:
            throw new Error(`No variant of OptionUpdateDTOExteriorColor exists with 'type=${value['type']}'`);
    }
}

