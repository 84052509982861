import { SpacedItems } from "../../../components/layouts/spaceditems/SpacedItems"
import { Tag } from "../../../components/tag/Tag"
import { NamedInteriorColorTile } from "../../../components/tiles/namedinteriorcolortile/NamedInteriorColorTile"
import { MissingOptionCustomizationDTOMissingOptionCustomizationDTOInteriorCustomization } from "../../../generated/pdsapi"
import { dashboardApi } from "../../../pdsapi"
import { DashboardPagedTableProps } from "../dashboardpagedtable/DashboardPagedTable"
import { missingOptionCustomizationsColumns } from "./parts/MissingOptionCustomizationsColumns"
import { spacing } from "@porsche-design-system/utilities"
import { routes } from "../../../common/routes"

export const MissingInteriorCustomizationsTableConfig: DashboardPagedTableProps<MissingOptionCustomizationDTOMissingOptionCustomizationDTOInteriorCustomization> =
    {
        titlePrefix: "Missing",
        itemsName: "Interior Customizations",

        getRoute: (missingOptionCustomization) =>
            routes.interiors.customizations.create(
                new URLSearchParams({
                    optionCode: missingOptionCustomization.optionCode,
                    modelGeneration: missingOptionCustomization.modelGeneration.key,
                })
            ),
        urlParamsPrefix: "interior-customizations.missing",

        fetchPage: dashboardApi.getMissingInteriorCustomizations,
        columns: [
            ...missingOptionCustomizationsColumns,
            {
                grow: 1,
                headerCellProps: {
                    content: "Similar Customizations",
                },
                buildCellContent: (missingOptionCustomization) => (
                    <SpacedItems direction={"column"} lineSpacing={spacing[4]}>
                        {missingOptionCustomization.similarCustomizations.map((similarCustomization, index) => (
                            <Tag
                                key={`similar_customization_${index}`}
                                onClick={() => window.open(routes.interiors.customizations.details(similarCustomization.key))}
                            >
                                <NamedInteriorColorTile
                                    label={`in ${similarCustomization.materialName} for ${similarCustomization.modelGenerationNames.join(", ")}`}
                                    seatColor={similarCustomization.color.seatColorHexCode}
                                    seatStitchingColor={similarCustomization.color.seatStitchingColorHexCode}
                                    cockpitColor={similarCustomization.color.cockpitColorHexCode}
                                    cockpitStitchingColor={similarCustomization.color.cockpitStitchingColorHexCode}
                                />
                            </Tag>
                        ))}
                    </SpacedItems>
                ),
            },
        ],
    }
