import { getProductDetailsContainer, ProductDetailsContainerConfig } from "../../../../../viewtemplates/details/ProductDetailsContainer"
import { optionApi } from "../../../../../pdsapi"
import { ProductDetailsTab } from "../../../../../viewtemplates/details/tabs/ProductDetailsTab"
import { ProductDetailsCard } from "../../../../../viewtemplates/details/cards/ProductDetailsCard"
import { OptionItemDetailsCardContent } from "./data/content/OptionItemDetailsCardContent"
import { OrderTypeOptionKey } from "../../OrderTypeOptionKey"
import { Route, useParams } from "react-router-dom"
import { routes } from "../../../../../common/routes"
import { LocalizationsItemDTO, OptionLocalizationItemDTO, OrderTypeOptionTypeDTO } from "../../../../../generated/pdsapi"
import { useOrderTypeSubpageCrumbs } from "../../useOrderTypeSubpageCrumbs"
import { enumToOptionType, optionTypes } from "../optionTypeMappings"
import { OptionItemDetailsCardNotification } from "./data/content/OptionItemDetailsCardNotification"
import { LinkedCustomizationCard } from "../../../../../components/linkedcustomizationcard/LinkedCustomizationCard"
import { LinkedOptionCustomizationCardContent } from "./data/linkedcustomization/LinkedOptionCustomizationCardContent"
import { OptionSimilarCustomizationsCard } from "./data/similarcustomizations/OptionSimilarCustomizationsCard"
import { DetailsDerivedLocalizationCard } from "../../../../../viewtemplates/details/cards/derivedlocalizationcard/DetailsDerivedLocalizationCard"
import { OptionMarketplaceSpecificsCardContent } from "../../../../../components/optionmarketplacespecificscard/content/OptionMarketplaceSpecificsCardContent"
import { NameItem } from "../../../../../components/formelements/derivednameformelement/DerivedNameFormElement"

const nameItem = (label: string | undefined, item: LocalizationsItemDTO): NameItem => ({
    label,
    isDerived: item.raw === undefined,
    value: item.withFallback,
})

export const OptionProductDetailsContainerConfig: ProductDetailsContainerConfig<OrderTypeOptionKey> = {
    getBaseInformation: optionApi.details.getBaseInformation,

    deleteByKey: optionApi.details.deleteByKey,
    tabsConfig: [
        {
            label: "Data",
            Content: (props) => (
                <ProductDetailsTab>
                    <ProductDetailsCard
                        {...props}
                        label={optionTypes[enumToOptionType(props.itemKey.type)] + " Option"}
                        getData={optionApi.details.getOption}
                        updateData={(data) =>
                            optionApi.details.updateOption({
                                orderTypeKey: data.orderTypeKey,
                                optionCode: data.optionCode,
                                optionUpdateDTOOption: data.update,
                            })
                        }
                        Content={OptionItemDetailsCardContent}
                        Notification={OptionItemDetailsCardNotification}
                    />
                    <LinkedCustomizationCard
                        {...props}
                        getLinkedCustomization={optionApi.details.getLinkedCustomization}
                        CustomizationContent={LinkedOptionCustomizationCardContent}
                        getCreateCustomizationRoute={routes.options.customizations.create}
                    />
                    <OptionSimilarCustomizationsCard {...props} />
                </ProductDetailsTab>
            ),
        },
        {
            label: "Name",
            Content: (props) => (
                <ProductDetailsTab>
                    <DetailsDerivedLocalizationCard
                        {...props}
                        label={"Localizations"}
                        getData={optionApi.details.getName}
                        updateData={({ orderTypeKey, optionCode, type, update }) =>
                            optionApi.details.updateName({
                                orderTypeKey,
                                optionCode,
                                type,
                                requestBody: update,
                            })
                        }
                        getViewProps={(data, languageTag) => {
                            const name: OptionLocalizationItemDTO = data[languageTag]
                            return {
                                primaryName: nameItem(undefined, name.merged),
                                secondaryNames: [nameItem("Option", name.option), nameItem("Customization", name.customization ?? {})],
                            }
                        }}
                        getEditProps={(data, languageTag) => ({
                            rawName: data[languageTag].option.raw,
                        })}
                    />
                </ProductDetailsTab>
            ),
        },
        {
            label: "Description",
            Content: (props) => (
                <ProductDetailsTab>
                    <DetailsDerivedLocalizationCard
                        {...props}
                        label={"Localizations"}
                        getData={optionApi.details.getDescription}
                        updateData={({ orderTypeKey, optionCode, type, update }) =>
                            optionApi.details.updateDescription({
                                orderTypeKey,
                                optionCode,
                                type,
                                requestBody: update,
                            })
                        }
                        getViewProps={(data, languageTag) => {
                            const name: OptionLocalizationItemDTO = data[languageTag]
                            return {
                                primaryName: nameItem(undefined, name.merged),
                                secondaryNames: [nameItem("Option", name.option), nameItem("Customization", name.customization ?? {})],
                            }
                        }}
                        getEditProps={(data, languageTag) => ({
                            rawName: data[languageTag].option.raw,
                        })}
                    />
                </ProductDetailsTab>
            ),
        },
        {
            label: "Market Specifics",
            Content: (props) => (
                <ProductDetailsTab>
                    <ProductDetailsCard
                        isDataEditable={false}
                        urlPathParamsPrefix={props.urlPathParamsPrefix}
                        itemKey={props.itemKey}
                        reloadDataIndicator={props.reloadDataIndicator}
                        triggerReload={props.triggerReload}
                        label={"Market Specifics"}
                        getData={optionApi.details.getMarketplaceSpecifics}
                        Content={OptionMarketplaceSpecificsCardContent}
                    />
                </ProductDetailsTab>
            ),
        },
    ],
}

const OptionDetailsContainer = getProductDetailsContainer(OptionProductDetailsContainerConfig)

export const OrderTypeOptionItemDetailsRoute = (
    <Route
        path={routes.models.orderTypes.subpages(":orderTypeKey").options.details(":optionCode", ":type" as OrderTypeOptionTypeDTO)}
        Component={() => {
            const params = useParams<OrderTypeOptionKey>()
            const parentCrumbs = useOrderTypeSubpageCrumbs(params.orderTypeKey)
            return (
                <OptionDetailsContainer
                    itemKey={params}
                    parentCrumbs={[
                        ...parentCrumbs,
                        {
                            name: "Options",
                            path: routes.models.orderTypes.subpages(params.orderTypeKey).options.list(),
                        },
                    ]}
                />
            )
        }}
    />
)
