/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    OptionSortField,
    OptionSortFieldFromJSON,
    OptionSortFieldToJSON,
    PageDTORoofColorItemDTO,
    PageDTORoofColorItemDTOFromJSON,
    PageDTORoofColorItemDTOToJSON,
    RoofColorFilterOptionsDTO,
    RoofColorFilterOptionsDTOFromJSON,
    RoofColorFilterOptionsDTOToJSON,
    SortDirection,
    SortDirectionFromJSON,
    SortDirectionToJSON,
} from '../models';

export interface GetRoofColorFilterOptionsRequest {
    languageTag: string;
}

export interface GetRoofColorsPageRequest {
    languageTag: string;
    offset: number;
    size: number;
    sortField: OptionSortField;
    sortDirection: SortDirection;
    search?: string;
    modelGenerationKeys?: Array<string>;
    modelYears?: Array<number>;
    orderTypeKeys?: Array<string>;
    colorTagKeys?: Array<string>;
}

/**
 * 
 */
export class RoofColorCatalogueWebControllerApi extends runtime.BaseAPI {

    /**
     * Get options to filter RoofColors
     */
    async getRoofColorFilterOptionsRaw(requestParameters: GetRoofColorFilterOptionsRequest): Promise<runtime.ApiResponse<RoofColorFilterOptionsDTO>> {
        if (requestParameters.languageTag === null || requestParameters.languageTag === undefined) {
            throw new runtime.RequiredError('languageTag','Required parameter requestParameters.languageTag was null or undefined when calling getRoofColorFilterOptions.');
        }

        const queryParameters: any = {};

        if (requestParameters.languageTag !== undefined) {
            queryParameters['languageTag'] = requestParameters.languageTag;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/web/roof-color/list/filter-options`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => RoofColorFilterOptionsDTOFromJSON(jsonValue));
    }

    /**
     * Get options to filter RoofColors
     */
    async getRoofColorFilterOptions(requestParameters: GetRoofColorFilterOptionsRequest): Promise<RoofColorFilterOptionsDTO> {
        const response = await this.getRoofColorFilterOptionsRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get page of RoofColors
     */
    async getRoofColorsPageRaw(requestParameters: GetRoofColorsPageRequest): Promise<runtime.ApiResponse<PageDTORoofColorItemDTO>> {
        if (requestParameters.languageTag === null || requestParameters.languageTag === undefined) {
            throw new runtime.RequiredError('languageTag','Required parameter requestParameters.languageTag was null or undefined when calling getRoofColorsPage.');
        }

        if (requestParameters.offset === null || requestParameters.offset === undefined) {
            throw new runtime.RequiredError('offset','Required parameter requestParameters.offset was null or undefined when calling getRoofColorsPage.');
        }

        if (requestParameters.size === null || requestParameters.size === undefined) {
            throw new runtime.RequiredError('size','Required parameter requestParameters.size was null or undefined when calling getRoofColorsPage.');
        }

        if (requestParameters.sortField === null || requestParameters.sortField === undefined) {
            throw new runtime.RequiredError('sortField','Required parameter requestParameters.sortField was null or undefined when calling getRoofColorsPage.');
        }

        if (requestParameters.sortDirection === null || requestParameters.sortDirection === undefined) {
            throw new runtime.RequiredError('sortDirection','Required parameter requestParameters.sortDirection was null or undefined when calling getRoofColorsPage.');
        }

        const queryParameters: any = {};

        if (requestParameters.languageTag !== undefined) {
            queryParameters['languageTag'] = requestParameters.languageTag;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.sortField !== undefined) {
            queryParameters['sortField'] = requestParameters.sortField;
        }

        if (requestParameters.sortDirection !== undefined) {
            queryParameters['sortDirection'] = requestParameters.sortDirection;
        }

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.modelGenerationKeys) {
            queryParameters['modelGenerationKeys'] = requestParameters.modelGenerationKeys;
        }

        if (requestParameters.modelYears) {
            queryParameters['modelYears'] = requestParameters.modelYears;
        }

        if (requestParameters.orderTypeKeys) {
            queryParameters['orderTypeKeys'] = requestParameters.orderTypeKeys;
        }

        if (requestParameters.colorTagKeys) {
            queryParameters['colorTagKeys'] = requestParameters.colorTagKeys;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/web/roof-color/list/page`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PageDTORoofColorItemDTOFromJSON(jsonValue));
    }

    /**
     * Get page of RoofColors
     */
    async getRoofColorsPage(requestParameters: GetRoofColorsPageRequest): Promise<PageDTORoofColorItemDTO> {
        const response = await this.getRoofColorsPageRaw(requestParameters);
        return await response.value();
    }

}
