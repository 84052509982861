import { TableFilterTagsConfig } from "../../../../components/filteredpagedtable/FilteredPagedTable"
import { Tag } from "../../../../components/tag/Tag"
import { EquipmentFilterOptionsDTO } from "../../../../generated/pdsapi"
import { EquipmentFilter } from "../../../../pdsapi"
import { findGroupedOption, findOption } from "../../../../viewtemplates/list/filter/ListFilterTagsConfig"
import { JSX } from "react"

export const EquipmentCatalogueFilterTagsConfig: TableFilterTagsConfig<EquipmentFilter, EquipmentFilterOptionsDTO> = ({ filterOptions, filter, setFilter }) => {
    const filterTags: JSX.Element[] = []

    filter.categories?.forEach((category) => {
        filterTags.push(
            <Tag
                key={`filterTag_category_${category}`}
                onRemove={() =>
                    setFilter((filter) => {
                        const newCategories = filter.categories?.filter((key) => category !== key)
                        return {
                            ...filter,
                            categories: newCategories?.length !== 0 ? newCategories : undefined,
                        }
                    })
                }
            >
                {findOption(filterOptions?.categories, category)?.label ?? category}
            </Tag>
        )
    })

    filter.tagKeys?.forEach((tagKey) => {
        filterTags.push(
            <Tag
                key={`filterTag_equipmentTagKey_${tagKey}`}
                onRemove={() =>
                    setFilter((filter) => {
                        const newTagKeys = filter.tagKeys?.filter((key) => tagKey !== key)
                        return {
                            ...filter,
                            tagKeys: newTagKeys?.length !== 0 ? newTagKeys : undefined,
                        }
                    })
                }
            >
                {findGroupedOption(filterOptions?.tags, tagKey)?.label ?? tagKey}
            </Tag>
        )
    })

    filter.modelGenerationKeys?.forEach((modelGenerationKey) => {
        filterTags.push(
            <Tag
                key={`filterTag_modelGenerationKey_${modelGenerationKey}`}
                onRemove={() =>
                    setFilter((filter) => {
                        const newModelGenerationKeys = filter.modelGenerationKeys?.filter((key) => modelGenerationKey !== key)
                        return {
                            ...filter,
                            modelGenerationKeys: newModelGenerationKeys?.length !== 0 ? newModelGenerationKeys : undefined,
                        }
                    })
                }
            >
                {findOption(filterOptions?.modelGenerations, modelGenerationKey)?.label ?? modelGenerationKey}
            </Tag>
        )
    })

    filter.modelYears?.forEach((modelYear) => {
        filterTags.push(
            <Tag
                key={`filterTag_modelYear_${modelYear}`}
                onRemove={() =>
                    setFilter((filter) => {
                        const newModelYears = filter.modelYears?.filter((my) => modelYear !== my)
                        return {
                            ...filter,
                            modelYears: newModelYears?.length !== 0 ? newModelYears : undefined,
                        }
                    })
                }
            >
                {modelYear}
            </Tag>
        )
    })

    filter.orderTypeKeys?.forEach((orderTypeKey) => {
        filterTags.push(
            <Tag
                key={`filterTag_orderType_${orderTypeKey}`}
                onRemove={() =>
                    setFilter((filter) => {
                        const newOrderTypeKeys = filter.orderTypeKeys?.filter((key) => key !== orderTypeKey)
                        return {
                            ...filter,
                            orderTypeKeys: newOrderTypeKeys?.length !== 0 ? newOrderTypeKeys : undefined,
                        }
                    })
                }
            >
                {findGroupedOption(filterOptions?.modelYearAndGenerationRelatedOrderTypes, orderTypeKey)?.label ?? orderTypeKey}
            </Tag>
        )
    })

    filter.families?.forEach((family) => {
        filterTags.push(
            <Tag
                key={`filterTag_family_${family}`}
                onRemove={() =>
                    setFilter((filter) => {
                        const newFamilies = filter.families?.filter((key) => family !== key)
                        return {
                            ...filter,
                            families: newFamilies?.length !== 0 ? newFamilies : undefined,
                        }
                    })
                }
            >
                {family}
            </Tag>
        )
    })

    return filterTags
}
