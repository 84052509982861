import { browserFlatpickrLanguage } from "../../../common/global"
import { DefaultFormElementLoading, EditLabeledContentWrapper, EditProps, FormElement, ViewLabeledContentWrapper, ViewProps } from "../FormElementWrapper"
import { Datepicker } from "@finder/ui-kit"
import { PText } from "@porsche-design-system/components-react"
import { Controller, FieldValues } from "react-hook-form"

export const getDateFormElement = <DATA, UPDATE extends FieldValues>(): FormElement<
    DATA,
    UPDATE,
    Date,
    DateFormElementViewProps,
    DateFormElementEditProps
> => ({
    Loading: DefaultFormElementLoading,
    View: DateFormElementView,
    Edit: DateFormElementEdit,
})

interface DateFormElementViewProps {
    value: Date | undefined
}

export const DateFormElementView = ({ label, value }: ViewProps<DateFormElementViewProps>) => (
    <ViewLabeledContentWrapper label={label}>
        <PText>{value?.toLocaleDateString() ?? "-"}</PText>
    </ViewLabeledContentWrapper>
)

interface DateFormElementEditProps {
    value: Date | undefined

    readOnly?: boolean
    optional?: boolean
}

export const DateFormElementEdit = <DATA, UPDATE extends FieldValues>({
    label,
    field,
    value,
    optional,
    readOnly,
    validationErrors,
    control,
}: EditProps<DATA, UPDATE, Date, DateFormElementEditProps>) => {
    const errorMessage = validationErrors.get(field._path)

    return (
        <EditLabeledContentWrapper label={label} optional={optional}>
            <Controller
                control={control}
                name={field._path}
                defaultValue={value as any}
                rules={{ required: !optional }}
                render={({ field }) => (
                    <Datepicker
                        value={(field.value as Date | undefined)?.toISOString()}
                        onChange={(dateString) => {
                            if (dateString === null || dateString === "") {
                                // must be set to null instead of undefined to detect fields values which are manually set to "undefinedValue"
                                field.onChange(null)
                            } else {
                                field.onChange(new Date(dateString))
                            }
                        }}
                        state={errorMessage ? "error" : "none"}
                        message={errorMessage}
                        disabled={readOnly}
                        locale={browserFlatpickrLanguage}
                        dateFormat={"yyyy-MM-dd"}
                    />
                )}
            />
        </EditLabeledContentWrapper>
    )
}
