import { FC } from "react"
import { OptionCustomizationLinksDTO } from "../../../../../../generated/pdsapi"
import { ProductDetailsCardContentProps } from "../../../../../../viewtemplates/details/cards/ProductDetailsCard"
import { rowGroupsSpacing, RowGroupWrapper } from "../../../../../../components/layouts/rowgroupwrapper/RowGroupWrapper"
import { SpacedItems } from "../../../../../../components/layouts/spaceditems/SpacedItems"
import { ReadonlyFormElementWrapper } from "../../../../../../components/formelements/ReadonlyFormElementWrapper"
import { getTagsFormElement } from "../../../../../../components/formelements/tagsformelement/TagsFormElement"
import { routes } from "../../../../../../common/routes"
import { BasicKey } from "../../../../../../viewtemplates/details/ProductDetailsContainer"

export const LinkedOptionsCardContent: FC<ProductDetailsCardContentProps<OptionCustomizationLinksDTO, object, BasicKey>> = ({ formDisplayData }) => (
    <SpacedItems direction={"column"} lineSpacing={rowGroupsSpacing}>
        <RowGroupWrapper label={"Options"} childrenSize={12}>
            <ReadonlyFormElementWrapper
                label={undefined}
                formDisplayData={formDisplayData}
                FormElement={getTagsFormElement()}
                getViewProps={(data) => ({
                    emptyLabel: "-",
                    tags: data.options.map((relatedOption) => ({
                        label: relatedOption.label,
                        route: routes.models.orderTypes.subpages(relatedOption.orderTypeKey).options.details(relatedOption.optionCode, relatedOption.type),
                    })),
                })}
            />
        </RowGroupWrapper>
    </SpacedItems>
)
