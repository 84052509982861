/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MissingOptionCustomizationDTORoofColorCustomization,
    MissingOptionCustomizationDTORoofColorCustomizationFromJSON,
    MissingOptionCustomizationDTORoofColorCustomizationFromJSONTyped,
    MissingOptionCustomizationDTORoofColorCustomizationToJSON,
    SelectOptionDTOString,
    SelectOptionDTOStringFromJSON,
    SelectOptionDTOStringFromJSONTyped,
    SelectOptionDTOStringToJSON,
} from './';

/**
 * 
 * @export
 * @interface MissingOptionCustomizationDTOMissingOptionCustomizationDTORoofColorCustomization
 */
export interface MissingOptionCustomizationDTOMissingOptionCustomizationDTORoofColorCustomization {
    /**
     * 
     * @type {string}
     * @memberof MissingOptionCustomizationDTOMissingOptionCustomizationDTORoofColorCustomization
     */
    optionCode: string;
    /**
     * 
     * @type {SelectOptionDTOString}
     * @memberof MissingOptionCustomizationDTOMissingOptionCustomizationDTORoofColorCustomization
     */
    modelGeneration: SelectOptionDTOString;
    /**
     * 
     * @type {Array<SelectOptionDTOString>}
     * @memberof MissingOptionCustomizationDTOMissingOptionCustomizationDTORoofColorCustomization
     */
    orderTypes: Array<SelectOptionDTOString>;
    /**
     * 
     * @type {Array<MissingOptionCustomizationDTORoofColorCustomization>}
     * @memberof MissingOptionCustomizationDTOMissingOptionCustomizationDTORoofColorCustomization
     */
    similarCustomizations: Array<MissingOptionCustomizationDTORoofColorCustomization>;
}

export function MissingOptionCustomizationDTOMissingOptionCustomizationDTORoofColorCustomizationFromJSON(json: any): MissingOptionCustomizationDTOMissingOptionCustomizationDTORoofColorCustomization {
    return MissingOptionCustomizationDTOMissingOptionCustomizationDTORoofColorCustomizationFromJSONTyped(json, false);
}

export function MissingOptionCustomizationDTOMissingOptionCustomizationDTORoofColorCustomizationFromJSONTyped(json: any, ignoreDiscriminator: boolean): MissingOptionCustomizationDTOMissingOptionCustomizationDTORoofColorCustomization {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'optionCode': json['optionCode'],
        'modelGeneration': SelectOptionDTOStringFromJSON(json['modelGeneration']),
        'orderTypes': ((json['orderTypes'] as Array<any>).map(SelectOptionDTOStringFromJSON)),
        'similarCustomizations': ((json['similarCustomizations'] as Array<any>).map(MissingOptionCustomizationDTORoofColorCustomizationFromJSON)),
    };
}

export function MissingOptionCustomizationDTOMissingOptionCustomizationDTORoofColorCustomizationToJSON(value?: MissingOptionCustomizationDTOMissingOptionCustomizationDTORoofColorCustomization | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'optionCode': value.optionCode,
        'modelGeneration': SelectOptionDTOStringToJSON(value.modelGeneration),
        'orderTypes': ((value.orderTypes as Array<any>).map(SelectOptionDTOStringToJSON)),
        'similarCustomizations': ((value.similarCustomizations as Array<any>).map(MissingOptionCustomizationDTORoofColorCustomizationToJSON)),
    };
}


