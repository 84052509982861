/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    OptionTagCreateRequestDTORoofColorInternal,
    OptionTagCreateRequestDTORoofColorInternalFromJSONTyped,
    OptionTagCreateRequestDTORoofColorInternalToJSON,
} from './';

/**
 * @type OptionTagCreateRequestDTORoofColor
 * 
 * @export
 */
export type OptionTagCreateRequestDTORoofColor = { type: 'RoofColor' } & OptionTagCreateRequestDTORoofColorInternal;

export function OptionTagCreateRequestDTORoofColorFromJSON(json: any): OptionTagCreateRequestDTORoofColor {
    return OptionTagCreateRequestDTORoofColorFromJSONTyped(json, false);
}

export function OptionTagCreateRequestDTORoofColorFromJSONTyped(json: any, ignoreDiscriminator: boolean): OptionTagCreateRequestDTORoofColor {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    switch (json['type']) {
        case 'RoofColor':
            return {...OptionTagCreateRequestDTORoofColorInternalFromJSONTyped(json, true), type: 'RoofColor'};
        default:
            throw new Error(`No variant of OptionTagCreateRequestDTORoofColor exists with 'type=${json['type']}'`);
    }
}

export function OptionTagCreateRequestDTORoofColorToJSON(value?: OptionTagCreateRequestDTORoofColor | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    switch (value['type']) {
        case 'RoofColor':
            return OptionTagCreateRequestDTORoofColorInternalToJSON(value);
        default:
            throw new Error(`No variant of OptionTagCreateRequestDTORoofColor exists with 'type=${value['type']}'`);
    }
}

