/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    OptionCustomizationAttributesDTOEquipmentInternal,
    OptionCustomizationAttributesDTOEquipmentInternalFromJSONTyped,
    OptionCustomizationAttributesDTOEquipmentInternalToJSON,
} from './';

/**
 * @type OptionCustomizationAttributesDTOEquipment
 * 
 * @export
 */
export type OptionCustomizationAttributesDTOEquipment = { type: 'Equipment' } & OptionCustomizationAttributesDTOEquipmentInternal;

export function OptionCustomizationAttributesDTOEquipmentFromJSON(json: any): OptionCustomizationAttributesDTOEquipment {
    return OptionCustomizationAttributesDTOEquipmentFromJSONTyped(json, false);
}

export function OptionCustomizationAttributesDTOEquipmentFromJSONTyped(json: any, ignoreDiscriminator: boolean): OptionCustomizationAttributesDTOEquipment {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    switch (json['type']) {
        case 'Equipment':
            return {...OptionCustomizationAttributesDTOEquipmentInternalFromJSONTyped(json, true), type: 'Equipment'};
        default:
            throw new Error(`No variant of OptionCustomizationAttributesDTOEquipment exists with 'type=${json['type']}'`);
    }
}

export function OptionCustomizationAttributesDTOEquipmentToJSON(value?: OptionCustomizationAttributesDTOEquipment | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    switch (value['type']) {
        case 'Equipment':
            return OptionCustomizationAttributesDTOEquipmentInternalToJSON(value);
        default:
            throw new Error(`No variant of OptionCustomizationAttributesDTOEquipment exists with 'type=${value['type']}'`);
    }
}

