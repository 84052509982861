import { OrderTypeListRoute } from "./list/OrderTypeListRoute"
import { OrderTypeDetailsRoute } from "./details/OrderTypeDetailsRoute"
import { OrderTypeCreateRoute } from "./create/OrderTypeCreateRoute"
import { orderTypeEquipmentRoutes } from "./equipment/orderTypeEquipmentRoutes"
import { orderTypeExteriorColorsRoutes } from "./exteriorcolors/orderTypeExteriorColorsRoutes"
import { orderTypePaintToSampleExteriorColorsRoutes } from "./painttosampleexteriorcolors/orderTypePaintToSampleExteriorColorsRoutes"
import { orderTypeInteriorsRoutes } from "./interiors/orderTypeInteriorsRoutes"
import { orderTypeRoofColorsRoutes } from "./roofcolors/orderTypeRoofColorsRoutes"
import { orderTypeOptionsRoutes } from "./options/orderTypeOptionsRoutes"

export const orderTypeRoutes = [
    OrderTypeListRoute,
    OrderTypeCreateRoute,
    OrderTypeDetailsRoute,
    ...orderTypeOptionsRoutes,
    ...orderTypeEquipmentRoutes,
    ...orderTypeExteriorColorsRoutes,
    ...orderTypePaintToSampleExteriorColorsRoutes,
    ...orderTypeInteriorsRoutes,
    ...orderTypeRoofColorsRoutes,
]
