import { TableColumnsConfig } from "../../../../components/filteredpagedtable/FilteredPagedTable"
import { ColumnBuilder } from "../../../../components/layouts/table/Table"
import { NamedColorTile } from "../../../../components/tiles/namedcolortile/NamedColorTile"
import { NamedExteriorColorTile } from "../../../../components/tiles/namedexteriorcolortile/NamedExteriorColorTile"
import { ExteriorColorItemDTO, ExteriorColorSortField } from "../../../../generated/pdsapi"
import { OptionImage } from "../../../../components/optionimage/OptionImage"

type ExteriorColorCatalogueColumnBuilder = ColumnBuilder<ExteriorColorItemDTO, ExteriorColorSortField>

export const ExteriorColorCatalogueColumnsConfig: TableColumnsConfig<ExteriorColorItemDTO, ExteriorColorSortField> = () => {
    const imageColumn: ExteriorColorCatalogueColumnBuilder = {
        grow: 3,
        headerCellProps: {
            content: "Preview",
        },
        buildCellContent: (exteriorColorDetails) => <OptionImage imageKey={exteriorColorDetails.imageKey} sizes={"100px"} />,
    }

    const orderTypeCodesColumn: ExteriorColorCatalogueColumnBuilder = {
        width: 80,
        headerCellProps: {
            content: "Order Types",
        },
        buildCellContent: (exteriorColorDetails) => exteriorColorDetails.orderTypeCodes.join(", "),
    }

    const modelYearColumn: ExteriorColorCatalogueColumnBuilder = {
        width: 80,
        headerCellProps: {
            content: "Model Year",
        },
        buildCellContent: (exteriorColorDetails) => exteriorColorDetails.modelYear.toString(),
    }

    const optionCodeColumn: ExteriorColorCatalogueColumnBuilder = {
        grow: 2,
        headerCellProps: {
            content: "Option Code",
        },
        buildCellContent: (exteriorColorDetails) => exteriorColorDetails.optionCode,
    }

    const nameColumn: ExteriorColorCatalogueColumnBuilder = {
        grow: 8,
        headerCellProps: {
            content: "Name",
        },
        buildCellContent: (exteriorColorDetails) =>
            exteriorColorDetails.hexCode ? (
                <NamedExteriorColorTile label={exteriorColorDetails.name ?? "-"} hexCode={exteriorColorDetails.hexCode} />
            ) : (
                (exteriorColorDetails.name ?? "-")
            ),
    }

    const exteriorColorTypeColumn: ExteriorColorCatalogueColumnBuilder = {
        grow: 5,
        headerCellProps: {
            content: "Type",
        },
        buildCellContent: (exteriorColorDetails) => exteriorColorDetails.colorTypeName ?? "-",
    }

    const colorTagColumn: ExteriorColorCatalogueColumnBuilder = {
        grow: 5,
        headerCellProps: {
            content: "Color Tag",
        },
        buildCellContent: (exteriorColorDetails) =>
            exteriorColorDetails.colorTag ? (
                <NamedColorTile hexCode={exteriorColorDetails.colorTag.hexCode} label={exteriorColorDetails.colorTag.label} />
            ) : (
                "-"
            ),
    }

    const modelGenerationColumn: ExteriorColorCatalogueColumnBuilder = {
        grow: 5,
        headerCellProps: {
            content: "Model Generation",
        },
        buildCellContent: (exteriorColorDetails) => exteriorColorDetails.modelGenerationName ?? "-",
    }

    return [imageColumn, orderTypeCodesColumn, modelYearColumn, optionCodeColumn, nameColumn, exteriorColorTypeColumn, colorTagColumn, modelGenerationColumn]
}
