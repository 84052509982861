import { technicalDataMenuItemGroups } from "../components/technicaldatamenu/technicalDataMenuItemGroups"
import _ from "lodash"
import { OptionCustomizationTypeDTO, OptionTagTypeDTO, OrderTypeOptionTypeDTO, PdsMarketplace } from "../generated/pdsapi"

const buildProductRoutes = <KEY>(root: string, convertKeyToPath: (key: KEY) => string) => ({
    list: () => root,
    create: (params?: URLSearchParams) => (params ? `${root}/create?${params.toString()}` : `${root}/create`),
    details: (key: KEY, params?: URLSearchParams) => (params ? `${root}/${convertKeyToPath(key)}?${params.toString()}` : `${root}/${convertKeyToPath(key)}`),
})

const buildProductRoutesWithSort = <KEY>(root: string, convertKeyToPath: (key: KEY) => string) => ({
    ...buildProductRoutes(root, convertKeyToPath),
    sort: () => `${root}/sort`,
})

const buildOrderTypeOptionRoutes = (orderTypeRoot: string, optionPath: string) => ({
    details: (optionCode: string) => `${orderTypeRoot}/${optionPath}/${optionCode.startsWith(":") ? optionCode : encodeURIComponent(optionCode)}`,
    list: () => `${orderTypeRoot}?selectedTab=2&tab2.${_.camelCase(optionPath)}.isExpanded=true`,
    create: (params?: URLSearchParams) => (params ? `${orderTypeRoot}/${optionPath}/create?${params.toString()}` : `${orderTypeRoot}/${optionPath}/create`),
})

const defaultKeyToPath = (key: string) => key

export const routes = {
    models: {
        modelSeries: buildProductRoutesWithSort("/models/model-series", defaultKeyToPath),
        modelGenerations: buildProductRoutesWithSort("/models/model-generations", defaultKeyToPath),
        derivatives: buildProductRoutesWithSort("/models/derivatives", defaultKeyToPath),
        derivativeCategories: buildProductRoutesWithSort("/models/derivative-categories", defaultKeyToPath),
        orderTypes: {
            ...buildProductRoutes("/models/order-types", defaultKeyToPath),
            subpages: (orderTypeKey: string) => {
                const root = buildProductRoutes("/models/order-types", defaultKeyToPath).details(orderTypeKey)
                return {
                    equipment: buildOrderTypeOptionRoutes(root, "equipment"),
                    exteriorColors: buildOrderTypeOptionRoutes(root, "exterior-colors"),
                    interiors: buildOrderTypeOptionRoutes(root, "interiors"),
                    options: {
                        details: (optionCode: string, type: OrderTypeOptionTypeDTO) =>
                            `${root}/options/${type}/${optionCode.startsWith(":") ? optionCode : encodeURIComponent(optionCode)}`,
                        list: () => `${root}?selectedTab=2&tab2.options.isExpanded=true`,
                        create: (params?: URLSearchParams) => (params ? `${root}/options/create?${params.toString()}` : `${root}/${"options"}/create`),
                    },
                    paintToSampleExteriorColors: buildOrderTypeOptionRoutes(root, "paint-to-sample-exterior-colors"),
                    roofColors: buildOrderTypeOptionRoutes(root, "roof-colors"),
                    technicalData: (groupIndex?: number, itemIndex?: number, marketplace?: PdsMarketplace) => {
                        const marketplaceParam = marketplace ? `&tab5.selectedMarketplace=${marketplace}` : ""
                        const groupIndexParam = groupIndex ? `&tab5.selectedTechnicalDataMenuItem.groupIndex=${groupIndex}` : ""
                        const itemIndexParam = itemIndex ? `&tab5.selectedTechnicalDataMenuItem.itemIndex=${itemIndex}` : ""
                        let route = `${root}?selectedTab=5${groupIndexParam}${itemIndexParam}${marketplaceParam}`
                        technicalDataMenuItemGroups.forEach((_, index) => (route += `&tab5.menu.accordion.${index}.isExpanded=${index === groupIndex}`))
                        return route
                    },
                }
            },
        },
    },
    equipment: {
        catalogue: () => "/equipment/catalogue",
        customizations: buildProductRoutes("/equipment/customizations", defaultKeyToPath),
        tags: buildProductRoutesWithSort("/equipment/tags", defaultKeyToPath),
    },
    exteriorColors: {
        catalogue: () => "/exterior-colors/catalogue",
        customizations: buildProductRoutes("/exterior-colors/customizations", defaultKeyToPath),
        tags: buildProductRoutesWithSort("/exterior-colors/tags", defaultKeyToPath),
        customColors: buildProductRoutes("/exterior-colors/custom-exterior-colors", defaultKeyToPath),
    },
    interiors: {
        catalogue: () => "/interiors/catalogue",
        customizations: buildProductRoutes("/interiors/customizations", defaultKeyToPath),
        colorTags: buildProductRoutesWithSort("/interiors/color-tags", defaultKeyToPath),
        customColors: buildProductRoutes("/interiors/custom-interior-colors", defaultKeyToPath),
    },
    options: {
        customizations: buildProductRoutes("/options/customizations", (key: { type: OptionCustomizationTypeDTO; key: string }) => `${key.type}/${key.key}`),
        tags: buildProductRoutesWithSort("/options/tags", (key: { type: OptionTagTypeDTO; key: string }) => `${key.type}/${key.key}`),
    },
    roofColors: {
        catalogue: () => "/roof-colors/catalogue",
        customizations: buildProductRoutes("/roof-colors/customizations", defaultKeyToPath),
        tags: buildProductRoutesWithSort("/roof-colors/tags", defaultKeyToPath),
    },
    other: {
        technicalData: {
            list: () => "/other/technical-data",
        },
    },
}
