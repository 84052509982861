/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    OptionCustomizationCreateRequestDTOInteriorInternal,
    OptionCustomizationCreateRequestDTOInteriorInternalFromJSONTyped,
    OptionCustomizationCreateRequestDTOInteriorInternalToJSON,
} from './';

/**
 * @type OptionCustomizationCreateRequestDTOInterior
 * 
 * @export
 */
export type OptionCustomizationCreateRequestDTOInterior = { type: 'Interior' } & OptionCustomizationCreateRequestDTOInteriorInternal;

export function OptionCustomizationCreateRequestDTOInteriorFromJSON(json: any): OptionCustomizationCreateRequestDTOInterior {
    return OptionCustomizationCreateRequestDTOInteriorFromJSONTyped(json, false);
}

export function OptionCustomizationCreateRequestDTOInteriorFromJSONTyped(json: any, ignoreDiscriminator: boolean): OptionCustomizationCreateRequestDTOInterior {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    switch (json['type']) {
        case 'Interior':
            return {...OptionCustomizationCreateRequestDTOInteriorInternalFromJSONTyped(json, true), type: 'Interior'};
        default:
            throw new Error(`No variant of OptionCustomizationCreateRequestDTOInterior exists with 'type=${json['type']}'`);
    }
}

export function OptionCustomizationCreateRequestDTOInteriorToJSON(value?: OptionCustomizationCreateRequestDTOInterior | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    switch (value['type']) {
        case 'Interior':
            return OptionCustomizationCreateRequestDTOInteriorInternalToJSON(value);
        default:
            throw new Error(`No variant of OptionCustomizationCreateRequestDTOInterior exists with 'type=${value['type']}'`);
    }
}

