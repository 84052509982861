import { propertiesOf } from "../../../../../common/property"
import { EditFormElementWrapper } from "../../../../../components/formelements/EditFormElementWrapper"
import { toOption } from "../../../../../components/formelements/selecttextformelement/Option"
import { getTagFormElement } from "../../../../../components/formelements/tagformelement/TagFormElement"
import { getTextFormElement } from "../../../../../components/formelements/textformelement/TextFormElement"
import { rowGroupsSpacing, RowGroupWrapper } from "../../../../../components/layouts/rowgroupwrapper/RowGroupWrapper"
import { SpacedItems } from "../../../../../components/layouts/spaceditems/SpacedItems"
import { DerivativeCategoryCreateOptionsDTO, DerivativeCategoryCreateRequestDTO } from "../../../../../generated/pdsapi"
import { ProductCreateContentProps } from "../../../../../viewtemplates/create/ProductCreateContainer"
import { CreateDefaultLocalizationsRow } from "../../../../../viewtemplates/create/components/createdefaultlocalizationsrow/CreateDefaultLocalizationsRow"
import { FC } from "react"
import { useLocation } from "react-router-dom"

export const DerivativeCategoryCreateAttributesCard: FC<ProductCreateContentProps<DerivativeCategoryCreateRequestDTO, DerivativeCategoryCreateOptionsDTO>> = ({
    formDisplayData,
}) => {
    const properties = propertiesOf<DerivativeCategoryCreateRequestDTO>()
    const searchParams = new URLSearchParams(useLocation().search)

    return (
        <SpacedItems direction={"column"} lineSpacing={rowGroupsSpacing}>
            <RowGroupWrapper label={"Configuration"}>
                <EditFormElementWrapper
                    label={"Code"}
                    formDisplayData={formDisplayData}
                    field={properties.key}
                    FormElement={getTextFormElement<DerivativeCategoryCreateOptionsDTO, DerivativeCategoryCreateRequestDTO>()}
                    getEditProps={() => ({
                        value: searchParams.get(properties.key._path) ?? "",
                    })}
                />

                <EditFormElementWrapper
                    label={"Model Series"}
                    formDisplayData={formDisplayData}
                    field={properties.modelSeriesKey}
                    FormElement={getTagFormElement<DerivativeCategoryCreateOptionsDTO, DerivativeCategoryCreateRequestDTO>()}
                    getEditProps={(data) => ({
                        value: searchParams.get(properties.modelSeriesKey._path) ?? "",
                        options: data.modelSeries.map(toOption),
                    })}
                />
            </RowGroupWrapper>

            <CreateDefaultLocalizationsRow
                label={"Name"}
                formDisplayData={formDisplayData}
                field={properties.name}
                getEditProps={() => ({
                    value: { de: "", en: "" },
                })}
            />

            <CreateDefaultLocalizationsRow
                label={"Description"}
                formDisplayData={formDisplayData}
                field={properties.description}
                getEditProps={() => ({
                    value: { de: "", en: "" },
                })}
            />
        </SpacedItems>
    )
}
