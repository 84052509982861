import { getEnumConverterGroup, getObjectURLParamConverter, stringConverterGroup } from "../../../../common/useURLSearchParamState"
import { DerivativeCategoryFilterOptionsDTO, DerivativeCategoryItemDTO, DerivativeCategorySortField, SortDirection } from "../../../../generated/pdsapi"
import { derivativeCategoryApi, DerivativeCategoryFilter } from "../../../../pdsapi"
import { getProductListContainer, ProductListContainerConfig } from "../../../../viewtemplates/list/ProductListContainer"
import { DerivativeCategoryListColumnsConfig } from "./columns/DerivativeCategoryListColumns"
import { DerivativeCategoryListFilter } from "./filter/DerivativeCategoryListFilter"
import { DerivativeCategoryListFilterTagsConfig } from "./filter/DerivativeCategoryListFilterTagsConfig"
import { routes } from "../../../../common/routes"
import { Route } from "react-router-dom"

export const DerivativeCategoryProductListContainerConfig: ProductListContainerConfig<
    DerivativeCategoryItemDTO,
    DerivativeCategoryFilter,
    DerivativeCategoryFilterOptionsDTO,
    DerivativeCategorySortField
> = {
    listName: "Derivative Categories",
    createItemLabel: "Add new Derivative Category",

    buildDetailsPath: (derivativeCategory) => routes.models.derivativeCategories.details(derivativeCategory.key),
    buildCreatePath: routes.models.derivativeCategories.create,
    buildSortPath: routes.models.derivativeCategories.sort,

    defaultFilter: {},
    filterUrlParamsConverter: getObjectURLParamConverter<DerivativeCategoryFilter>({
        modelSeriesKeys: stringConverterGroup.optionalArray,
    }),
    defaultSorting: {
        sortField: DerivativeCategorySortField.SORT_INDEX,
        sortDirection: SortDirection.ASC,
    },
    sortingUrlParamsConverter: getObjectURLParamConverter({
        sortField: getEnumConverterGroup(DerivativeCategorySortField).required,
        sortDirection: getEnumConverterGroup(SortDirection).required,
    }),
    fetchPage: derivativeCategoryApi.list.getPage,
    fetchFilterOptions: derivativeCategoryApi.list.getFilterOptions,

    defaultShowsFilter: false,
    Filter: DerivativeCategoryListFilter,
    filterTagsConfig: DerivativeCategoryListFilterTagsConfig,

    columnsConfig: DerivativeCategoryListColumnsConfig,
}

const DerivativeCategoryListContainer = getProductListContainer(DerivativeCategoryProductListContainerConfig)

export const DerivativeCategoryListRoute = <Route path={routes.models.derivativeCategories.list()} Component={DerivativeCategoryListContainer} />
