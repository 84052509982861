import { DefaultCardViewContentWrapper } from "../../../../../components/layouts/defaultcardviewcontentwrapper/DefaultCardViewContentWrapper"
import { ExteriorColorCreateOptionsDTO, ExteriorColorCreateRequestDTO } from "../../../../../generated/pdsapi"
import { exteriorColorApi } from "../../../../../pdsapi"
import { getProductCreateContainer, ProductCreateContainerConfig } from "../../../../../viewtemplates/create/ProductCreateContainer"
import { OrderTypeExteriorColorCreateOptionCard } from "./option/OrderTypeExteriorColorCreateOptionCard"
import { OldOrderTypeOptionKey } from "../../OrderTypeOptionKey"
import { routes } from "../../../../../common/routes"
import { Route, useParams } from "react-router-dom"
import { useOrderTypeSubpageCrumbs } from "../../useOrderTypeSubpageCrumbs"

export const OrderTypeExteriorColorProductCreateContainerConfig: ProductCreateContainerConfig<
    ExteriorColorCreateRequestDTO,
    ExteriorColorCreateOptionsDTO,
    OldOrderTypeOptionKey
> = {
    headline: "Create new ExteriorColor",
    buildDetailsPath: ({ orderTypeKey, optionCode }) => routes.models.orderTypes.subpages(orderTypeKey).exteriorColors.details(optionCode),

    create: (item) =>
        exteriorColorApi.create.create({
            exteriorColorCreateRequestDTO: item,
        }),
    getCreateOptions: exteriorColorApi.create.getCreateOptions,

    Content: (props) => (
        <DefaultCardViewContentWrapper>
            <OrderTypeExteriorColorCreateOptionCard {...props} />
        </DefaultCardViewContentWrapper>
    ),
}

const OrderTypeExteriorColorCreateContainer = getProductCreateContainer(OrderTypeExteriorColorProductCreateContainerConfig)

export const OrderTypeExteriorColorCreateRoute = (
    <Route
        path={routes.models.orderTypes.subpages(":orderTypeKey").exteriorColors.create()}
        Component={() => {
            const params = useParams<{ orderTypeKey: string }>()
            const parentCrumbs = useOrderTypeSubpageCrumbs(params.orderTypeKey)

            return (
                <OrderTypeExteriorColorCreateContainer
                    parentCrumbs={[
                        ...parentCrumbs,
                        {
                            name: "ExteriorColors",
                            path: routes.models.orderTypes.subpages(params.orderTypeKey).exteriorColors.list(),
                        },
                    ]}
                />
            )
        }}
    />
)
