import { DefaultCardViewContentWrapper } from "../../../../components/layouts/defaultcardviewcontentwrapper/DefaultCardViewContentWrapper"
import { DerivativeCategoryCreateOptionsDTO, DerivativeCategoryCreateRequestDTO } from "../../../../generated/pdsapi"
import { derivativeCategoryApi } from "../../../../pdsapi"
import { getProductCreateContainer, ProductCreateContainerConfig } from "../../../../viewtemplates/create/ProductCreateContainer"
import { DerivativeCategoryCreateAttributesCard } from "./attributes/DerivativeCategoryCreateAttributesCard"
import { Route } from "react-router-dom"
import { routes } from "../../../../common/routes"
import { DerivativeCategoryProductListContainerConfig } from "../list/DerivativeCategoryListRoute"

export const DerivativeCategoryProductCreateContainerConfig: ProductCreateContainerConfig<
    DerivativeCategoryCreateRequestDTO,
    DerivativeCategoryCreateOptionsDTO
> = {
    headline: "Create new Derivative Category",
    buildDetailsPath: ({ key }) => routes.models.derivativeCategories.details(key),

    create: (item) =>
        derivativeCategoryApi.create.create({
            derivativeCategoryCreateRequestDTO: item,
        }),
    getCreateOptions: derivativeCategoryApi.create.getCreateOptions,

    Content: (props) => (
        <DefaultCardViewContentWrapper>
            <DerivativeCategoryCreateAttributesCard {...props} />
        </DefaultCardViewContentWrapper>
    ),
}

const DerivativeCategoryCreateContainer = getProductCreateContainer(DerivativeCategoryProductCreateContainerConfig)

export const DerivativeCategoryCreateRoute = (
    <Route
        path={routes.models.derivativeCategories.create()}
        element={
            <DerivativeCategoryCreateContainer
                parentCrumbs={[
                    {
                        name: DerivativeCategoryProductListContainerConfig.listName,
                        path: routes.models.derivativeCategories.list(),
                    },
                ]}
            />
        }
    />
)
