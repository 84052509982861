/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    EquipmentTagCategory,
    EquipmentTagCategoryFromJSON,
    EquipmentTagCategoryToJSON,
    EquipmentTagFilterOptionsDTO,
    EquipmentTagFilterOptionsDTOFromJSON,
    EquipmentTagFilterOptionsDTOToJSON,
    OptionTagSortField,
    OptionTagSortFieldFromJSON,
    OptionTagSortFieldToJSON,
    PageDTOEquipmentTagItemDTO,
    PageDTOEquipmentTagItemDTOFromJSON,
    PageDTOEquipmentTagItemDTOToJSON,
    SortDirection,
    SortDirectionFromJSON,
    SortDirectionToJSON,
} from '../models';

export interface GetEquipmentTagFilterOptionsRequest {
    languageTag: string;
}

export interface GetEquipmentTagsPageRequest {
    languageTag: string;
    offset: number;
    size: number;
    sortField: OptionTagSortField;
    sortDirection: SortDirection;
    search?: string;
    active?: boolean;
    categories?: Array<EquipmentTagCategory>;
}

/**
 * 
 */
export class EquipmentTagListWebControllerApi extends runtime.BaseAPI {

    /**
     * Get options to filter EquipmentTags
     */
    async getEquipmentTagFilterOptionsRaw(requestParameters: GetEquipmentTagFilterOptionsRequest): Promise<runtime.ApiResponse<EquipmentTagFilterOptionsDTO>> {
        if (requestParameters.languageTag === null || requestParameters.languageTag === undefined) {
            throw new runtime.RequiredError('languageTag','Required parameter requestParameters.languageTag was null or undefined when calling getEquipmentTagFilterOptions.');
        }

        const queryParameters: any = {};

        if (requestParameters.languageTag !== undefined) {
            queryParameters['languageTag'] = requestParameters.languageTag;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/web/equipment-tags/list/filter-options`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => EquipmentTagFilterOptionsDTOFromJSON(jsonValue));
    }

    /**
     * Get options to filter EquipmentTags
     */
    async getEquipmentTagFilterOptions(requestParameters: GetEquipmentTagFilterOptionsRequest): Promise<EquipmentTagFilterOptionsDTO> {
        const response = await this.getEquipmentTagFilterOptionsRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get page of EquipmentTags
     */
    async getEquipmentTagsPageRaw(requestParameters: GetEquipmentTagsPageRequest): Promise<runtime.ApiResponse<PageDTOEquipmentTagItemDTO>> {
        if (requestParameters.languageTag === null || requestParameters.languageTag === undefined) {
            throw new runtime.RequiredError('languageTag','Required parameter requestParameters.languageTag was null or undefined when calling getEquipmentTagsPage.');
        }

        if (requestParameters.offset === null || requestParameters.offset === undefined) {
            throw new runtime.RequiredError('offset','Required parameter requestParameters.offset was null or undefined when calling getEquipmentTagsPage.');
        }

        if (requestParameters.size === null || requestParameters.size === undefined) {
            throw new runtime.RequiredError('size','Required parameter requestParameters.size was null or undefined when calling getEquipmentTagsPage.');
        }

        if (requestParameters.sortField === null || requestParameters.sortField === undefined) {
            throw new runtime.RequiredError('sortField','Required parameter requestParameters.sortField was null or undefined when calling getEquipmentTagsPage.');
        }

        if (requestParameters.sortDirection === null || requestParameters.sortDirection === undefined) {
            throw new runtime.RequiredError('sortDirection','Required parameter requestParameters.sortDirection was null or undefined when calling getEquipmentTagsPage.');
        }

        const queryParameters: any = {};

        if (requestParameters.languageTag !== undefined) {
            queryParameters['languageTag'] = requestParameters.languageTag;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.sortField !== undefined) {
            queryParameters['sortField'] = requestParameters.sortField;
        }

        if (requestParameters.sortDirection !== undefined) {
            queryParameters['sortDirection'] = requestParameters.sortDirection;
        }

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.active !== undefined) {
            queryParameters['active'] = requestParameters.active;
        }

        if (requestParameters.categories) {
            queryParameters['categories'] = requestParameters.categories;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/web/equipment-tags/list/page`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PageDTOEquipmentTagItemDTOFromJSON(jsonValue));
    }

    /**
     * Get page of EquipmentTags
     */
    async getEquipmentTagsPage(requestParameters: GetEquipmentTagsPageRequest): Promise<PageDTOEquipmentTagItemDTO> {
        const response = await this.getEquipmentTagsPageRaw(requestParameters);
        return await response.value();
    }

}
