import { TagFormElementView } from "../../../../../../../components/formelements/tagformelement/TagFormElement"
import { TagsFormElementView } from "../../../../../../../components/formelements/tagsformelement/TagsFormElement"
import { TextFormElementView } from "../../../../../../../components/formelements/textformelement/TextFormElement"
import { rowGroupsSpacing, RowGroupWrapper } from "../../../../../../../components/layouts/rowgroupwrapper/RowGroupWrapper"
import { SpacedItems } from "../../../../../../../components/layouts/spaceditems/SpacedItems"
import { LinkedEquipmentCustomizationDTOCustomization } from "../../../../../../../generated/pdsapi"
import { routes } from "../../../../../../../common/routes"

export const EquipmentLinkedCustomizationCardContent = ({ customization }: { customization: LinkedEquipmentCustomizationDTOCustomization }) => (
    <SpacedItems direction={"column"} lineSpacing={rowGroupsSpacing}>
        <RowGroupWrapper label={"Configuration"}>
            <TagFormElementView
                label={undefined}
                tag={{
                    label: "Jump to customization",
                    route: routes.equipment.customizations.details(customization.key),
                }}
            />
        </RowGroupWrapper>
        <RowGroupWrapper label={"Name"}>
            {(["de", "en"] as ("en" | "de")[]).map((languageTag) => (
                <TextFormElementView key={languageTag} label={`Name - ${languageTag}`} value={customization.value?.name[languageTag]} />
            ))}
        </RowGroupWrapper>
        <RowGroupWrapper label={"Attributes"} childrenSize={12}>
            <TagsFormElementView
                label={undefined}
                emptyLabel={"-"}
                tags={
                    customization.value?.tags.map((tag) => ({
                        label: tag.label,
                        route: routes.equipment.tags.details(tag.key),
                    })) ?? []
                }
            />
        </RowGroupWrapper>
    </SpacedItems>
)
