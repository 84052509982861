import { getEnumConverterGroup, getObjectURLParamConverter, numberConverterGroup, stringConverterGroup } from "../../../common/useURLSearchParamState"
import { ExteriorColorFilterOptionsDTO, ExteriorColorItemDTO, ExteriorColorSortField, ExteriorColorType, SortDirection } from "../../../generated/pdsapi"
import { exteriorColorApi, ExteriorColorFilter } from "../../../pdsapi"
import { getProductListContainer, ProductListContainerConfig } from "../../../viewtemplates/list/ProductListContainer"
import { ExteriorColorCatalogueColumnsConfig } from "./columns/ExteriorColorCatalogueColumns"
import { ExteriorColorCatalogueFilter } from "./filter/ExteriorColorCatalogueFilter"
import { ExteriorColorCatalogueFilterTagsConfig } from "./filter/ExteriorColorCatalogueFilterTagsConfig"
import { routes } from "../../../common/routes"
import { Route } from "react-router-dom"

export const ExteriorColorCatalogueContainerConfig: ProductListContainerConfig<
    ExteriorColorItemDTO,
    ExteriorColorFilter,
    ExteriorColorFilterOptionsDTO,
    ExteriorColorSortField
> = {
    listName: "Exterior Color Catalogue",
    itemsName: "Exterior Colors",

    buildDetailsPath: (item) => routes.models.orderTypes.subpages(item.orderTypeKey).exteriorColors.details(item.optionCode),
    buildCreatePath: undefined,
    buildSortPath: undefined,

    defaultFilter: {},
    filterUrlParamsConverter: getObjectURLParamConverter<ExteriorColorFilter>({
        modelGenerationKeys: stringConverterGroup.optionalArray,
        modelYears: numberConverterGroup.optionalArray,
        orderTypeKeys: stringConverterGroup.optionalArray,
        exteriorColorTypes: getEnumConverterGroup(ExteriorColorType).optionalArray,
        colorTagKeys: stringConverterGroup.optionalArray,
    }),
    defaultSorting: {
        sortField: ExteriorColorSortField.SORT_INDEX,
        sortDirection: SortDirection.ASC,
    },
    sortingUrlParamsConverter: getObjectURLParamConverter({
        sortField: getEnumConverterGroup(ExteriorColorSortField).required,
        sortDirection: getEnumConverterGroup(SortDirection).required,
    }),
    fetchPage: exteriorColorApi.catalogue.getPage,
    fetchFilterOptions: exteriorColorApi.catalogue.getFilterOptions,

    defaultShowsFilter: true,
    Filter: ExteriorColorCatalogueFilter,
    filterTagsConfig: ExteriorColorCatalogueFilterTagsConfig,

    columnsConfig: ExteriorColorCatalogueColumnsConfig,
}

const ExteriorColorCatalogueContainer = getProductListContainer(ExteriorColorCatalogueContainerConfig)

export const ExteriorColorCatalogueRoute = <Route path={routes.exteriorColors.catalogue()} Component={ExteriorColorCatalogueContainer} />
