import { routes } from "../../../../common/routes"
import { booleanConverterGroup, getEnumConverterGroup, getObjectURLParamConverter } from "../../../../common/useURLSearchParamState"
import {
    EquipmentTagCategory,
    OptionTagFilterOptionsDTO,
    OptionTagItemDTO,
    OptionTagSortField,
    OptionTagTypeDTO,
    SortDirection,
} from "../../../../generated/pdsapi"
import { optionTagApi, OptionTagFilter } from "../../../../pdsapi"
import { getProductListContainer, ProductListContainerConfig } from "../../../../viewtemplates/list/ProductListContainer"
import { OptionTagListColumnsConfig } from "./columns/OptionTagListColumns"
import { OptionTagListFilter } from "./filter/OptionTagListFilter"
import { OptionTagListFilterTagsConfig } from "./filter/OptionTagListFilterTagsConfig"
import { optionTagTypeToEnum } from "../optionTagTypes"
import { Route } from "react-router-dom"

export const OptionTagProductListContainerConfig: ProductListContainerConfig<OptionTagItemDTO, OptionTagFilter, OptionTagFilterOptionsDTO, OptionTagSortField> =
    {
        listName: "Option Tags",
        createItemLabel: "Add new Option Tag",

        buildDetailsPath: (optionTag) =>
            routes.options.tags.details({
                type: optionTagTypeToEnum(optionTag.type),
                key: optionTag.key,
            }),
        buildCreatePath: routes.options.tags.create,
        buildSortPath: routes.options.tags.sort,

        defaultFilter: {},
        filterUrlParamsConverter: getObjectURLParamConverter<OptionTagFilter>({
            types: getEnumConverterGroup(OptionTagTypeDTO).optionalArray,
            active: booleanConverterGroup.optional,
            equipmentTagCategories: getEnumConverterGroup(EquipmentTagCategory).optionalArray,
        }),
        defaultSorting: {
            sortField: OptionTagSortField.SORT_INDEX,
            sortDirection: SortDirection.ASC,
        },
        sortingUrlParamsConverter: getObjectURLParamConverter({
            sortField: getEnumConverterGroup(OptionTagSortField).required,
            sortDirection: getEnumConverterGroup(SortDirection).required,
        }),
        fetchPage: optionTagApi.list.getPage,
        fetchFilterOptions: optionTagApi.list.getFilterOptions,

        defaultShowsFilter: false,
        Filter: OptionTagListFilter,
        filterTagsConfig: OptionTagListFilterTagsConfig,

        columnsConfig: OptionTagListColumnsConfig,
    }

const OptionTagListContainer = getProductListContainer(OptionTagProductListContainerConfig)

export const OptionTagListRoute = <Route path={routes.options.tags.list()} Component={OptionTagListContainer} />
