import { propertiesOf } from "../../../../../../common/property"
import { FormElementWrapper } from "../../../../../../components/formelements/FormElementWrapper"
import { toGroupedOption } from "../../../../../../components/formelements/groupedselecttextformelement/GroupedSelectOption"
import { getGroupedTagFormElement } from "../../../../../../components/formelements/groupedtagformelement/GroupedTagFormElement"
import { toOption } from "../../../../../../components/formelements/selecttextformelement/Option"
import { getSelectTextFormElement } from "../../../../../../components/formelements/selecttextformelement/SelectTextFormElement"
import { getTextFormElement } from "../../../../../../components/formelements/textformelement/TextFormElement"
import { rowGroupsSpacing, RowGroupWrapper } from "../../../../../../components/layouts/rowgroupwrapper/RowGroupWrapper"
import { SpacedItems } from "../../../../../../components/layouts/spaceditems/SpacedItems"
import { DerivativeAttributesDTO, DerivativeAttributesUpdateDTO } from "../../../../../../generated/pdsapi"
import { ProductDetailsCardContentProps } from "../../../../../../viewtemplates/details/cards/ProductDetailsCard"
import { DetailsDefaultLocalizationsFormRow } from "../../../../../../viewtemplates/details/cards/components/defaultlocalizationsformrow/DetailsDefaultLocalizationsFormRow"
import { FC } from "react"
import { routes } from "../../../../../../common/routes"
import { BasicKey } from "../../../../../../viewtemplates/details/ProductDetailsContainer"

export const DerivativeDetailsAttributesCardContent: FC<ProductDetailsCardContentProps<DerivativeAttributesDTO, DerivativeAttributesUpdateDTO, BasicKey>> = ({
    formDisplayData,
}) => {
    const properties = propertiesOf<DerivativeAttributesUpdateDTO>()

    return (
        <SpacedItems direction={"column"} lineSpacing={rowGroupsSpacing}>
            <RowGroupWrapper label={"Configuration"}>
                <FormElementWrapper
                    label={"Code"}
                    formDisplayData={formDisplayData}
                    field={properties.key}
                    FormElement={getTextFormElement<DerivativeAttributesDTO, DerivativeAttributesUpdateDTO>()}
                    getViewProps={(data) => ({
                        value: data.key,
                        showAsHint: false,
                    })}
                    getEditProps={(data) => ({
                        value: data.key,
                        readOnly: true,
                    })}
                />

                <FormElementWrapper
                    label={"Derivative Category"}
                    formDisplayData={formDisplayData}
                    field={properties.derivativeCategory}
                    FormElement={getGroupedTagFormElement<DerivativeAttributesDTO, DerivativeAttributesUpdateDTO>()}
                    getViewProps={(data) => ({
                        text: data.derivativeCategory.value.label,
                        tag: {
                            label: data.derivativeCategory.value.label,
                            route: routes.models.derivativeCategories.details(data.derivativeCategory.value.key),
                        },
                    })}
                    getEditProps={(data) => ({
                        value: data.derivativeCategory.value.key,
                        options: data.derivativeCategory.categorizedOptions.map(toGroupedOption),
                    })}
                />

                <FormElementWrapper
                    label={"Body Type"}
                    formDisplayData={formDisplayData}
                    field={properties.bodyType}
                    FormElement={getSelectTextFormElement<DerivativeAttributesDTO, DerivativeAttributesUpdateDTO>()}
                    getViewProps={(data) => ({
                        text: data.bodyType.value.label,
                    })}
                    getEditProps={(data) => ({
                        value: data.bodyType.value.key,
                        options: data.bodyType.options.map(toOption),
                    })}
                />
            </RowGroupWrapper>

            <DetailsDefaultLocalizationsFormRow
                label={"Name"}
                formDisplayData={formDisplayData}
                field={properties.name}
                getViewProps={(data) => ({
                    value: data.name,
                })}
                getEditProps={(data) => ({
                    value: data.name,
                })}
            />
        </SpacedItems>
    )
}
