/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    OptionCustomizationAttributesDTOInteriorInternal,
    OptionCustomizationAttributesDTOInteriorInternalFromJSONTyped,
    OptionCustomizationAttributesDTOInteriorInternalToJSON,
} from './';

/**
 * @type OptionCustomizationAttributesDTOInterior
 * 
 * @export
 */
export type OptionCustomizationAttributesDTOInterior = { type: 'Interior' } & OptionCustomizationAttributesDTOInteriorInternal;

export function OptionCustomizationAttributesDTOInteriorFromJSON(json: any): OptionCustomizationAttributesDTOInterior {
    return OptionCustomizationAttributesDTOInteriorFromJSONTyped(json, false);
}

export function OptionCustomizationAttributesDTOInteriorFromJSONTyped(json: any, ignoreDiscriminator: boolean): OptionCustomizationAttributesDTOInterior {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    switch (json['type']) {
        case 'Interior':
            return {...OptionCustomizationAttributesDTOInteriorInternalFromJSONTyped(json, true), type: 'Interior'};
        default:
            throw new Error(`No variant of OptionCustomizationAttributesDTOInterior exists with 'type=${json['type']}'`);
    }
}

export function OptionCustomizationAttributesDTOInteriorToJSON(value?: OptionCustomizationAttributesDTOInterior | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    switch (value['type']) {
        case 'Interior':
            return OptionCustomizationAttributesDTOInteriorInternalToJSON(value);
        default:
            throw new Error(`No variant of OptionCustomizationAttributesDTOInterior exists with 'type=${value['type']}'`);
    }
}

