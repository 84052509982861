/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    OptionCustomizationAttributesUpdateDTOEquipmentValue,
    OptionCustomizationAttributesUpdateDTOEquipmentValueFromJSON,
    OptionCustomizationAttributesUpdateDTOEquipmentValueFromJSONTyped,
    OptionCustomizationAttributesUpdateDTOEquipmentValueToJSON,
    OptionCustomizationConditionalValueUpdateDTOModelYearRange,
    OptionCustomizationConditionalValueUpdateDTOModelYearRangeFromJSON,
    OptionCustomizationConditionalValueUpdateDTOModelYearRangeFromJSONTyped,
    OptionCustomizationConditionalValueUpdateDTOModelYearRangeToJSON,
} from './';

/**
 * 
 * @export
 * @interface OptionCustomizationConditionalValueUpdateDTOOptionCustomizationAttributesUpdateDTOEquipmentValue
 */
export interface OptionCustomizationConditionalValueUpdateDTOOptionCustomizationAttributesUpdateDTOEquipmentValue {
    /**
     * 
     * @type {string}
     * @memberof OptionCustomizationConditionalValueUpdateDTOOptionCustomizationAttributesUpdateDTOEquipmentValue
     */
    key: string;
    /**
     * 
     * @type {OptionCustomizationConditionalValueUpdateDTOModelYearRange}
     * @memberof OptionCustomizationConditionalValueUpdateDTOOptionCustomizationAttributesUpdateDTOEquipmentValue
     */
    modelYearRange: OptionCustomizationConditionalValueUpdateDTOModelYearRange;
    /**
     * 
     * @type {Array<string>}
     * @memberof OptionCustomizationConditionalValueUpdateDTOOptionCustomizationAttributesUpdateDTOEquipmentValue
     */
    derivativeKeys?: Array<string>;
    /**
     * 
     * @type {OptionCustomizationAttributesUpdateDTOEquipmentValue}
     * @memberof OptionCustomizationConditionalValueUpdateDTOOptionCustomizationAttributesUpdateDTOEquipmentValue
     */
    value: OptionCustomizationAttributesUpdateDTOEquipmentValue;
}

export function OptionCustomizationConditionalValueUpdateDTOOptionCustomizationAttributesUpdateDTOEquipmentValueFromJSON(json: any): OptionCustomizationConditionalValueUpdateDTOOptionCustomizationAttributesUpdateDTOEquipmentValue {
    return OptionCustomizationConditionalValueUpdateDTOOptionCustomizationAttributesUpdateDTOEquipmentValueFromJSONTyped(json, false);
}

export function OptionCustomizationConditionalValueUpdateDTOOptionCustomizationAttributesUpdateDTOEquipmentValueFromJSONTyped(json: any, ignoreDiscriminator: boolean): OptionCustomizationConditionalValueUpdateDTOOptionCustomizationAttributesUpdateDTOEquipmentValue {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'key': json['key'],
        'modelYearRange': OptionCustomizationConditionalValueUpdateDTOModelYearRangeFromJSON(json['modelYearRange']),
        'derivativeKeys': !exists(json, 'derivativeKeys') ? undefined : json['derivativeKeys'],
        'value': OptionCustomizationAttributesUpdateDTOEquipmentValueFromJSON(json['value']),
    };
}

export function OptionCustomizationConditionalValueUpdateDTOOptionCustomizationAttributesUpdateDTOEquipmentValueToJSON(value?: OptionCustomizationConditionalValueUpdateDTOOptionCustomizationAttributesUpdateDTOEquipmentValue | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'key': value.key,
        'modelYearRange': OptionCustomizationConditionalValueUpdateDTOModelYearRangeToJSON(value.modelYearRange),
        'derivativeKeys': value.derivativeKeys,
        'value': OptionCustomizationAttributesUpdateDTOEquipmentValueToJSON(value.value),
    };
}


