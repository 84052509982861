import { TableFilterProps } from "../../../../../components/filteredpagedtable/FilteredPagedTable"
import { toOption } from "../../../../../components/formelements/selecttextformelement/Option"
import { ColumnGroupsWrapper } from "../../../../../components/layouts/columngroupswrapper/ColumnGroupsWrapper"
import { ColumnGroupWrapper } from "../../../../../components/layouts/columngroupwrapper/ColumnGroupWrapper"
import { InteriorMaterial, OptionCustomizationFilterOptionsDTO, OptionCustomizationTypeDTO } from "../../../../../generated/pdsapi"
import { OptionCustomizationFilter } from "../../../../../pdsapi"
import { MultiSelect } from "@finder/ui-kit"
import { FunctionComponent } from "react"
import { toGroupedOption } from "../../../../../components/formelements/groupedselecttextformelement/GroupedSelectOption"

export const OptionCustomizationListFilter: FunctionComponent<TableFilterProps<OptionCustomizationFilter, OptionCustomizationFilterOptionsDTO>> = ({
    filter,
    setFilter,
    filterOptions,
}) => {
    const typeFilter = (
        <MultiSelect
            label={"Types"}
            placeholder={"All"}
            clearable={true}
            searchable={true}
            options={filterOptions?.types?.map(toOption)}
            value={filter.types ?? []}
            onChange={(types) =>
                setFilter((filter) => ({
                    ...filter,
                    types: types.length === 0 ? undefined : (types as OptionCustomizationTypeDTO[]),
                }))
            }
        />
    )
    const modelGenerationFilter = (
        <MultiSelect
            label={"Model Generations"}
            placeholder={"All"}
            clearable={true}
            searchable={true}
            options={filterOptions?.modelGenerations?.map(toOption)}
            value={filter.modelGenerationKeys ?? []}
            onChange={(modelGenerationKeys) =>
                setFilter((filter) => ({
                    ...filter,
                    modelGenerationKeys: modelGenerationKeys.length === 0 ? undefined : modelGenerationKeys,
                }))
            }
        />
    )
    const equipmentTagFilter = (
        <MultiSelect
            label={"Equipment Tags"}
            placeholder={"All"}
            clearable={true}
            searchable={true}
            options={filterOptions?.categorizedEquipmentTags?.map(toGroupedOption)}
            value={filter.equipmentTagKeys ?? []}
            onChange={(equipmentTagKeys) =>
                setFilter((filter) => ({
                    ...filter,
                    equipmentTagKeys: equipmentTagKeys.length === 0 ? undefined : equipmentTagKeys,
                }))
            }
        />
    )
    const interiorColorTagFilter = (
        <MultiSelect
            label={"Interior Color Tags"}
            placeholder={"All"}
            clearable={true}
            searchable={true}
            options={filterOptions?.interiorColorTags?.map(toOption)}
            value={filter.interiorColorTagKeys ?? []}
            onChange={(interiorColorTagKeys) =>
                setFilter((filter) => ({
                    ...filter,
                    interiorColorTagKeys: interiorColorTagKeys.length === 0 ? undefined : interiorColorTagKeys,
                }))
            }
        />
    )
    const exteriorColorTagFilter = (
        <MultiSelect
            label={"Exterior Color Tags"}
            placeholder={"All"}
            clearable={true}
            searchable={true}
            options={filterOptions?.exteriorColorTags?.map(toOption)}
            value={filter.exteriorColorTagKeys ?? []}
            onChange={(exteriorColorTagKeys) =>
                setFilter((filter) => ({
                    ...filter,
                    exteriorColorTagKeys: exteriorColorTagKeys.length === 0 ? undefined : exteriorColorTagKeys,
                }))
            }
        />
    )
    const roofColorTagFilter = (
        <MultiSelect
            label={"Roof Color Tags"}
            placeholder={"All"}
            clearable={true}
            searchable={true}
            options={filterOptions?.roofColorTags?.map(toOption)}
            value={filter.roofColorTagKeys ?? []}
            onChange={(roofColorTagKeys) =>
                setFilter((filter) => ({
                    ...filter,
                    roofColorTagKeys: roofColorTagKeys.length === 0 ? undefined : roofColorTagKeys,
                }))
            }
        />
    )
    const interiorMaterialFilter = (
        <MultiSelect
            label={"Interior Materials"}
            placeholder={"All"}
            clearable={true}
            searchable={true}
            options={filterOptions?.interiorMaterials?.map(toOption)}
            value={filter.interiorMaterials ?? []}
            onChange={(interiorMaterials) =>
                setFilter((filter) => ({
                    ...filter,
                    interiorMaterials: interiorMaterials.length === 0 ? undefined : (interiorMaterials as InteriorMaterial[]),
                }))
            }
        />
    )

    return (
        <ColumnGroupsWrapper>
            <ColumnGroupWrapper label={"Configuration"}>
                {typeFilter}
                {modelGenerationFilter}
            </ColumnGroupWrapper>
            <ColumnGroupWrapper label={"Tag"}>
                {equipmentTagFilter}
                {interiorColorTagFilter}
                {exteriorColorTagFilter}
                {roofColorTagFilter}
            </ColumnGroupWrapper>
            <ColumnGroupWrapper label={"Other"}>{interiorMaterialFilter}</ColumnGroupWrapper>
        </ColumnGroupsWrapper>
    )
}
