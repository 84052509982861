/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    OptionTagAttributesDTOEquipment,
    OptionTagAttributesDTOExteriorColor,
    OptionTagAttributesDTOInteriorColor,
    OptionTagAttributesDTORoofColor,
    OptionTagAttributesDTOEquipmentFromJSONTyped,
    OptionTagAttributesDTOEquipmentToJSON,
    OptionTagAttributesDTOExteriorColorFromJSONTyped,
    OptionTagAttributesDTOExteriorColorToJSON,
    OptionTagAttributesDTOInteriorColorFromJSONTyped,
    OptionTagAttributesDTOInteriorColorToJSON,
    OptionTagAttributesDTORoofColorFromJSONTyped,
    OptionTagAttributesDTORoofColorToJSON,
} from './';

/**
 * @type OptionTagAttributesDTO
 * 
 * @export
 */
export type OptionTagAttributesDTO = { type: 'Equipment' } & OptionTagAttributesDTOEquipment | { type: 'ExteriorColor' } & OptionTagAttributesDTOExteriorColor | { type: 'InteriorColor' } & OptionTagAttributesDTOInteriorColor | { type: 'RoofColor' } & OptionTagAttributesDTORoofColor;

export function OptionTagAttributesDTOFromJSON(json: any): OptionTagAttributesDTO {
    return OptionTagAttributesDTOFromJSONTyped(json, false);
}

export function OptionTagAttributesDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): OptionTagAttributesDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    switch (json['type']) {
        case 'Equipment':
            return {...OptionTagAttributesDTOEquipmentFromJSONTyped(json, true), type: 'Equipment'};
        case 'ExteriorColor':
            return {...OptionTagAttributesDTOExteriorColorFromJSONTyped(json, true), type: 'ExteriorColor'};
        case 'InteriorColor':
            return {...OptionTagAttributesDTOInteriorColorFromJSONTyped(json, true), type: 'InteriorColor'};
        case 'RoofColor':
            return {...OptionTagAttributesDTORoofColorFromJSONTyped(json, true), type: 'RoofColor'};
        default:
            throw new Error(`No variant of OptionTagAttributesDTO exists with 'type=${json['type']}'`);
    }
}

export function OptionTagAttributesDTOToJSON(value?: OptionTagAttributesDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    switch (value['type']) {
        case 'Equipment':
            return OptionTagAttributesDTOEquipmentToJSON(value);
        case 'ExteriorColor':
            return OptionTagAttributesDTOExteriorColorToJSON(value);
        case 'InteriorColor':
            return OptionTagAttributesDTOInteriorColorToJSON(value);
        case 'RoofColor':
            return OptionTagAttributesDTORoofColorToJSON(value);
        default:
            throw new Error(`No variant of OptionTagAttributesDTO exists with 'type=${value['type']}'`);
    }
}

