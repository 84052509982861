/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SelectOptionDTOExteriorColorGroup,
    SelectOptionDTOExteriorColorGroupFromJSON,
    SelectOptionDTOExteriorColorGroupFromJSONTyped,
    SelectOptionDTOExteriorColorGroupToJSON,
} from './';

/**
 * 
 * @export
 * @interface ExteriorColorCreateOptionsDTO
 */
export interface ExteriorColorCreateOptionsDTO {
    /**
     * 
     * @type {Array<SelectOptionDTOExteriorColorGroup>}
     * @memberof ExteriorColorCreateOptionsDTO
     */
    exteriorColorGroups: Array<SelectOptionDTOExteriorColorGroup>;
}

export function ExteriorColorCreateOptionsDTOFromJSON(json: any): ExteriorColorCreateOptionsDTO {
    return ExteriorColorCreateOptionsDTOFromJSONTyped(json, false);
}

export function ExteriorColorCreateOptionsDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExteriorColorCreateOptionsDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'exteriorColorGroups': ((json['exteriorColorGroups'] as Array<any>).map(SelectOptionDTOExteriorColorGroupFromJSON)),
    };
}

export function ExteriorColorCreateOptionsDTOToJSON(value?: ExteriorColorCreateOptionsDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'exteriorColorGroups': ((value.exteriorColorGroups as Array<any>).map(SelectOptionDTOExteriorColorGroupToJSON)),
    };
}


