import { getEnumConverterGroup, getObjectURLParamConverter, stringConverterGroup, URLParamConverter } from "../../../../common/useURLSearchParamState"
import { CustomInteriorColorFilterOptionsDTO, CustomInteriorColorItemDTO, SortDirection } from "../../../../generated/pdsapi"
import { customInteriorColorApi, CustomInteriorColorFilter } from "../../../../pdsapi"
import { getProductListContainer, ProductListContainerConfig } from "../../../../viewtemplates/list/ProductListContainer"
import { CustomInteriorColorListColumnsConfig } from "./columns/CustomInteriorColorListColumns"
import { CustomInteriorColorListFilter } from "./filter/CustomInteriorColorListFilter"
import { CustomInteriorColorListFilterTagsConfig } from "./filter/CustomInteriorColorListFilterTagsConfig"
import { routes } from "../../../../common/routes"
import { Route } from "react-router-dom"

export const CustomInteriorColorProductListContainerConfig: ProductListContainerConfig<
    CustomInteriorColorItemDTO,
    CustomInteriorColorFilter,
    CustomInteriorColorFilterOptionsDTO,
    unknown
> = {
    listName: "Custom Interior Colors",
    createItemLabel: "Add new Custom Interior Color",

    buildDetailsPath: (customInteriorColor) => routes.interiors.customColors.details(customInteriorColor.key),
    buildCreatePath: routes.interiors.customColors.create,
    buildSortPath: undefined,

    defaultFilter: {},
    filterUrlParamsConverter: getObjectURLParamConverter<CustomInteriorColorFilter>({
        interiorColorTagKeys: stringConverterGroup.optionalArray,
    }),
    defaultSorting: {
        sortField: undefined,
        sortDirection: SortDirection.ASC,
    },
    sortingUrlParamsConverter: getObjectURLParamConverter({
        sortField: stringConverterGroup.optional as URLParamConverter<unknown>,
        sortDirection: getEnumConverterGroup(SortDirection).required,
    }),
    fetchPage: customInteriorColorApi.list.getPage,
    fetchFilterOptions: customInteriorColorApi.list.getFilterOptions,

    defaultShowsFilter: false,
    Filter: CustomInteriorColorListFilter,
    filterTagsConfig: CustomInteriorColorListFilterTagsConfig,

    columnsConfig: CustomInteriorColorListColumnsConfig,
}

const CustomInteriorColorListContainer = getProductListContainer(CustomInteriorColorProductListContainerConfig)

export const CustomInteriorColorListRoute = <Route path={routes.interiors.customColors.list()} Component={CustomInteriorColorListContainer} />
