import { DefaultCardViewContentWrapper } from "../../../../components/layouts/defaultcardviewcontentwrapper/DefaultCardViewContentWrapper"
import { EquipmentTagCreateOptionsDTO, EquipmentTagCreateRequestDTO } from "../../../../generated/pdsapi"
import { equipmentTagApi } from "../../../../pdsapi"
import { getProductCreateContainer, ProductCreateContainerConfig } from "../../../../viewtemplates/create/ProductCreateContainer"
import { EquipmentTagCreateAttributesCard } from "./attributes/EquipmentTagCreateAttributesCard"
import { routes } from "../../../../common/routes"
import { EquipmentTagProductListContainerConfig } from "../list/EquipmentTagListRoute"
import { Route } from "react-router-dom"

export const EquipmentTagProductCreateContainerConfig: ProductCreateContainerConfig<EquipmentTagCreateRequestDTO, EquipmentTagCreateOptionsDTO> = {
    headline: "Create new Equipment Tag",
    buildDetailsPath: ({ key }) => routes.equipment.tags.details(key),

    create: (item) =>
        equipmentTagApi.create.create({
            equipmentTagCreateRequestDTO: item,
        }),
    getCreateOptions: equipmentTagApi.create.getCreateOptions,

    Content: (props) => (
        <DefaultCardViewContentWrapper>
            <EquipmentTagCreateAttributesCard {...props} />
        </DefaultCardViewContentWrapper>
    ),
}

const EquipmentTagCreateContainer = getProductCreateContainer(EquipmentTagProductCreateContainerConfig)

export const EquipmentTagCreateRoute = (
    <Route
        path={routes.equipment.tags.create()}
        element={
            <EquipmentTagCreateContainer
                parentCrumbs={[
                    {
                        name: EquipmentTagProductListContainerConfig.listName,
                        path: routes.equipment.tags.list(),
                    },
                ]}
            />
        }
    />
)
