import { DefaultCardViewContentWrapper } from "../../../../../components/layouts/defaultcardviewcontentwrapper/DefaultCardViewContentWrapper"
import { RoofColorCreateRequestDTO } from "../../../../../generated/pdsapi"
import { roofColorApi } from "../../../../../pdsapi"
import { getProductCreateContainer, ProductCreateContainerConfig } from "../../../../../viewtemplates/create/ProductCreateContainer"
import { OrderTypeRoofColorCreateOptionCard } from "./option/OrderTypeRoofColorCreateOptionCard"
import { routes } from "../../../../../common/routes"
import { useOrderTypeSubpageCrumbs } from "../../useOrderTypeSubpageCrumbs"
import { Route, useParams } from "react-router-dom"

export const OrderTypeRoofColorProductCreateContainerConfig: ProductCreateContainerConfig<
    RoofColorCreateRequestDTO,
    unknown,
    { orderTypeKey: string; optionCode: string }
> = {
    headline: "Create new Roof Color",
    buildDetailsPath: ({ orderTypeKey, optionCode }: { orderTypeKey: string; optionCode: string }) =>
        routes.models.orderTypes.subpages(orderTypeKey).roofColors.details(optionCode),

    create: (item) =>
        roofColorApi.create.create({
            roofColorCreateRequestDTO: item,
        }),
    getCreateOptions: async () => ({}),

    Content: (props) => (
        <DefaultCardViewContentWrapper>
            <OrderTypeRoofColorCreateOptionCard {...props} />
        </DefaultCardViewContentWrapper>
    ),
}

const OrderTypeRoofColorCreateContainer = getProductCreateContainer(OrderTypeRoofColorProductCreateContainerConfig)

export const OrderTypeRoofColorCreateRoute = (
    <Route
        path={routes.models.orderTypes.subpages(":orderTypeKey").roofColors.create()}
        Component={() => {
            const params = useParams<{ orderTypeKey: string }>()
            const parentCrumbs = useOrderTypeSubpageCrumbs(params.orderTypeKey)

            return (
                <OrderTypeRoofColorCreateContainer
                    parentCrumbs={[
                        ...parentCrumbs,
                        {
                            name: "Roof Colors",
                            path: routes.models.orderTypes.subpages(params.orderTypeKey).roofColors.list(),
                        },
                    ]}
                />
            )
        }}
    />
)
