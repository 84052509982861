import { modelSeriesApi } from "../../../../pdsapi"
import { BasicKey, getProductDetailsContainer, ProductDetailsContainerConfig } from "../../../../viewtemplates/details/ProductDetailsContainer"
import { ProductDetailsCard } from "../../../../viewtemplates/details/cards/ProductDetailsCard"
import { DetailsLocalizationCard } from "../../../../viewtemplates/details/cards/localizationcard/DetailsLocalizationCard"
import { ProductDetailsTab } from "../../../../viewtemplates/details/tabs/ProductDetailsTab"
import { ModelSeriesDetailsAttributesCardContent } from "./data/attributes/ModelSeriesDetailsAttributesCardContent"
import { ModelSeriesLinksCardContent } from "./data/links/ModelSeriesLinksCardContent"
import { Route, useParams } from "react-router-dom"
import { routes } from "../../../../common/routes"
import { ModelSeriesProductListContainerConfig } from "../list/ModelSeriesListRoute"

export const ModelSeriesProductDetailsContainerConfig: ProductDetailsContainerConfig<BasicKey> = {
    getBaseInformation: modelSeriesApi.details.getBaseInformation,
    deleteByKey: modelSeriesApi.details.deleteByKey,
    tabsConfig: [
        {
            label: "Data",
            Content: (props) => (
                <ProductDetailsTab>
                    <ProductDetailsCard
                        {...props}
                        label={"Attributes"}
                        getData={modelSeriesApi.details.getAttributes}
                        updateData={({ key, update }) =>
                            modelSeriesApi.details.updateAttributes({
                                key,
                                modelSeriesAttributesUpdateDTO: update,
                            })
                        }
                        Content={ModelSeriesDetailsAttributesCardContent}
                    />
                    <ProductDetailsCard {...props} label={"Links"} getData={modelSeriesApi.details.getLinks} Content={ModelSeriesLinksCardContent} />
                </ProductDetailsTab>
            ),
        },
        {
            label: "Localization",
            Content: (props) => (
                <ProductDetailsTab>
                    <DetailsLocalizationCard
                        {...props}
                        label={"Localizations"}
                        getData={modelSeriesApi.details.getName}
                        updateData={({ key, update }) =>
                            modelSeriesApi.details.updateName({
                                key,
                                requestBody: update,
                            })
                        }
                    />
                </ProductDetailsTab>
            ),
        },
    ],
}

const ModelSeriesDetailsContainer = getProductDetailsContainer(ModelSeriesProductDetailsContainerConfig)

export const ModelSeriesDetailsRoute = (
    <Route
        path={routes.models.modelSeries.details(":key")}
        Component={() => {
            const params = useParams<{ key: string }>()

            return (
                <ModelSeriesDetailsContainer
                    itemKey={params}
                    parentCrumbs={[
                        {
                            name: ModelSeriesProductListContainerConfig.listName,
                            path: routes.models.modelSeries.list(),
                        },
                    ]}
                />
            )
        }}
    />
)
