import { TableFilterTagsConfig } from "../../../../../components/filteredpagedtable/FilteredPagedTable"
import { Tag } from "../../../../../components/tag/Tag"
import { OptionCustomizationFilterOptionsDTO } from "../../../../../generated/pdsapi"
import { OptionCustomizationFilter } from "../../../../../pdsapi"
import { findGroupedOption, findOption } from "../../../../../viewtemplates/list/filter/ListFilterTagsConfig"
import { JSX } from "react"

export const OptionCustomizationListFilterTagsConfig: TableFilterTagsConfig<OptionCustomizationFilter, OptionCustomizationFilterOptionsDTO> = ({
    filter,
    setFilter,
    filterOptions,
}) => {
    const filterTags: JSX.Element[] = []

    filter.types?.forEach((type) => {
        filterTags.push(
            <Tag
                key={`filterTag_type_${type}`}
                onRemove={() =>
                    setFilter((filter) => {
                        const newTypes = filter.types?.filter((t) => type !== t)
                        return {
                            ...filter,
                            types: newTypes?.length !== 0 ? newTypes : undefined,
                        }
                    })
                }
            >
                {findOption(filterOptions?.types, type)?.label ?? type}
            </Tag>
        )
    })

    filter.modelGenerationKeys?.forEach((modelGenerationKey) => {
        filterTags.push(
            <Tag
                key={`filterTag_modelGenerationKey_${modelGenerationKey}`}
                onRemove={() =>
                    setFilter((filter) => {
                        const newModelGenerationKeys = filter.modelGenerationKeys?.filter((key) => modelGenerationKey !== key)
                        return {
                            ...filter,
                            modelGenerationKeys: newModelGenerationKeys?.length !== 0 ? newModelGenerationKeys : undefined,
                        }
                    })
                }
            >
                {findOption(filterOptions?.modelGenerations, modelGenerationKey)?.label ?? modelGenerationKey}
            </Tag>
        )
    })

    filter.equipmentTagKeys?.forEach((equipmentTagKey) => {
        filterTags.push(
            <Tag
                key={`filterTag_equipmentTagKey_${equipmentTagKey}`}
                onRemove={() =>
                    setFilter((filter) => {
                        const newEquipmentTagKeys = filter.equipmentTagKeys?.filter((key) => equipmentTagKey !== key)
                        return {
                            ...filter,
                            equipmentTagKeys: newEquipmentTagKeys?.length !== 0 ? newEquipmentTagKeys : undefined,
                        }
                    })
                }
            >
                {findGroupedOption(filterOptions?.categorizedEquipmentTags, equipmentTagKey)?.label ?? equipmentTagKey}
            </Tag>
        )
    })

    filter.interiorColorTagKeys?.forEach((interiorColorTagKey) => {
        filterTags.push(
            <Tag
                key={`filterTag_interiorColorTagKey_${interiorColorTagKey}`}
                onRemove={() =>
                    setFilter((filter) => {
                        const newInteriorColorTagKeys = filter.interiorColorTagKeys?.filter((key) => interiorColorTagKey !== key)
                        return {
                            ...filter,
                            interiorColorTagKeys: newInteriorColorTagKeys?.length !== 0 ? newInteriorColorTagKeys : undefined,
                        }
                    })
                }
            >
                {findOption(filterOptions?.interiorColorTags, interiorColorTagKey)?.label ?? interiorColorTagKey}
            </Tag>
        )
    })

    filter.exteriorColorTagKeys?.forEach((exteriorColorTagKey) => {
        filterTags.push(
            <Tag
                key={`filterTag_exteriorColorTagKey_${exteriorColorTagKey}`}
                onRemove={() =>
                    setFilter((filter) => {
                        const newExteriorColorTagKeys = filter.exteriorColorTagKeys?.filter((key) => exteriorColorTagKey !== key)
                        return {
                            ...filter,
                            exteriorColorTagKeys: newExteriorColorTagKeys?.length !== 0 ? newExteriorColorTagKeys : undefined,
                        }
                    })
                }
            >
                {findOption(filterOptions?.exteriorColorTags, exteriorColorTagKey)?.label ?? exteriorColorTagKey}
            </Tag>
        )
    })

    filter.roofColorTagKeys?.forEach((roofColorTagKey) => {
        filterTags.push(
            <Tag
                key={`filterTag_roofColorTagKey_${roofColorTagKey}`}
                onRemove={() =>
                    setFilter((filter) => {
                        const newRoofColorTagKeys = filter.roofColorTagKeys?.filter((key) => roofColorTagKey !== key)
                        return {
                            ...filter,
                            roofColorTagKeys: newRoofColorTagKeys?.length !== 0 ? newRoofColorTagKeys : undefined,
                        }
                    })
                }
            >
                {findOption(filterOptions?.roofColorTags, roofColorTagKey)?.label ?? roofColorTagKey}
            </Tag>
        )
    })

    filter.interiorMaterials?.forEach((interiorMaterial) => {
        filterTags.push(
            <Tag
                key={`filterTag_interiorMaterial_${interiorMaterial}`}
                onRemove={() =>
                    setFilter((filter) => {
                        const newInteriorMaterials = filter.interiorMaterials?.filter((material) => interiorMaterial !== material)
                        return {
                            ...filter,
                            interiorMaterials: newInteriorMaterials?.length !== 0 ? newInteriorMaterials : undefined,
                        }
                    })
                }
            >
                {findOption(filterOptions?.interiorMaterials, interiorMaterial)?.label ?? interiorMaterial}
            </Tag>
        )
    })

    return filterTags
}
