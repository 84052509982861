/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    OptionDTOExteriorColorInternal,
    OptionDTOExteriorColorInternalFromJSONTyped,
    OptionDTOExteriorColorInternalToJSON,
} from './';

/**
 * @type OptionDTOExteriorColor
 * 
 * @export
 */
export type OptionDTOExteriorColor = { type: 'ExteriorColor' } & OptionDTOExteriorColorInternal;

export function OptionDTOExteriorColorFromJSON(json: any): OptionDTOExteriorColor {
    return OptionDTOExteriorColorFromJSONTyped(json, false);
}

export function OptionDTOExteriorColorFromJSONTyped(json: any, ignoreDiscriminator: boolean): OptionDTOExteriorColor {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    switch (json['type']) {
        case 'ExteriorColor':
            return {...OptionDTOExteriorColorInternalFromJSONTyped(json, true), type: 'ExteriorColor'};
        default:
            throw new Error(`No variant of OptionDTOExteriorColor exists with 'type=${json['type']}'`);
    }
}

export function OptionDTOExteriorColorToJSON(value?: OptionDTOExteriorColor | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    switch (value['type']) {
        case 'ExteriorColor':
            return OptionDTOExteriorColorInternalToJSON(value);
        default:
            throw new Error(`No variant of OptionDTOExteriorColor exists with 'type=${value['type']}'`);
    }
}

