/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    OptionGroupDTOString,
    OptionGroupDTOStringFromJSON,
    OptionGroupDTOStringFromJSONTyped,
    OptionGroupDTOStringToJSON,
    SelectOptionDTOString,
    SelectOptionDTOStringFromJSON,
    SelectOptionDTOStringFromJSONTyped,
    SelectOptionDTOStringToJSON,
} from './';

/**
 * 
 * @export
 * @interface EquipmentCustomizationCreateOptionsDTO
 */
export interface EquipmentCustomizationCreateOptionsDTO {
    /**
     * 
     * @type {Array<SelectOptionDTOString>}
     * @memberof EquipmentCustomizationCreateOptionsDTO
     */
    modelGenerations: Array<SelectOptionDTOString>;
    /**
     * 
     * @type {Array<OptionGroupDTOString>}
     * @memberof EquipmentCustomizationCreateOptionsDTO
     */
    categorizedEquipmentTags: Array<OptionGroupDTOString>;
}

export function EquipmentCustomizationCreateOptionsDTOFromJSON(json: any): EquipmentCustomizationCreateOptionsDTO {
    return EquipmentCustomizationCreateOptionsDTOFromJSONTyped(json, false);
}

export function EquipmentCustomizationCreateOptionsDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): EquipmentCustomizationCreateOptionsDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'modelGenerations': ((json['modelGenerations'] as Array<any>).map(SelectOptionDTOStringFromJSON)),
        'categorizedEquipmentTags': ((json['categorizedEquipmentTags'] as Array<any>).map(OptionGroupDTOStringFromJSON)),
    };
}

export function EquipmentCustomizationCreateOptionsDTOToJSON(value?: EquipmentCustomizationCreateOptionsDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'modelGenerations': ((value.modelGenerations as Array<any>).map(SelectOptionDTOStringToJSON)),
        'categorizedEquipmentTags': ((value.categorizedEquipmentTags as Array<any>).map(OptionGroupDTOStringToJSON)),
    };
}


