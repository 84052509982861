import { derivativeCategoryApi } from "../../../../pdsapi"
import { BasicKey, getProductDetailsContainer, ProductDetailsContainerConfig } from "../../../../viewtemplates/details/ProductDetailsContainer"
import { ProductDetailsCard } from "../../../../viewtemplates/details/cards/ProductDetailsCard"
import { DetailsLocalizationCard } from "../../../../viewtemplates/details/cards/localizationcard/DetailsLocalizationCard"
import { ProductDetailsTab } from "../../../../viewtemplates/details/tabs/ProductDetailsTab"
import { DerivativeCategoryDetailsAttributesCardContent } from "./data/attributes/DerivativeCategoryDetailsAttributesCardContent"
import { DerivativeCategoryLinksCard } from "./data/links/DerivativeCategoryLinksCard"
import { Route, useParams } from "react-router-dom"
import { routes } from "../../../../common/routes"
import { DerivativeCategoryProductListContainerConfig } from "../list/DerivativeCategoryListRoute"

export const DerivativeCategoryProductDetailsContainerConfig: ProductDetailsContainerConfig<BasicKey> = {
    getBaseInformation: derivativeCategoryApi.details.getBaseInformation,
    deleteByKey: derivativeCategoryApi.details.deleteByKey,
    tabsConfig: [
        {
            label: "Data",
            Content: (props) => (
                <ProductDetailsTab>
                    <ProductDetailsCard
                        {...props}
                        label={"Attributes"}
                        getData={derivativeCategoryApi.details.getAttributes}
                        updateData={({ key, update }) =>
                            derivativeCategoryApi.details.updateAttributes({
                                key,
                                derivativeCategoryAttributesUpdateDTO: update,
                            })
                        }
                        Content={DerivativeCategoryDetailsAttributesCardContent}
                    />
                    <DerivativeCategoryLinksCard {...props} />
                </ProductDetailsTab>
            ),
        },
        {
            label: "Name",
            Content: (props) => (
                <ProductDetailsTab>
                    <DetailsLocalizationCard
                        {...props}
                        label={"Localizations"}
                        getData={derivativeCategoryApi.details.getNameLocalizations}
                        updateData={(update) =>
                            derivativeCategoryApi.details.updateNameLocalizations({
                                key: update.key,
                                requestBody: update.update,
                            })
                        }
                    />
                </ProductDetailsTab>
            ),
        },
        {
            label: "Description",
            Content: (props) => (
                <ProductDetailsTab>
                    <DetailsLocalizationCard
                        {...props}
                        label={"Localizations"}
                        getData={derivativeCategoryApi.details.getDescriptionLocalizations}
                        updateData={(update) =>
                            derivativeCategoryApi.details.updateDescriptionLocalizations({
                                key: update.key,
                                requestBody: update.update,
                            })
                        }
                    />
                </ProductDetailsTab>
            ),
        },
    ],
}

const DerivativeCategoryDetailsContainer = getProductDetailsContainer(DerivativeCategoryProductDetailsContainerConfig)

export const DerivativeCategoryDetailsRoute = (
    <Route
        path={routes.models.derivativeCategories.details(":key")}
        Component={() => {
            const params = useParams<{ key: string }>()

            return (
                <DerivativeCategoryDetailsContainer
                    itemKey={params}
                    parentCrumbs={[
                        {
                            name: DerivativeCategoryProductListContainerConfig.listName,
                            path: routes.models.derivativeCategories.list(),
                        },
                    ]}
                />
            )
        }}
    />
)
