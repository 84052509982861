import { showErrorToast } from "../../../../common/errorToastHelper"
import { NameItem } from "../../../../components/formelements/derivednameformelement/DerivedNameFormElement"
import { LocalizationsItemDTO, OrderTypeDetailsContextDTO, OrderTypeNameItemDTO } from "../../../../generated/pdsapi"
import { useJsonMemorizedValue } from "../../../../hooks/useJsonMemorizedValue"
import { orderTypeApi } from "../../../../pdsapi"
import {
    BasicKey,
    getProductDetailsContainer,
    ProductDetailsContainerConfig,
    ProductDetailsContainerProps,
} from "../../../../viewtemplates/details/ProductDetailsContainer"
import { ProductDetailsCard } from "../../../../viewtemplates/details/cards/ProductDetailsCard"
import { DetailsDerivedLocalizationCard } from "../../../../viewtemplates/details/cards/derivedlocalizationcard/DetailsDerivedLocalizationCard"
import { ProductDetailsTab } from "../../../../viewtemplates/details/tabs/ProductDetailsTab"
import { OrderTypeDetailsContext } from "./OrderTypeDetailsContext"
import { OrderTypeDetailsAttributesCardContent } from "./data/attributes/OrderTypeDetailsAttributesCardContent"
import { OrderTypeOptionsCard } from "./options/OrderTypeOptionsCard"
import { orderTypeEquipmentCardConfig } from "./options/equipment/OrderTypeEquipmentCard"
import { orderTypeExteriorColorsCardConfig } from "./options/exteriorcolors/OrderTypeExteriorColorsCard"
import { orderTypeInteriorsCardConfig } from "./options/interiors/OrderTypeInteriorsCard"
import { orderTypeRoofColorsCardConfig } from "./options/roofcolors/OrderTypeRoofColorsCard"
import { OrderTypeEquipmentPackagesCard } from "./packages/equipmentpackages/OrderTypeEquipmentPackagesCard"
import { OrderTypeStandardEquipmentCard } from "./standardequipments/standardequipment/OrderTypeStandardEquipmentCard"
import { OrderTypeTechnicalDataTab } from "./technicaldata/OrderTypeTechnicalDataTab"
import { useToast } from "@finder/ui-kit"
import { FC, useEffect, useRef, useState } from "react"
import { Route, useParams } from "react-router-dom"
import { OrderTypePressEmbargoesCardContent } from "./pressembargoes/OrderTypePressEmbargoesCardContent"
import { OrderTypeMediaCard } from "./media/OrderTypeMediaCard"
import { orderTypePaintToSampleExteriorColorsCardConfig } from "./options/painttosampleexteriorcolors/OrderTypePaintToSampleExteriorColorsCard"
import { orderTypeOptionItemsCardConfig } from "./options/items/OrderTypeOptionItemsCard"
import { routes } from "../../../../common/routes"
import { OrderTypeProductListContainerConfig } from "../list/OrderTypeListRoute"

const nameItem = (label: string | undefined, item: LocalizationsItemDTO): NameItem => ({
    label,
    isDerived: item.raw === undefined,
    value: item.withFallback,
})

export const OrderTypeProductDetailsContainerConfig: ProductDetailsContainerConfig<BasicKey> = {
    getBaseInformation: orderTypeApi.details.getBaseInformation,

    deleteByKey: orderTypeApi.details.deleteByKey,
    tabsConfig: [
        {
            label: "Data",
            Content: (props) => (
                <ProductDetailsTab>
                    <ProductDetailsCard
                        {...props}
                        label={"Name"}
                        getData={orderTypeApi.details.data.getAttributes}
                        updateData={({ key, update }) =>
                            orderTypeApi.details.data.updateAttributes({
                                key,
                                orderTypeAttributesUpdateDTO: update,
                            })
                        }
                        Content={OrderTypeDetailsAttributesCardContent}
                    />
                </ProductDetailsTab>
            ),
        },
        {
            label: "Localization",
            Content: (props) => (
                <ProductDetailsTab>
                    <DetailsDerivedLocalizationCard
                        {...props}
                        label={"Name"}
                        getData={orderTypeApi.details.localization.getName}
                        updateData={({ key, update }) =>
                            orderTypeApi.details.localization.updateManualName({
                                key,
                                requestBody: update,
                            })
                        }
                        getViewProps={(data, languageTag) => {
                            const name: OrderTypeNameItemDTO = data[languageTag]
                            return {
                                primaryName: nameItem(undefined, name.merged),
                                secondaryNames: [nameItem("Manual", name.manual), nameItem("Synced", name.synced)],
                            }
                        }}
                        getEditProps={(data, languageTag) => ({
                            rawName: data[languageTag].manual.raw,
                        })}
                    />
                </ProductDetailsTab>
            ),
        },
        {
            label: "Options",
            Content: ({ itemKey: { key: orderTypeKey }, urlPathParamsPrefix }) => (
                <ProductDetailsTab>
                    <OrderTypeOptionsCard
                        orderTypeKey={orderTypeKey}
                        urlPathParamsPrefix={`${urlPathParamsPrefix}.options`}
                        {...orderTypeOptionItemsCardConfig}
                    ></OrderTypeOptionsCard>
                </ProductDetailsTab>
            ),
        },
        {
            label: "Options (Deprecated)",
            Content: ({ itemKey: { key: orderTypeKey }, urlPathParamsPrefix }) => (
                <ProductDetailsTab>
                    <OrderTypeOptionsCard
                        orderTypeKey={orderTypeKey}
                        urlPathParamsPrefix={`${urlPathParamsPrefix}.equipment`}
                        {...orderTypeEquipmentCardConfig}
                    />
                    <OrderTypeOptionsCard
                        orderTypeKey={orderTypeKey}
                        urlPathParamsPrefix={`${urlPathParamsPrefix}.exterior-colors`}
                        {...orderTypeExteriorColorsCardConfig}
                    />
                    <OrderTypeOptionsCard
                        orderTypeKey={orderTypeKey}
                        urlPathParamsPrefix={`${urlPathParamsPrefix}.paint-to-sample-exterior-colors`}
                        {...orderTypePaintToSampleExteriorColorsCardConfig}
                    ></OrderTypeOptionsCard>
                    <OrderTypeOptionsCard
                        orderTypeKey={orderTypeKey}
                        urlPathParamsPrefix={`${urlPathParamsPrefix}.interiors`}
                        {...orderTypeInteriorsCardConfig}
                    />
                    <OrderTypeOptionsCard
                        orderTypeKey={orderTypeKey}
                        urlPathParamsPrefix={`${urlPathParamsPrefix}.roof-colors`}
                        {...orderTypeRoofColorsCardConfig}
                    />
                </ProductDetailsTab>
            ),
        },
        {
            label: "Packages",
            Content: (props) => (
                <ProductDetailsTab>
                    <OrderTypeEquipmentPackagesCard {...props} />
                </ProductDetailsTab>
            ),
        },
        {
            label: "Standard Equipments",
            Content: (props) => (
                <ProductDetailsTab>
                    <OrderTypeStandardEquipmentCard {...props} />
                </ProductDetailsTab>
            ),
        },
        {
            label: "Technical Data",
            Content: OrderTypeTechnicalDataTab,
        },
        {
            label: "Press Embargoes",
            Content: (props) => (
                <ProductDetailsTab>
                    <ProductDetailsCard
                        {...props}
                        label={"Press Embargoes"}
                        getData={orderTypeApi.details.pressEmbargoes.getPressEmbargoes}
                        updateData={({ key, update }) => {
                            update.global = update.global ?? undefined // we replace null with undefined here to prevent bug in OpenAPI generated code
                            return orderTypeApi.details.pressEmbargoes.updatePressEmbargoes({
                                key,
                                orderTypeManualPressEmbargoesUpdateDTO: update,
                            })
                        }}
                        Content={OrderTypePressEmbargoesCardContent}
                    />
                </ProductDetailsTab>
            ),
        },
        {
            label: "Media",
            Content: OrderTypeMediaCard,
        },
    ],
}

const DefaultOrderTypeDetailsContainer = getProductDetailsContainer(OrderTypeProductDetailsContainerConfig)

const OrderTypeDetailsContainer: FC<ProductDetailsContainerProps<BasicKey>> = (props) => {
    const toastRef = useRef(useToast())
    const [orderTypeDetailsContext, setOrderTypeDetailsContext] = useState<OrderTypeDetailsContextDTO>({
        isEditable: false,
        isTechnicalDataEditable: false,
    })
    const key = useJsonMemorizedValue(props.itemKey)

    useEffect(() => {
        const fetchContext = async () => {
            try {
                const context = await orderTypeApi.details.getContext(key)
                setOrderTypeDetailsContext(context)
            } catch (e) {
                showErrorToast(toastRef.current, e)
            }
        }
        fetchContext()
    }, [key])

    return (
        <OrderTypeDetailsContext.Provider value={orderTypeDetailsContext}>
            <DefaultOrderTypeDetailsContainer {...props} />
        </OrderTypeDetailsContext.Provider>
    )
}

export const OrderTypeDetailsRoute = (
    <Route
        path={routes.models.orderTypes.details(":key")}
        Component={() => {
            const params = useParams<{ key: string }>()

            return (
                <OrderTypeDetailsContainer
                    itemKey={params}
                    parentCrumbs={[
                        {
                            name: OrderTypeProductListContainerConfig.listName,
                            path: routes.models.orderTypes.list(),
                        },
                    ]}
                />
            )
        }}
    />
)
