import { routes } from "../../../common/routes"
import { UnmaintainedCustomColorDTO } from "../../../generated/pdsapi"
import { dashboardApi } from "../../../pdsapi"
import { DashboardPagedTableProps } from "../dashboardpagedtable/DashboardPagedTable"
import { unmaintainedCustomColorColumns } from "./parts/UnmaintainedCustomColorColumns"

export const UnmaintainedCustomInteriorColorsTableConfig: DashboardPagedTableProps<UnmaintainedCustomColorDTO> = {
    titlePrefix: "Unmaintained",
    itemsName: "Custom Interior Colors",

    getRoute: (unmaintainedCustomColor) => routes.interiors.customColors.details(unmaintainedCustomColor.key),

    urlParamsPrefix: "custom-interior-colors.unmaintained",
    columns: unmaintainedCustomColorColumns,
    fetchPage: dashboardApi.getUnmaintainedCustomInteriorColors,
}
