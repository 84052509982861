/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    InteriorColorTileDTO,
    InteriorColorTileDTOFromJSON,
    InteriorColorTileDTOFromJSONTyped,
    InteriorColorTileDTOToJSON,
} from './';

/**
 * 
 * @export
 * @interface OptionCustomizationItemDTOInteriorInternal
 */
export interface OptionCustomizationItemDTOInteriorInternal {
    /**
     * 
     * @type {string}
     * @memberof OptionCustomizationItemDTOInteriorInternal
     */
    key: string;
    /**
     * 
     * @type {string}
     * @memberof OptionCustomizationItemDTOInteriorInternal
     */
    optionCode: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof OptionCustomizationItemDTOInteriorInternal
     */
    modelGenerationNames: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof OptionCustomizationItemDTOInteriorInternal
     */
    type: string;
    /**
     * 
     * @type {InteriorColorTileDTO}
     * @memberof OptionCustomizationItemDTOInteriorInternal
     */
    color: InteriorColorTileDTO;
    /**
     * 
     * @type {string}
     * @memberof OptionCustomizationItemDTOInteriorInternal
     */
    interiorMaterialName: string;
}

export function OptionCustomizationItemDTOInteriorInternalFromJSON(json: any): OptionCustomizationItemDTOInteriorInternal {
    return OptionCustomizationItemDTOInteriorInternalFromJSONTyped(json, false);
}

export function OptionCustomizationItemDTOInteriorInternalFromJSONTyped(json: any, ignoreDiscriminator: boolean): OptionCustomizationItemDTOInteriorInternal {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'key': json['key'],
        'optionCode': json['optionCode'],
        'modelGenerationNames': json['modelGenerationNames'],
        'type': json['type'],
        'color': InteriorColorTileDTOFromJSON(json['color']),
        'interiorMaterialName': json['interiorMaterialName'],
    };
}

export function OptionCustomizationItemDTOInteriorInternalToJSON(value?: OptionCustomizationItemDTOInteriorInternal | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'key': value.key,
        'optionCode': value.optionCode,
        'modelGenerationNames': value.modelGenerationNames,
        'type': value.type,
        'color': InteriorColorTileDTOToJSON(value.color),
        'interiorMaterialName': value.interiorMaterialName,
    };
}


