import { ViewState } from "../../../../../../../common/ViewState"
import { showErrorToast } from "../../../../../../../common/errorToastHelper"
import { browserLanguage } from "../../../../../../../common/global"
import { EmptyView } from "../../../../../../../components/emptyview/EmptyView"
import { toTagOption } from "../../../../../../../components/formelements/tagformelement/TagOption"
import { TagsFormElementView } from "../../../../../../../components/formelements/tagsformelement/TagsFormElement"
import { rowGroupsSpacing, RowGroupWrapper } from "../../../../../../../components/layouts/rowgroupwrapper/RowGroupWrapper"
import { SpacedItems } from "../../../../../../../components/layouts/spaceditems/SpacedItems"
import { WidgetWrapper } from "../../../../../../../components/layouts/widgetwrapper/WidgetWrapper"
import { SimilarEquipmentCustomizationsDTO } from "../../../../../../../generated/pdsapi"
import { equipmentApi } from "../../../../../../../pdsapi"
import { ProductDetailsTabConfigContentProps } from "../../../../../../../viewtemplates/details/ProductDetailsContainer"
import { useToast } from "@finder/ui-kit"
import { FC, useEffect, useRef, useState } from "react"
import { routes } from "../../../../../../../common/routes"

export const EquipmentSimilarCustomizationsCard: FC<ProductDetailsTabConfigContentProps<{ orderTypeKey: string; optionCode: string }>> = ({ itemKey }) => {
    const toastRef = useRef(useToast())
    const [similarCustomizations, setSimilarCustomizations] = useState<SimilarEquipmentCustomizationsDTO>()

    useEffect(() => {
        const fetchSimilarCustomizations = async () => {
            try {
                const linkedCustomization = await equipmentApi.details.getSimilarCustomizations({
                    ...itemKey,
                    languageTag: browserLanguage,
                })
                setSimilarCustomizations(linkedCustomization)
            } catch (e) {
                showErrorToast(toastRef.current, e)
            }
        }

        fetchSimilarCustomizations()
    }, [itemKey, toastRef])

    return (
        <WidgetWrapper label={"Similar Customizations"}>
            {similarCustomizations ? (
                <EquipmentSimilarCustomizationsCardContent similarCustomizations={similarCustomizations} />
            ) : (
                <EmptyView viewState={ViewState.LOADING} />
            )}
        </WidgetWrapper>
    )
}

export const EquipmentSimilarCustomizationsCardContent = ({ similarCustomizations }: { similarCustomizations: SimilarEquipmentCustomizationsDTO }) => (
    <SpacedItems direction={"column"} lineSpacing={rowGroupsSpacing}>
        <RowGroupWrapper label={"Customizations for other Model Generations"} childrenSize={12}>
            <TagsFormElementView
                label={undefined}
                emptyLabel={"-"}
                tags={similarCustomizations.customizationsForOtherModelGenerations.map((customization) =>
                    toTagOption(customization, routes.equipment.customizations.details)
                )}
            />
        </RowGroupWrapper>
    </SpacedItems>
)
