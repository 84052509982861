import { DefaultCardViewContentWrapper } from "../../../../components/layouts/defaultcardviewcontentwrapper/DefaultCardViewContentWrapper"
import { DerivativeCreateOptionsDTO, DerivativeCreateRequestDTO } from "../../../../generated/pdsapi"
import { derivativeApi } from "../../../../pdsapi"
import { getProductCreateContainer, ProductCreateContainerConfig } from "../../../../viewtemplates/create/ProductCreateContainer"
import { DerivativeCreateAttributesCard } from "./attributes/DerivativeCreateAttributesCard"
import { Route } from "react-router-dom"
import { routes } from "../../../../common/routes"
import { DerivativeProductListContainerConfig } from "../list/DerivativeListRoute"

export const DerivativeProductCreateContainerConfig: ProductCreateContainerConfig<DerivativeCreateRequestDTO, DerivativeCreateOptionsDTO> = {
    headline: "Create new Derivative",
    buildDetailsPath: ({ key }) => routes.models.derivatives.details(key),

    create: (item) =>
        derivativeApi.create.create({
            derivativeCreateRequestDTO: item,
        }),
    getCreateOptions: derivativeApi.create.getCreateOptions,

    Content: (props) => (
        <DefaultCardViewContentWrapper>
            <DerivativeCreateAttributesCard {...props} />
        </DefaultCardViewContentWrapper>
    ),
}

const DerivativeCreateContainer = getProductCreateContainer(DerivativeProductCreateContainerConfig)

export const DerivativeCreateRoute = (
    <Route
        path={routes.models.derivatives.create()}
        element={
            <DerivativeCreateContainer
                parentCrumbs={[
                    {
                        name: DerivativeProductListContainerConfig.listName,
                        path: routes.models.derivatives.list(),
                    },
                ]}
            />
        }
    />
)
