import { DefaultCardViewContentWrapper } from "../../../../../components/layouts/defaultcardviewcontentwrapper/DefaultCardViewContentWrapper"
import { InteriorCreateRequestDTO } from "../../../../../generated/pdsapi"
import { interiorApi } from "../../../../../pdsapi"
import { getProductCreateContainer, ProductCreateContainerConfig } from "../../../../../viewtemplates/create/ProductCreateContainer"
import { OrderTypeInteriorCreateOptionCard } from "./option/OrderTypeInteriorCreateOptionCard"
import { OldOrderTypeOptionKey } from "../../OrderTypeOptionKey"
import { routes } from "../../../../../common/routes"
import { Route, useParams } from "react-router-dom"
import { useOrderTypeSubpageCrumbs } from "../../useOrderTypeSubpageCrumbs"

export const OrderTypeInteriorProductCreateContainerConfig: ProductCreateContainerConfig<InteriorCreateRequestDTO, unknown, OldOrderTypeOptionKey> = {
    headline: "Create new Interior",
    buildDetailsPath: ({ orderTypeKey, optionCode }) => routes.models.orderTypes.subpages(orderTypeKey).interiors.details(optionCode),

    create: (item) =>
        interiorApi.create.create({
            interiorCreateRequestDTO: item,
        }),
    getCreateOptions: async () => ({}),

    Content: (props) => (
        <DefaultCardViewContentWrapper>
            <OrderTypeInteriorCreateOptionCard {...props} />
        </DefaultCardViewContentWrapper>
    ),
}

const OrderTypeInteriorCreateContainer = getProductCreateContainer(OrderTypeInteriorProductCreateContainerConfig)

export const OrderTypeInteriorCreateRoute = (
    <Route
        path={routes.models.orderTypes.subpages(":orderTypeKey").interiors.create()}
        Component={() => {
            const params = useParams<{ orderTypeKey: string }>()
            const parentCrumbs = useOrderTypeSubpageCrumbs(params.orderTypeKey)

            return (
                <OrderTypeInteriorCreateContainer
                    parentCrumbs={[
                        ...parentCrumbs,
                        {
                            name: "Interiors",
                            path: routes.models.orderTypes.subpages(params.orderTypeKey).interiors.list(),
                        },
                    ]}
                />
            )
        }}
    />
)
