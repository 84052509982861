import { ViewState } from "../../../../../../common/ViewState"
import { showErrorToast } from "../../../../../../common/errorToastHelper"
import { browserLanguage } from "../../../../../../common/global"
import { EmptyView } from "../../../../../../components/emptyview/EmptyView"
import { toTagOption } from "../../../../../../components/formelements/tagformelement/TagOption"
import { TagsFormElementView } from "../../../../../../components/formelements/tagsformelement/TagsFormElement"
import { rowGroupsSpacing, RowGroupWrapper } from "../../../../../../components/layouts/rowgroupwrapper/RowGroupWrapper"
import { SpacedItems } from "../../../../../../components/layouts/spaceditems/SpacedItems"
import { WidgetWrapper } from "../../../../../../components/layouts/widgetwrapper/WidgetWrapper"
import { DerivativeCategoryLinksDTO } from "../../../../../../generated/pdsapi"
import { derivativeCategoryApi } from "../../../../../../pdsapi"
import { BasicKey, ProductDetailsTabConfigContentProps } from "../../../../../../viewtemplates/details/ProductDetailsContainer"
import { useToast } from "@finder/ui-kit"
import { FC, useEffect, useRef, useState } from "react"
import { routes } from "../../../../../../common/routes"

export const DerivativeCategoryLinksCard: FC<ProductDetailsTabConfigContentProps<BasicKey>> = ({ itemKey: { key } }) => {
    const toastRef = useRef(useToast())
    const [links, setLinks] = useState<DerivativeCategoryLinksDTO>()

    useEffect(() => {
        const fetchLinks = async () => {
            try {
                const links = await derivativeCategoryApi.details.getLinks({
                    key,
                    languageTag: browserLanguage,
                })
                setLinks(links)
            } catch (e) {
                showErrorToast(toastRef.current, e)
            }
        }

        fetchLinks()
    }, [key, toastRef])

    return (
        <WidgetWrapper label={"Links"}>
            {links ? <DerivativeCategoryLinksCardContent links={links} /> : <EmptyView viewState={ViewState.LOADING} />}
        </WidgetWrapper>
    )
}

export const DerivativeCategoryLinksCardContent = ({ links }: { links: DerivativeCategoryLinksDTO }) => (
    <SpacedItems direction={"column"} lineSpacing={rowGroupsSpacing}>
        <RowGroupWrapper label={"Derivatives"} childrenSize={12}>
            <TagsFormElementView
                label={undefined}
                tags={links.derivatives.map((derivative) => toTagOption(derivative, routes.models.derivatives.details))}
                emptyLabel={"-"}
            />
        </RowGroupWrapper>
    </SpacedItems>
)
