import { routes } from "../../../common/routes"
import { getEnumConverterGroup, getObjectURLParamConverter, numberConverterGroup, stringConverterGroup } from "../../../common/useURLSearchParamState"
import { OptionSortField, RoofColorFilterOptionsDTO, RoofColorItemDTO, SortDirection } from "../../../generated/pdsapi"
import { roofColorApi, RoofColorFilter } from "../../../pdsapi"
import { getProductListContainer, ProductListContainerConfig } from "../../../viewtemplates/list/ProductListContainer"
import { RoofColorCatalogueColumnsConfig } from "./columns/RoofColorCatalogueColumns"
import { RoofColorCatalogueFilter } from "./filter/RoofColorCatalogueFilter"
import { RoofColorCatalogueFilterTagsConfig } from "./filter/RoofColorCatalogueFilterTagsConfig"
import { Route } from "react-router-dom"

export const RoofColorCatalogueContainerConfig: ProductListContainerConfig<RoofColorItemDTO, RoofColorFilter, RoofColorFilterOptionsDTO, OptionSortField> = {
    listName: "Roof Color Catalogue",
    itemsName: "Roof Colors",

    buildDetailsPath: (item) => routes.models.orderTypes.subpages(item.orderTypeKey).roofColors.details(item.optionCode),
    buildCreatePath: undefined,
    buildSortPath: undefined,

    defaultFilter: {},
    filterUrlParamsConverter: getObjectURLParamConverter<RoofColorFilter>({
        modelGenerationKeys: stringConverterGroup.optionalArray,
        modelYears: numberConverterGroup.optionalArray,
        orderTypeKeys: stringConverterGroup.optionalArray,
        colorTagKeys: stringConverterGroup.optionalArray,
    }),
    defaultSorting: {
        sortField: OptionSortField.SORT_INDEX,
        sortDirection: SortDirection.ASC,
    },
    sortingUrlParamsConverter: getObjectURLParamConverter({
        sortField: getEnumConverterGroup(OptionSortField).required,
        sortDirection: getEnumConverterGroup(SortDirection).required,
    }),
    fetchPage: roofColorApi.catalogue.getPage,
    fetchFilterOptions: roofColorApi.catalogue.getFilterOptions,

    defaultShowsFilter: true,
    Filter: RoofColorCatalogueFilter,
    filterTagsConfig: RoofColorCatalogueFilterTagsConfig,

    columnsConfig: RoofColorCatalogueColumnsConfig,
}

const RoofColorCatalogueContainer = getProductListContainer(RoofColorCatalogueContainerConfig)

export const RoofColorCatalogueRoute = <Route path={routes.roofColors.catalogue()} Component={RoofColorCatalogueContainer} />
