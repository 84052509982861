import { routes } from "../../../../common/routes"
import { SpacedItems } from "../../../../components/layouts/spaceditems/SpacedItems"
import { ColumnBuilder } from "../../../../components/layouts/table/Table"
import { Tag } from "../../../../components/tag/Tag"
import { SelectOptionDTOString } from "../../../../generated/pdsapi"
import { Tooltip } from "@finder/ui-kit"
import { spacing } from "@porsche-design-system/utilities"

interface MissingOptionCustomizationDTO {
    optionCode: string
    modelGeneration: SelectOptionDTOString
    orderTypes: Array<SelectOptionDTOString>
}

export const missingOptionCustomizationsColumns: ColumnBuilder<MissingOptionCustomizationDTO, undefined>[] = [
    {
        width: 100,
        headerCellProps: {
            content: "Option Code",
        },
        buildCellContent: (missingOptionCustomization) => missingOptionCustomization.optionCode,
    },
    {
        width: 150,
        headerCellProps: {
            content: "Model Generation",
        },
        buildCellContent: (missingOptionCustomization) => missingOptionCustomization.modelGeneration.label,
    },
    {
        grow: 1,
        headerCellProps: {
            content: "Affected Order Types",
        },
        buildCellContent: (missingOptionCustomization) => (
            <SpacedItems direction={"column"} lineSpacing={spacing[4]}>
                {missingOptionCustomization.orderTypes.slice(0, 2).map((orderType) => (
                    <Tag key={`orderType_${orderType.key}`} onClick={() => window.open(routes.models.orderTypes.details(orderType.key))}>
                        {orderType.label}
                    </Tag>
                ))}
                {missingOptionCustomization.orderTypes.length > 2 ? (
                    <Tooltip
                        content={missingOptionCustomization.orderTypes
                            .slice(2)
                            .map((orderType) => orderType.label)
                            .join("\n")}
                    >
                        and {missingOptionCustomization.orderTypes.length - 2} more
                    </Tooltip>
                ) : null}
            </SpacedItems>
        ),
    },
]
