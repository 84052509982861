import { modelSeriesApi } from "../../../../../pdsapi"
import { getProductSortContainer, ProductSortContainerConfig } from "../../../../../viewtemplates/sort/ProductSortContainer"
import { Route } from "react-router-dom"
import { routes } from "../../../../../common/routes"
import { ModelSeriesProductListContainerConfig } from "../../list/ModelSeriesListRoute"

export const ModelSeriesSortContainerTemplateProps: ProductSortContainerConfig = {
    fetchSortables: modelSeriesApi.sort.getSortableItems,
    updateSortIndices: (sortIndexUpdate) => modelSeriesApi.sort.updateSortIndices({ sortIndexUpdateRequestDTO: sortIndexUpdate }),
}

const ModelSeriesSortContainer = getProductSortContainer(ModelSeriesSortContainerTemplateProps)

export const ModelSeriesSortRoute = (
    <Route
        path={routes.models.modelSeries.sort()}
        element={
            <ModelSeriesSortContainer
                parentCrumbs={[
                    {
                        name: ModelSeriesProductListContainerConfig.listName,
                        path: routes.models.modelSeries.list(),
                    },
                ]}
            />
        }
    />
)
