import { exteriorColorCustomizationApi } from "../../../../pdsapi"
import { getCustomizationDetailsContainer } from "../../../../viewtemplates/details/CustomizationDetailsContainer"
import { BasicKey, ProductDetailsContainerConfig } from "../../../../viewtemplates/details/ProductDetailsContainer"
import { ProductDetailsCard } from "../../../../viewtemplates/details/cards/ProductDetailsCard"
import { DetailsLinkedOptionsCard } from "../../../../viewtemplates/details/cards/linkedoptionscard/DetailsLinkedOptionsCard"
import { DetailsVehicleImagesCard } from "../../../../viewtemplates/details/cards/vehicleimagescard/DetailsVehicleImagesCard"
import { ProductDetailsTab } from "../../../../viewtemplates/details/tabs/ProductDetailsTab"
import { DetailsOptionCustomizationLocalizationTab } from "../../../../viewtemplates/details/tabs/optioncustomizationlocalizationtab/DetailsOptionCustomizationLocalizationTab"
import { ExteriorColorCustomizationDetailsAttributesCardContent } from "./data/attributes/ExteriorColorCustomizationDetailsAttributesCardContent"
import { CustomizationVideosTab } from "../../../../viewtemplates/details/tabs/optionscustomizationvideotab/CustomizationVideosTab"
import { routes } from "../../../../common/routes"
import { ExteriorColorCustomizationProductListContainerConfig } from "../list/ExteriorColorCustomizationListRoute"
import { Route, useParams } from "react-router-dom"

export const ExteriorColorCustomizationProductDetailsContainerConfig: ProductDetailsContainerConfig<BasicKey> = {
    getBaseInformation: exteriorColorCustomizationApi.details.getBaseInformation,
    deleteByKey: exteriorColorCustomizationApi.details.deleteByKey,
    tabsConfig: [
        {
            label: "Data",
            Content: (props) => (
                <ProductDetailsTab>
                    <ProductDetailsCard
                        {...props}
                        label={"Attributes"}
                        getData={exteriorColorCustomizationApi.details.getAttributes}
                        updateData={({ key, update }) =>
                            exteriorColorCustomizationApi.details.updateAttributes({
                                key,
                                oldOptionCustomizationAttributesUpdateDTOExteriorColorCustomizationAttributesValueUpdateDTO: update,
                            })
                        }
                        Content={ExteriorColorCustomizationDetailsAttributesCardContent}
                    />
                    <DetailsLinkedOptionsCard
                        {...props}
                        getData={exteriorColorCustomizationApi.details.getLinks}
                        getRoute={({ orderTypeKey, optionCode }) => routes.models.orderTypes.subpages(orderTypeKey).exteriorColors.details(optionCode)}
                    />
                    <DetailsVehicleImagesCard {...props} load={exteriorColorCustomizationApi.details.getVehicleImages} />
                </ProductDetailsTab>
            ),
        },
        {
            label: "Name",
            Content: (props) => (
                <DetailsOptionCustomizationLocalizationTab
                    {...props}
                    getData={exteriorColorCustomizationApi.details.getName}
                    updateDefaultValue={exteriorColorCustomizationApi.details.updateDefaultName}
                    updateConditionalValue={exteriorColorCustomizationApi.details.updateConditionalValueName}
                />
            ),
        },
        {
            label: "Description",
            Content: (props) => (
                <DetailsOptionCustomizationLocalizationTab
                    {...props}
                    getData={exteriorColorCustomizationApi.details.getDescription}
                    updateDefaultValue={exteriorColorCustomizationApi.details.updateDefaultDescription}
                    updateConditionalValue={exteriorColorCustomizationApi.details.updateConditionalValueDescription}
                />
            ),
        },
        {
            label: "Videos",
            Content: (props) => (
                <CustomizationVideosTab
                    {...props}
                    getData={exteriorColorCustomizationApi.details.getVideos}
                    updateDefaultValue={exteriorColorCustomizationApi.details.updateDefaultVideo}
                    updateConditionalValue={exteriorColorCustomizationApi.details.updateConditionalVideo}
                />
            ),
        },
    ],
}

const ExteriorColorCustomizationDetailsContainer = getCustomizationDetailsContainer(ExteriorColorCustomizationProductDetailsContainerConfig)

export const ExteriorColorCustomizationDetailsRoute = (
    <Route
        path={routes.exteriorColors.customizations.details(":key")}
        Component={() => {
            const params = useParams<{ key: string }>()

            return (
                <ExteriorColorCustomizationDetailsContainer
                    itemKey={params}
                    parentCrumbs={[
                        {
                            name: ExteriorColorCustomizationProductListContainerConfig.listName,
                            path: routes.exteriorColors.customizations.list(),
                        },
                    ]}
                />
            )
        }}
    />
)
