import { routes } from "../../../common/routes"
import { SpacedItems } from "../../../components/layouts/spaceditems/SpacedItems"
import { Tag } from "../../../components/tag/Tag"
import { NamedColorTile } from "../../../components/tiles/namedcolortile/NamedColorTile"
import { MissingOptionCustomizationDTOMissingOptionCustomizationDTOExteriorColorCustomization } from "../../../generated/pdsapi"
import { dashboardApi } from "../../../pdsapi"
import { DashboardPagedTableProps } from "../dashboardpagedtable/DashboardPagedTable"
import { missingOptionCustomizationsColumns } from "./parts/MissingOptionCustomizationsColumns"
import { spacing } from "@porsche-design-system/utilities"

export const MissingExteriorColorCustomizationsTableConfig: DashboardPagedTableProps<MissingOptionCustomizationDTOMissingOptionCustomizationDTOExteriorColorCustomization> =
    {
        titlePrefix: "Missing",
        itemsName: "Exterior Color Customizations",

        getRoute: (missingOptionCustomization) =>
            routes.exteriorColors.customizations.create(
                new URLSearchParams({
                    optionCode: missingOptionCustomization.optionCode,
                    modelGeneration: missingOptionCustomization.modelGeneration.key,
                })
            ),
        urlParamsPrefix: "exterior-color-customizations.missing",

        fetchPage: dashboardApi.getMissingExteriorColorCustomizations,
        columns: [
            ...missingOptionCustomizationsColumns,
            {
                grow: 1,
                headerCellProps: {
                    content: "Similar Customizations",
                },
                buildCellContent: (missingOptionCustomization) => (
                    <SpacedItems direction={"column"} lineSpacing={spacing[4]}>
                        {missingOptionCustomization.similarCustomizations.map((similarCustomization, index) => (
                            <Tag
                                key={`similar_customization_${index}`}
                                onClick={() => window.open(routes.exteriorColors.customizations.details(similarCustomization.key))}
                            >
                                <NamedColorTile
                                    label={`${similarCustomization.colorTypeName} for ${similarCustomization.modelGenerationNames.join(", ")}`}
                                    hexCode={similarCustomization.hexCode}
                                />
                            </Tag>
                        ))}
                    </SpacedItems>
                ),
            },
        ],
    }
