import { routes } from "../../../../common/routes"
import { booleanConverterGroup, getEnumConverterGroup, getObjectURLParamConverter } from "../../../../common/useURLSearchParamState"
import { InteriorColorTagItemDTO, OptionTagSortField, SortDirection } from "../../../../generated/pdsapi"
import { interiorColorTagApi, InteriorColorTagFilter } from "../../../../pdsapi"
import { getProductListContainer, ProductListContainerConfig } from "../../../../viewtemplates/list/ProductListContainer"
import { InteriorColorTagListColumnsConfig } from "./columns/InteriorColorTagListColumns"
import { InteriorColorTagListFilter } from "./filter/InteriorColorTagListFilter"
import { InteriorColorTagListFilterTagsConfig } from "./filter/InteriorColorTagListFilterTagsConfig"
import { Route } from "react-router-dom"

export const InteriorColorTagProductListContainerConfig: ProductListContainerConfig<
    InteriorColorTagItemDTO,
    InteriorColorTagFilter,
    unknown,
    OptionTagSortField
> = {
    listName: "Interior Color Tags",
    createItemLabel: "Add new InteriorColor Tag",

    buildDetailsPath: (interiorColorTag) => routes.interiors.colorTags.details(interiorColorTag.key),
    buildCreatePath: routes.interiors.colorTags.create,
    buildSortPath: routes.interiors.colorTags.sort,

    defaultFilter: {},
    filterUrlParamsConverter: getObjectURLParamConverter<InteriorColorTagFilter>({
        active: booleanConverterGroup.optional,
    }),
    defaultSorting: {
        sortField: OptionTagSortField.SORT_INDEX,
        sortDirection: SortDirection.ASC,
    },
    sortingUrlParamsConverter: getObjectURLParamConverter({
        sortField: getEnumConverterGroup(OptionTagSortField).required,
        sortDirection: getEnumConverterGroup(SortDirection).required,
    }),
    fetchPage: interiorColorTagApi.list.getPage,
    fetchFilterOptions: async () => ({}),

    defaultShowsFilter: false,
    Filter: InteriorColorTagListFilter,
    filterTagsConfig: InteriorColorTagListFilterTagsConfig,

    columnsConfig: InteriorColorTagListColumnsConfig,
}

const InteriorColorTagListContainer = getProductListContainer(InteriorColorTagProductListContainerConfig)

export const InteriorColorTagListRoute = <Route path={routes.interiors.colorTags.list()} Component={InteriorColorTagListContainer} />
