import { TableColumnsConfig } from "../../../../../components/filteredpagedtable/FilteredPagedTable"
import { ColumnBuilder } from "../../../../../components/layouts/table/Table"
import { OptionTagItemDTO, OptionTagSortField } from "../../../../../generated/pdsapi"
import { activeColumn } from "../../../../../viewtemplates/list/columns/activeColumn"
import { NamedColorTile } from "../../../../../components/tiles/namedcolortile/NamedColorTile"

type OptionTagColumnBuilder = ColumnBuilder<OptionTagItemDTO, OptionTagSortField>

export const OptionTagListColumnsConfig: TableColumnsConfig<OptionTagItemDTO, OptionTagSortField> = ({ sorting, setSorting }) => {
    const typeColumn: OptionTagColumnBuilder = {
        width: 30,
        headerCellProps: {
            content: "Type",
        },
        buildCellContent: (optionTag) => {
            switch (optionTag.type) {
                case "Equipment":
                    return "EQ"
                case "InteriorColor":
                    return "IC"
                case "ExteriorColor":
                    return "EC"
                case "RoofColor":
                    return "RC"
            }
        },
    }
    const sortIndexColumn: OptionTagColumnBuilder = {
        width: 30,
        headerCellProps: {
            content: "#",
            sorting: {
                field: OptionTagSortField.SORT_INDEX,
                value: sorting,
                setValue: setSorting,
            },
        },
        buildCellContent: (optionTag) => optionTag.sortIndex.toString(),
    }
    const nameColumn: OptionTagColumnBuilder = {
        grow: 1,
        headerCellProps: {
            content: "Name",
        },
        buildCellContent: (optionTag) => optionTag.name,
    }
    const additionalFieldsColumn: OptionTagColumnBuilder = {
        grow: 1,
        headerCellProps: {
            content: "Additional fields",
        },
        buildCellContent: (optionTag) => {
            switch (optionTag.type) {
                case "Equipment":
                    return optionTag.categoryName
                case "InteriorColor":
                case "ExteriorColor":
                case "RoofColor":
                    return optionTag.hexCode ? <NamedColorTile hexCode={optionTag.hexCode} label={optionTag.hexCode} /> : "-"
            }
        },
    }

    return [typeColumn, sortIndexColumn, nameColumn, additionalFieldsColumn, activeColumn]
}
