/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    OptionCreateRequestDTOPaintToSampleExteriorColorInternal,
    OptionCreateRequestDTOPaintToSampleExteriorColorInternalFromJSONTyped,
    OptionCreateRequestDTOPaintToSampleExteriorColorInternalToJSON,
} from './';

/**
 * @type OptionCreateRequestDTOPaintToSampleExteriorColor
 * 
 * @export
 */
export type OptionCreateRequestDTOPaintToSampleExteriorColor = { type: 'PaintToSampleExteriorColor' } & OptionCreateRequestDTOPaintToSampleExteriorColorInternal;

export function OptionCreateRequestDTOPaintToSampleExteriorColorFromJSON(json: any): OptionCreateRequestDTOPaintToSampleExteriorColor {
    return OptionCreateRequestDTOPaintToSampleExteriorColorFromJSONTyped(json, false);
}

export function OptionCreateRequestDTOPaintToSampleExteriorColorFromJSONTyped(json: any, ignoreDiscriminator: boolean): OptionCreateRequestDTOPaintToSampleExteriorColor {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    switch (json['type']) {
        case 'PaintToSampleExteriorColor':
            return {...OptionCreateRequestDTOPaintToSampleExteriorColorInternalFromJSONTyped(json, true), type: 'PaintToSampleExteriorColor'};
        default:
            throw new Error(`No variant of OptionCreateRequestDTOPaintToSampleExteriorColor exists with 'type=${json['type']}'`);
    }
}

export function OptionCreateRequestDTOPaintToSampleExteriorColorToJSON(value?: OptionCreateRequestDTOPaintToSampleExteriorColor | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    switch (value['type']) {
        case 'PaintToSampleExteriorColor':
            return OptionCreateRequestDTOPaintToSampleExteriorColorInternalToJSON(value);
        default:
            throw new Error(`No variant of OptionCreateRequestDTOPaintToSampleExteriorColor exists with 'type=${value['type']}'`);
    }
}

