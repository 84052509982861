import { booleanConverterGroup, getEnumConverterGroup, getObjectURLParamConverter } from "../../../../common/useURLSearchParamState"
import { ExteriorColorTagItemDTO, OptionTagSortField, SortDirection } from "../../../../generated/pdsapi"
import { exteriorColorTagApi, ExteriorColorTagFilter } from "../../../../pdsapi"
import { getProductListContainer, ProductListContainerConfig } from "../../../../viewtemplates/list/ProductListContainer"
import { ExteriorColorTagListColumnsConfig } from "./columns/ExteriorColorTagListColumns"
import { ExteriorColorTagListFilter } from "./filter/ExteriorColorTagListFilter"
import { ExteriorColorTagListFilterTagsConfig } from "./filter/ExteriorColorTagListFilterTagsConfig"
import { routes } from "../../../../common/routes"
import { Route } from "react-router-dom"

export const ExteriorColorTagProductListContainerConfig: ProductListContainerConfig<
    ExteriorColorTagItemDTO,
    ExteriorColorTagFilter,
    unknown,
    OptionTagSortField
> = {
    listName: "Exterior Color Tags",
    createItemLabel: "Add new ExteriorColor Tag",

    buildDetailsPath: (exteriorColorTag) => routes.exteriorColors.tags.details(exteriorColorTag.key),
    buildCreatePath: routes.exteriorColors.tags.create,
    buildSortPath: routes.exteriorColors.tags.sort,

    defaultFilter: {},
    filterUrlParamsConverter: getObjectURLParamConverter<ExteriorColorTagFilter>({
        active: booleanConverterGroup.optional,
    }),
    defaultSorting: {
        sortField: OptionTagSortField.SORT_INDEX,
        sortDirection: SortDirection.ASC,
    },
    sortingUrlParamsConverter: getObjectURLParamConverter({
        sortField: getEnumConverterGroup(OptionTagSortField).required,
        sortDirection: getEnumConverterGroup(SortDirection).required,
    }),
    fetchPage: exteriorColorTagApi.list.getPage,
    fetchFilterOptions: async () => ({}),

    defaultShowsFilter: false,
    Filter: ExteriorColorTagListFilter,
    filterTagsConfig: ExteriorColorTagListFilterTagsConfig,

    columnsConfig: ExteriorColorTagListColumnsConfig,
}

const ExteriorColorTagListContainer = getProductListContainer(ExteriorColorTagProductListContainerConfig)

export const ExteriorColorTagListRoute = <Route path={routes.exteriorColors.tags.list()} Component={ExteriorColorTagListContainer} />
