/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    OptionTagCreateRequestDTOEquipmentInternal,
    OptionTagCreateRequestDTOEquipmentInternalFromJSONTyped,
    OptionTagCreateRequestDTOEquipmentInternalToJSON,
} from './';

/**
 * @type OptionTagCreateRequestDTOEquipment
 * 
 * @export
 */
export type OptionTagCreateRequestDTOEquipment = { type: 'Equipment' } & OptionTagCreateRequestDTOEquipmentInternal;

export function OptionTagCreateRequestDTOEquipmentFromJSON(json: any): OptionTagCreateRequestDTOEquipment {
    return OptionTagCreateRequestDTOEquipmentFromJSONTyped(json, false);
}

export function OptionTagCreateRequestDTOEquipmentFromJSONTyped(json: any, ignoreDiscriminator: boolean): OptionTagCreateRequestDTOEquipment {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    switch (json['type']) {
        case 'Equipment':
            return {...OptionTagCreateRequestDTOEquipmentInternalFromJSONTyped(json, true), type: 'Equipment'};
        default:
            throw new Error(`No variant of OptionTagCreateRequestDTOEquipment exists with 'type=${json['type']}'`);
    }
}

export function OptionTagCreateRequestDTOEquipmentToJSON(value?: OptionTagCreateRequestDTOEquipment | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    switch (value['type']) {
        case 'Equipment':
            return OptionTagCreateRequestDTOEquipmentInternalToJSON(value);
        default:
            throw new Error(`No variant of OptionTagCreateRequestDTOEquipment exists with 'type=${value['type']}'`);
    }
}

