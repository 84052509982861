import { toTagOption } from "../../../../components/formelements/tagformelement/TagOption"
import { TagsFormElementView } from "../../../../components/formelements/tagsformelement/TagsFormElement"
import { ColumnBuilder } from "../../../../components/layouts/table/Table"
import { IncompleteCustomizationDTO } from "../../../../generated/pdsapi"
import { routes } from "../../../../common/routes"

export const incompleteCustomizationTableColumns: ColumnBuilder<IncompleteCustomizationDTO, undefined>[] = [
    {
        width: 100,
        headerCellProps: {
            content: "Option Code",
        },
        buildCellContent: (incompleteCustomization) => incompleteCustomization.optionCode,
    },
    {
        grow: 1,
        headerCellProps: {
            content: "Model Generations",
        },
        buildCellContent: (incompleteCustomization) => (
            <TagsFormElementView
                label={undefined}
                emptyLabel={"-"}
                tags={incompleteCustomization.modelGenerations.map((modelGeneration) => toTagOption(modelGeneration, routes.models.modelGenerations.details))}
            />
        ),
    },
]
