import { usePdsBootContext } from "../../common/PdsBootContext"
import { ViewState } from "../../common/ViewState"
import { showErrorToast } from "../../common/errorToastHelper"
import { browserLanguage } from "../../common/global"
import { UserAuthority } from "../../generated/pdsapi"
import { EmptyView } from "../emptyview/EmptyView"
import { Centered } from "../layouts/centered/Centered"
import { WidgetWrapper } from "../layouts/widgetwrapper/WidgetWrapper"
import { NewCustomizationButton } from "../newcustomizationbutton/NewCustomizationButton"
import { useToast } from "@finder/ui-kit"
import { PIcon, PText } from "@porsche-design-system/components-react"
import { JSX, useEffect, useRef, useState } from "react"
import { OldOrderTypeOptionKey } from "../../views/models/ordertypes/OrderTypeOptionKey"

interface LinkedCustomization<CUSTOMIZATION> {
    customization?: CUSTOMIZATION
    modelGenerationKey?: string
    hasSimilarCustomizations: boolean
}

interface LinkedCustomizationCardProps<CUSTOMIZATION, KEY extends OldOrderTypeOptionKey> {
    itemKey: KEY
    getLinkedCustomization: (
        props: KEY & {
            languageTag: string
        }
    ) => Promise<LinkedCustomization<CUSTOMIZATION>>
    CustomizationContent: (props: { customization: CUSTOMIZATION }) => JSX.Element
    getCreateCustomizationRoute: (params: URLSearchParams) => string
}

export const LinkedCustomizationCard = <CUSTOMIZATION, KEY extends OldOrderTypeOptionKey>({
    itemKey,
    getLinkedCustomization,
    CustomizationContent,
    getCreateCustomizationRoute,
}: LinkedCustomizationCardProps<CUSTOMIZATION, KEY>) => {
    const toastRef = useRef(useToast())
    const [linkedCustomization, setLinkedCustomization] = useState<LinkedCustomization<CUSTOMIZATION>>()

    useEffect(() => {
        const fetchLinkedCustomization = async () => {
            try {
                const linkedCustomization = await getLinkedCustomization({
                    ...itemKey,
                    languageTag: browserLanguage,
                })
                setLinkedCustomization(linkedCustomization)
            } catch (e) {
                showErrorToast(toastRef.current, e)
            }
        }

        fetchLinkedCustomization()
    }, [itemKey, toastRef, getLinkedCustomization])

    return linkedCustomization ? (
        <LinkedCustomizationCardContent
            linkedCustomization={linkedCustomization}
            optionCode={itemKey.optionCode}
            CustomizationContent={CustomizationContent}
            getCreateCustomizationRoute={getCreateCustomizationRoute}
        />
    ) : (
        <WidgetWrapper label={"Linked Customization"}>
            <EmptyView viewState={ViewState.LOADING} />
        </WidgetWrapper>
    )
}

export const LinkedCustomizationCardContent = <CUSTOMIZATION,>({
    linkedCustomization,
    optionCode,
    CustomizationContent,
    getCreateCustomizationRoute,
}: {
    linkedCustomization: LinkedCustomization<CUSTOMIZATION>
    optionCode: string
    CustomizationContent: (props: { customization: CUSTOMIZATION }) => JSX.Element
    getCreateCustomizationRoute: (params: URLSearchParams) => string
}) => {
    const { hasAuthority } = usePdsBootContext()

    const buttons =
        !linkedCustomization.customization && hasAuthority(UserAuthority.MAINTAIN_DATA)
            ? [
                  <NewCustomizationButton
                      key={"newCustomizationButton"}
                      createPath={getCreateCustomizationRoute(
                          linkedCustomization.modelGenerationKey
                              ? new URLSearchParams({
                                    optionCode,
                                    modelGenerationKeys: linkedCustomization.modelGenerationKey,
                                })
                              : new URLSearchParams({ optionCode })
                      )}
                      buttonLabel={"Add new Customization"}
                      hasSimilarCustomizations={linkedCustomization.hasSimilarCustomizations}
                  />,
              ]
            : []

    return (
        <WidgetWrapper label={"Linked Customization"} buttons={buttons}>
            {linkedCustomization.customization ? (
                <CustomizationContent customization={linkedCustomization.customization} />
            ) : linkedCustomization.hasSimilarCustomizations ? (
                <Centered>
                    <Centered height={"70px"}>
                        <PIcon key={"warnExistingSimilarCustomizationsIcon"} name={"information"} size={"large"} />
                    </Centered>
                    <PText key={"warnExistingSimilarCustomizationsMessage"} color={"neutral-contrast-medium"} weight={"thin"} align={"center"}>
                        Similar customizations exist (see below). Consider editing these instead of adding a new one.
                    </PText>
                </Centered>
            ) : null}
        </WidgetWrapper>
    )
}
