import { DefaultCardViewContentWrapper } from "../../../../components/layouts/defaultcardviewcontentwrapper/DefaultCardViewContentWrapper"
import { RoofColorTagCreateRequestDTO } from "../../../../generated/pdsapi"
import { roofColorTagApi } from "../../../../pdsapi"
import { getProductCreateContainer, ProductCreateContainerConfig } from "../../../../viewtemplates/create/ProductCreateContainer"
import { RoofColorTagCreateAttributesCard } from "./attributes/RoofColorTagCreateAttributesCard"
import { Route } from "react-router-dom"
import { routes } from "../../../../common/routes"
import { RoofColorTagProductListContainerConfig } from "../list/RoofColorTagListRoute"

export const RoofColorTagProductCreateContainerConfig: ProductCreateContainerConfig<RoofColorTagCreateRequestDTO, unknown> = {
    headline: "Create new Roof Color Tag",
    buildDetailsPath: ({ key }) => routes.roofColors.tags.details(key),

    create: (item) =>
        roofColorTagApi.create.create({
            roofColorTagCreateRequestDTO: item,
        }),
    getCreateOptions: async () => ({}),

    Content: (props) => (
        <DefaultCardViewContentWrapper>
            <RoofColorTagCreateAttributesCard {...props} />
        </DefaultCardViewContentWrapper>
    ),
}

const RoofColorTagCreateContainer = getProductCreateContainer(RoofColorTagProductCreateContainerConfig)

export const RoofColorTagCreateRoute = (
    <Route
        path={routes.roofColors.tags.create()}
        element={
            <RoofColorTagCreateContainer
                parentCrumbs={[
                    {
                        name: RoofColorTagProductListContainerConfig.listName,
                        path: routes.roofColors.tags.list(),
                    },
                ]}
            />
        }
    />
)
