import { DefaultCardViewContentWrapper } from "../../../../components/layouts/defaultcardviewcontentwrapper/DefaultCardViewContentWrapper"
import { CustomExteriorColorCreateOptionsDTO, CustomExteriorColorCreateRequestDTO } from "../../../../generated/pdsapi"
import { customExteriorColorApi } from "../../../../pdsapi"
import { getProductCreateContainer, ProductCreateContainerConfig } from "../../../../viewtemplates/create/ProductCreateContainer"
import { CustomExteriorColorCreateAttributesCard } from "./attributes/CustomExteriorColorCreateAttributesCard"
import { CustomExteriorColorCreateVehicleImagesCardContent } from "./vehicleimages/CustomExteriorColorCreateVehicleImagesCardContent"
import { routes } from "../../../../common/routes"
import { Route } from "react-router-dom"
import { CustomExteriorColorProductListContainerConfig } from "../list/CustomExteriorColorListRoute"

export const CustomExteriorColorProductCreateContainerConfig: ProductCreateContainerConfig<
    CustomExteriorColorCreateRequestDTO,
    CustomExteriorColorCreateOptionsDTO
> = {
    headline: "Create new Custom Exterior Color",
    buildDetailsPath: ({ key }) => routes.exteriorColors.customColors.details(key),

    create: (item) =>
        customExteriorColorApi.create.create({
            customExteriorColorCreateRequestDTO: item,
        }),
    getCreateOptions: customExteriorColorApi.create.getOptions,

    Content: (props) => (
        <>
            <DefaultCardViewContentWrapper>
                <CustomExteriorColorCreateAttributesCard {...props} />
            </DefaultCardViewContentWrapper>
            <DefaultCardViewContentWrapper>
                <CustomExteriorColorCreateVehicleImagesCardContent {...props} />
            </DefaultCardViewContentWrapper>
        </>
    ),
}

const CustomExteriorColorCreateContainer = getProductCreateContainer(CustomExteriorColorProductCreateContainerConfig)

export const CustomExteriorColorCreateRoute = (
    <Route
        path={routes.exteriorColors.customColors.create()}
        element={
            <CustomExteriorColorCreateContainer
                parentCrumbs={[
                    {
                        name: CustomExteriorColorProductListContainerConfig.listName,
                        path: routes.exteriorColors.customColors.list(),
                    },
                ]}
            />
        }
    />
)
