/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SelectOptionDTOString,
    SelectOptionDTOStringFromJSON,
    SelectOptionDTOStringFromJSONTyped,
    SelectOptionDTOStringToJSON,
} from './';

/**
 * 
 * @export
 * @interface ExteriorColorCustomizationFilterOptionsDTO
 */
export interface ExteriorColorCustomizationFilterOptionsDTO {
    /**
     * 
     * @type {Array<SelectOptionDTOString>}
     * @memberof ExteriorColorCustomizationFilterOptionsDTO
     */
    modelGenerations: Array<SelectOptionDTOString>;
    /**
     * 
     * @type {Array<SelectOptionDTOString>}
     * @memberof ExteriorColorCustomizationFilterOptionsDTO
     */
    exteriorColorTags: Array<SelectOptionDTOString>;
}

export function ExteriorColorCustomizationFilterOptionsDTOFromJSON(json: any): ExteriorColorCustomizationFilterOptionsDTO {
    return ExteriorColorCustomizationFilterOptionsDTOFromJSONTyped(json, false);
}

export function ExteriorColorCustomizationFilterOptionsDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExteriorColorCustomizationFilterOptionsDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'modelGenerations': ((json['modelGenerations'] as Array<any>).map(SelectOptionDTOStringFromJSON)),
        'exteriorColorTags': ((json['exteriorColorTags'] as Array<any>).map(SelectOptionDTOStringFromJSON)),
    };
}

export function ExteriorColorCustomizationFilterOptionsDTOToJSON(value?: ExteriorColorCustomizationFilterOptionsDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'modelGenerations': ((value.modelGenerations as Array<any>).map(SelectOptionDTOStringToJSON)),
        'exteriorColorTags': ((value.exteriorColorTags as Array<any>).map(SelectOptionDTOStringToJSON)),
    };
}


