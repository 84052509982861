import { optionTagApi } from "../../../../pdsapi"
import { getProductSortContainer, ProductSortContainerConfig } from "../../../../viewtemplates/sort/ProductSortContainer"
import { Route } from "react-router-dom"
import { routes } from "../../../../common/routes"
import { OptionTagProductListContainerConfig } from "../list/OptionTagListRoute"

export const OptionTagSortContainerTemplateProps: ProductSortContainerConfig = {
    fetchSortables: optionTagApi.sort.getSortableItems,
    updateSortIndices: (sortIndexUpdate) => optionTagApi.sort.updateSortIndices({ sortIndexUpdateRequestDTO: sortIndexUpdate }),
}

const OptionTagSortContainer = getProductSortContainer(OptionTagSortContainerTemplateProps)

export const OptionTagSortRoute = (
    <Route
        path={routes.options.tags.sort()}
        element={
            <OptionTagSortContainer
                parentCrumbs={[
                    {
                        name: OptionTagProductListContainerConfig.listName,
                        path: routes.options.tags.list(),
                    },
                ]}
            />
        }
    />
)
