import { propertiesOf } from "../../../../../../common/property"
import { FormElementWrapper } from "../../../../../../components/formelements/FormElementWrapper"
import { getBoolFormElement } from "../../../../../../components/formelements/boolformelement/BoolFormElement"
import { toOption } from "../../../../../../components/formelements/selecttextformelement/Option"
import { getSelectTextFormElement } from "../../../../../../components/formelements/selecttextformelement/SelectTextFormElement"
import { getTextFormElement } from "../../../../../../components/formelements/textformelement/TextFormElement"
import { rowGroupsSpacing, RowGroupWrapper } from "../../../../../../components/layouts/rowgroupwrapper/RowGroupWrapper"
import { SpacedItems } from "../../../../../../components/layouts/spaceditems/SpacedItems"
import { EquipmentTagAttributesDTO, EquipmentTagAttributesUpdateDTO } from "../../../../../../generated/pdsapi"
import { ProductDetailsCardContentProps } from "../../../../../../viewtemplates/details/cards/ProductDetailsCard"
import { DetailsDefaultLocalizationsFormRow } from "../../../../../../viewtemplates/details/cards/components/defaultlocalizationsformrow/DetailsDefaultLocalizationsFormRow"
import { FC } from "react"
import { BasicKey } from "../../../../../../viewtemplates/details/ProductDetailsContainer"

export const EquipmentTagDetailsAttributesCardContent: FC<
    ProductDetailsCardContentProps<EquipmentTagAttributesDTO, EquipmentTagAttributesUpdateDTO, BasicKey>
> = ({ formDisplayData }) => {
    const properties = propertiesOf<EquipmentTagAttributesUpdateDTO>()

    return (
        <SpacedItems direction={"column"} lineSpacing={rowGroupsSpacing}>
            <RowGroupWrapper label={"Configuration"}>
                <FormElementWrapper
                    label={"Code"}
                    formDisplayData={formDisplayData}
                    field={properties.key}
                    FormElement={getTextFormElement<EquipmentTagAttributesDTO, EquipmentTagAttributesUpdateDTO>()}
                    getViewProps={(data) => ({
                        value: data.key,
                        showAsHint: false,
                    })}
                    getEditProps={(data) => ({
                        value: data.key,
                        readOnly: true,
                    })}
                />

                <FormElementWrapper
                    label={"Category"}
                    formDisplayData={formDisplayData}
                    field={properties.category}
                    FormElement={getSelectTextFormElement<EquipmentTagAttributesDTO, EquipmentTagAttributesUpdateDTO>()}
                    getViewProps={(data) => ({
                        text: data.category.value.label,
                    })}
                    getEditProps={(data) => ({
                        value: data.category.value.key,
                        options: data.category.options.map(toOption),
                    })}
                />
            </RowGroupWrapper>

            <DetailsDefaultLocalizationsFormRow
                label={"Name"}
                formDisplayData={formDisplayData}
                field={properties.name}
                getViewProps={(data) => ({
                    value: data.name,
                })}
                getEditProps={(data) => ({
                    value: data.name,
                })}
            />

            <RowGroupWrapper label={"Status"}>
                <FormElementWrapper
                    label={"Active"}
                    formDisplayData={formDisplayData}
                    field={properties.active}
                    FormElement={getBoolFormElement<EquipmentTagAttributesDTO, EquipmentTagAttributesUpdateDTO>()}
                    getViewProps={(data) => ({
                        falseMessage: "Inactive",
                        trueMessage: "Active",
                        value: data.active,
                    })}
                    getEditProps={(data) => ({
                        falseMessage: "Inactive",
                        trueMessage: "Active",
                        value: data.active,
                    })}
                />
            </RowGroupWrapper>
        </SpacedItems>
    )
}
