/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    OptionDTOPaintToSampleExteriorColor,
    OptionDTOPaintToSampleExteriorColorFromJSON,
    OptionDTOPaintToSampleExteriorColorFromJSONTyped,
    OptionDTOPaintToSampleExteriorColorToJSON,
} from './';

/**
 * 
 * @export
 * @interface PageDTOOptionDTOPaintToSampleExteriorColor
 */
export interface PageDTOOptionDTOPaintToSampleExteriorColor {
    /**
     * 
     * @type {number}
     * @memberof PageDTOOptionDTOPaintToSampleExteriorColor
     */
    totalItemCount: number;
    /**
     * 
     * @type {Array<OptionDTOPaintToSampleExteriorColor>}
     * @memberof PageDTOOptionDTOPaintToSampleExteriorColor
     */
    content: Array<OptionDTOPaintToSampleExteriorColor>;
}

export function PageDTOOptionDTOPaintToSampleExteriorColorFromJSON(json: any): PageDTOOptionDTOPaintToSampleExteriorColor {
    return PageDTOOptionDTOPaintToSampleExteriorColorFromJSONTyped(json, false);
}

export function PageDTOOptionDTOPaintToSampleExteriorColorFromJSONTyped(json: any, ignoreDiscriminator: boolean): PageDTOOptionDTOPaintToSampleExteriorColor {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'totalItemCount': json['totalItemCount'],
        'content': ((json['content'] as Array<any>).map(OptionDTOPaintToSampleExteriorColorFromJSON)),
    };
}

export function PageDTOOptionDTOPaintToSampleExteriorColorToJSON(value?: PageDTOOptionDTOPaintToSampleExteriorColor | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'totalItemCount': value.totalItemCount,
        'content': ((value.content as Array<any>).map(OptionDTOPaintToSampleExteriorColorToJSON)),
    };
}


