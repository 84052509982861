import { routes } from "../../../../common/routes"
import { roofColorCustomizationApi } from "../../../../pdsapi"
import { getCustomizationDetailsContainer } from "../../../../viewtemplates/details/CustomizationDetailsContainer"
import { BasicKey, ProductDetailsContainerConfig } from "../../../../viewtemplates/details/ProductDetailsContainer"
import { ProductDetailsCard } from "../../../../viewtemplates/details/cards/ProductDetailsCard"
import { DetailsLinkedOptionsCard } from "../../../../viewtemplates/details/cards/linkedoptionscard/DetailsLinkedOptionsCard"
import { DetailsVehicleImagesCard } from "../../../../viewtemplates/details/cards/vehicleimagescard/DetailsVehicleImagesCard"
import { ProductDetailsTab } from "../../../../viewtemplates/details/tabs/ProductDetailsTab"
import { DetailsOptionCustomizationLocalizationTab } from "../../../../viewtemplates/details/tabs/optioncustomizationlocalizationtab/DetailsOptionCustomizationLocalizationTab"
import { RoofColorCustomizationDetailsAttributesCardContent } from "./data/attributes/RoofColorCustomizationDetailsAttributesCardContent"
import { Route, useParams } from "react-router-dom"
import { RoofColorCustomizationProductListContainerConfig } from "../list/RoofColorCustomizationListRoute"

export const RoofColorCustomizationProductDetailsContainerConfig: ProductDetailsContainerConfig<BasicKey> = {
    getBaseInformation: roofColorCustomizationApi.details.getBaseInformation,
    deleteByKey: roofColorCustomizationApi.details.deleteByKey,
    tabsConfig: [
        {
            label: "Data",
            Content: (props) => (
                <ProductDetailsTab>
                    <ProductDetailsCard
                        {...props}
                        label={"Attributes"}
                        getData={roofColorCustomizationApi.details.getAttributes}
                        updateData={({ key, update }) =>
                            roofColorCustomizationApi.details.updateAttributes({
                                key,
                                oldOptionCustomizationAttributesUpdateDTORoofColorCustomizationAttributesValueUpdateDTO: update,
                            })
                        }
                        Content={RoofColorCustomizationDetailsAttributesCardContent}
                    />
                    <DetailsLinkedOptionsCard
                        {...props}
                        getData={roofColorCustomizationApi.details.getLinks}
                        getRoute={({ orderTypeKey, optionCode }) => routes.models.orderTypes.subpages(orderTypeKey).roofColors.details(optionCode)}
                    />
                    <DetailsVehicleImagesCard {...props} load={roofColorCustomizationApi.details.getVehicleImages} />
                </ProductDetailsTab>
            ),
        },
        {
            label: "Name",
            Content: (props) => (
                <DetailsOptionCustomizationLocalizationTab
                    {...props}
                    getData={roofColorCustomizationApi.details.getName}
                    updateDefaultValue={roofColorCustomizationApi.details.updateDefaultName}
                    updateConditionalValue={roofColorCustomizationApi.details.updateConditionalValueName}
                />
            ),
        },
        {
            label: "Description",
            Content: (props) => (
                <DetailsOptionCustomizationLocalizationTab
                    {...props}
                    getData={roofColorCustomizationApi.details.getDescription}
                    updateDefaultValue={roofColorCustomizationApi.details.updateDefaultDescription}
                    updateConditionalValue={roofColorCustomizationApi.details.updateConditionalValueDescription}
                />
            ),
        },
    ],
}

const RoofColorCustomizationDetailsContainer = getCustomizationDetailsContainer(RoofColorCustomizationProductDetailsContainerConfig)

export const RoofColorCustomizationDetailsRoute = (
    <Route
        path={routes.roofColors.customizations.details(":key")}
        Component={() => {
            const params = useParams<{ key: string }>()

            return (
                <RoofColorCustomizationDetailsContainer
                    itemKey={params}
                    parentCrumbs={[
                        {
                            name: RoofColorCustomizationProductListContainerConfig.listName,
                            path: routes.roofColors.customizations.list(),
                        },
                    ]}
                />
            )
        }}
    />
)
