import { TagFormElementView } from "../../../../../../../components/formelements/tagformelement/TagFormElement"
import { TextFormElementView } from "../../../../../../../components/formelements/textformelement/TextFormElement"
import { rowGroupsSpacing, RowGroupWrapper } from "../../../../../../../components/layouts/rowgroupwrapper/RowGroupWrapper"
import { SpacedItems } from "../../../../../../../components/layouts/spaceditems/SpacedItems"
import { LinkedPaintToSampleExteriorColorCustomizationDTOCustomization, OptionCustomizationTypeDTO } from "../../../../../../../generated/pdsapi"
import { routes } from "../../../../../../../common/routes"

export const LinkedPaintToSampleExteriorColorCustomizationCardContent = ({
    customization,
}: {
    customization: LinkedPaintToSampleExteriorColorCustomizationDTOCustomization
}) => (
    <SpacedItems direction={"column"} lineSpacing={rowGroupsSpacing}>
        <RowGroupWrapper label={"Configuration"}>
            <TagFormElementView
                label={undefined}
                tag={{
                    label: "Jump to customization",
                    route: routes.options.customizations.details({
                        type: OptionCustomizationTypeDTO.PAINT_TO_SAMPLE_EXTERIOR_COLOR,
                        key: customization.key,
                    }),
                }}
            />
        </RowGroupWrapper>
        <RowGroupWrapper label={"Name"}>
            {(["de", "en"] as ("en" | "de")[]).map((languageTag) => (
                <TextFormElementView key={languageTag} label={`Name - ${languageTag}`} value={customization.value?.name[languageTag]} />
            ))}
        </RowGroupWrapper>
    </SpacedItems>
)
