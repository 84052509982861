import { routes } from "../../../../../../common/routes"
import { getObjectURLParamConverter, stringConverterGroup } from "../../../../../../common/useURLSearchParamState"
import { OptionSortField, OrderTypeRoofColorFilterOptionsDTO, OrderTypeRoofColorItemDTO } from "../../../../../../generated/pdsapi"
import { orderTypeApi, OrderTypeRoofColorsFilter } from "../../../../../../pdsapi"
import { OrderTypeOptionsCardProps } from "../OrderTypeOptionsCard"
import { OrderTypeRoofColorsCardColumnsConfig } from "./columns/OrderTypeRoofColorsCardColumns"
import { OrderTypeRoofColorsCardFilter } from "./filter/OrderTypeRoofColorsCardFilter"
import { OrderTypeRoofColorsCardFilterTagsConfig } from "./filter/OrderTypeRoofColorsCardFilterTagsConfig"

export const orderTypeRoofColorsCardConfig: OrderTypeOptionsCardProps<
    OrderTypeRoofColorItemDTO,
    OrderTypeRoofColorsFilter,
    OrderTypeRoofColorFilterOptionsDTO,
    OptionSortField
> = {
    label: "RoofColor",

    getCreateRoute: (orderTypeKey) => routes.models.orderTypes.subpages(orderTypeKey).roofColors.create(),
    getDetailsRoute: (orderTypeKey, option) => routes.models.orderTypes.subpages(orderTypeKey).roofColors.details(option.optionCode),
    getOptions: orderTypeApi.details.options.getRoofColors,
    getFilterOptions: orderTypeApi.details.options.getRoofColorFilterOptions,

    defaultSortField: OptionSortField.SORT_INDEX,
    sortFieldEnum: OptionSortField,

    defaultFilter: {},
    filterUrlParamsConverter: getObjectURLParamConverter<OrderTypeRoofColorsFilter>({
        tagKeys: stringConverterGroup.optionalArray,
    }),

    columnsConfig: OrderTypeRoofColorsCardColumnsConfig,
    FilterComponent: OrderTypeRoofColorsCardFilter,
    filterTagsConfig: OrderTypeRoofColorsCardFilterTagsConfig,

    isInitiallyExpanded: false,
}
