import { booleanConverterGroup, getEnumConverterGroup, getObjectURLParamConverter } from "../../../../common/useURLSearchParamState"
import { OptionTagSortField, RoofColorTagItemDTO, SortDirection } from "../../../../generated/pdsapi"
import { roofColorTagApi, RoofColorTagFilter } from "../../../../pdsapi"
import { getProductListContainer, ProductListContainerConfig } from "../../../../viewtemplates/list/ProductListContainer"
import { RoofColorTagListColumnsConfig } from "./columns/RoofColorTagListColumns"
import { RoofColorTagListFilter } from "./filter/RoofColorTagListFilter"
import { RoofColorTagListFilterTagsConfig } from "./filter/RoofColorTagListFilterTagsConfig"
import { routes } from "../../../../common/routes"
import { Route } from "react-router-dom"

export const RoofColorTagProductListContainerConfig: ProductListContainerConfig<RoofColorTagItemDTO, RoofColorTagFilter, unknown, OptionTagSortField> = {
    listName: "Roof Color Tags",
    createItemLabel: "Add new RoofColor Tag",

    buildDetailsPath: (roofColorTag) => routes.roofColors.tags.details(roofColorTag.key),
    buildCreatePath: routes.roofColors.tags.create,
    buildSortPath: routes.roofColors.tags.sort,

    defaultFilter: {},
    filterUrlParamsConverter: getObjectURLParamConverter<RoofColorTagFilter>({
        active: booleanConverterGroup.optional,
    }),
    defaultSorting: {
        sortField: OptionTagSortField.SORT_INDEX,
        sortDirection: SortDirection.ASC,
    },
    sortingUrlParamsConverter: getObjectURLParamConverter({
        sortField: getEnumConverterGroup(OptionTagSortField).required,
        sortDirection: getEnumConverterGroup(SortDirection).required,
    }),
    fetchPage: roofColorTagApi.list.getPage,
    fetchFilterOptions: async () => ({}),

    defaultShowsFilter: false,
    Filter: RoofColorTagListFilter,
    filterTagsConfig: RoofColorTagListFilterTagsConfig,

    columnsConfig: RoofColorTagListColumnsConfig,
}

const RoofColorTagListContainer = getProductListContainer(RoofColorTagProductListContainerConfig)

export const RoofColorTagListRoute = <Route path={routes.roofColors.tags.list()} Component={RoofColorTagListContainer} />
