import { propertiesOf, Property } from "../../../../../../common/property"
import { FormElementWrapper, ViewLabeledContentWrapper } from "../../../../../../components/formelements/FormElementWrapper"
import { getBoolFormElement } from "../../../../../../components/formelements/boolformelement/BoolFormElement"
import { getColorPickerFormElement } from "../../../../../../components/formelements/colorpickerformelement/ColorPickerFormElement"
import { rowGroupsSpacing, RowGroupWrapper } from "../../../../../../components/layouts/rowgroupwrapper/RowGroupWrapper"
import { SpacedItems } from "../../../../../../components/layouts/spaceditems/SpacedItems"
import {
    OptionTagAttributesDTO,
    OptionTagAttributesDTOEquipment,
    OptionTagAttributesDTOExteriorColor,
    OptionTagAttributesDTOInteriorColor,
    OptionTagAttributesDTORoofColor,
    OptionTagAttributesUpdateDTOEquipment,
    OptionTagAttributesUpdateDTOExteriorColor,
    OptionTagAttributesUpdateDTOInteriorColor,
    OptionTagAttributesUpdateDTOOptionTag,
    OptionTagAttributesUpdateDTORoofColor,
} from "../../../../../../generated/pdsapi"
import { ProductDetailsCardContentProps } from "../../../../../../viewtemplates/details/cards/ProductDetailsCard"
import { DetailsDefaultLocalizationsFormRow } from "../../../../../../viewtemplates/details/cards/components/defaultlocalizationsformrow/DetailsDefaultLocalizationsFormRow"
import { FC } from "react"
import { PText } from "@porsche-design-system/components-react"
import { FormDisplayData } from "../../../../../../components/formelements/FormDisplayData"
import { getSelectTextFormElement } from "../../../../../../components/formelements/selecttextformelement/SelectTextFormElement"
import { optionTagTypeOptions } from "../../../optionTagTypes"
import { toOption } from "../../../../../../components/formelements/selecttextformelement/Option"
import { OptionTagKey } from "../../../OptionTagKey"

export const OptionTagDetailsAttributesCardContent: FC<
    ProductDetailsCardContentProps<OptionTagAttributesDTO, OptionTagAttributesUpdateDTOOptionTag, OptionTagKey>
> = ({ formDisplayData }) => {
    const properties = propertiesOf<OptionTagAttributesUpdateDTOOptionTag>()

    return (
        <SpacedItems direction={"column"} lineSpacing={rowGroupsSpacing}>
            <RowGroupWrapper label={"Configuration"}>
                <FormElementWrapper
                    label={"Type"}
                    formDisplayData={formDisplayData}
                    field={properties.type}
                    FormElement={getSelectTextFormElement<OptionTagAttributesDTO, OptionTagAttributesUpdateDTOOptionTag>()}
                    getViewProps={(data) => ({
                        text: optionTagTypeOptions.find((option) => option.value === data.type)?.label ?? data.type,
                    })}
                    getEditProps={(data) => ({
                        value: data.type,
                        options: optionTagTypeOptions,
                        readOnly: true,
                    })}
                />

                <ViewLabeledContentWrapper label={"Key"}>
                    <PText>{formDisplayData.data?.key ?? "-"}</PText>
                </ViewLabeledContentWrapper>
            </RowGroupWrapper>

            <DetailsDefaultLocalizationsFormRow
                label={"Name"}
                formDisplayData={formDisplayData}
                field={properties.name}
                getViewProps={(data) => ({
                    value: data.name,
                })}
                getEditProps={(data) => ({
                    value: data.name,
                })}
            />

            <RowGroupWrapper label={"Status"}>
                <FormElementWrapper
                    label={"Active"}
                    formDisplayData={formDisplayData}
                    field={properties.active}
                    FormElement={getBoolFormElement<OptionTagAttributesDTO, OptionTagAttributesUpdateDTOOptionTag>()}
                    getViewProps={(data) => ({
                        falseMessage: "Inactive",
                        trueMessage: "Active",
                        value: data.active,
                    })}
                    getEditProps={(data) => ({
                        falseMessage: "Inactive",
                        trueMessage: "Active",
                        value: data.active,
                    })}
                />
            </RowGroupWrapper>
            <SpecificOptionCustomizationValueContent properties={properties} formDisplayData={formDisplayData} />
        </SpacedItems>
    )
}

interface SpecificOptionCustomizationValueContentProps<
    ATTRIBUTES extends OptionTagAttributesDTO = OptionTagAttributesDTO,
    UPDATE extends OptionTagAttributesUpdateDTOOptionTag = OptionTagAttributesUpdateDTOOptionTag,
> {
    properties: Property<UPDATE, UPDATE>
    formDisplayData: FormDisplayData<ATTRIBUTES, UPDATE>
}

const SpecificOptionCustomizationValueContent: FC<SpecificOptionCustomizationValueContentProps> = (props) => {
    switch (props.formDisplayData.data?.type) {
        case "Equipment": {
            const { formDisplayData, properties } = props as SpecificOptionCustomizationValueContentProps<
                OptionTagAttributesDTOEquipment,
                OptionTagAttributesUpdateDTOEquipment
            >

            return (
                <RowGroupWrapper label={"Category"}>
                    <FormElementWrapper
                        label={"Equipment Tag Category"}
                        formDisplayData={formDisplayData}
                        field={properties.category}
                        FormElement={getSelectTextFormElement<OptionTagAttributesDTOEquipment, OptionTagAttributesUpdateDTOEquipment>()}
                        getViewProps={(data) => ({
                            text: data.category.value.label,
                        })}
                        getEditProps={(data) => ({
                            value: data.category.value.key,
                            options: data.category.options.map(toOption),
                        })}
                    />
                </RowGroupWrapper>
            )
        }

        case "InteriorColor":
        case "ExteriorColor":
        case "RoofColor": {
            type AttributesDTO = OptionTagAttributesDTOInteriorColor | OptionTagAttributesDTOExteriorColor | OptionTagAttributesDTORoofColor
            type AttributesUpdateDTO =
                | OptionTagAttributesUpdateDTOInteriorColor
                | OptionTagAttributesUpdateDTOExteriorColor
                | OptionTagAttributesUpdateDTORoofColor
            const { formDisplayData, properties } = props as SpecificOptionCustomizationValueContentProps<AttributesDTO, AttributesUpdateDTO>

            return (
                <RowGroupWrapper label={"Color"}>
                    <FormElementWrapper
                        label={"Hex Code"}
                        formDisplayData={formDisplayData}
                        field={properties.hexCode}
                        FormElement={getColorPickerFormElement<AttributesDTO, AttributesUpdateDTO>()}
                        getViewProps={(data) => ({ hexValue: data.hexCode })}
                        getEditProps={(data) => ({ hexValue: data.hexCode })}
                    />
                </RowGroupWrapper>
            )
        }
        case undefined:
            return null
    }
}
