import { getLanguageGroups } from "../../../../../common/getLanguageGroups"
import { propertiesOf } from "../../../../../common/property"
import { FormElementWrapper } from "../../../../../components/formelements/FormElementWrapper"
import {
    DerivedNameFormElementEditProps,
    DerivedNameFormElementViewProps,
    getDerivedNameFormElement,
} from "../../../../../components/formelements/derivednameformelement/DerivedNameFormElement"
import { RowGroupWrapper } from "../../../../../components/layouts/rowgroupwrapper/RowGroupWrapper"
import { SpacedItems } from "../../../../../components/layouts/spaceditems/SpacedItems"
import { LocalizationsUpdateDTO } from "../../../../../pdsapi"
import { ProductDetailsCardContentProps } from "../../ProductDetailsCard"
import { spacing } from "@porsche-design-system/utilities"
import { useMemo } from "react"
import { useIntl } from "react-intl"

export interface DetailsDerivedLocalizationCardContentProps<DATA> {
    getViewProps: (data: DATA, languageTag: string) => DerivedNameFormElementViewProps
    getEditProps: (data: DATA, languageTag: string) => DerivedNameFormElementEditProps
}

export const DetailsDerivedLocalizationCardContent = <DATA, KEY>({
    formDisplayData,
    getViewProps,
    getEditProps,
}: ProductDetailsCardContentProps<DATA, LocalizationsUpdateDTO, KEY> & DetailsDerivedLocalizationCardContentProps<DATA>) => {
    const intl = useIntl()
    const properties = propertiesOf<LocalizationsUpdateDTO>()

    const languageGroups = useMemo(() => getLanguageGroups(Object.keys(formDisplayData.data ?? {}), intl), [formDisplayData.data, intl])

    return (
        <SpacedItems lineSpacing={spacing[32]} direction={"column"}>
            {languageGroups.map(({ label, languages }) => (
                <RowGroupWrapper key={label} label={label}>
                    {languages.map(({ label, languageTag }) => (
                        <FormElementWrapper
                            key={languageTag}
                            label={label}
                            formDisplayData={formDisplayData}
                            field={properties._get(languageTag)}
                            FormElement={getDerivedNameFormElement<DATA, LocalizationsUpdateDTO>()}
                            getViewProps={(data) => getViewProps(data, languageTag)}
                            getEditProps={(data) => getEditProps(data, languageTag)}
                        />
                    ))}
                </RowGroupWrapper>
            ))}
        </SpacedItems>
    )
}
