/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SelectOptionDTOInteriorMaterial,
    SelectOptionDTOInteriorMaterialFromJSON,
    SelectOptionDTOInteriorMaterialFromJSONTyped,
    SelectOptionDTOInteriorMaterialToJSON,
    SelectOptionDTOString,
    SelectOptionDTOStringFromJSON,
    SelectOptionDTOStringFromJSONTyped,
    SelectOptionDTOStringToJSON,
} from './';

/**
 * 
 * @export
 * @interface InteriorCustomizationCreateOptionsDTO
 */
export interface InteriorCustomizationCreateOptionsDTO {
    /**
     * 
     * @type {Array<SelectOptionDTOString>}
     * @memberof InteriorCustomizationCreateOptionsDTO
     */
    modelGenerations: Array<SelectOptionDTOString>;
    /**
     * 
     * @type {Array<SelectOptionDTOString>}
     * @memberof InteriorCustomizationCreateOptionsDTO
     */
    interiorColorTags: Array<SelectOptionDTOString>;
    /**
     * 
     * @type {Array<SelectOptionDTOInteriorMaterial>}
     * @memberof InteriorCustomizationCreateOptionsDTO
     */
    interiorMaterials: Array<SelectOptionDTOInteriorMaterial>;
}

export function InteriorCustomizationCreateOptionsDTOFromJSON(json: any): InteriorCustomizationCreateOptionsDTO {
    return InteriorCustomizationCreateOptionsDTOFromJSONTyped(json, false);
}

export function InteriorCustomizationCreateOptionsDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): InteriorCustomizationCreateOptionsDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'modelGenerations': ((json['modelGenerations'] as Array<any>).map(SelectOptionDTOStringFromJSON)),
        'interiorColorTags': ((json['interiorColorTags'] as Array<any>).map(SelectOptionDTOStringFromJSON)),
        'interiorMaterials': ((json['interiorMaterials'] as Array<any>).map(SelectOptionDTOInteriorMaterialFromJSON)),
    };
}

export function InteriorCustomizationCreateOptionsDTOToJSON(value?: InteriorCustomizationCreateOptionsDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'modelGenerations': ((value.modelGenerations as Array<any>).map(SelectOptionDTOStringToJSON)),
        'interiorColorTags': ((value.interiorColorTags as Array<any>).map(SelectOptionDTOStringToJSON)),
        'interiorMaterials': ((value.interiorMaterials as Array<any>).map(SelectOptionDTOInteriorMaterialToJSON)),
    };
}


