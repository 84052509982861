/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    RelatedOptionDTOStringString,
    RelatedOptionDTOStringStringFromJSON,
    RelatedOptionDTOStringStringFromJSONTyped,
    RelatedOptionDTOStringStringToJSON,
    SelectOptionDTOString,
    SelectOptionDTOStringFromJSON,
    SelectOptionDTOStringFromJSONTyped,
    SelectOptionDTOStringToJSON,
} from './';

/**
 * 
 * @export
 * @interface ModelGenerationCreateOptionsDTO
 */
export interface ModelGenerationCreateOptionsDTO {
    /**
     * 
     * @type {Array<SelectOptionDTOString>}
     * @memberof ModelGenerationCreateOptionsDTO
     */
    modelSeries: Array<SelectOptionDTOString>;
    /**
     * 
     * @type {Array<RelatedOptionDTOStringString>}
     * @memberof ModelGenerationCreateOptionsDTO
     */
    modelSeriesRelatedModelGenerations: Array<RelatedOptionDTOStringString>;
}

export function ModelGenerationCreateOptionsDTOFromJSON(json: any): ModelGenerationCreateOptionsDTO {
    return ModelGenerationCreateOptionsDTOFromJSONTyped(json, false);
}

export function ModelGenerationCreateOptionsDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): ModelGenerationCreateOptionsDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'modelSeries': ((json['modelSeries'] as Array<any>).map(SelectOptionDTOStringFromJSON)),
        'modelSeriesRelatedModelGenerations': ((json['modelSeriesRelatedModelGenerations'] as Array<any>).map(RelatedOptionDTOStringStringFromJSON)),
    };
}

export function ModelGenerationCreateOptionsDTOToJSON(value?: ModelGenerationCreateOptionsDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'modelSeries': ((value.modelSeries as Array<any>).map(SelectOptionDTOStringToJSON)),
        'modelSeriesRelatedModelGenerations': ((value.modelSeriesRelatedModelGenerations as Array<any>).map(RelatedOptionDTOStringStringToJSON)),
    };
}


