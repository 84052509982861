import { Crumb } from "../../../components/breadcrumb/Breadcrumb"
import { useOrderTypeName } from "../../../hooks/useOrderTypeName"
import { OrderTypeProductListContainerConfig } from "./list/OrderTypeListRoute"
import { routes } from "../../../common/routes"

export const useOrderTypeSubpageCrumbs = (orderTypeKey: string): Crumb[] => {
    const orderTypeName = useOrderTypeName(orderTypeKey)

    return [
        {
            name: OrderTypeProductListContainerConfig.listName,
            path: routes.models.orderTypes.list(),
        },
        {
            name: orderTypeName,
            path: routes.models.orderTypes.details(orderTypeKey),
        },
    ]
}
