import { TableColumnsConfig } from "../../../../../../../components/filteredpagedtable/FilteredPagedTable"
import { ColumnBuilder } from "../../../../../../../components/layouts/table/Table"
import { getSortIndexColumnBuilder } from "../../../../../../../components/pagedtable/columns/columnBuilder"
import { NamedColorTile } from "../../../../../../../components/tiles/namedcolortile/NamedColorTile"
import { OptionSortField, OrderTypeRoofColorItemDTO } from "../../../../../../../generated/pdsapi"
import { OptionImage } from "../../../../../../../components/optionimage/OptionImage"

type RoofColorItemCardColumnBuilder = ColumnBuilder<OrderTypeRoofColorItemDTO, OptionSortField>

export const OrderTypeRoofColorsCardColumnsConfig: TableColumnsConfig<OrderTypeRoofColorItemDTO, OptionSortField> = ({ sorting, setSorting }) => {
    const previewColumn: RoofColorItemCardColumnBuilder = {
        grow: 1,
        headerCellProps: {
            content: "Preview",
        },
        buildCellContent: (roofColor) => <OptionImage imageKey={roofColor.imageKey} sizes={"100px"} />,
    }

    const sortIndexColumn: RoofColorItemCardColumnBuilder = getSortIndexColumnBuilder((roofColor) => roofColor.sortIndex, {
        field: OptionSortField.SORT_INDEX,
        value: sorting,
        setValue: setSorting,
    })

    const optionCodeColumn: RoofColorItemCardColumnBuilder = {
        grow: 1,
        headerCellProps: {
            content: "Option Code",
        },
        buildCellContent: (roofColor) => roofColor.optionCode,
    }

    const nameColumn: RoofColorItemCardColumnBuilder = {
        grow: 3,
        headerCellProps: {
            content: "Name",
        },
        buildCellContent: (roofColor) => roofColor.name ?? "-",
    }

    const colorColumn: RoofColorItemCardColumnBuilder = {
        grow: 2,
        headerCellProps: {
            content: "Color",
        },
        buildCellContent: (roofColor) => (roofColor.color ? <NamedColorTile label={roofColor.color.label} hexCode={roofColor.color.hexCode} /> : "-"),
    }

    const tagColumn: RoofColorItemCardColumnBuilder = {
        grow: 3,
        headerCellProps: {
            content: "Tag",
        },
        buildCellContent: (roofColor) => (roofColor.tag ? <NamedColorTile label={roofColor.tag.label} hexCode={roofColor.tag.hexCode} /> : "-"),
    }

    return [previewColumn, sortIndexColumn, optionCodeColumn, nameColumn, colorColumn, tagColumn]
}
