/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    FuelType,
    FuelTypeFromJSON,
    FuelTypeFromJSONTyped,
    FuelTypeToJSON,
} from './';

/**
 * 
 * @export
 * @interface SelectOptionDTOFuelType
 */
export interface SelectOptionDTOFuelType {
    /**
     * 
     * @type {FuelType}
     * @memberof SelectOptionDTOFuelType
     */
    key: FuelType;
    /**
     * 
     * @type {string}
     * @memberof SelectOptionDTOFuelType
     */
    label: string;
}

export function SelectOptionDTOFuelTypeFromJSON(json: any): SelectOptionDTOFuelType {
    return SelectOptionDTOFuelTypeFromJSONTyped(json, false);
}

export function SelectOptionDTOFuelTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): SelectOptionDTOFuelType {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'key': FuelTypeFromJSON(json['key']),
        'label': json['label'],
    };
}

export function SelectOptionDTOFuelTypeToJSON(value?: SelectOptionDTOFuelType | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'key': FuelTypeToJSON(value.key),
        'label': value.label,
    };
}


