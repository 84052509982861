/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SelectOptionDTOOrderTypeOptionTypeDTO,
    SelectOptionDTOOrderTypeOptionTypeDTOFromJSON,
    SelectOptionDTOOrderTypeOptionTypeDTOFromJSONTyped,
    SelectOptionDTOOrderTypeOptionTypeDTOToJSON,
} from './';

/**
 * 
 * @export
 * @interface OrderTypeOptionFilterOptionsDTO
 */
export interface OrderTypeOptionFilterOptionsDTO {
    /**
     * 
     * @type {Array<SelectOptionDTOOrderTypeOptionTypeDTO>}
     * @memberof OrderTypeOptionFilterOptionsDTO
     */
    types: Array<SelectOptionDTOOrderTypeOptionTypeDTO>;
}

export function OrderTypeOptionFilterOptionsDTOFromJSON(json: any): OrderTypeOptionFilterOptionsDTO {
    return OrderTypeOptionFilterOptionsDTOFromJSONTyped(json, false);
}

export function OrderTypeOptionFilterOptionsDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrderTypeOptionFilterOptionsDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'types': ((json['types'] as Array<any>).map(SelectOptionDTOOrderTypeOptionTypeDTOFromJSON)),
    };
}

export function OrderTypeOptionFilterOptionsDTOToJSON(value?: OrderTypeOptionFilterOptionsDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'types': ((value.types as Array<any>).map(SelectOptionDTOOrderTypeOptionTypeDTOToJSON)),
    };
}


