/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    OptionTagCreateRequestDTOEquipment,
    OptionTagCreateRequestDTOExteriorColor,
    OptionTagCreateRequestDTOInteriorColor,
    OptionTagCreateRequestDTORoofColor,
    OptionTagCreateRequestDTOEquipmentFromJSONTyped,
    OptionTagCreateRequestDTOEquipmentToJSON,
    OptionTagCreateRequestDTOExteriorColorFromJSONTyped,
    OptionTagCreateRequestDTOExteriorColorToJSON,
    OptionTagCreateRequestDTOInteriorColorFromJSONTyped,
    OptionTagCreateRequestDTOInteriorColorToJSON,
    OptionTagCreateRequestDTORoofColorFromJSONTyped,
    OptionTagCreateRequestDTORoofColorToJSON,
} from './';

/**
 * @type OptionTagCreateRequestDTO
 * 
 * @export
 */
export type OptionTagCreateRequestDTO = { type: 'Equipment' } & OptionTagCreateRequestDTOEquipment | { type: 'ExteriorColor' } & OptionTagCreateRequestDTOExteriorColor | { type: 'InteriorColor' } & OptionTagCreateRequestDTOInteriorColor | { type: 'RoofColor' } & OptionTagCreateRequestDTORoofColor;

export function OptionTagCreateRequestDTOFromJSON(json: any): OptionTagCreateRequestDTO {
    return OptionTagCreateRequestDTOFromJSONTyped(json, false);
}

export function OptionTagCreateRequestDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): OptionTagCreateRequestDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    switch (json['type']) {
        case 'Equipment':
            return {...OptionTagCreateRequestDTOEquipmentFromJSONTyped(json, true), type: 'Equipment'};
        case 'ExteriorColor':
            return {...OptionTagCreateRequestDTOExteriorColorFromJSONTyped(json, true), type: 'ExteriorColor'};
        case 'InteriorColor':
            return {...OptionTagCreateRequestDTOInteriorColorFromJSONTyped(json, true), type: 'InteriorColor'};
        case 'RoofColor':
            return {...OptionTagCreateRequestDTORoofColorFromJSONTyped(json, true), type: 'RoofColor'};
        default:
            throw new Error(`No variant of OptionTagCreateRequestDTO exists with 'type=${json['type']}'`);
    }
}

export function OptionTagCreateRequestDTOToJSON(value?: OptionTagCreateRequestDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    switch (value['type']) {
        case 'Equipment':
            return OptionTagCreateRequestDTOEquipmentToJSON(value);
        case 'ExteriorColor':
            return OptionTagCreateRequestDTOExteriorColorToJSON(value);
        case 'InteriorColor':
            return OptionTagCreateRequestDTOInteriorColorToJSON(value);
        case 'RoofColor':
            return OptionTagCreateRequestDTORoofColorToJSON(value);
        default:
            throw new Error(`No variant of OptionTagCreateRequestDTO exists with 'type=${value['type']}'`);
    }
}

