import { TableFilterTagsConfig } from "../../../../components/filteredpagedtable/FilteredPagedTable"
import { Tag } from "../../../../components/tag/Tag"
import { InteriorFilterOptionsDTO } from "../../../../generated/pdsapi"
import { InteriorFilter } from "../../../../pdsapi"
import { findGroupedOption, findOption } from "../../../../viewtemplates/list/filter/ListFilterTagsConfig"
import { JSX } from "react"

export const InteriorCatalogueFilterTagsConfig: TableFilterTagsConfig<InteriorFilter, InteriorFilterOptionsDTO> = ({ filterOptions, filter, setFilter }) => {
    const filterTags: JSX.Element[] = []

    filter.materials?.forEach((material) => {
        filterTags.push(
            <Tag
                key={`filterTag_material_${material}`}
                onRemove={() =>
                    setFilter((filter) => {
                        const newMaterials = filter.materials?.filter((key) => material !== key)
                        return {
                            ...filter,
                            materials: newMaterials?.length !== 0 ? newMaterials : undefined,
                        }
                    })
                }
            >
                {findOption(filterOptions?.materials, material)?.label ?? material}
            </Tag>
        )
    })

    filter.colorTagKeys?.forEach((colorTagKey) => {
        filterTags.push(
            <Tag
                key={`filterTag_interiorColorTagKey_${colorTagKey}`}
                onRemove={() =>
                    setFilter((filter) => {
                        const newColorTagKeys = filter.colorTagKeys?.filter((key) => colorTagKey !== key)
                        return {
                            ...filter,
                            colorTagKeys: newColorTagKeys?.length !== 0 ? newColorTagKeys : undefined,
                        }
                    })
                }
            >
                {findOption(filterOptions?.colorTags, colorTagKey)?.label ?? colorTagKey}
            </Tag>
        )
    })

    filter.modelGenerationKeys?.forEach((modelGenerationKey) => {
        filterTags.push(
            <Tag
                key={`filterTag_modelGenerationKey_${modelGenerationKey}`}
                onRemove={() =>
                    setFilter((filter) => {
                        const newModelGenerationKeys = filter.modelGenerationKeys?.filter((key) => modelGenerationKey !== key)
                        return {
                            ...filter,
                            modelGenerationKeys: newModelGenerationKeys?.length !== 0 ? newModelGenerationKeys : undefined,
                        }
                    })
                }
            >
                {findOption(filterOptions?.modelGenerations, modelGenerationKey)?.label ?? modelGenerationKey}
            </Tag>
        )
    })

    filter.modelYears?.forEach((modelYear) => {
        filterTags.push(
            <Tag
                key={`filterTag_modelYear_${modelYear}`}
                onRemove={() =>
                    setFilter((filter) => {
                        const newModelYears = filter.modelYears?.filter((my) => modelYear !== my)
                        return {
                            ...filter,
                            modelYears: newModelYears?.length !== 0 ? newModelYears : undefined,
                        }
                    })
                }
            >
                {modelYear}
            </Tag>
        )
    })

    filter.orderTypeKeys?.forEach((orderTypeKey) => {
        filterTags.push(
            <Tag
                key={`filterTag_orderType_${orderTypeKey}`}
                onRemove={() =>
                    setFilter((filter) => {
                        const newOrderTypeKeys = filter.orderTypeKeys?.filter((key) => key !== orderTypeKey)
                        return {
                            ...filter,
                            orderTypeKeys: newOrderTypeKeys?.length !== 0 ? newOrderTypeKeys : undefined,
                        }
                    })
                }
            >
                {findGroupedOption(filterOptions?.modelYearAndGenerationRelatedOrderTypes, orderTypeKey)?.label ?? orderTypeKey}
            </Tag>
        )
    })

    return filterTags
}
