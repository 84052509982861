/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    OptionTagTypeDTO,
    OptionTagTypeDTOFromJSON,
    OptionTagTypeDTOFromJSONTyped,
    OptionTagTypeDTOToJSON,
} from './';

/**
 * 
 * @export
 * @interface SelectOptionDTOOptionTagTypeDTO
 */
export interface SelectOptionDTOOptionTagTypeDTO {
    /**
     * 
     * @type {OptionTagTypeDTO}
     * @memberof SelectOptionDTOOptionTagTypeDTO
     */
    key: OptionTagTypeDTO;
    /**
     * 
     * @type {string}
     * @memberof SelectOptionDTOOptionTagTypeDTO
     */
    label: string;
}

export function SelectOptionDTOOptionTagTypeDTOFromJSON(json: any): SelectOptionDTOOptionTagTypeDTO {
    return SelectOptionDTOOptionTagTypeDTOFromJSONTyped(json, false);
}

export function SelectOptionDTOOptionTagTypeDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): SelectOptionDTOOptionTagTypeDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'key': OptionTagTypeDTOFromJSON(json['key']),
        'label': json['label'],
    };
}

export function SelectOptionDTOOptionTagTypeDTOToJSON(value?: SelectOptionDTOOptionTagTypeDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'key': OptionTagTypeDTOToJSON(value.key),
        'label': value.label,
    };
}


