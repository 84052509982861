import { derivativeApi } from "../../../../pdsapi"
import { getProductSortContainer, ProductSortContainerConfig } from "../../../../viewtemplates/sort/ProductSortContainer"
import { Route } from "react-router-dom"
import { routes } from "../../../../common/routes"
import { DerivativeProductListContainerConfig } from "../list/DerivativeListRoute"

export const DerivativeSortContainerTemplateProps: ProductSortContainerConfig = {
    fetchSortables: derivativeApi.sort.getSortableItems,
    updateSortIndices: (sortIndexUpdate) => derivativeApi.sort.updateSortIndices({ sortIndexUpdateRequestDTO: sortIndexUpdate }),
}

const DerivativeSortContainer = getProductSortContainer(DerivativeSortContainerTemplateProps)

export const DerivativeSortRoute = (
    <Route
        path={routes.models.derivatives.sort()}
        element={
            <DerivativeSortContainer
                parentCrumbs={[
                    {
                        name: DerivativeProductListContainerConfig.listName,
                        path: routes.models.derivatives.list(),
                    },
                ]}
            />
        }
    />
)
