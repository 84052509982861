import { getEnumConverterGroup, getObjectURLParamConverter, stringConverterGroup } from "../../../../common/useURLSearchParamState"
import {
    EquipmentCustomizationFilterOptionsDTO,
    EquipmentCustomizationItemDTO,
    EquipmentCustomizationSortField,
    SortDirection,
} from "../../../../generated/pdsapi"
import { equipmentCustomizationApi, EquipmentCustomizationFilter } from "../../../../pdsapi"
import { getProductListContainer, ProductListContainerConfig } from "../../../../viewtemplates/list/ProductListContainer"
import { EquipmentCustomizationListColumnsConfig } from "./columns/EquipmentCustomizationListColumns"
import { EquipmentCustomizationListFilter } from "./filter/EquipmentCustomizationListFilter"
import { EquipmentCustomizationListFilterTagsConfig } from "./filter/EquipmentCustomizationListFilterTagsConfig"
import { routes } from "../../../../common/routes"
import { Route } from "react-router-dom"

export const EquipmentCustomizationProductListContainerConfig: ProductListContainerConfig<
    EquipmentCustomizationItemDTO,
    EquipmentCustomizationFilter,
    EquipmentCustomizationFilterOptionsDTO,
    EquipmentCustomizationSortField
> = {
    listName: "Equipment Customizations",
    createItemLabel: "Add new Equipment Customization",

    buildDetailsPath: (equipmentCustomization) => routes.equipment.customizations.details(equipmentCustomization.key),
    buildCreatePath: routes.equipment.customizations.create,
    buildSortPath: undefined,

    defaultFilter: {},
    filterUrlParamsConverter: getObjectURLParamConverter<EquipmentCustomizationFilter>({
        optionCodes: stringConverterGroup.optionalArray,
        modelGenerationKeys: stringConverterGroup.optionalArray,
        equipmentTagKeys: stringConverterGroup.optionalArray,
    }),
    defaultSorting: {
        sortField: EquipmentCustomizationSortField.OPTION_CODE,
        sortDirection: SortDirection.ASC,
    },
    sortingUrlParamsConverter: getObjectURLParamConverter({
        sortField: getEnumConverterGroup(EquipmentCustomizationSortField).required,
        sortDirection: getEnumConverterGroup(SortDirection).required,
    }),
    fetchPage: equipmentCustomizationApi.list.getPage,
    fetchFilterOptions: equipmentCustomizationApi.list.getFilterOptions,

    defaultShowsFilter: false,
    Filter: EquipmentCustomizationListFilter,
    filterTagsConfig: EquipmentCustomizationListFilterTagsConfig,

    columnsConfig: EquipmentCustomizationListColumnsConfig,
}

const EquipmentCustomizationListContainer = getProductListContainer(EquipmentCustomizationProductListContainerConfig)

export const EquipmentCustomizationListRoute = <Route path={routes.equipment.customizations.list()} Component={EquipmentCustomizationListContainer} />
