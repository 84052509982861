import { DefaultCardViewContentWrapper } from "../../../../components/layouts/defaultcardviewcontentwrapper/DefaultCardViewContentWrapper"
import { OptionCustomizationCreateOptionsDTO, OptionCustomizationCreateRequestDTO } from "../../../../generated/pdsapi"
import { optionCustomizationApi } from "../../../../pdsapi"
import { getProductCreateContainer, ProductCreateContainerConfig } from "../../../../viewtemplates/create/ProductCreateContainer"
import { VehicleImagesCreateCard } from "../../../../viewtemplates/create/cards/vehicleimagescard/VehicleImagesCreateCard"
import { OptionCustomizationCreateAttributesCard } from "./attributes/OptionCustomizationCreateAttributesCard"
import { optionCustomizationTypeToEnum } from "../optionCustomizationTypes"
import { getEditData } from "../../../../components/formelements/FormDisplayData"
import { Route } from "react-router-dom"
import { routes } from "../../../../common/routes"
import { OptionCustomizationKey } from "../details/OptionCustomizationKey"
import { OptionCustomizationListContainerConfig } from "../list/OptionCustomizationListRoute"

export const OptionCustomizationProductCreateContainerConfig: ProductCreateContainerConfig<
    OptionCustomizationCreateRequestDTO,
    OptionCustomizationCreateOptionsDTO,
    OptionCustomizationKey
> = {
    headline: "Create new Option Customization",
    buildDetailsPath: routes.options.customizations.details,

    create: (item) =>
        optionCustomizationApi.create.create({
            optionCustomizationCreateRequestDTO: item,
        }),
    getCreateOptions: optionCustomizationApi.create.getCreateOptions,

    Content: (props) => {
        const type = getEditData(props.formDisplayData)?.watch("type")
        return (
            <DefaultCardViewContentWrapper>
                <OptionCustomizationCreateAttributesCard {...props} />
                {type && (
                    <VehicleImagesCreateCard
                        formDisplayData={props.formDisplayData}
                        getVehicleImages={(args) =>
                            optionCustomizationApi.create.getVehicleImages({
                                ...args,
                                type: optionCustomizationTypeToEnum(type),
                            })
                        }
                    />
                )}
            </DefaultCardViewContentWrapper>
        )
    },
}

const OptionCustomizationCreateContainer = getProductCreateContainer(OptionCustomizationProductCreateContainerConfig)

export const OptionCustomizationCreateRoute = (
    <Route
        path={routes.options.customizations.create()}
        element={
            <OptionCustomizationCreateContainer
                parentCrumbs={[
                    {
                        name: OptionCustomizationListContainerConfig.listName,
                        path: routes.options.customizations.list(),
                    },
                ]}
            />
        }
    />
)
