/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SelectOptionDTODriveTrain,
    SelectOptionDTODriveTrainFromJSON,
    SelectOptionDTODriveTrainFromJSONTyped,
    SelectOptionDTODriveTrainToJSON,
} from './';

/**
 * 
 * @export
 * @interface OptionalSelectDTODriveTrain
 */
export interface OptionalSelectDTODriveTrain {
    /**
     * 
     * @type {SelectOptionDTODriveTrain}
     * @memberof OptionalSelectDTODriveTrain
     */
    value?: SelectOptionDTODriveTrain;
    /**
     * 
     * @type {Array<SelectOptionDTODriveTrain>}
     * @memberof OptionalSelectDTODriveTrain
     */
    options: Array<SelectOptionDTODriveTrain>;
}

export function OptionalSelectDTODriveTrainFromJSON(json: any): OptionalSelectDTODriveTrain {
    return OptionalSelectDTODriveTrainFromJSONTyped(json, false);
}

export function OptionalSelectDTODriveTrainFromJSONTyped(json: any, ignoreDiscriminator: boolean): OptionalSelectDTODriveTrain {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'value': !exists(json, 'value') ? undefined : SelectOptionDTODriveTrainFromJSON(json['value']),
        'options': ((json['options'] as Array<any>).map(SelectOptionDTODriveTrainFromJSON)),
    };
}

export function OptionalSelectDTODriveTrainToJSON(value?: OptionalSelectDTODriveTrain | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'value': SelectOptionDTODriveTrainToJSON(value.value),
        'options': ((value.options as Array<any>).map(SelectOptionDTODriveTrainToJSON)),
    };
}


