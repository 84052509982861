import { booleanConverterGroup, getEnumConverterGroup, getObjectURLParamConverter } from "../../../../common/useURLSearchParamState"
import { EquipmentTagCategory, EquipmentTagFilterOptionsDTO, EquipmentTagItemDTO, OptionTagSortField, SortDirection } from "../../../../generated/pdsapi"
import { equipmentTagApi, EquipmentTagFilter } from "../../../../pdsapi"
import { getProductListContainer, ProductListContainerConfig } from "../../../../viewtemplates/list/ProductListContainer"
import { EquipmentTagListColumnsConfig } from "./columns/EquipmentTagListColumns"
import { EquipmentTagListFilter } from "./filter/EquipmentTagListFilter"
import { EquipmentTagListFilterTagsConfig } from "./filter/EquipmentTagListFilterTagsConfig"
import { routes } from "../../../../common/routes"
import { Route } from "react-router-dom"

export const EquipmentTagProductListContainerConfig: ProductListContainerConfig<
    EquipmentTagItemDTO,
    EquipmentTagFilter,
    EquipmentTagFilterOptionsDTO,
    OptionTagSortField
> = {
    listName: "Equipment Tags",
    createItemLabel: "Add new Equipment Tag",

    buildDetailsPath: (equipmentTag) => routes.equipment.tags.details(equipmentTag.key),
    buildCreatePath: routes.equipment.tags.create,
    buildSortPath: routes.equipment.tags.sort,

    defaultFilter: {},
    filterUrlParamsConverter: getObjectURLParamConverter<EquipmentTagFilter>({
        active: booleanConverterGroup.optional,
        categories: getEnumConverterGroup(EquipmentTagCategory).optionalArray,
    }),
    defaultSorting: {
        sortField: OptionTagSortField.SORT_INDEX,
        sortDirection: SortDirection.ASC,
    },
    sortingUrlParamsConverter: getObjectURLParamConverter({
        sortField: getEnumConverterGroup(OptionTagSortField).required,
        sortDirection: getEnumConverterGroup(SortDirection).required,
    }),
    fetchPage: equipmentTagApi.list.getPage,
    fetchFilterOptions: equipmentTagApi.list.getFilterOptions,

    defaultShowsFilter: false,
    Filter: EquipmentTagListFilter,
    filterTagsConfig: EquipmentTagListFilterTagsConfig,

    columnsConfig: EquipmentTagListColumnsConfig,
}

const EquipmentTagListContainer = getProductListContainer(EquipmentTagProductListContainerConfig)

export const EquipmentTagListRoute = <Route path={routes.equipment.tags.list()} Component={EquipmentTagListContainer} />
