/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    OptionCreateRequestDTOExteriorColor,
    OptionCreateRequestDTOPaintToSampleExteriorColor,
    OptionCreateRequestDTOExteriorColorFromJSONTyped,
    OptionCreateRequestDTOExteriorColorToJSON,
    OptionCreateRequestDTOPaintToSampleExteriorColorFromJSONTyped,
    OptionCreateRequestDTOPaintToSampleExteriorColorToJSON,
} from './';

/**
 * @type OptionCreateRequestDTO
 * 
 * @export
 */
export type OptionCreateRequestDTO = { type: 'ExteriorColor' } & OptionCreateRequestDTOExteriorColor | { type: 'PaintToSampleExteriorColor' } & OptionCreateRequestDTOPaintToSampleExteriorColor;

export function OptionCreateRequestDTOFromJSON(json: any): OptionCreateRequestDTO {
    return OptionCreateRequestDTOFromJSONTyped(json, false);
}

export function OptionCreateRequestDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): OptionCreateRequestDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    switch (json['type']) {
        case 'ExteriorColor':
            return {...OptionCreateRequestDTOExteriorColorFromJSONTyped(json, true), type: 'ExteriorColor'};
        case 'PaintToSampleExteriorColor':
            return {...OptionCreateRequestDTOPaintToSampleExteriorColorFromJSONTyped(json, true), type: 'PaintToSampleExteriorColor'};
        default:
            throw new Error(`No variant of OptionCreateRequestDTO exists with 'type=${json['type']}'`);
    }
}

export function OptionCreateRequestDTOToJSON(value?: OptionCreateRequestDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    switch (value['type']) {
        case 'ExteriorColor':
            return OptionCreateRequestDTOExteriorColorToJSON(value);
        case 'PaintToSampleExteriorColor':
            return OptionCreateRequestDTOPaintToSampleExteriorColorToJSON(value);
        default:
            throw new Error(`No variant of OptionCreateRequestDTO exists with 'type=${value['type']}'`);
    }
}

