import { DefaultCardViewContentWrapper } from "../../../../components/layouts/defaultcardviewcontentwrapper/DefaultCardViewContentWrapper"
import { CustomInteriorColorCreateOptionsDTO, CustomInteriorColorCreateRequestDTO } from "../../../../generated/pdsapi"
import { customInteriorColorApi } from "../../../../pdsapi"
import { getProductCreateContainer, ProductCreateContainerConfig } from "../../../../viewtemplates/create/ProductCreateContainer"
import { CustomInteriorColorCreateAttributesCard } from "./attributes/CustomInteriorColorCreateAttributesCard"
import { CustomInteriorColorCreateVehicleImagesCardContent } from "./vehicleimages/CustomInteriorColorCreateVehicleImagesCardContent"
import { routes } from "../../../../common/routes"
import { Route } from "react-router-dom"
import { CustomInteriorColorProductListContainerConfig } from "../list/CustomInteriorColorListRoute"

export const CustomInteriorColorProductCreateContainerConfig: ProductCreateContainerConfig<
    CustomInteriorColorCreateRequestDTO,
    CustomInteriorColorCreateOptionsDTO
> = {
    headline: "Create new Custom Interior Color",
    buildDetailsPath: ({ key }) => routes.interiors.customColors.details(key),

    create: (item) =>
        customInteriorColorApi.create.create({
            customInteriorColorCreateRequestDTO: item,
        }),
    getCreateOptions: customInteriorColorApi.create.getOptions,

    Content: (props) => (
        <>
            <DefaultCardViewContentWrapper>
                <CustomInteriorColorCreateAttributesCard {...props} />
            </DefaultCardViewContentWrapper>
            <DefaultCardViewContentWrapper>
                <CustomInteriorColorCreateVehicleImagesCardContent {...props} />
            </DefaultCardViewContentWrapper>
        </>
    ),
}

const CustomInteriorColorCreateContainer = getProductCreateContainer(CustomInteriorColorProductCreateContainerConfig)

export const CustomInteriorColorCreateRoute = (
    <Route
        path={routes.interiors.customColors.create()}
        element={
            <CustomInteriorColorCreateContainer
                parentCrumbs={[
                    {
                        name: CustomInteriorColorProductListContainerConfig.listName,
                        path: routes.interiors.customColors.list(),
                    },
                ]}
            />
        }
    />
)
