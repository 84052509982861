/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    EquipmentTagCategory,
    EquipmentTagCategoryFromJSON,
    EquipmentTagCategoryToJSON,
    OptionTagFilterOptionsDTO,
    OptionTagFilterOptionsDTOFromJSON,
    OptionTagFilterOptionsDTOToJSON,
    OptionTagSortField,
    OptionTagSortFieldFromJSON,
    OptionTagSortFieldToJSON,
    OptionTagTypeDTO,
    OptionTagTypeDTOFromJSON,
    OptionTagTypeDTOToJSON,
    PageDTOOptionTagItemDTO,
    PageDTOOptionTagItemDTOFromJSON,
    PageDTOOptionTagItemDTOToJSON,
    SortDirection,
    SortDirectionFromJSON,
    SortDirectionToJSON,
} from '../models';

export interface GetOptionTagFilterOptionsRequest {
    languageTag: string;
}

export interface GetOptionTagsPageRequest {
    languageTag: string;
    offset: number;
    size: number;
    sortField: OptionTagSortField;
    sortDirection: SortDirection;
    types?: Array<OptionTagTypeDTO>;
    equipmentTagCategories?: Array<EquipmentTagCategory>;
    search?: string;
    active?: boolean;
}

/**
 * 
 */
export class OptionTagListWebControllerApi extends runtime.BaseAPI {

    /**
     * Get options to filter OptionTags
     */
    async getOptionTagFilterOptionsRaw(requestParameters: GetOptionTagFilterOptionsRequest): Promise<runtime.ApiResponse<OptionTagFilterOptionsDTO>> {
        if (requestParameters.languageTag === null || requestParameters.languageTag === undefined) {
            throw new runtime.RequiredError('languageTag','Required parameter requestParameters.languageTag was null or undefined when calling getOptionTagFilterOptions.');
        }

        const queryParameters: any = {};

        if (requestParameters.languageTag !== undefined) {
            queryParameters['languageTag'] = requestParameters.languageTag;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/web/option-tags/list/filter-options`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => OptionTagFilterOptionsDTOFromJSON(jsonValue));
    }

    /**
     * Get options to filter OptionTags
     */
    async getOptionTagFilterOptions(requestParameters: GetOptionTagFilterOptionsRequest): Promise<OptionTagFilterOptionsDTO> {
        const response = await this.getOptionTagFilterOptionsRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get page of OptionTags
     */
    async getOptionTagsPageRaw(requestParameters: GetOptionTagsPageRequest): Promise<runtime.ApiResponse<PageDTOOptionTagItemDTO>> {
        if (requestParameters.languageTag === null || requestParameters.languageTag === undefined) {
            throw new runtime.RequiredError('languageTag','Required parameter requestParameters.languageTag was null or undefined when calling getOptionTagsPage.');
        }

        if (requestParameters.offset === null || requestParameters.offset === undefined) {
            throw new runtime.RequiredError('offset','Required parameter requestParameters.offset was null or undefined when calling getOptionTagsPage.');
        }

        if (requestParameters.size === null || requestParameters.size === undefined) {
            throw new runtime.RequiredError('size','Required parameter requestParameters.size was null or undefined when calling getOptionTagsPage.');
        }

        if (requestParameters.sortField === null || requestParameters.sortField === undefined) {
            throw new runtime.RequiredError('sortField','Required parameter requestParameters.sortField was null or undefined when calling getOptionTagsPage.');
        }

        if (requestParameters.sortDirection === null || requestParameters.sortDirection === undefined) {
            throw new runtime.RequiredError('sortDirection','Required parameter requestParameters.sortDirection was null or undefined when calling getOptionTagsPage.');
        }

        const queryParameters: any = {};

        if (requestParameters.languageTag !== undefined) {
            queryParameters['languageTag'] = requestParameters.languageTag;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.sortField !== undefined) {
            queryParameters['sortField'] = requestParameters.sortField;
        }

        if (requestParameters.sortDirection !== undefined) {
            queryParameters['sortDirection'] = requestParameters.sortDirection;
        }

        if (requestParameters.types) {
            queryParameters['types'] = requestParameters.types;
        }

        if (requestParameters.equipmentTagCategories) {
            queryParameters['equipmentTagCategories'] = requestParameters.equipmentTagCategories;
        }

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.active !== undefined) {
            queryParameters['active'] = requestParameters.active;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/web/option-tags/list/page`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PageDTOOptionTagItemDTOFromJSON(jsonValue));
    }

    /**
     * Get page of OptionTags
     */
    async getOptionTagsPage(requestParameters: GetOptionTagsPageRequest): Promise<PageDTOOptionTagItemDTO> {
        const response = await this.getOptionTagsPageRaw(requestParameters);
        return await response.value();
    }

}
