/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    EquipmentSortField,
    EquipmentSortFieldFromJSON,
    EquipmentSortFieldToJSON,
    ExteriorColorSortField,
    ExteriorColorSortFieldFromJSON,
    ExteriorColorSortFieldToJSON,
    ExteriorColorType,
    ExteriorColorTypeFromJSON,
    ExteriorColorTypeToJSON,
    InteriorMaterial,
    InteriorMaterialFromJSON,
    InteriorMaterialToJSON,
    InteriorSortField,
    InteriorSortFieldFromJSON,
    InteriorSortFieldToJSON,
    OptionSortField,
    OptionSortFieldFromJSON,
    OptionSortFieldToJSON,
    OrderTypeEquipmentFilterOptionsDTO,
    OrderTypeEquipmentFilterOptionsDTOFromJSON,
    OrderTypeEquipmentFilterOptionsDTOToJSON,
    OrderTypeExteriorColorFilterOptionsDTO,
    OrderTypeExteriorColorFilterOptionsDTOFromJSON,
    OrderTypeExteriorColorFilterOptionsDTOToJSON,
    OrderTypeInteriorFilterOptionsDTO,
    OrderTypeInteriorFilterOptionsDTOFromJSON,
    OrderTypeInteriorFilterOptionsDTOToJSON,
    OrderTypeOptionFilterOptionsDTO,
    OrderTypeOptionFilterOptionsDTOFromJSON,
    OrderTypeOptionFilterOptionsDTOToJSON,
    OrderTypeOptionTypeDTO,
    OrderTypeOptionTypeDTOFromJSON,
    OrderTypeOptionTypeDTOToJSON,
    OrderTypeRoofColorFilterOptionsDTO,
    OrderTypeRoofColorFilterOptionsDTOFromJSON,
    OrderTypeRoofColorFilterOptionsDTOToJSON,
    PageDTOOrderTypeEquipmentItemDTO,
    PageDTOOrderTypeEquipmentItemDTOFromJSON,
    PageDTOOrderTypeEquipmentItemDTOToJSON,
    PageDTOOrderTypeInteriorItemDTO,
    PageDTOOrderTypeInteriorItemDTOFromJSON,
    PageDTOOrderTypeInteriorItemDTOToJSON,
    PageDTOOrderTypeOptionItemDTO,
    PageDTOOrderTypeOptionItemDTOFromJSON,
    PageDTOOrderTypeOptionItemDTOToJSON,
    PageDTOOrderTypeOptionItemDTOExteriorColor,
    PageDTOOrderTypeOptionItemDTOExteriorColorFromJSON,
    PageDTOOrderTypeOptionItemDTOExteriorColorToJSON,
    PageDTOOrderTypeOptionItemDTOPaintToSampleExteriorColor,
    PageDTOOrderTypeOptionItemDTOPaintToSampleExteriorColorFromJSON,
    PageDTOOrderTypeOptionItemDTOPaintToSampleExteriorColorToJSON,
    PageDTOOrderTypeRoofColorItemDTO,
    PageDTOOrderTypeRoofColorItemDTOFromJSON,
    PageDTOOrderTypeRoofColorItemDTOToJSON,
    PaintToSampleExteriorColorSortField,
    PaintToSampleExteriorColorSortFieldFromJSON,
    PaintToSampleExteriorColorSortFieldToJSON,
    SimpleApiError,
    SimpleApiErrorFromJSON,
    SimpleApiErrorToJSON,
    SortDirection,
    SortDirectionFromJSON,
    SortDirectionToJSON,
    VehicleEquipmentCategory,
    VehicleEquipmentCategoryFromJSON,
    VehicleEquipmentCategoryToJSON,
} from '../models';

export interface GetAllOfOrderTypeRequest {
    key: string;
    languageTag: string;
    offset: number;
    size: number;
    sortField: OptionSortField;
    sortDirection: SortDirection;
    types?: Array<OrderTypeOptionTypeDTO>;
    search?: string;
}

export interface GetEquipmentOfOrderTypeRequest {
    key: string;
    languageTag: string;
    offset: number;
    size: number;
    sortField: EquipmentSortField;
    sortDirection: SortDirection;
    search?: string;
    categories?: Array<VehicleEquipmentCategory>;
    tagKeys?: Array<string>;
    families?: Array<string>;
}

export interface GetExteriorColorsOfOrderTypeRequest {
    key: string;
    languageTag: string;
    offset: number;
    size: number;
    sortField: ExteriorColorSortField;
    sortDirection: SortDirection;
    search?: string;
    colorTypes?: Array<ExteriorColorType>;
    tagKeys?: Array<string>;
}

export interface GetFilterOptionsOfAllOfOrderTypeRequest {
    key: string;
    languageTag: string;
}

export interface GetFilterOptionsOfEquipmentOfOrderTypeRequest {
    key: string;
    languageTag: string;
}

export interface GetFilterOptionsOfExteriorColorsOfOrderTypeRequest {
    key: string;
    languageTag: string;
}

export interface GetFilterOptionsOfInteriorsOfOrderTypeRequest {
    key: string;
    languageTag: string;
}

export interface GetFilterOptionsOfRoofColorsOfOrderTypeRequest {
    key: string;
    languageTag: string;
}

export interface GetInteriorsOfOrderTypeRequest {
    key: string;
    languageTag: string;
    offset: number;
    size: number;
    sortField: InteriorSortField;
    sortDirection: SortDirection;
    search?: string;
    materials?: Array<InteriorMaterial>;
    colorTagKeys?: Array<string>;
}

export interface GetPaintToSampleExteriorColorsOfOrderTypeRequest {
    key: string;
    languageTag: string;
    offset: number;
    size: number;
    sortField: PaintToSampleExteriorColorSortField;
    sortDirection: SortDirection;
    search?: string;
}

export interface GetRoofColorsOfOrderTypeRequest {
    key: string;
    languageTag: string;
    offset: number;
    size: number;
    sortField: OptionSortField;
    sortDirection: SortDirection;
    search?: string;
    tagKeys?: Array<string>;
}

/**
 * 
 */
export class OrderTypeOptionsWebControllerApi extends runtime.BaseAPI {

    /**
     * Get all of OrderType with given key
     */
    async getAllOfOrderTypeRaw(requestParameters: GetAllOfOrderTypeRequest): Promise<runtime.ApiResponse<PageDTOOrderTypeOptionItemDTO>> {
        if (requestParameters.key === null || requestParameters.key === undefined) {
            throw new runtime.RequiredError('key','Required parameter requestParameters.key was null or undefined when calling getAllOfOrderType.');
        }

        if (requestParameters.languageTag === null || requestParameters.languageTag === undefined) {
            throw new runtime.RequiredError('languageTag','Required parameter requestParameters.languageTag was null or undefined when calling getAllOfOrderType.');
        }

        if (requestParameters.offset === null || requestParameters.offset === undefined) {
            throw new runtime.RequiredError('offset','Required parameter requestParameters.offset was null or undefined when calling getAllOfOrderType.');
        }

        if (requestParameters.size === null || requestParameters.size === undefined) {
            throw new runtime.RequiredError('size','Required parameter requestParameters.size was null or undefined when calling getAllOfOrderType.');
        }

        if (requestParameters.sortField === null || requestParameters.sortField === undefined) {
            throw new runtime.RequiredError('sortField','Required parameter requestParameters.sortField was null or undefined when calling getAllOfOrderType.');
        }

        if (requestParameters.sortDirection === null || requestParameters.sortDirection === undefined) {
            throw new runtime.RequiredError('sortDirection','Required parameter requestParameters.sortDirection was null or undefined when calling getAllOfOrderType.');
        }

        const queryParameters: any = {};

        if (requestParameters.languageTag !== undefined) {
            queryParameters['languageTag'] = requestParameters.languageTag;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.sortField !== undefined) {
            queryParameters['sortField'] = requestParameters.sortField;
        }

        if (requestParameters.sortDirection !== undefined) {
            queryParameters['sortDirection'] = requestParameters.sortDirection;
        }

        if (requestParameters.types) {
            queryParameters['types'] = requestParameters.types;
        }

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/web/order-types/details/{key}/options/all`.replace(`{${"key"}}`, encodeURIComponent(String(requestParameters.key))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PageDTOOrderTypeOptionItemDTOFromJSON(jsonValue));
    }

    /**
     * Get all of OrderType with given key
     */
    async getAllOfOrderType(requestParameters: GetAllOfOrderTypeRequest): Promise<PageDTOOrderTypeOptionItemDTO> {
        const response = await this.getAllOfOrderTypeRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get equipment of OrderType with given key
     */
    async getEquipmentOfOrderTypeRaw(requestParameters: GetEquipmentOfOrderTypeRequest): Promise<runtime.ApiResponse<PageDTOOrderTypeEquipmentItemDTO>> {
        if (requestParameters.key === null || requestParameters.key === undefined) {
            throw new runtime.RequiredError('key','Required parameter requestParameters.key was null or undefined when calling getEquipmentOfOrderType.');
        }

        if (requestParameters.languageTag === null || requestParameters.languageTag === undefined) {
            throw new runtime.RequiredError('languageTag','Required parameter requestParameters.languageTag was null or undefined when calling getEquipmentOfOrderType.');
        }

        if (requestParameters.offset === null || requestParameters.offset === undefined) {
            throw new runtime.RequiredError('offset','Required parameter requestParameters.offset was null or undefined when calling getEquipmentOfOrderType.');
        }

        if (requestParameters.size === null || requestParameters.size === undefined) {
            throw new runtime.RequiredError('size','Required parameter requestParameters.size was null or undefined when calling getEquipmentOfOrderType.');
        }

        if (requestParameters.sortField === null || requestParameters.sortField === undefined) {
            throw new runtime.RequiredError('sortField','Required parameter requestParameters.sortField was null or undefined when calling getEquipmentOfOrderType.');
        }

        if (requestParameters.sortDirection === null || requestParameters.sortDirection === undefined) {
            throw new runtime.RequiredError('sortDirection','Required parameter requestParameters.sortDirection was null or undefined when calling getEquipmentOfOrderType.');
        }

        const queryParameters: any = {};

        if (requestParameters.languageTag !== undefined) {
            queryParameters['languageTag'] = requestParameters.languageTag;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.sortField !== undefined) {
            queryParameters['sortField'] = requestParameters.sortField;
        }

        if (requestParameters.sortDirection !== undefined) {
            queryParameters['sortDirection'] = requestParameters.sortDirection;
        }

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.categories) {
            queryParameters['categories'] = requestParameters.categories;
        }

        if (requestParameters.tagKeys) {
            queryParameters['tagKeys'] = requestParameters.tagKeys;
        }

        if (requestParameters.families) {
            queryParameters['families'] = requestParameters.families;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/web/order-types/details/{key}/options/equipment`.replace(`{${"key"}}`, encodeURIComponent(String(requestParameters.key))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PageDTOOrderTypeEquipmentItemDTOFromJSON(jsonValue));
    }

    /**
     * Get equipment of OrderType with given key
     */
    async getEquipmentOfOrderType(requestParameters: GetEquipmentOfOrderTypeRequest): Promise<PageDTOOrderTypeEquipmentItemDTO> {
        const response = await this.getEquipmentOfOrderTypeRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get exteriorColors of OrderType with given key
     */
    async getExteriorColorsOfOrderTypeRaw(requestParameters: GetExteriorColorsOfOrderTypeRequest): Promise<runtime.ApiResponse<PageDTOOrderTypeOptionItemDTOExteriorColor>> {
        if (requestParameters.key === null || requestParameters.key === undefined) {
            throw new runtime.RequiredError('key','Required parameter requestParameters.key was null or undefined when calling getExteriorColorsOfOrderType.');
        }

        if (requestParameters.languageTag === null || requestParameters.languageTag === undefined) {
            throw new runtime.RequiredError('languageTag','Required parameter requestParameters.languageTag was null or undefined when calling getExteriorColorsOfOrderType.');
        }

        if (requestParameters.offset === null || requestParameters.offset === undefined) {
            throw new runtime.RequiredError('offset','Required parameter requestParameters.offset was null or undefined when calling getExteriorColorsOfOrderType.');
        }

        if (requestParameters.size === null || requestParameters.size === undefined) {
            throw new runtime.RequiredError('size','Required parameter requestParameters.size was null or undefined when calling getExteriorColorsOfOrderType.');
        }

        if (requestParameters.sortField === null || requestParameters.sortField === undefined) {
            throw new runtime.RequiredError('sortField','Required parameter requestParameters.sortField was null or undefined when calling getExteriorColorsOfOrderType.');
        }

        if (requestParameters.sortDirection === null || requestParameters.sortDirection === undefined) {
            throw new runtime.RequiredError('sortDirection','Required parameter requestParameters.sortDirection was null or undefined when calling getExteriorColorsOfOrderType.');
        }

        const queryParameters: any = {};

        if (requestParameters.languageTag !== undefined) {
            queryParameters['languageTag'] = requestParameters.languageTag;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.sortField !== undefined) {
            queryParameters['sortField'] = requestParameters.sortField;
        }

        if (requestParameters.sortDirection !== undefined) {
            queryParameters['sortDirection'] = requestParameters.sortDirection;
        }

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.colorTypes) {
            queryParameters['colorTypes'] = requestParameters.colorTypes;
        }

        if (requestParameters.tagKeys) {
            queryParameters['tagKeys'] = requestParameters.tagKeys;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/web/order-types/details/{key}/options/exterior-colors`.replace(`{${"key"}}`, encodeURIComponent(String(requestParameters.key))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PageDTOOrderTypeOptionItemDTOExteriorColorFromJSON(jsonValue));
    }

    /**
     * Get exteriorColors of OrderType with given key
     */
    async getExteriorColorsOfOrderType(requestParameters: GetExteriorColorsOfOrderTypeRequest): Promise<PageDTOOrderTypeOptionItemDTOExteriorColor> {
        const response = await this.getExteriorColorsOfOrderTypeRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get filterOptionsOfAll of OrderType with given key
     */
    async getFilterOptionsOfAllOfOrderTypeRaw(requestParameters: GetFilterOptionsOfAllOfOrderTypeRequest): Promise<runtime.ApiResponse<OrderTypeOptionFilterOptionsDTO>> {
        if (requestParameters.key === null || requestParameters.key === undefined) {
            throw new runtime.RequiredError('key','Required parameter requestParameters.key was null or undefined when calling getFilterOptionsOfAllOfOrderType.');
        }

        if (requestParameters.languageTag === null || requestParameters.languageTag === undefined) {
            throw new runtime.RequiredError('languageTag','Required parameter requestParameters.languageTag was null or undefined when calling getFilterOptionsOfAllOfOrderType.');
        }

        const queryParameters: any = {};

        if (requestParameters.languageTag !== undefined) {
            queryParameters['languageTag'] = requestParameters.languageTag;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/web/order-types/details/{key}/options/all/filter-options`.replace(`{${"key"}}`, encodeURIComponent(String(requestParameters.key))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => OrderTypeOptionFilterOptionsDTOFromJSON(jsonValue));
    }

    /**
     * Get filterOptionsOfAll of OrderType with given key
     */
    async getFilterOptionsOfAllOfOrderType(requestParameters: GetFilterOptionsOfAllOfOrderTypeRequest): Promise<OrderTypeOptionFilterOptionsDTO> {
        const response = await this.getFilterOptionsOfAllOfOrderTypeRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get filterOptionsOfEquipment of OrderType with given key
     */
    async getFilterOptionsOfEquipmentOfOrderTypeRaw(requestParameters: GetFilterOptionsOfEquipmentOfOrderTypeRequest): Promise<runtime.ApiResponse<OrderTypeEquipmentFilterOptionsDTO>> {
        if (requestParameters.key === null || requestParameters.key === undefined) {
            throw new runtime.RequiredError('key','Required parameter requestParameters.key was null or undefined when calling getFilterOptionsOfEquipmentOfOrderType.');
        }

        if (requestParameters.languageTag === null || requestParameters.languageTag === undefined) {
            throw new runtime.RequiredError('languageTag','Required parameter requestParameters.languageTag was null or undefined when calling getFilterOptionsOfEquipmentOfOrderType.');
        }

        const queryParameters: any = {};

        if (requestParameters.languageTag !== undefined) {
            queryParameters['languageTag'] = requestParameters.languageTag;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/web/order-types/details/{key}/options/equipment/filter-options`.replace(`{${"key"}}`, encodeURIComponent(String(requestParameters.key))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => OrderTypeEquipmentFilterOptionsDTOFromJSON(jsonValue));
    }

    /**
     * Get filterOptionsOfEquipment of OrderType with given key
     */
    async getFilterOptionsOfEquipmentOfOrderType(requestParameters: GetFilterOptionsOfEquipmentOfOrderTypeRequest): Promise<OrderTypeEquipmentFilterOptionsDTO> {
        const response = await this.getFilterOptionsOfEquipmentOfOrderTypeRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get filterOptionsOfExteriorColors of OrderType with given key
     */
    async getFilterOptionsOfExteriorColorsOfOrderTypeRaw(requestParameters: GetFilterOptionsOfExteriorColorsOfOrderTypeRequest): Promise<runtime.ApiResponse<OrderTypeExteriorColorFilterOptionsDTO>> {
        if (requestParameters.key === null || requestParameters.key === undefined) {
            throw new runtime.RequiredError('key','Required parameter requestParameters.key was null or undefined when calling getFilterOptionsOfExteriorColorsOfOrderType.');
        }

        if (requestParameters.languageTag === null || requestParameters.languageTag === undefined) {
            throw new runtime.RequiredError('languageTag','Required parameter requestParameters.languageTag was null or undefined when calling getFilterOptionsOfExteriorColorsOfOrderType.');
        }

        const queryParameters: any = {};

        if (requestParameters.languageTag !== undefined) {
            queryParameters['languageTag'] = requestParameters.languageTag;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/web/order-types/details/{key}/options/exterior-colors/filter-options`.replace(`{${"key"}}`, encodeURIComponent(String(requestParameters.key))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => OrderTypeExteriorColorFilterOptionsDTOFromJSON(jsonValue));
    }

    /**
     * Get filterOptionsOfExteriorColors of OrderType with given key
     */
    async getFilterOptionsOfExteriorColorsOfOrderType(requestParameters: GetFilterOptionsOfExteriorColorsOfOrderTypeRequest): Promise<OrderTypeExteriorColorFilterOptionsDTO> {
        const response = await this.getFilterOptionsOfExteriorColorsOfOrderTypeRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get filterOptionsOfInteriors of OrderType with given key
     */
    async getFilterOptionsOfInteriorsOfOrderTypeRaw(requestParameters: GetFilterOptionsOfInteriorsOfOrderTypeRequest): Promise<runtime.ApiResponse<OrderTypeInteriorFilterOptionsDTO>> {
        if (requestParameters.key === null || requestParameters.key === undefined) {
            throw new runtime.RequiredError('key','Required parameter requestParameters.key was null or undefined when calling getFilterOptionsOfInteriorsOfOrderType.');
        }

        if (requestParameters.languageTag === null || requestParameters.languageTag === undefined) {
            throw new runtime.RequiredError('languageTag','Required parameter requestParameters.languageTag was null or undefined when calling getFilterOptionsOfInteriorsOfOrderType.');
        }

        const queryParameters: any = {};

        if (requestParameters.languageTag !== undefined) {
            queryParameters['languageTag'] = requestParameters.languageTag;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/web/order-types/details/{key}/options/interiors/filter-options`.replace(`{${"key"}}`, encodeURIComponent(String(requestParameters.key))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => OrderTypeInteriorFilterOptionsDTOFromJSON(jsonValue));
    }

    /**
     * Get filterOptionsOfInteriors of OrderType with given key
     */
    async getFilterOptionsOfInteriorsOfOrderType(requestParameters: GetFilterOptionsOfInteriorsOfOrderTypeRequest): Promise<OrderTypeInteriorFilterOptionsDTO> {
        const response = await this.getFilterOptionsOfInteriorsOfOrderTypeRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get filterOptionsOfRoofColors of OrderType with given key
     */
    async getFilterOptionsOfRoofColorsOfOrderTypeRaw(requestParameters: GetFilterOptionsOfRoofColorsOfOrderTypeRequest): Promise<runtime.ApiResponse<OrderTypeRoofColorFilterOptionsDTO>> {
        if (requestParameters.key === null || requestParameters.key === undefined) {
            throw new runtime.RequiredError('key','Required parameter requestParameters.key was null or undefined when calling getFilterOptionsOfRoofColorsOfOrderType.');
        }

        if (requestParameters.languageTag === null || requestParameters.languageTag === undefined) {
            throw new runtime.RequiredError('languageTag','Required parameter requestParameters.languageTag was null or undefined when calling getFilterOptionsOfRoofColorsOfOrderType.');
        }

        const queryParameters: any = {};

        if (requestParameters.languageTag !== undefined) {
            queryParameters['languageTag'] = requestParameters.languageTag;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/web/order-types/details/{key}/options/roof-colors/filter-options`.replace(`{${"key"}}`, encodeURIComponent(String(requestParameters.key))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => OrderTypeRoofColorFilterOptionsDTOFromJSON(jsonValue));
    }

    /**
     * Get filterOptionsOfRoofColors of OrderType with given key
     */
    async getFilterOptionsOfRoofColorsOfOrderType(requestParameters: GetFilterOptionsOfRoofColorsOfOrderTypeRequest): Promise<OrderTypeRoofColorFilterOptionsDTO> {
        const response = await this.getFilterOptionsOfRoofColorsOfOrderTypeRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get interiors of OrderType with given key
     */
    async getInteriorsOfOrderTypeRaw(requestParameters: GetInteriorsOfOrderTypeRequest): Promise<runtime.ApiResponse<PageDTOOrderTypeInteriorItemDTO>> {
        if (requestParameters.key === null || requestParameters.key === undefined) {
            throw new runtime.RequiredError('key','Required parameter requestParameters.key was null or undefined when calling getInteriorsOfOrderType.');
        }

        if (requestParameters.languageTag === null || requestParameters.languageTag === undefined) {
            throw new runtime.RequiredError('languageTag','Required parameter requestParameters.languageTag was null or undefined when calling getInteriorsOfOrderType.');
        }

        if (requestParameters.offset === null || requestParameters.offset === undefined) {
            throw new runtime.RequiredError('offset','Required parameter requestParameters.offset was null or undefined when calling getInteriorsOfOrderType.');
        }

        if (requestParameters.size === null || requestParameters.size === undefined) {
            throw new runtime.RequiredError('size','Required parameter requestParameters.size was null or undefined when calling getInteriorsOfOrderType.');
        }

        if (requestParameters.sortField === null || requestParameters.sortField === undefined) {
            throw new runtime.RequiredError('sortField','Required parameter requestParameters.sortField was null or undefined when calling getInteriorsOfOrderType.');
        }

        if (requestParameters.sortDirection === null || requestParameters.sortDirection === undefined) {
            throw new runtime.RequiredError('sortDirection','Required parameter requestParameters.sortDirection was null or undefined when calling getInteriorsOfOrderType.');
        }

        const queryParameters: any = {};

        if (requestParameters.languageTag !== undefined) {
            queryParameters['languageTag'] = requestParameters.languageTag;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.sortField !== undefined) {
            queryParameters['sortField'] = requestParameters.sortField;
        }

        if (requestParameters.sortDirection !== undefined) {
            queryParameters['sortDirection'] = requestParameters.sortDirection;
        }

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.materials) {
            queryParameters['materials'] = requestParameters.materials;
        }

        if (requestParameters.colorTagKeys) {
            queryParameters['colorTagKeys'] = requestParameters.colorTagKeys;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/web/order-types/details/{key}/options/interiors`.replace(`{${"key"}}`, encodeURIComponent(String(requestParameters.key))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PageDTOOrderTypeInteriorItemDTOFromJSON(jsonValue));
    }

    /**
     * Get interiors of OrderType with given key
     */
    async getInteriorsOfOrderType(requestParameters: GetInteriorsOfOrderTypeRequest): Promise<PageDTOOrderTypeInteriorItemDTO> {
        const response = await this.getInteriorsOfOrderTypeRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get paintToSampleExteriorColors of OrderType with given key
     */
    async getPaintToSampleExteriorColorsOfOrderTypeRaw(requestParameters: GetPaintToSampleExteriorColorsOfOrderTypeRequest): Promise<runtime.ApiResponse<PageDTOOrderTypeOptionItemDTOPaintToSampleExteriorColor>> {
        if (requestParameters.key === null || requestParameters.key === undefined) {
            throw new runtime.RequiredError('key','Required parameter requestParameters.key was null or undefined when calling getPaintToSampleExteriorColorsOfOrderType.');
        }

        if (requestParameters.languageTag === null || requestParameters.languageTag === undefined) {
            throw new runtime.RequiredError('languageTag','Required parameter requestParameters.languageTag was null or undefined when calling getPaintToSampleExteriorColorsOfOrderType.');
        }

        if (requestParameters.offset === null || requestParameters.offset === undefined) {
            throw new runtime.RequiredError('offset','Required parameter requestParameters.offset was null or undefined when calling getPaintToSampleExteriorColorsOfOrderType.');
        }

        if (requestParameters.size === null || requestParameters.size === undefined) {
            throw new runtime.RequiredError('size','Required parameter requestParameters.size was null or undefined when calling getPaintToSampleExteriorColorsOfOrderType.');
        }

        if (requestParameters.sortField === null || requestParameters.sortField === undefined) {
            throw new runtime.RequiredError('sortField','Required parameter requestParameters.sortField was null or undefined when calling getPaintToSampleExteriorColorsOfOrderType.');
        }

        if (requestParameters.sortDirection === null || requestParameters.sortDirection === undefined) {
            throw new runtime.RequiredError('sortDirection','Required parameter requestParameters.sortDirection was null or undefined when calling getPaintToSampleExteriorColorsOfOrderType.');
        }

        const queryParameters: any = {};

        if (requestParameters.languageTag !== undefined) {
            queryParameters['languageTag'] = requestParameters.languageTag;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.sortField !== undefined) {
            queryParameters['sortField'] = requestParameters.sortField;
        }

        if (requestParameters.sortDirection !== undefined) {
            queryParameters['sortDirection'] = requestParameters.sortDirection;
        }

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/web/order-types/details/{key}/options/paint-to-sample-exterior-colors`.replace(`{${"key"}}`, encodeURIComponent(String(requestParameters.key))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PageDTOOrderTypeOptionItemDTOPaintToSampleExteriorColorFromJSON(jsonValue));
    }

    /**
     * Get paintToSampleExteriorColors of OrderType with given key
     */
    async getPaintToSampleExteriorColorsOfOrderType(requestParameters: GetPaintToSampleExteriorColorsOfOrderTypeRequest): Promise<PageDTOOrderTypeOptionItemDTOPaintToSampleExteriorColor> {
        const response = await this.getPaintToSampleExteriorColorsOfOrderTypeRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get roofColors of OrderType with given key
     */
    async getRoofColorsOfOrderTypeRaw(requestParameters: GetRoofColorsOfOrderTypeRequest): Promise<runtime.ApiResponse<PageDTOOrderTypeRoofColorItemDTO>> {
        if (requestParameters.key === null || requestParameters.key === undefined) {
            throw new runtime.RequiredError('key','Required parameter requestParameters.key was null or undefined when calling getRoofColorsOfOrderType.');
        }

        if (requestParameters.languageTag === null || requestParameters.languageTag === undefined) {
            throw new runtime.RequiredError('languageTag','Required parameter requestParameters.languageTag was null or undefined when calling getRoofColorsOfOrderType.');
        }

        if (requestParameters.offset === null || requestParameters.offset === undefined) {
            throw new runtime.RequiredError('offset','Required parameter requestParameters.offset was null or undefined when calling getRoofColorsOfOrderType.');
        }

        if (requestParameters.size === null || requestParameters.size === undefined) {
            throw new runtime.RequiredError('size','Required parameter requestParameters.size was null or undefined when calling getRoofColorsOfOrderType.');
        }

        if (requestParameters.sortField === null || requestParameters.sortField === undefined) {
            throw new runtime.RequiredError('sortField','Required parameter requestParameters.sortField was null or undefined when calling getRoofColorsOfOrderType.');
        }

        if (requestParameters.sortDirection === null || requestParameters.sortDirection === undefined) {
            throw new runtime.RequiredError('sortDirection','Required parameter requestParameters.sortDirection was null or undefined when calling getRoofColorsOfOrderType.');
        }

        const queryParameters: any = {};

        if (requestParameters.languageTag !== undefined) {
            queryParameters['languageTag'] = requestParameters.languageTag;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.sortField !== undefined) {
            queryParameters['sortField'] = requestParameters.sortField;
        }

        if (requestParameters.sortDirection !== undefined) {
            queryParameters['sortDirection'] = requestParameters.sortDirection;
        }

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.tagKeys) {
            queryParameters['tagKeys'] = requestParameters.tagKeys;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/web/order-types/details/{key}/options/roof-colors`.replace(`{${"key"}}`, encodeURIComponent(String(requestParameters.key))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PageDTOOrderTypeRoofColorItemDTOFromJSON(jsonValue));
    }

    /**
     * Get roofColors of OrderType with given key
     */
    async getRoofColorsOfOrderType(requestParameters: GetRoofColorsOfOrderTypeRequest): Promise<PageDTOOrderTypeRoofColorItemDTO> {
        const response = await this.getRoofColorsOfOrderTypeRaw(requestParameters);
        return await response.value();
    }

}
