import { TechnicalDataAttribute } from "../../generated/pdsapi"
import { TechnicalDataMenuItemIndex } from "./TechnicalDataMenu"

export interface TechnicalDataMenuItemGroup {
    label: string
    items: TechnicalDataMenuItem[]
}

export interface TechnicalDataMenuItem {
    label: string
    relatedAttributes: TechnicalDataAttribute[]
}

export const technicalDataMenuItemGroups: TechnicalDataMenuItemGroup[] = [
    {
        label: "Engine",
        items: [
            {
                label: "Displacement",
                relatedAttributes: [TechnicalDataAttribute.ENGINE_DISPLACEMENT],
            },
            {
                label: "Number of Cylinders",
                relatedAttributes: [TechnicalDataAttribute.ENGINE_NUMBER_OF_CYLINDERS],
            },
            {
                label: "Max Torque",
                relatedAttributes: [
                    TechnicalDataAttribute.ENGINE_MAX_TORQUE_COMBINED,
                    TechnicalDataAttribute.ENGINE_MAX_TORQUE_COMBUSTION_ENGINE,
                    TechnicalDataAttribute.ENGINE_MAX_TORQUE_COMBUSTION_ENGINE_WITH_OVERBOOST,
                    TechnicalDataAttribute.ENGINE_MAX_TORQUE_ELECTRIC_ENGINE,
                    TechnicalDataAttribute.ENGINE_MAX_TORQUE_LAUNCH_CONTROL,
                ],
            },
            {
                label: "Max Torque at RPM",
                relatedAttributes: [
                    TechnicalDataAttribute.ENGINE_MAX_TORQUE_AT_RPM_COMBINED_VALUE,
                    TechnicalDataAttribute.ENGINE_MAX_TORQUE_AT_RPM_COMBINED_RANGE,
                    TechnicalDataAttribute.ENGINE_MAX_TORQUE_AT_RPM_COMBUSTION_ENGINE_VALUE,
                    TechnicalDataAttribute.ENGINE_MAX_TORQUE_AT_RPM_COMBUSTION_ENGINE_RANGE,
                    TechnicalDataAttribute.ENGINE_MAX_TORQUE_AT_RPM_COMBUSTION_ENGINE_WITH_OVERBOOST_VALUE,
                    TechnicalDataAttribute.ENGINE_MAX_TORQUE_AT_RPM_COMBUSTION_ENGINE_WITH_OVERBOOST_RANGE,
                    TechnicalDataAttribute.ENGINE_MAX_TORQUE_AT_RPM_ELECTRIC_ENGINE_VALUE,
                    TechnicalDataAttribute.ENGINE_MAX_TORQUE_AT_RPM_ELECTRIC_ENGINE_RANGE,
                ],
            },
            {
                label: "Power",
                relatedAttributes: [
                    TechnicalDataAttribute.ENGINE_POWER_COMBINED_KILOWATTS,
                    TechnicalDataAttribute.ENGINE_POWER_COMBINED_HORSEPOWER,
                    TechnicalDataAttribute.ENGINE_POWER_COMBINED_BRAKE_HORSEPOWER,
                    TechnicalDataAttribute.ENGINE_POWER_COMBUSTION_ENGINE_KILOWATTS,
                    TechnicalDataAttribute.ENGINE_POWER_COMBUSTION_ENGINE_HORSEPOWER,
                    TechnicalDataAttribute.ENGINE_POWER_COMBUSTION_ENGINE_BRAKE_HORSEPOWER,
                    TechnicalDataAttribute.ENGINE_POWER_ELECTRIC_ENGINE_KILOWATTS,
                    TechnicalDataAttribute.ENGINE_POWER_ELECTRIC_ENGINE_HORSEPOWER,
                    TechnicalDataAttribute.ENGINE_POWER_ELECTRIC_ENGINE_BRAKE_HORSEPOWER,
                    TechnicalDataAttribute.ENGINE_POWER_UP_TO_KILOWATTS,
                    TechnicalDataAttribute.ENGINE_POWER_UP_TO_HORSEPOWER,
                    TechnicalDataAttribute.ENGINE_POWER_UP_TO_BRAKE_HORSEPOWER,
                    TechnicalDataAttribute.ENGINE_POWER_OVERBOOST_LAUNCH_CONTROL_UP_TO_KILOWATTS,
                    TechnicalDataAttribute.ENGINE_POWER_OVERBOOST_LAUNCH_CONTROL_UP_TO_HORSEPOWER,
                    TechnicalDataAttribute.ENGINE_POWER_OVERBOOST_LAUNCH_CONTROL_UP_TO_BRAKE_HORSEPOWER,
                ],
            },
            {
                label: "Max Power at RPM",
                relatedAttributes: [
                    TechnicalDataAttribute.ENGINE_MAX_POWER_AT_RPM_COMBINED_VALUE,
                    TechnicalDataAttribute.ENGINE_MAX_POWER_AT_RPM_COMBINED_RANGE,
                    TechnicalDataAttribute.ENGINE_MAX_POWER_AT_RPM_COMBUSTION_ENGINE_VALUE,
                    TechnicalDataAttribute.ENGINE_MAX_POWER_AT_RPM_COMBUSTION_ENGINE_RANGE,
                    TechnicalDataAttribute.ENGINE_MAX_POWER_AT_RPM_ELECTRIC_ENGINE_VALUE,
                    TechnicalDataAttribute.ENGINE_MAX_POWER_AT_RPM_ELECTRIC_ENGINE_RANGE,
                ],
            },
            {
                label: "Max RPM",
                relatedAttributes: [TechnicalDataAttribute.ENGINE_MAX_RPM],
            },
            {
                label: "Engine Layout",
                relatedAttributes: [TechnicalDataAttribute.ENGINE_ENGINE_LAYOUT],
            },
            {
                label: "Engine Design",
                relatedAttributes: [TechnicalDataAttribute.ENGINE_ENGINE_DESIGN],
            },
            {
                label: "Transmission",
                relatedAttributes: [TechnicalDataAttribute.ENGINE_TRANSMISSION],
            },
            {
                label: "Fuel Type",
                relatedAttributes: [TechnicalDataAttribute.ENGINE_FUEL_TYPE],
            },
            {
                label: "Engine Bore",
                relatedAttributes: [TechnicalDataAttribute.ENGINE_ENGINE_BORE],
            },
            {
                label: "Engine Stroke",
                relatedAttributes: [TechnicalDataAttribute.ENGINE_ENGINE_STROKE],
            },
            {
                label: "Max Power per Liter",
                relatedAttributes: [
                    TechnicalDataAttribute.ENGINE_MAX_POWER_PER_LITER_COMBUSTION_ENGINE_KILOWATTS_PER_LITER,
                    TechnicalDataAttribute.ENGINE_MAX_POWER_PER_LITER_COMBUSTION_ENGINE_HORSEPOWER_PER_LITER,
                ],
            },
        ],
    },
    {
        label: "Performance",
        items: [
            {
                label: "Top Speed",
                relatedAttributes: [TechnicalDataAttribute.PERFORMANCE_TOP_SPEED],
            },
            {
                label: "Electric Top Speed",
                relatedAttributes: [TechnicalDataAttribute.PERFORMANCE_ELECTRICAL_TOP_SPEED],
            },
            {
                label: "Acceleration",
                relatedAttributes: [
                    TechnicalDataAttribute.PERFORMANCE_ACCELERATION_FROM_0_TO_100_KILOMETERS_PER_HOUR,
                    TechnicalDataAttribute.PERFORMANCE_ACCELERATION_FROM_0_TO_100_KILOMETERS_PER_HOUR_LAUNCH_CONTROL,
                    TechnicalDataAttribute.PERFORMANCE_ACCELERATION_FROM_0_TO_100_KILOMETERS_PER_HOUR_SPORT_CHRONO,
                    TechnicalDataAttribute.PERFORMANCE_ACCELERATION_FROM_0_TO_160_KILOMETERS_PER_HOUR,
                    TechnicalDataAttribute.PERFORMANCE_ACCELERATION_FROM_0_TO_160_KILOMETERS_PER_HOUR_LAUNCH_CONTROL,
                    TechnicalDataAttribute.PERFORMANCE_ACCELERATION_FROM_0_TO_160_KILOMETERS_PER_HOUR_SPORT_CHRONO,
                    TechnicalDataAttribute.PERFORMANCE_ACCELERATION_FROM_0_TO_200_KILOMETERS_PER_HOUR,
                    TechnicalDataAttribute.PERFORMANCE_ACCELERATION_FROM_0_TO_200_KILOMETERS_PER_HOUR_LAUNCH_CONTROL,
                    TechnicalDataAttribute.PERFORMANCE_ACCELERATION_FROM_0_TO_200_KILOMETERS_PER_HOUR_SPORT_CHRONO,
                    TechnicalDataAttribute.PERFORMANCE_ACCELERATION_FROM_80_TO_120_KILOMETERS_PER_HOUR_IN_GEAR,
                    TechnicalDataAttribute.PERFORMANCE_ACCELERATION_FROM_0_TO_300_KILOMETERS_PER_HOUR,
                    TechnicalDataAttribute.PERFORMANCE_ACCELERATION_FROM_80_TO_120_KILOMETERS_PER_HOUR_IN_GEAR_SPORT_CHRONO,
                    TechnicalDataAttribute.PERFORMANCE_ACCELERATION_FROM_80_TO_120_KILOMETERS_PER_HOUR_ELASTICITY_5TH_GEAR,
                    TechnicalDataAttribute.PERFORMANCE_ACCELERATION_FROM_0_TO_60_MILES_PER_HOUR,
                    TechnicalDataAttribute.PERFORMANCE_ACCELERATION_FROM_0_TO_60_MILES_PER_HOUR_LAUNCH_CONTROL,
                    TechnicalDataAttribute.PERFORMANCE_ACCELERATION_FROM_0_TO_60_MILES_PER_HOUR_SPORT_CHRONO,
                    TechnicalDataAttribute.PERFORMANCE_ACCELERATION_QUARTER_MILE,
                    TechnicalDataAttribute.PERFORMANCE_ACCELERATION_QUARTER_MILE_SPORT_CHRONO,
                ],
            },
        ],
    },
    {
        label: "Body",
        items: [
            {
                label: "Unladen Weight",
                relatedAttributes: [
                    TechnicalDataAttribute.BODY_UNLADEN_WEIGHT_DIN,
                    TechnicalDataAttribute.BODY_UNLADEN_WEIGHT_EC,
                    TechnicalDataAttribute.BODY_UNLADEN_WEIGHT_INDIVIDUAL_VEHICLE,
                ],
            },
            {
                label: "Load",
                relatedAttributes: [TechnicalDataAttribute.BODY_LOAD_MAXIMUM_LOAD, TechnicalDataAttribute.BODY_LOAD_MAXIMUM_ROOF_LOAD],
            },
            {
                label: "Permissible Gross Weight",
                relatedAttributes: [TechnicalDataAttribute.BODY_PERMISSIBLE_GROSS_WEIGHT],
            },
            {
                label: "Length",
                relatedAttributes: [TechnicalDataAttribute.BODY_LENGTH],
            },
            {
                label: "Width",
                relatedAttributes: [TechnicalDataAttribute.BODY_WIDTH_WITH_MIRRORS, TechnicalDataAttribute.BODY_WIDTH_WITHOUT_MIRRORS],
            },
            {
                label: "Height",
                relatedAttributes: [TechnicalDataAttribute.BODY_HEIGHT],
            },
            {
                label: "Wheel Base",
                relatedAttributes: [TechnicalDataAttribute.BODY_WHEEL_BASE],
            },
            {
                label: "Track",
                relatedAttributes: [TechnicalDataAttribute.BODY_TRACK_FRONT, TechnicalDataAttribute.BODY_TRACK_REAR],
            },
            {
                label: "Turning Radius",
                relatedAttributes: [TechnicalDataAttribute.BODY_TURNING_RADIUS],
            },
            {
                label: "Fuel Tank Capacity",
                relatedAttributes: [TechnicalDataAttribute.BODY_FUEL_TANK_CAPACITY],
            },
            {
                label: "Luggage",
                relatedAttributes: [
                    TechnicalDataAttribute.BODY_LUGGAGE_VOLUME,
                    TechnicalDataAttribute.BODY_LUGGAGE_VOLUME_SEATS_FOLDED,
                    TechnicalDataAttribute.BODY_LUGGAGE_FRONT_VOLUME,
                    TechnicalDataAttribute.BODY_LUGGAGE_REAR_VOLUME,
                    TechnicalDataAttribute.BODY_LUGGAGE_MAX_VOLUME_REAR,
                ],
            },
            {
                label: "Drag Coefficient",
                relatedAttributes: [TechnicalDataAttribute.BODY_DRAG_COEFFICIENT],
            },
            {
                label: "Trailer Coupling",
                relatedAttributes: [
                    TechnicalDataAttribute.BODY_TRAILER_COUPLING_MAX_TOWING_LOAD_BRAKED,
                    TechnicalDataAttribute.BODY_TRAILER_COUPLING_MAX_TOWING_LOAD_UNBRAKED,
                    TechnicalDataAttribute.BODY_TRAILER_COUPLING_MAX_VERTICAL_LOAD,
                ],
            },
            {
                label: "Max Wading Depth",
                relatedAttributes: [
                    TechnicalDataAttribute.BODY_MAX_WADING_DEPTH_STEEL_SUSPENSION,
                    TechnicalDataAttribute.BODY_MAX_WADING_DEPTH_AIR_SUSPENSION_HIGH_LEVEL,
                ],
            },
            {
                label: "Approach Departure Angle",
                relatedAttributes: [
                    TechnicalDataAttribute.BODY_APPROACH_DEPARTURE_ANGLE_STEEL_SUSPENSION_FRONT,
                    TechnicalDataAttribute.BODY_APPROACH_DEPARTURE_ANGLE_STEEL_SUSPENSION_REAR,
                    TechnicalDataAttribute.BODY_APPROACH_DEPARTURE_ANGLE_AIR_SUSPENSION_HIGH_LEVEL_FRONT,
                    TechnicalDataAttribute.BODY_APPROACH_DEPARTURE_ANGLE_AIR_SUSPENSION_HIGH_LEVEL_REAR,
                ],
            },
            {
                label: "Ramp Break Over Angle",
                relatedAttributes: [
                    TechnicalDataAttribute.BODY_RAMP_BREAK_OVER_ANGLE_STEEL_SUSPENSION,
                    TechnicalDataAttribute.BODY_RAMP_BREAK_OVER_ANGLE_AIR_SUSPENSION_HIGH_LEVEL,
                ],
            },
            {
                label: "Max Ground Clearance",
                relatedAttributes: [
                    TechnicalDataAttribute.BODY_MAX_GROUND_CLEARANCE_STEEL_SUSPENSION,
                    TechnicalDataAttribute.BODY_MAX_GROUND_CLEARANCE_AIR_SUSPENSION_NORMAL_LEVEL,
                    TechnicalDataAttribute.BODY_MAX_GROUND_CLEARANCE_AIR_SUSPENSION_HIGH_LEVEL,
                ],
            },
        ],
    },
    {
        label: "Consumption Emission",
        items: [
            {
                label: "Fuel Consumption",
                relatedAttributes: [
                    TechnicalDataAttribute.CONSUMPTION_EMISSION_GENERAL_FUEL_CONSUMPTION_COMBINED,
                    TechnicalDataAttribute.CONSUMPTION_EMISSION_GENERAL_FUEL_CONSUMPTION_URBAN,
                    TechnicalDataAttribute.CONSUMPTION_EMISSION_GENERAL_FUEL_CONSUMPTION_EXTRA_URBAN,
                ],
            },
            {
                label: "Electricity Consumption",
                relatedAttributes: [TechnicalDataAttribute.CONSUMPTION_EMISSION_GENERAL_ELECTRICITY_CONSUMPTION_COMBINED],
            },
            {
                label: "CO2 Emissions",
                relatedAttributes: [TechnicalDataAttribute.CONSUMPTION_EMISSION_GENERAL_CO2_EMISSIONS],
            },
        ],
    },
    {
        label: "Charging",
        items: [
            {
                label: "Battery Capacity",
                relatedAttributes: [TechnicalDataAttribute.CHARGING_BATTERY_CAPACITY_GROSS, TechnicalDataAttribute.CHARGING_BATTERY_CAPACITY_NET],
            },
            {
                label: "Charging Time",
                relatedAttributes: [
                    TechnicalDataAttribute.CHARGING_CHARGING_TIME_AC_9POINT6_KW_TO_100_PERCENT,
                    TechnicalDataAttribute.CHARGING_CHARGING_TIME_AC_11_KW_TO_100_PERCENT,
                    TechnicalDataAttribute.CHARGING_CHARGING_TIME_DC_50_KW_UP_TO_100_KM,
                    TechnicalDataAttribute.CHARGING_CHARGING_TIME_DC_270_KW_UP_TO_100_KM,
                    TechnicalDataAttribute.CHARGING_CHARGING_TIME_DC_50_KW_5_TO_80_PERCENT,
                    TechnicalDataAttribute.CHARGING_CHARGING_TIME_DC_270_KW_5_TO_80_PERCENT,
                    TechnicalDataAttribute.CHARGING_CHARGING_TIME_DC_120_KW_10_TO_80_PERCENT,
                    TechnicalDataAttribute.CHARGING_CHARGING_TIME_DC_150_KW_10_TO_80_PERCENT,
                    TechnicalDataAttribute.CHARGING_CHARGING_TIME_DC_MAX_KW_10_TO_80_PERCENT,
                    TechnicalDataAttribute.CHARGING_CHARGING_TIME_DC_400_A_10_TO_80_PERCENT,
                ],
            },
            {
                label: "Max Charging Power DC",
                relatedAttributes: [TechnicalDataAttribute.CHARGING_MAX_CHARGING_POWER_DC],
            },
        ],
    },
    {
        label: "Range",
        items: [
            {
                label: "EPA",
                relatedAttributes: [TechnicalDataAttribute.RANGE_EPA],
            },
            {
                label: "ECE",
                relatedAttributes: [TechnicalDataAttribute.RANGE_ECE_ELECTRICALCOMBINED],
            },
            {
                label: "NRCan",
                relatedAttributes: [TechnicalDataAttribute.RANGE_NRCAN],
            },
        ],
    },
]

export const technicalDataAttributeIndices: Record<string, TechnicalDataMenuItemIndex> = {}

technicalDataMenuItemGroups.forEach((group, groupIndex) => {
    group.items.forEach((item, itemIndex) => {
        item.relatedAttributes.forEach((attribute) => {
            technicalDataAttributeIndices[attribute] = { groupIndex, itemIndex }
        })
    })
})
